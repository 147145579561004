import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import config from "../../../config";
import api from "../../../api";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
import useAuth from "function/useAuth";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const MasterConfig = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [id, setId] = useState("");
  const [status, setStatus] = useState("ACTIVE");
  const [value, setValue] = useState("");
  const [type, setType] = useState("");
  const [typeList, setTypeList] = useState([]);

  // Create
  const [configId, setConfigId] = useState("");
  const [configType, setConfigType] = useState("");
  const [configTypeTh, setConfigTypeTh] = useState("");
  const [configValue1, setConfigValue1] = useState("");
  const [configDesc1, setConfigDesc1] = useState("");
  const [configValue2, setConfigValue2] = useState("");
  const [configDesc2, setConfigDesc2] = useState("");
  const [configStatus, setConfigStatus] = useState("ACTIVE");
  const [openEdit, setOpenEdit] = useState(false);

  const [popupAdd, setPopupAdd] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const statusList = [
    { name: "ใช้งาน", value: "ACTIVE" },
    { name: "ไม่ได้ใช้งาน", value: "INACTIVE" },
  ];

  useAuth();

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  function getUserData() {
    setIsLoading(true);
    const getUserString = localStorage.getItem("userData");
    if (getUserString) {
      const getUser = JSON.parse(getUserString);
      setUser(getUser);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  function searchData() {
    api
      .post(`${config.mainAPI}/searchMasterConfig`, {
        id: id,
        status: status,
        value: value,
        role: user?.role,
        type: type,
      })
      .then(function (response) {
        const value = response.data.data;
        console.log("value searchData", value);
        setList(value);
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              item.config_id,
              item.config_value1,
              item.config_type,
              item.status
            )
          )
        );
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  }

  const addEditConfig = () => {
    setPopupAdd(true);
  };

  // insertUpdateConfigMaster
  const handleSubmit = () => {
    const payload = {
      mode: isEdit ? "edit" : "add",
      role: user.role,
      id: configId,
      type: configType,
      typeTh: configTypeTh,
      value: configValue1,
      desc: configDesc1,
      value2: configValue2,
      desc2: configDesc2,
      status: configStatus,
    };
    api
      .post(`${config.mainAPI}/addEditMasterConfig`, {
        ...payload,
      })
      .then(function (response) {
        setPopupAdd(false);
        setIsLoading(false);
        clearDataCreateConfig();
      })
      .catch(function (error) {
        setPopupAdd(false);
        setIsLoading(false);
      });
  };

  const handleCancelPopup = () => {
    setPopupAdd(false);
    clearDataCreateConfig();
  };

  const clearDataCreateConfig = () => {
    setConfigId("");
    setConfigType("");
    setConfigTypeTh("");
    setConfigValue1("");
    setConfigDesc1("");
    setConfigValue2("");
    setConfigDesc2("");
    setConfigStatus("");
    setIsEdit(false);
  };

  const getTypeConfig = () => {
    api
      .post(`${config.mainAPI}/listTypeConfig`, {
        role: JSON.parse(localStorage.getItem("userData")).role,
      })
      .then(function (response) {
        const value = response.data.data;
        const data = value.map((item) => ({
          name: item.config_type_th,
          value: item.config_type,
        }));
        console.log("dat", data);
        setTypeList(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  function clearDataSearch() {
    setId("");
    setStatus("");
    setValue("");
    setType("");
    setList([]);
  }

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(order, id, value, type, status) {
    return {
      order,
      id,
      value,
      type,
      status,
      id,
      id,
      id,
    };
  }

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    { id: "id", label: "รหัส", minWidth: 100 },
    { id: "value", label: "ผลลัพธ์", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    {
      id: "edit",
      label: "แก้ไข",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckDetail(row)}>
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckDetail(row)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  function handleCheckDetail(row) {
    let value = list.find((item) => (item.config_id = row.id));
    setIsEdit(true);
    setPopupAdd(true);
    setConfigId(value.config_id ? value.config_id : "");
    setConfigType(value.config_type ? value.config_type : "");
    setConfigTypeTh(value.config_type_th ? value.config_type_th : "");
    setConfigValue1(value.config_value1 ? value.config_value1 : "");
    setConfigDesc1(value.config_value1_desc ? value.config_value1_desc : "");
    setConfigDesc2(value.config_value2 ? value.config_value2 : "");
    setConfigValue2(value.config_value2_desc ? value.config_value2_desc : "");
    setConfigStatus(value.status ? value.status : "");
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getUserData();
    getTypeConfig();
  }, []);

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", mt: 3 }}
        >
          จัดการทั้งหมด
        </Typography>
        <Box sx={{ padding: "30px" }}>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ค้นหาข้อมูล"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", alignSelf: "center" }}
                      >
                        รหัส:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        color="dark"
                        value={id}
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        size="small"
                        onChange={(e) => setId(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", alignSelf: "center" }}
                      >
                        ผลลัพธ์:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        color="dark"
                        value={value}
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        size="small"
                        onChange={(e) => setValue(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", alignSelf: "center" }}
                      >
                        ประเภท:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {typeList &&
                          typeList.map((column) => (
                            <MenuItem key={column.value} value={column.value}>
                              {column.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          alignSelf: "center",
                          placeSelf: "center",
                        }}
                      >
                        สถานะ:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {statusList &&
                          statusList.map((column) => (
                            <MenuItem key={column.value} value={column.value}>
                              {column.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={clearDataSearch}
                    sx={{ marginRight: 2 }}
                  >
                    ล้างค่า
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={searchData}
                    sx={{ marginRight: 2 }}
                  >
                    ค้นหา
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<AddCircleIcon />}
                    onClick={addEditConfig}
                  >
                    สร้าง
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        {list && list.length > 0 ? (
          <>
            <Box sx={{ padding: "30px" }}>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลลูกหนี้"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "30px",
                        }}
                      >
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align="center"
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredRows
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                  <TableRow key={row.order}>
                                    {columns.map((column) => (
                                      <TableCell key={column.id} align="center">
                                        {column.render
                                          ? column.render(row)
                                          : row[column.id]}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </>
        ) : null}
      </Card>

      <Dialog open={popupAdd} fullWidth maxWidth={"md"}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCancelPopup}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Typography
            variant="h3"
            sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
          >
            เพิ่มรายการ
          </Typography>
          <Box>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px" }}>รหัส:</Typography>
                <TextField
                  type="text"
                  color="dark"
                  value={configId}
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setConfigId(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>ประเภท(EN):</Typography>
                <TextField
                  type="text"
                  color="dark"
                  value={configType}
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setConfigType(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>ประเภท(ไทย):</Typography>
                <TextField
                  type="text"
                  color="dark"
                  value={configTypeTh}
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setConfigTypeTh(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>ผลลัพธ์:</Typography>
                <TextField
                  type="text"
                  color="dark"
                  value={configValue1}
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setConfigValue1(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>คำอธิบาย:</Typography>
                <TextField
                  type="text"
                  color="dark"
                  value={configDesc1}
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setConfigDesc1(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>ผลลัพธ์2:</Typography>
                <TextField
                  type="text"
                  color="dark"
                  value={configValue2}
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setConfigValue2(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>คำอธิบาย2:</Typography>
                <TextField
                  type="text"
                  color="dark"
                  value={configDesc2}
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setConfigDesc2(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                <Select
                  value={configStatus}
                  onChange={(e) => setConfigStatus(e.target.value)}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <MenuItem key="ACTIVE" value="ACTIVE">
                    ใช้งาน
                  </MenuItem>
                  <MenuItem key="INACTIVE" value="INACTIVE">
                    ไม่ได้ใช้งาน
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="contained"
              color="error"
              sx={{ mr: 3 }}
              onClick={handleCancelPopup}
            >
              ยกเลิก
            </Button>
            <Button variant="contained" color="success" onClick={handleSubmit}>
              บันทึก
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* <Dialog open={openMessage} fullWidth maxWidth={"xs"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseMessage}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box textAlign="center">
              <ErrorIcon sx={{ color: "#ff2929", fontSize: 100 }} />
            </Box>
            <Typography
              variant="h1"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              ข้อความแจ้งเตือน
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#1f1f1f",
              }}
            >
              หากลบแล้วเอกสารจะหายไป คุณต้องการที่จะลบเอกสารนี้ใช่หรือไม่?
            </Typography>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ mr: 3 }}
                onClick={handleDeleteForm}
              >
                ลบ
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseDelete}
              >
                ปิด
              </Button>
            </Box>
          </DialogContent>
        </Dialog> */}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default MasterConfig;
