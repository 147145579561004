import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import config from "../../config";
import api from "../../api";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
import useAuth from "../../function/useAuth";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";

const CalendarView = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [idCard, setIdCard] = useState("");
  const [gsbNumber, setGsbNumber] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openFirstTimeLogin, setOpenFirstTimeLogin] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const localizer = momentLocalizer(moment);
  const [eventCalendar, setEventCalendar] = useState([]);
  const [textCalendar, setTextCalendar] = useState("");

  const typeList = [
    { id: 0, name: "สินเชื่อ", value: "loan" },
    { id: 1, name: "ขับไล่", value: "expal" },
    { id: 2, name: "บัตรเครดิต", value: "credit" },
    { id: 3, name: "บังคับคดี", value: "led" },
    { id: 4, name: "ล้มละลาย", value: "lose" },
    { id: 5, name: "ฟื้นฟูกิจการ", value: "heal" },
  ];

  const handleCloseOpenWarning = () => {
    setOpenWarning(false);
  };

  const handleCloseOpenFirstTimeLogin = () => {
    setOpenFirstTimeLogin(false);
  };

  const handleSubmitSetNewPassword = () => {
    console.log("eiei");
    setIsLoading(true);
    console.log("user.username", user.username);
    console.log("newPassword", newPassword);
    api
      .post(`${config.mainAPI}/updateNewPasswordFirstTimeLogin`, {
        username: user.username,
        password: newPassword,
      })
      .then(function (response) {
        setOpenFirstTimeLogin(false);
        setIsLoading(false);
        localStorage.removeItem("user_data");
        localStorage.removeItem("userData");
        localStorage.removeItem("isLoggedIn");
        window.location.reload();
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  // useAuth();

  function searchData() {
    if (type) {
      axios
        .post(`${config.mainAPI}/searchDataInformation`, {
          name: name,
          idCard: idCard,
          type: type,
          gsbNumber: gsbNumber,
          role: user.role,
          debtCenter: user.debtCenter,
        })
        .then(function (response) {
          const value = response.data.data;
          console.log("value searchData", value);
          setList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.defendant_fullname,
                item.defendant_type,
                item.document_code_gen
              )
            )
          );
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    } else {
      // Error
      setOpenWarning(true);
    }
  }

  function clearDataSearch() {
    setName("");
    setIdCard("");
    setGsbNumber("");
    setType("");
    setList([]);
  }

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(order, list, type, code) {
    return {
      order,
      list,
      type,
      code,
    };
  }


  function handleCheckDetail(row) {
    if (row.type == "สินเชื่อ") {
      navigate("/loan/detail", { state: { params: row.code } });
    } else if (row.type == "ขับไล่") {
      navigate("/expal/detail", { state: { params: row.code } });
    } else if (row.type == "บัตรเครดิต") {
      navigate("/credit/detail", { state: { params: row.code } });
    }
  }
  const getDataIntoCalendar = () => {
    axios
      .get(`${config.mainAPI}/setDataIntoCalendar`)
      .then((response) => {
        const value = response?.data.data;
        console.log("value", value);

        // value.map((item) => )
        value.map((item) => {
          const date = new Date(item.go_court_date);
          const timeParts = item.go_court_time ? item.go_court_time.split(":") : null;
          // Extract hours and minutes from the time string
          const hours = timeParts ? parseInt(timeParts[0], 10) : null;
          const minutes = timeParts ? parseInt(timeParts[1], 10) : null;

          // Set the hours and minutes on the existing date object
          date.setHours(hours);
          date.setMinutes(minutes);

          let sum = {
            title: `${item.go_court_type} ${item.go_court_place ? item.go_court_place : ""}`,
            start: date,
            end: date,
            details: `${item.go_court_detail}`,
          };
          setEventCalendar((item1) => [...item1, sum]);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log("usereieina", userData);
    if (userData) {
      setUser(userData);
      userData.firstTimeLogin == 0
        ? setOpenFirstTimeLogin(true)
        : setOpenFirstTimeLogin(false);
    }
    getDataIntoCalendar();
  }, []);

  const openCalendarEvent = (event) => {
    setOpenCalendar(true);
    const text = `คดี${event.title} วันที่: ${moment(event.start)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm:ss")} ${
      event.detail ? `รายละเอียด: ${event.detail}` : ``
    }`;
    setTextCalendar(text);
  };

  const closeCalendarEvent = () => {
    setOpenCalendar(false);
    setTextCalendar("");
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? "red" : event.color,
    };
    return {
      style,
    };
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", mt: 3 }}
        >
          ปฏิทินการปฏิบัติงาน
        </Typography>
        {user.role != "bank" ? (
          <>
            <Box sx={{ padding: 5 }}>
              {/* <Typography
                sx={{
                  fontSize: "18px",
                  alignSelf: "center",
                  placeSelf: "center",
                  textAlign: "center",
                  fontWeight: 700,
                  mb: 3,
                }}
              >
                ปฏิทินการปฏิบัติงาน
              </Typography> */}
              <Calendar
                localizer={localizer}
                events={eventCalendar}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 700 }}
                eventPropGetter={eventStyleGetter}
                onSelectEvent={(event) => openCalendarEvent(event)}
              />
            </Box>
          </>
        ) : null}
      </Card>


      <Dialog open={openCalendar} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            รายละเอียด
          </Typography>
          <Typography sx={{ mb: 3, fontSize: 16,mt:3 }} textAlign="center">
            {textCalendar}
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={closeCalendarEvent}
                sx={{ marginRight: 2 }}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>


      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default CalendarView;
