import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Ensure this import is correct
import moment from "moment";
import "moment-timezone";
import "moment/locale/th"; // Import Thai locale

const CheckTokenExpiration = ({ children }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const checkTokenValidity = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return false; // No token, considered not valid
    }

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      const expirationTime1 = moment(decoded.exp * 1000).tz("Asia/Bangkok"); // Convert exp to milliseconds, create Moment object, and set to Bangkok time zone
      console.log(
        "Token expiration time (Thai):",
        expirationTime1.format("LLLL")
      );

      if (decoded.exp < currentTime) {
        // Token expired
        return false;
      }else{
        // console.log('eiei');
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }

    return true; // Token is valid
  };

  useEffect(() => {
    if (!checkTokenValidity()) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      localStorage.removeItem("isLoggedIn");
      setShouldRedirect(true); // Update to trigger redirection
    }
  }, [location]); // Ensure location is defined or use a different dependency

  if (shouldRedirect) {
    // Conditionally render the Navigate component based on state
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default CheckTokenExpiration;
