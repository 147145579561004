import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment/moment';
import {
    TextField,
    Card,
    Button,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    IconButton,
    Select,
    MenuItem,
    Grid,
    Backdrop,
    CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import UnpublishedRoundedIcon from '@mui/icons-material/UnpublishedRounded';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const Documents = () => {
    const [user, setUser] = useState([]);
    const [document, setDocument] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [province, setProvince] = useState('');
    const [listProvince, setListProvince] = useState(null);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [filePath, setFilePath] = useState('');
    const [checkFile, setCheckFile] = useState(false);
    const [type, setType] = useState('');
    const [fileExport, setFileExport] = useState('');
    const [code, setCode] = useState('');
    const [nameCreate, setNameCreate] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Search
    const [findDefendent, setFindDefendent] = useState('');
    const [findDate, setFindDate] = useState('');
    const [findProvince, setFindProvince] = useState('');
    const [findDebtCenter, setFindDebtCenter] = useState('');
    const [findCourtCode, setFindCourtCode] = useState('');
    const [findCompanyCode, setFindCompanyCode] = useState('');

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        console.log('usereieina', userData);
        setUser(userData);
        getDocument(userData);
    }, []);
    const navigate = useNavigate();

    // function getData() {
    //     // const id = value.hospital_id;
    //     // const getrole = value.user_role;
    //     // console.log('getrole', getrole);
    //     // let status = 0;
    //     // if (getrole === 'director hospital') {
    //     //     status = 1;
    //     // } else if (getrole === 'officer') {
    //     //     status = 2;
    //     // } else if (getrole === 'assistant') {
    //     //     status = 3;
    //     // } else if (getrole === 'director') {
    //     //     status = 4;
    //     // }
    //     axios
    //         .get(`http://localhost:6969/documents`)
    //         .then((response) => {
    //             console.log('response', response);
    //             // console.log(response.data.data);
    //             // const value = response.data.data;
    //             // setRows(
    //             //     value.map((item, index) =>
    //             //         createData(
    //             //             index + 1,
    //             //             item.created_at,
    //             //             item.document_code,
    //             //             item.document_title,
    //             //             item.document_file,
    //             //             item.created_by,
    //             //             item.document_detail,
    //             //             item.document_file_path
    //             //         )
    //             //     )
    //             // );
    //             // Rest of the code
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }

    function handleDownload() {
        setIsLoading(true);
        axios
            .get(`${config.mainAPI}/export-documents-excel`)
            .then((response) => {
                const download_url = `${config.mainAPI}/download-documents-excel`;
                window.location.href = download_url;
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
    }

    function getDocument(userData) {
        setIsLoading(true);
        axios
            .get(`${config.mainAPI}/documents/${userData.id}/${userData.role}`)
            .then((response) => {
                console.log(response.data.data);
                const value = response.data.data;
                setRows(
                    value.map((item, index) =>
                        createData(
                            index + 1,
                            item.date_start,
                            item.company_code,
                            item.black_case_number,
                            item.document_type,
                            item.defendant_fullname,
                            item.document_code_gen
                        )
                    )
                );
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }

    // function getDocument() {
    //     setIsLoading(true);
    //     axios
    //         .get(`${config.mainAPI}/documents1/${user.user_id}/${user.user_role}`)
    //         .then((response) => {
    //             console.log(response.data.data);
    //             const value = response.data.data;
    //             setRows(
    //                 value.map((item, index) =>
    //                     createData(
    //                         index + 1,
    //                         item.date_start,
    //                         item.company_code,
    //                         item.black_case_number,
    //                         item.document_type,
    //                         item.defendant_fullname,
    //                         item.document_code_gen
    //                     )
    //                 )
    //             );
    //             setIsLoading(false);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             setIsLoading(false);
    //         });
    // }

    function searchDocument() {
        setIsLoading(true);
        const payload = {
            defendent: findDefendent,
            date: findDate,
            province: findProvince,
            debtCenter: findDebtCenter,
            courtCode: findCourtCode,
            companyCode: findCompanyCode
        };
        axios
            .post(`${config.mainAPI}/document-search`, {
                ...payload
            })
            .then(function (response) {
                const value = response.data.data;
                setRows(
                    value.map((item, index) =>
                        createData(
                            index + 1,
                            item.date_start,
                            item.company_code,
                            item.black_case_number,
                            item.document_type,
                            item.defendant_fullname,
                            item.document_code_gen
                        )
                    )
                );
                setOpenSearch(false);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setOpenSearch(false);
                setIsLoading(false);
            });

        // axios
        //     .post('${config.mainAPI}/search-documents')
        //     .then((response) => {
        //         console.log(response.data.data);
        //         const value = response.data.data;
        //         setRows(
        //             value.map((item, index) =>
        //                 createData(
        //                     index + 1,
        //                     item.date_start,
        //                     item.company_code,
        //                     item.black_case_number,
        //                     item.document_type,
        //                     item.defendant_fullname,
        //                     item.document_code_gen
        //                 )
        //             )
        //         );
        //         setIsLoading(false);
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });
    }

    const columns = [
        { id: 'order', label: 'ลำดับที่', minWidth: 100 },
        { id: 'date', label: 'วันที่รับเรื่อง', minWidth: 100 },
        { id: 'code', label: 'เลขคดีบริษัท', minWidth: 100 },
        { id: 'black', label: 'เลขคดีดำ', minWidth: 100 },
        { id: 'type', label: 'ประเภท', minWidth: 100 },
        { id: 'defendant', label: 'จำเลย', minWidth: 100 },
        // { id: 'status', label: 'สถานะ', minWidth: 100 },
        // {
        //     id: 'file',
        //     label: 'ไฟล์',
        //     minWidth: 100,
        //     render: (row) => (
        //         <>
        //             <IconButton aria-label="approve" onClick={() => handleDownload(row.path)}>
        //                 <DownloadIcon />
        //             </IconButton>
        //         </>
        //     )
        // },
        {
            id: 'detail',
            label: 'รายละเอียด',
            minWidth: 100,
            render: (row) => (
                <>
                    {/* <IconButton aria-label="approve" onClick={(() => navigate('/loan/form'), { state: { params: row.code } })}> */}
                    <IconButton onClick={() => handleCheckForm(row)}>
                        <VisibilityIcon />
                    </IconButton>
                </>
            )
        },
        {
            id: 'delete',
            label: '',
            minWidth: 100,
            render: (row) => (
                <>
                    {/* <IconButton aria-label="approve" onClick={(() => navigate('/loan/form'), { state: { params: row.code } })}> */}
                    <IconButton onClick={() => handleClickOpenDelete(row)}>
                        <DeleteForeverIcon color="error" />
                    </IconButton>
                </>
            )
        }
    ];

    function createData(order, date, code, black, type, defendant, codegen, path) {
        const formattedDate = moment(date).locale('th').add(543, 'year').format('DD/MM/YYYY');
        return { order, date: formattedDate, code, black, type, defendant, codegen, path };
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleProvinceChange = (event) => {
        const value = event.target.value;
        console.log(value);
        setProvince(value);
    };

    // function handleDownload(path) {
    //     const file_path = path;
    //     const download_url = `http://localhost:6969/download-file?file_path=${file_path}`;
    //     window.location.href = download_url;
    // }

    const handleClickOpenSearch = (row) => {
        setOpenSearch(true);
    };

    const handleCloseSearch = (row) => {
        setFindDefendent('');
        setFindDate('');
        setFindProvince('');
        setFindDebtCenter('');
        setFindCourtCode('');
        setFindCompanyCode('');
        setOpenSearch(false);
    };

    const handleClickOpenCreate = (row) => {
        setOpenCreate(true);
    };

    const handleCloseCreate = (row) => {
        setOpenCreate(false);
    };

    const handleClickOpenDelete = (row) => {
        console.log(row.codegen);
        setCode(row.codegen);
        setOpenDelete(true);
    };

    const handleCloseDelete = (row) => {
        setCode('');
        setOpenDelete(false);
    };

    const handleClickCreate = () => {
        setOpenCreate(false);
        if (type == 'สินเชื่อ' || type == 'บัตรเครดิต') {
            navigate('/loan/form');
        } else if (type == 'ขับไล่') {
            navigate('/expal/form');
        }
    };

    const handleClickOpenExport = (row) => {
        setOpenExport(true);
    };

    const handleCloseExport = (row) => {
        setOpenExport(false);
    };

    const handleClickExport = () => {
        setOpenExport(false);
        if (type == 'สินเชื่อ') {
            navigate('/loan/form');
        } else if (type == 'ขับไล่') {
            navigate('/expal/form');
        } else if (type == 'บัตรเครดิต') {
            navigate('/credit/form');
        }
    };

    const filteredRows = rows.filter((row) => {
        return Object.values(row).some((value) => {
            return String(value).toLowerCase().includes(searchTerm.toLowerCase());
        });
    });

    const handleCheckForm = (row) => {
        navigate('/loan/form', { state: { params: row.codegen } });
    };

    const handleDeleteForm = () => {
        setIsLoading(true);
        const userData = JSON.parse(localStorage.getItem('userData'));
        axios
            .post(`${config.mainAPI}/document-delete`, {
                genCode: code
            })
            .then(function (response) {
                if (response.data.success) {
                    setCode('');
                    setOpenDelete(false);
                    getDocument(userData);
                }
            })
            .catch(function (error) {
                setOpenDelete(false);
                setIsLoading(false);
                console.log(error);
            });
    };

    // const uploadFile = async (e) => {
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     formData.append('fileName', fileName);
    //     try {
    //         const res = await axios.post('http://localhost:7000/upload', formData);
    //         console.log(res);
    //         setFileName(res.data.name);
    //         setFilePath(res.data.path);
    //         setCheckFile(true);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    // function handleDownload(path) {
    //     const file_path = path;
    //     const download_url = `http://localhost:7000/download-file?file_path=${file_path}`;
    //     window.location.href = download_url;
    // }

    // https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json
    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    return (
        <div>
            <Card sx={{ minWidth: 275, minHeight: '100vh' }}>
                <Typography variant="h3" sx={{ fontWeight: 500, textAlign: 'center', marginTop: '10px' }}>
                    จัดการเอกสาร
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 3, marginTop: 3 }}>
                    <Typography sx={{ fontWeight: 500 }}>ค้นหา</Typography>
                    <TextField
                        margin="dense"
                        id="search"
                        name="search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{ marginLeft: 3, width: '75%' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 3, marginTop: 3, float: 'right' }}>
                    <Button
                        variant="outlined"
                        onClick={handleDownload}
                        sx={{ marginRight: '20px', marginTop: '10px', marginBottom: '20px' }}
                        color="primary"
                        startIcon={<FileOpenIcon />}
                    >
                        {/* <Typography sx={{ color: '#000' }}>Export Excel</Typography> */}
                        Export Excel
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handleClickOpenSearch}
                        sx={{ marginRight: '20px', marginTop: '10px', marginBottom: '20px' }}
                        color="primary"
                        startIcon={<FindInPageIcon />}
                    >
                        ค้นหา
                    </Button>

                    <Button
                        variant="outlined"
                        onClick={handleClickOpenCreate}
                        color="primary"
                        sx={{ marginRight: '20px', marginTop: '10px', marginBottom: '20px' }}
                        startIcon={<AddCircleIcon />}
                    >
                        เพิ่มเอกสาร
                    </Button>
                </Box>

                {/* {user.role == 'owner' || user.role == 'user' ? (
                        <>
                            <Button
                                variant="outlined"
                                onClick={handleClickOpenCreate}
                                sx={{ marginRight: '20px', marginTop: '10px', marginBottom: '20px' }}
                                color="primary"
                                startIcon={<AddCircleIcon />}
                            >
                                เพิ่มเอกสาร
                            </Button>
                        </>
                    ) : null} */}
                <Paper
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '30px'
                    }}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth }}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.order}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align="center">
                                                {column.render ? column.render(row) : row[column.id]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>

                <Dialog open={openSearch} fullWidth maxWidth={'md'}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseSearch}>
                        <Typography variant="h3" sx={{ fontWeight: 500, textAlign: 'center', color: '#000' }}>
                            ค้นหาเอกสาร
                        </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontSize: '16px' }}>จำเลย</Typography>
                                <TextField
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    sx={{ marginTop: '10px', marginBottom: '10px' }}
                                    onChange={(e) => setFindDefendent(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontSize: '16px' }}>วันที่</Typography>
                                <TextField
                                    type="date"
                                    sx={{ mt: 2, width: '100%', marginTop: '10px', marginBottom: '10px' }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => setFindDate(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontSize: '16px' }}>จังหวัด</Typography>
                                <Select
                                    value={province}
                                    onChange={handleProvinceChange}
                                    variant="outlined"
                                    fullWidth
                                    sx={{ marginTop: '10px', marginBottom: '10px' }}
                                >
                                    {listProvince &&
                                        listProvince.map((column) => (
                                            <MenuItem key={column.id} value={column.name_th}>
                                                {column.name_th}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontSize: '16px' }}>เขตศูนย์หนี้</Typography>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    sx={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}
                                    onChange={(e) => setFindDebtCenter(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontSize: '16px' }}>เลขคดีของศาล</Typography>
                                <TextField
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    sx={{ marginTop: '10px', marginBottom: '10px' }}
                                    onChange={(e) => setFindCourtCode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontSize: '16px' }}>เลขคดีของบริษัท</Typography>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    sx={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}
                                    onChange={(e) => setFindCompanyCode(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" color="error" onClick={handleCloseSearch} sx={{ marginRight: 2 }}>
                                    ยกเลิก
                                </Button>
                                <Button variant="contained" color="primary" onClick={searchDocument}>
                                    ยืนยัน
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <Dialog open={openCreate} fullWidth maxWidth={'md'}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseCreate}>
                        <Typography variant="h3" sx={{ fontWeight: 500, textAlign: 'center', color: '#000' }}>
                            เพิ่มเอกสาร
                        </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontSize: '16px' }}>ประเภทคดี</Typography>
                                <Select
                                    value={type || ''}
                                    onChange={(e) => setType(e.target.value)}
                                    variant="outlined"
                                    sx={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}
                                >
                                    <MenuItem value="สินเชื่อ">สินเชื่อ</MenuItem>
                                    <MenuItem value="ขับไล่">ขับไล่</MenuItem>
                                    <MenuItem value="บัตรเครดิต">บัตรเครดิต</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" color="error" onClick={handleCloseCreate} sx={{ marginRight: 2 }}>
                                    ยกเลิก
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleClickCreate}>
                                    ยืนยัน
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <Dialog open={openExport} fullWidth maxWidth={'md'}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseExport}>
                        <Typography variant="h4" sx={{ fontWeight: 500, textAlign: 'center', color: '#000' }}>
                            Export Excel
                        </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontSize: '16px' }}>ประเภทคดี</Typography>
                                <Select
                                    onChange={(e) => setType(e.target.value)}
                                    variant="outlined"
                                    sx={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}
                                >
                                    <MenuItem value="สินเชื่อ">สินเชื่อ</MenuItem>
                                    <MenuItem value="ขับไล่">ขับไล่</MenuItem>
                                    <MenuItem value="บัตรเครดิต">บัตรเครดิต</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" color="error" onClick={handleCloseExport} sx={{ marginRight: 2 }}>
                                    ยกเลิก
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleClickExport}>
                                    ยืนยัน
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <Dialog open={openDelete} fullWidth maxWidth={'xs'}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDelete}></BootstrapDialogTitle>
                    <DialogContent>
                        <Box textAlign="center">
                            <ErrorIcon sx={{ color: '#ff2929', fontSize: 100 }} />
                        </Box>
                        <Typography variant="h1" sx={{ fontWeight: 500, textAlign: 'center', color: '#000' }}>
                            คำเตือน
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{ fontWeight: 500, textAlign: 'center', marginTop: '20px', marginBottom: '20px', color: '#1f1f1f' }}
                        >
                            หากลบแล้วเอกสารจะหายไป คุณต้องการที่จะลบเอกสารนี้ใช่หรือไม่?
                        </Typography>
                        <Box textAlign="center" sx={{ marginTop: '20px', marginBottom: '20px' }}>
                            <Button variant="contained" color="error" sx={{ mr: 3 }} onClick={handleDeleteForm}>
                                ลบ
                            </Button>
                            <Button variant="outlined" color="inherit" onClick={handleCloseDelete}>
                                ปิด
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </Card>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default Documents;
