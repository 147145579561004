import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import logo from "../../../assets/images/logo_THANIC.png";
import background from "../../../assets/images/330129607_915200719496089_s1859609924739240000_n.jpg";
import config from "../../../config";
// import jwtDecode from "jwt-decode";




const theme = createTheme({
  typography: {
    fontFamily: "Sarabun, sans-serif",
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#202020",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#202020",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#202020",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#202020",
            },
          },
        },
      },
    },
  },
});



const Login = () => {
  const [openFailure, setOpenFailure] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/login`, {
        username: username,
        password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then(function (response) {
        setIsLoading(false);
        console.log('response.data',response.data);
        if (response.data.success == true) {
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("token", response.data.token);
          getUserData(response.data.token)
          // window.location.href = `${config.mainURL}/home`;
        } else {
          setOpenFailure(true);
        }




        // const data = response.data;
        // setIsLoading(false);
        // if (data.success === true) {
        //   localStorage.setItem("userData", JSON.stringify(data?.data));
        //   const isLoggedIn = true;
        //   if (isLoggedIn) {
        //     localStorage.setItem("isLoggedIn", true);
        //     window.location.href = `${config.mainURL}/home`;
        //   }
        // } else {
        //   setOpenFailure(true);
        // }
      })
      .catch(function (error) {
        setIsLoading(false);
        setOpenFailure(true);
      });
  };

  const handleCloseFailure = () => {
    setOpenFailure(false);
  };

  const getUserData = (token) => {
    axios
      .get(`${config.mainAPI}/authen`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success == true) {
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          window.location.href = `${config.mainURL}/home`;
        } else {
          localStorage.removeItem("isLoggedIn");
          localStorage.removeItem("token");
          setOpenFailure(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(true);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="logoLogin"
              style={{ width: 200, marginBottom: 20 }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              ระบบสนับสนุนการปฏิบัติงาน <br /> บริษัท ธนิก การบัญชีและกฎหมาย
              จำกัด
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <TextField
                label="ชื่อสมาชิก"
                type="text"
                fullWidth
                variant="outlined"
                required
                sx={{ mt: 1, mb: 2, backgroundColor: "#ffffff" }}
                onChange={(event) => setUsername(event.target.value)}
              />
              <TextField
                label="รหัสผ่าน"
                type="password"
                fullWidth
                variant="outlined"
                required
                sx={{ mt: 2, mb: 2, backgroundColor: "#ffffff" }}
                onChange={(event) => setPassword(event.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#202020",
                  "&:hover": {
                    backgroundColor: "#444444",
                  },
                }}
                onClick={handleSubmit}
              >
                เข้าสู่ระบบ
              </Button>
            </Box>
          </Box>
        </Grid>

        <Dialog open={openFailure} fullWidth={true} maxWidth={"xs"}>
          <DialogContent>
            <Box textAlign="center">
              <ErrorIcon sx={{ color: "#ff0c34", fontSize: 100 }} />
            </Box>

            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#ff0c34",
              }}
            >
              เข้าสู่ระบบผิดพลาด กรุณาลองใหม่อีกครั้ง
            </Typography>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={handleCloseFailure}
              >
                ปิด
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
};

export default Login;
