import React from "react";
import image404 from "../../../assets/images/5203299-removebg-preview (1).png";

const PageNotFound = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "2rem" }}>
      <img src={image404} style={{ width: "40%" }} />
      <h1>Page Not Found</h1>
    </div>
  );
};

export default PageNotFound;
