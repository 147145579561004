import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import config from "../../../config";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";

const DocumentsLegalExecutionDepartment = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [idCard, setIdCard] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openWarningExecution, setOpenWarningExecution] = useState(false);
  const [openSaveFailure, setOpenSaveFailure] = useState(false);
  const [codeGen, setCodeGen] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [user, setUser] = useState();
  const [gsbNumber, setGsbNumber] = useState("");

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    setUser(JSON.parse(userData));
  }, []);

  const typeList = [
    { id: 0, name: "สินเชื่อ", value: "loan" },
    { id: 1, name: "ขับไล่", value: "expal" },
    { id: 2, name: "บัตรเครดิต", value: "credit" },
    { id: 3, name: "บังคับคดี", value: "led" },
    { id: 4, name: "ล้มละลาย", value: "lose" },
    { id: 5, name: "ฟื้นฟูกิจการ", value: "heal" },
  ];

  const handleCloseOpenWarning = () => {
    setOpenWarning(false);
    setOpenSaveFailure(false);
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleSubmitCreateExecution = () => {
    // setOpenWarningExecution(false);
    const payload = {
      id: codeGen,
      type: typeSelected,
      user: user.id,
    };
    axios
      .post(`${config.mainAPI}/updateExecutionCase`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response", response);
        if (response.data.success) {
          setOpenWarningExecution(false);
          saveActivityLog(`Click update execution case by id:${payload.id}`);
        } else {
          setOpenWarningExecution(false);
          setOpenSaveFailure(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleCloseOpenWarningExecution = () => {
    setCodeGen("");
    setTypeSelected("");
    setOpenWarningExecution(false);
  };

  // useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function searchData() {
    console.log("55555");
    if (type) {
      axios
        .post(`${config.mainAPI}/searchDataInformation`, {
          name: name,
          idCard: idCard,
          type: type,
          gsbNumber: gsbNumber,
        })
        .then(function (response) {
          const value = response.data.data;
          // const type = response.data.type;
          console.log("value searchData", value);
          setList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.defendant_fullname,
                // type,
                item.defendant_type,
                item.execution_case,
                item.document_code_gen
              )
            )
          );
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    } else {
      // Error
      setOpenWarning(true);
    }
  }

  function clearDataSearch() {
    setName("");
    setIdCard("");
    setType("");
    setList([]);
    setGsbNumber("");
  }

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(order, list, type, status, code) {
    const typeShow =
      type == "loan"
        ? "สินเชื่อ"
        : type == "credit"
        ? "บัตรเครดิต"
        : type == "evication"
        ? "ขับไล่"
        : null;
    const statusShow = status == 1 ? "บังคับคดี" : "-";
    return {
      order,
      list,
      type,
      status: statusShow,
      // status,
      code,
    };
  }

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    { id: "list", label: "ลูกหนี้", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "status", label: "สถานะ", minWidth: 100 },
    {
      id: "detail",
      label: "รายละเอียด",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckDetail(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "execution",
      label: "บังคับคดี",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={row.status == "บังคับคดี"}
            onClick={() => handleCreateExecution(row)}
          >
            <DoneAllIcon
              color={row.status == "บังคับคดี" ? "error" : "success"}
            />
          </IconButton>
        </>
      ),
    },
  ];

  function handleCheckDetail(row) {
    console.log("row", row);
    if (row.type == "loan" || row.type == "สินเชื่อ") {
      navigate("/loan/detail", { state: { params: row.code } });
    } else if (row.type == "expal" || row.type == "ขับไล่") {
      navigate("/expal/detail", { state: { params: row.code } });
    } else if (row.type == "credit" || row.type == "บัตรเครดิต") {
      navigate("/credit/detail", { state: { params: row.code } });
    }
  }

  function handleCreateExecution(row) {
    setCodeGen(row.code);
    setTypeSelected(row.type);
    setOpenWarningExecution(true);
  }

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Box sx={{ padding: "30px" }}>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="รายการบังคับคดี"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", alignSelf: "center" }}
                      >
                        ชื่อลูกหนี้:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        color="dark"
                        value={name}
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        size="small"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          alignSelf: "center",
                          placeSelf: "center",
                        }}
                      >
                        เลขบัตรประชาชน:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        color="dark"
                        value={idCard}
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        size="small"
                        inputProps={{ maxLength: 13 }}
                        onChange={(e) => setIdCard(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", alignSelf: "center" }}
                      >
                        ประเภทคดี:<span style={{ color: "red" }}> *</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        size="small"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {typeList &&
                          typeList.map((column) => (
                            <MenuItem key={column.value} value={column.value}>
                              {column.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          alignSelf: "center",
                          placeSelf: "center",
                        }}
                      >
                        เลขสัญญา:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        color="dark"
                        value={gsbNumber}
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        size="small"
                        onChange={(e) => setGsbNumber(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={clearDataSearch}
                    sx={{ marginRight: 2 }}
                  >
                    ล้างค่า
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={searchData}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        {list.length > 0 ? (
          <>
            <Box sx={{ padding: "30px" }}>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลลูกหนี้"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "30px",
                        }}
                      >
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align="center"
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredRows
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                  <TableRow key={row.order}>
                                    {columns.map((column) => (
                                      <TableCell key={column.id} align="center">
                                        {column.render
                                          ? column.render(row)
                                          : row[column.id]}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </>
        ) : null}
      </Card>

      <Dialog open={openWarning} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            การแจ้งเตือน
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            กรุณาระบุประเภทคดีที่ต้องการที่จะค้นหาข้อมูล
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseOpenWarning}
                sx={{ marginRight: 2 }}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openWarningExecution} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            การแจ้งเตือน
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            คุณต้องการเพิ่มคดีนี้ในรายการบังคับคดีใช่ไหม?
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseOpenWarningExecution}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>

              <Button
                variant="contained"
                color="success"
                onClick={handleSubmitCreateExecution}
                sx={{ marginRight: 2 }}
              >
                ยืนยัน
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openSaveFailure} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            การแจ้งเตือน
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            ตรวจพบข้อผิดพลาดในการบันทึกกรุณาลองใหม่อีกครั้ง
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseOpenWarning}
                sx={{ marginRight: 2 }}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsLegalExecutionDepartment;
