

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./styles/styles.css";

import {
  TextField,
  Card,
  Button,
  Typography,
  Paper,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import moment from "moment/moment";
import provincesData from "../../../components/province.json";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import useAuth from "function/useAuth";
import config from '../../../config';
import api from '../../../api';

const DocumentsExecutionForm = () => {
  const [user, setUser] = useState();
  const [userCompany, setUserCompany] = useState(null);
  const { state } = useLocation();
  const params = state ? state.params : null;
  const genCode =
    params != null
      ? params
      : `Thanic-Expal-${moment().format("YYMMDDHHmmssSS")}`;
  const [isLoading, setIsLoading] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  // List Data
  const [debtCenterList, setDebtCenterList] = useState([]);
  const [prefixList, setPrefixList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const [type, setType] = useState("");
  const [finish, setFinish] = useState("");
  const [prefix, setPrefix] = useState(""); // คำนำหน้า
  const [firstName, setFirstName] = useState(""); // ชื่อจริง
  const [lastName, setLastName] = useState(""); // นามสกุล
  const [idCard, setIdCard] = useState(""); // นามสกุล
  const [nameCompany, setNameCompany] = useState(""); // ชื่อบริษัท
  const [debtCenter, setDebtCenter] = useState(""); // ศูนย์หนี้
  const [court, setCourt] = useState(""); // ศาล
  const [blackCaseNumber, setBlackCaseNumber] = useState(""); // เลขคดีดำเลขที่
  const [blackCaseYear, setBlackCaseYear] = useState(""); // เลขคดีดำเลขที่
  const [redCaseNumber, setRedCaseNumber] = useState(""); // เลขคดีแดงเลขที่
  const [redCaseYear, setRedCaseYear] = useState(""); // เลขคดีแดงเลขที่
  const [tambon, setTambon] = useState("");
  const [amphoe, setAmphoe] = useState("");
  const [province, setProvince] = useState("");
  const [tambonName, setTambonName] = useState("");
  const [amphoeName, setAmphoeName] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [fileNumber, setFileNumber] = useState(""); // เลขแฟ้ม
  const [dateStart, setDateStart] = useState(""); // วันที่รับเรื่อง
  const [deadline, setDeadline] = useState(""); // วันกำหนดส่ง
  const [propertyCode, setPropertyCode] = useState(""); // รหัสทรัพย์
  const [sector, setSector] = useState(""); // ภาค
  const [issueAWarranOfExecutionDate, setIssueAWarranOfExecutionDate] =
    useState(""); // วันขอออกหมายบังคับคดี
  const [executionOfficerDate, setExecutionOfficerDate] = useState(""); // วันนัดเจ้าหน้าที่บังคับคดี
  const [issueAnArrestWarrant, setIssueAnArrestWarrant] = useState(""); // วันขอออกหมายจับ
  const [debtorsAndDependentsDayOut, setDebtorsAndDependentsDayOut] =
    useState(""); // วันลูกหนี้และบริวารออก
  const [licenseDocument, setLicenseDocument] = useState(""); // เอกสารสิทธิ์
  const [licenseDocumentCode, setLicenseDocumentCode] = useState(""); // เอกสารสิทธิ์เลขที่
  const [caseStatus, setCaseStatus] = useState(""); // สถานะคดีปัจจุบัน
  const [note, setNote] = useState("");
  const [chooseMemberCompany, setChooseMemberCompany] = useState(""); // ไอดีผู้รับผิดชอบคดี
  const [chooseMemberCompanyName, setChooseMemberCompanyName] = useState(""); // ผู้รับผิดชอบคดี
  const [countDefendant, setCountDefendant] = useState(0); // จำนวนจำเลยร่วม
  const [coDefendantPrefix, setCoDefendantPrefix] = useState([]); // คำนำหน้าจำเลยร่วม
  const [coDefendantName, setCoDefendantName] = useState([]); // ชื่อจำเลยร่วม
  const [coDefendantLastName, setCoDefendantLastName] = useState([]); // นามสกุลจำเลยร่วม
  const [coDefendantAddress, setCoDefendantAddress] = useState([]); // ที่อยู่จำเลยร่วม
  const [coDefendantIdCard, setCoDefendantIdCard] = useState([]); // บัตรประชาชนจำเลยร่วม
  const [coDefendantStatus, setCoDefendantStatus] = useState([]); // สถานะจำเลยร่วม
  const [statusDefendant, setStatusDefendant] = useState([]);
  const [addressCoDefendant, setAddressCoDefendant] = useState([]);
  const [prefixDefendantAgent, setPrefixDefendantAgent] = useState([]); // ชื่อตัวแทนจำเลยร่วม
  const [nameDefendantAgent, setNameDefendantAgent] = useState([]); // ชื่อตัวแทนจำเลยร่วม
  const [lastNameDefendantAgent, setLastNameDefendantAgent] = useState([]); // นามสกุลตัวแทนจำเลยร่วม
  const [idCardDefendantAgent, setIdCardDefendantAgent] = useState([]); // รหัสบัตรประชาชนตัวแทนจำเลยร่วม
  const [addressDefendantAgent, setAddressDefendantAgent] = useState([]); // ที่อยู่ตัวแทนจำเลยร่วม
  const [statusDefendantAgent, setStatusDefendantAgent] = useState([]); // สถานะตัวแทนจำเลยร่วม
  const [courtCode, setCourtCode] = useState(""); // เลขคดีศาล
  const [companyCode, setCompanyCode] = useState(""); // รายละเอียดสถานที่
  const [locationDetail, setLocationDetail] = useState(""); // เลขคดีบริษัท
  const [titleDeed, setTitleDeed] = useState(""); // โฉนดที่ดิน
  const [landNumber, setLandNumber] = useState(""); // เลขที่ที่ดิน
  const [explorePage, setExplorePage] = useState(""); // หน้าสำรวจ
  useAuth();

  function getUserCompany() {
    axios
      .get(`${config.mainAPI}/users-company`)
      .then((response) => {
        setUserCompany(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDdlPrefix() {
    axios
      .get(`${config.mainAPI}/prefix-list`)
      .then((response) => {
        console.log(`value`, response.data.data);
        setPrefixList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDdlDebtCenter() {
    api
      .get(`${config.mainAPI}/debtCenter`)
      .then((response) => {
        console.log(`value`, response.data.data);
        setDebtCenterList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleBack = () => {
    navigate("/documents");
  };

  const handleProvinceChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const provinceName = event.currentTarget.getAttribute("name");
      setProvince(value);
      setProvinceName(provinceName);
      setAmphoe("");
      setTambon("");
    }
  };

  const handleAmphoeChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const amphoeName = event.currentTarget.getAttribute("name");
      setAmphoe(value);
      setAmphoeName(amphoeName);
      setTambon("");
    }
  };

  const handleTambonChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const tambonName = event.currentTarget.getAttribute("name");
      const tambonZipcode = event.currentTarget.getAttribute("code");
      setZipcode(tambonZipcode);
      setTambon(value);
      setTambonName(tambonName);
    }
  };

  const getAmphoesByProvinceId = (provinceId) => {
    if (provinceId) {
      return provincesData.find((province) => province.id === provinceId)
        .amphure;
    }
  };

  const getTambonsByAmphoeId = (amphoeId) => {
    console.log("amphoeId", amphoeId);
    if (amphoeId) {
      return provincesData
        .flatMap((province) => province.amphure)
        .find((amphoe) => amphoe.id === amphoeId).tambon;
    }
  };

  const handleSubmit = () => {
    const caseData = params != null ? "edit" : "add";
    const payload = {
      caseData: caseData,
      genCode: genCode,
      finish: finish,
      type: type,
      prefix: prefix,
      firstName: firstName,
      lastName: lastName,
      idCard: idCard,
      nameCompany: nameCompany,
      debtCenter: debtCenter,
      coDefendant: coDefendant,
      countDefendant: parseInt(countDefendant),
      defendantAgent: defendantAgent,
      court: court,
      blackCaseNumber: blackCaseNumber,
      redCaseNumber: redCaseNumber,
      blackCaseYear: blackCaseYear,
      redCaseYear: redCaseYear,
      tambon: tambon,
      amphoe: amphoe,
      province: province,
      tambonName: tambonName,
      amphoeName: amphoeName,
      provinceName: provinceName,
      zipcode: zipcode,
      fileNumber: fileNumber,
      dateStart: dateStart,
      deadline: deadline,
      propertyCode: propertyCode,
      sector: sector,
      issueAWarranOfExecutionDate: issueAWarranOfExecutionDate,
      executionOfficerDate: executionOfficerDate,
      issueAnArrestWarrant: issueAnArrestWarrant,
      debtorsAndDependentsDayOut: debtorsAndDependentsDayOut,
      licenseDocument: licenseDocument,
      licenseDocumentCode: licenseDocumentCode,
      caseStatus: caseStatus,
      note: note,
      chooseMemberCompany: chooseMemberCompany,
      chooseMemberCompanyName: chooseMemberCompanyName,
      coDefendant: coDefendant,
      courtCode: courtCode,
      companyCode: companyCode,
      titleDeed: titleDeed,
      locationDetail: locationDetail,
      landNumber: landNumber,
      explorePage: explorePage,
      user: user?.username,
    };
    console.log("payload", payload);
    axios
      .post(`${config.mainAPI}/expal`, {
        ...payload,
      })
      .then(function (response) {
        setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleWorkByChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setChooseMemberCompany(workById);
    setChooseMemberCompanyName(workByName);
  };

  const handleCoDefendantStatusChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantStatus((prevStatusDefendant) => {
      const newStatusDefendant = [...prevStatusDefendant];
      newStatusDefendant[index] = value;
      return newStatusDefendant;
    });
  };

  const handleCoDefendantPrefixChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantPrefix((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantLastNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantLastName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantAddressChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantAddress((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  const handleCoDefendantIdCardChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantIdCard((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  const handlePrefixDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setPrefixDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleLastNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setLastNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleIdCardDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setIdCardDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleAddressDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setAddressDefendantAgent((prevStatusDefendant) => {
      const newAddressDefendantAgent = [...prevStatusDefendant];
      newAddressDefendantAgent[index] = value;
      return newAddressDefendantAgent;
    });
  };

  const handleStatusDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setStatusDefendantAgent((prevStatusDefendant) => {
      const newStatusDefendantAgent = [...prevStatusDefendant];
      newStatusDefendantAgent[index] = value;
      return newStatusDefendantAgent;
    });
  };

  const coDefendant = coDefendantName.map((item, index) => ({
    prefix: coDefendantPrefix[index],
    name: item,
    lastname: coDefendantLastName[index],
    status: coDefendantStatus[index],
    address: coDefendantAddress[index],
    idCard: coDefendantIdCard[index],
    role: `จำเลยร่วมที่${[index + 1]}`,
    order: index + 1,
  }));

  const defendantAgent = nameDefendantAgent.map((item, index) => ({
    prefix: prefixDefendantAgent[index] || null,
    name: item || null,
    lastname: lastNameDefendantAgent[index] || null,
    address: addressDefendantAgent[index] || null,
    idCard: idCardDefendantAgent[index] || null,
    status: statusDefendantAgent[index] || null,
    role: `ตัวแทนจำเลยร่วมที่${[index + 1]}` || null,
    order: index + 1,
  }));

  const listCoDefendantStatus = [
    { id: 0, value: "มีชีวิตอยู่" },
    { id: 1, value: "เสียชีวิต" },
  ];

  const listStatusDead = [
    { id: 0, value: "ผู้จัดการมรดก" },
    { id: 1, value: "คู่สมรส" },
    { id: 2, value: "บิดา-มารดา" },
  ];

  const getDataForm = () => {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/expal/${params}`)
      .then((response) => {
        console.log("response.data.data", response.data.data);
        const value = response.data.data[0];
        setDateStart(moment(value.date_start).format("YYYY-MM-DD"));
        setDeadline(moment(value.date_send).format("YYYY-MM-DD"));
        setType(value.type);
        if (value.type == "บุคคล") {
          setFileNumber(value.file_number);
          setPrefix(value.defendant_prefix);
          setFirstName(value.defendant_firstname);
          setLastName(value.defendant_lastname);
          setIdCard(value.defendant_idcard);
        } else if (value.type == "นิติบุคคล") {
          setNameCompany(value.defendant_fullname);
        }
        setTambon(parseInt(value.defendant_tambon));
        setTambonName(value.defendant_tambon_show);
        setAmphoe(parseInt(value.defendant_amphoe));
        setAmphoeName(value.defendant_amphoe_show);
        setProvince(parseInt(value.defendant_province));
        setProvinceName(value.defendant_province_show);
        if (value.defendant_province) {
          console.log("value.defendant_province", value.defendant_province);
          getAmphoesByProvinceId(parseInt(value.defendant_province));
        }
        if (value.defendant_amphoe) {
          console.log("value.defendant_amphoe", value.defendant_amphoe);
          getTambonsByAmphoeId(parseInt(value.defendant_amphoe));
        }
        setZipcode(parseInt(value.defendant_zipcode));
        setDebtCenter(value.debt_center);
        setCourt(value.court);
        setPropertyCode(value.property_code);
        setSector(value.sector);
        setBlackCaseNumber(value.black_case_number);
        setBlackCaseYear(value.black_case_year);
        setRedCaseNumber(value.red_case_number);
        setRedCaseYear(value.red_case_year);
        setIssueAWarranOfExecutionDate(
          moment(value.issue_warran_of_execution_date).format("YYYY-MM-DD")
        );
        setIssueAnArrestWarrant(value.issue_arrest_warrant);
        setLicenseDocument(value.license_document);
        setLicenseDocumentCode(value.license_document_code);
        setCaseStatus(value.case_status);
        setExecutionOfficerDate(
          moment(value.execution_officer_date).format("YYYY-MM-DD")
        );
        setIssueAnArrestWarrant(
          moment(value.issue_arrest_warrant).format("YYYY-MM-DD")
        );
        setDebtorsAndDependentsDayOut(
          moment(value.debtors_and_dependents_day_out).format("YYYY-MM-DD")
        );
        setLocationDetail(value.location_detail);
        setTitleDeed(value.title_deed);
        setLandNumber(value.land_number);
        setExplorePage(value.explore_page);
        setNote(value.note);
        setChooseMemberCompany(value.work_by);
        setCountDefendant(value.codefendant_count);
        if (value.codefendant_count > 0) {
          value.coDefendant.forEach((item, index) => {
            setCoDefendantPrefix((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_prefix;
              return updatedState;
            });

            setCoDefendantName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_firstname;
              return updatedState;
            });

            setCoDefendantLastName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_lastname;
              return updatedState;
            });

            setCoDefendantAddress((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_address;
              return updatedState;
            });

            setCoDefendantIdCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_idcard;
              return updatedState;
            });

            setCoDefendantStatus((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_status;
              return updatedState;
            });
          });
        }
        // setPrefixDefendantAgent(value.);
        // setNameDefendantAgent(value.);
        // setLastNameDefendantAgent(value.);
        // setIdCardDefendantAgent(value.);
        // setAddressDefendantAgent(value.);
        // setStatusDefendantAgent(value.);
        setCompanyCode(value.company_code);
        setCourtCode(value.company_code);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleCloseFailure = () => {
    setOpenFailure(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate("/expal");
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log("datana", JSON.parse(userData));
    setUser(JSON.parse(userData));
    getUserCompany();
    getDdlPrefix();
    getDdlDebtCenter();
    if (params) {
      getDataForm();
    }
  }, []);
  const navigate = useNavigate();

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
        >
          แบบฟอร์มบังคับคดี
        </Typography>
        <Box sx={{ padding: "30px" }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                วันที่รับเรื่อง:
              </Typography>
              <TextField
                type="date"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={dateStart}
                onChange={(e) => setDateStart(e.target.value)}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>เลขแฟ้ม:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={fileNumber}
                onChange={(e) => setFileNumber(e.target.value)}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            {/* <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: '16px' }}>เสร็จ:</Typography>
                            <TextField
                                type="text"
color="dark"

                                fullWidth
                                sx={{ marginTop: '10px', marginBottom: '10px', backgroundColor: '#ffffff' }}
                                value={finish}
                                onChange={(e) => setFinish(e.target.value)}
                            />
                        </Grid> */}
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>วันกำหนดส่ง:</Typography>
              <TextField
                type="date"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={deadline}
                onChange={(e) => setDeadline(e.target.value)}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                ประเภท<span style={{ color: "red" }}> *</span>:
              </Typography>
              <Select
                value={type || ""}
                onChange={(e) => setType(e.target.value)}
                variant="outlined"
                color="dark"
                sx={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}
                disabled
              >
                <MenuItem value="บุคคล">บุคคล</MenuItem>
                <MenuItem value="นิติบุคคล">นิติบุคคล</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              {type == "บุคคล" ? (
                <Grid>
                  <Typography sx={{ fontSize: "16px" }}>คำนำหน้า:</Typography>
                  <Select
                    value={prefix}
                    onChange={(e) => setPrefix(e.target.value)}
                    variant="outlined"
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    disabled
                  >
                    {prefixList &&
                      prefixList.map((column) => (
                        <MenuItem
                          key={column.prefix_id}
                          value={column.prefix_name_th}
                        >
                          {column.prefix_name_th}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              ) : null}

              {type == "นิติบุคคล" ? (
                <Grid>
                  <Typography sx={{ fontSize: "16px" }}>ชื่อบริษัท:</Typography>
                  <TextField
                    type="text"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={nameCompany}
                    onChange={(e) => setNameCompany(e.target.value)}
                    disabled
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          {type == "บุคคล" ? (
            <>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>ชื่อ:</Typography>
                  <TextField
                    type="text"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>นามสกุล:</Typography>
                  <TextField
                    type="text"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขที่บัตรประชาชน:
                  </Typography>
                  <TextField
                    type="text"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={idCard}
                    onChange={(e) => setIdCard(e.target.value)}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำนวนจำเลยร่วม:
                  </Typography>
                  <TextField
                    id="countDefendant"
                    name="countDefendant"
                    type="number"
                    color="dark"
                    placeholder="กรุณาระบุจำนวน"
                    fullWidth
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0 } }}
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={countDefendant}
                    onChange={(event) => setCountDefendant(event.target.value)}
                    disabled
                  />
                </Grid>

                {countDefendant > 0 && (
                  <>
                    {[...Array(parseInt(countDefendant))].map((_, i) => (
                      <React.Fragment key={i}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            สถานะจำเลยร่วมที่ {i + 1}:
                          </Typography>
                          <Select
                            id={`coDefendantStatus${i}`}
                            name={`coDefendantStatus${i}`}
                            value={coDefendantStatus[i] || ""}
                            onChange={(event) =>
                              handleCoDefendantStatusChange(event, i)
                            }
                            variant="outlined"
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              width: "100%",
                            }}
                            disabled
                          >
                            <MenuItem value="">
                              โปรดเลือกสถานะของจำเลยร่วมที่ {i + 1}
                            </MenuItem>
                            {listCoDefendantStatus &&
                              listCoDefendantStatus.map((column) => (
                                <MenuItem key={column.id} value={column.value}>
                                  {column.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            คำนำหน้าจำเลยร่วมที่ {i + 1}:
                          </Typography>
                          <Select
                            id={`coDefendantPrefix${i}`}
                            name={`coDefendantPrefix${i}`}
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              width: "100%",
                            }}
                            value={coDefendantPrefix[i] || ""}
                            color="dark"
                            onChange={(e) =>
                              handleCoDefendantPrefixChange(e, i)
                            }
                            disabled
                          >
                            {prefixList &&
                              prefixList.map((column) => (
                                <MenuItem
                                  key={column.prefix_id}
                                  value={column.prefix_name_th}
                                >
                                  {column.prefix_name_th}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ชื่อจำเลยร่วมที่ {i + 1}:
                          </Typography>
                          <TextField
                            id={`coDefendantName${i}`}
                            name={`coDefendantName${i}`}
                            value={coDefendantName[i] || ""}
                            onChange={(e) => handleCoDefendantNameChange(e, i)}
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            นามสกุลจำเลยร่วมที่ {i + 1}:
                          </Typography>
                          <TextField
                            id={`coDefendantLastName${i}`}
                            name={`coDefendantLastName${i}`}
                            value={coDefendantLastName[i] || ""}
                            onChange={(e) =>
                              handleCoDefendantLastNameChange(e, i)
                            }
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ที่อยู่จำเลยร่วมที่ {i + 1}:
                          </Typography>
                          <TextField
                            id={`coDefendantAddress${i}`}
                            name={`coDefendantAddress${i}`}
                            value={coDefendantAddress[i] || ""}
                            onChange={(e) =>
                              handleCoDefendantAddressChange(e, i)
                            }
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            บัตรประชาชนจำเลยร่วมที่ {i + 1}:
                          </Typography>
                          <TextField
                            id={`coDefendantIdCard${i}`}
                            name={`coDefendantIdCard${i}`}
                            value={coDefendantIdCard[i] || ""}
                            onChange={(e) =>
                              handleCoDefendantIdCardChange(e, i)
                            }
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 13 }}
                          />
                        </Grid>
                        {coDefendantStatus[i] == "เสียชีวิต" ? (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                สถานะตัวแทนจำเลยร่วมที่ {i + 1}:
                              </Typography>
                              <Select
                                id={`statusDefendantAgent${i}`}
                                name={`statusDefendantAgent${i}`}
                                value={statusDefendantAgent[i] || ""}
                                onChange={(event) =>
                                  handleStatusDefendantAgentChange(event, i)
                                }
                                variant="outlined"
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  width: "100%",
                                }}
                              >
                                {listStatusDead &&
                                  listStatusDead.map((column) => (
                                    <MenuItem
                                      key={column.id}
                                      value={column.value}
                                    >
                                      {column.value}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                คำนำหน้าตัวแทนจำเลยร่วมที่ {i + 1}:
                              </Typography>
                              <Select
                                id={`prefixDefendantAgent${i}`}
                                name={`prefixDefendantAgent${i}`}
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  width: "100%",
                                }}
                                value={prefixDefendantAgent[i] || ""}
                                color="dark"
                                onChange={(e) =>
                                  handlePrefixDefendantAgentChange(e, i)
                                }
                              >
                                {prefixList &&
                                  prefixList.map((column) => (
                                    <MenuItem
                                      key={column.prefix_id}
                                      value={column.prefix_name_th}
                                    >
                                      {column.prefix_name_th}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ชื่อตัวแทนจำเลยร่วมที่ {i + 1}:
                              </Typography>
                              <TextField
                                id={`nameDefendantAgent${i}`}
                                name={`nameDefendantAgent${i}`}
                                value={nameDefendantAgent[i] || ""}
                                onChange={(e) =>
                                  handleNameDefendantAgentChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                นามสกุลตัวแทนจำเลยร่วมที่ {i + 1}:
                              </Typography>
                              <TextField
                                id={`lastNameDefendantAgent${i}`}
                                name={`lastNameDefendantAgent${i}`}
                                value={lastNameDefendantAgent[i] || ""}
                                onChange={(e) =>
                                  handleLastNameDefendantAgentChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ที่อยู่ตัวแทนจำเลยร่วมที่ {i + 1}:
                              </Typography>
                              <TextField
                                id={`addressDefendantAgent${i}`}
                                name={`addressDefendantAgent${i}`}
                                value={addressDefendantAgent[i] || ""}
                                onChange={(e) =>
                                  handleAddressDefendantAgentChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                บัตรประชาชนตัวแทนจำเลยร่วมที่ {i + 1}:
                              </Typography>
                              <TextField
                                id={`idCardDefendantAgent${i}`}
                                name={`idCardDefendantAgent${i}`}
                                value={idCardDefendantAgent[i] || ""}
                                onChange={(e) =>
                                  handleIdCardDefendantAgentChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                inputProps={{ maxLength: 13 }}
                              />
                            </Grid>
                          </>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Grid>
            </>
          ) : null}

          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "16px" }}>รหัสทรัพย์:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={propertyCode}
                onChange={(e) => setPropertyCode(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>ภาค:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={sector}
                onChange={(e) => setSector(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>ศูนย์หนี้:</Typography>
              <Select
                value={debtCenter || ""}
                onChange={(e) => setDebtCenter(e.target.value)}
                variant="outlined"
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                {debtCenterList &&
                  debtCenterList.map((column) => (
                    <MenuItem
                      key={column.debt_center_id}
                      value={column.debt_center_name}
                    >
                      {column.debt_center_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>ศาล:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={court}
                onChange={(e) => setCourt(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>หมายเลขคดีดำ:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={blackCaseNumber}
                onChange={(e) => setBlackCaseNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>คดีดำปี:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                helperText="ตัวอย่าง: 2566"
                value={blackCaseYear}
                inputProps={{ maxLength: 4 }}
                onChange={(e) => setBlackCaseYear(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>หมายเลขคดีแดง:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={redCaseNumber}
                onChange={(e) => setRedCaseNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>คดีแดงปี:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                helperText="ตัวอย่าง: 2566"
                value={redCaseYear}
                inputProps={{ maxLength: 4 }}
                onChange={(e) => setRedCaseYear(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                วันขอออกหมายบังคับคดี:
              </Typography>
              <TextField
                type="date"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={issueAWarranOfExecutionDate}
                onChange={(e) => setIssueAWarranOfExecutionDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                วันนัดเจ้าหน้าที่บังคับคดี:
              </Typography>
              <TextField
                type="date"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={executionOfficerDate}
                onChange={(e) => setExecutionOfficerDate(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                วันขอออกหมายจับ:
              </Typography>
              <TextField
                type="date"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={issueAnArrestWarrant}
                onChange={(e) => setIssueAnArrestWarrant(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                วันลูกหนี้และบริวารออก:
              </Typography>
              <TextField
                type="date"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={debtorsAndDependentsDayOut}
                onChange={(e) => setDebtorsAndDependentsDayOut(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>เอกสารสิทธิ์:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={licenseDocument}
                onChange={(e) => setLicenseDocument(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                เอกสารสิทธิ์เลขที่:
              </Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={licenseDocumentCode}
                onChange={(e) => setLicenseDocumentCode(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>จังหวัด:</Typography>
              <Select
                value={province || ""}
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                <MenuItem value="">โปรดระบุจังหวัด</MenuItem>
                {provincesData.map((province) => (
                  <MenuItem
                    key={province.id}
                    value={province.id}
                    name={province.name_th}
                    onClick={(event) => handleProvinceChange(event)}
                  >
                    {province.name_th}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {province ? (
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>อำเภอ:</Typography>
                <Select
                  value={amphoe || ""}
                  fullWidth
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <MenuItem value="">โปรดระบุอำเภอ</MenuItem>
                  {getAmphoesByProvinceId(province).map((amphoe) => (
                    <MenuItem
                      key={amphoe.id}
                      value={amphoe.id}
                      name={amphoe.name_th}
                      onClick={(event) => handleAmphoeChange(event)}
                    >
                      {amphoe.name_th}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : null}

            {amphoe ? (
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>ตำบล:</Typography>
                <Select
                  value={tambon || ""}
                  fullWidth
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <MenuItem value="">โปรดระบุตำบล</MenuItem>
                  {getTambonsByAmphoeId(amphoe).map((tambon) => (
                    <MenuItem
                      key={tambon.id}
                      value={tambon.id}
                      name={tambon.name_th}
                      code={tambon.zip_code}
                      onClick={(event) => handleTambonChange(event)}
                    >
                      {tambon.name_th}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : null}
            {tambon ? (
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>รหัสไปรษณี:</Typography>
                <TextField
                  id="comment"
                  name="comment"
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  disabled
                  value={zipcode || ""}
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "16px" }}>
                รายละเอียดสถานที่:
              </Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={locationDetail}
                helperText="ตัวอย่าง: ทาวน์เฮาส์ 2 ชั้น"
                onChange={(e) => setLocationDetail(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                โฉนดที่ดินเลขที่:
              </Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={titleDeed}
                onChange={(e) => setTitleDeed(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>เลขที่ที่ดิน:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={landNumber}
                onChange={(e) => setLandNumber(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>หน้าสำรวจ:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={explorePage}
                onChange={(e) => setExplorePage(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                สถานะคดีปัจจุบัน:
              </Typography>
              <Select
                value={caseStatus || ""}
                onChange={(e) => setCaseStatus(e.target.value)}
                variant="outlined"
                color="dark"
                sx={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}
              >
                <MenuItem value="">โปรดระบุ</MenuItem>
                <MenuItem value="อยู่ระหว่างลงพื้นที่ตรวจสอบ">
                  อยู่ระหว่างลงพื้นที่ตรวจสอบ
                </MenuItem>
                <MenuItem value="ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์ แต่ขอขยายระยะเวลาออกจากทรัพย์">
                  ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์
                  แต่ขอขยายระยะเวลาออกจากทรัพย์
                </MenuItem>
                <MenuItem value="อยู่ระหว่างเจรจาซื้อทรัพย์คืน">
                  อยู่ระหว่างเจรจาซื้อทรัพย์คืน
                </MenuItem>
                <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ออกหมายขับไล่">
                  อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ออกหมายขับไล่
                </MenuItem>
                <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการ  ออกหมายจับ">
                  อยู่ระหว่างสำนักงานทนายความดำเนินการ ออกหมายจับ
                </MenuItem>
                <MenuItem value="ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว">
                  ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว
                </MenuItem>
                <MenuItem value="ส่งมอบทรัพย์แล้ว">ส่งมอบทรัพย์แล้ว</MenuItem>
                <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ประสานตำรวจจับกุม">
                  อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย
                  ประสานตำรวจจับกุม
                </MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>หมายเหตุ:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>เลขคดีศาล:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={courtCode}
                onChange={(e) => setCourtCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>เลขคดีบริษัท:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={companyCode}
                onChange={(e) => setCompanyCode(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "16px" }}>
                ผู้รับผิดชอบคดี<span style={{ color: "red" }}> *</span>
              </Typography>
              <Select
                value={chooseMemberCompany}
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                required
              >
                {userCompany
                  ? userCompany.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        name={item.fullname}
                        id={item.id}
                        onClick={(event) => handleWorkByChange(event)}
                      >
                        {item.fullname}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>วันนำคำบังคับ:</Typography>
              <TextField
                type="date"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={executionOfficerDate}
                onChange={(e) => setExecutionOfficerDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>วันครบคำบังคับ:</Typography>
              <TextField
                type="date"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={executionOfficerDate}
                onChange={(e) => setExecutionOfficerDate(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={handleBack}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button variant="outlined" color="success" onClick={handleSubmit}>
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>

      <Dialog open={openFailure} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <ErrorIcon sx={{ color: "#ff0c34", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#ff0c34",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseFailure}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openSuccess} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <CheckCircleOutlineIcon sx={{ color: "#52c41a", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h2"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#1f1f1f",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลสำเร็จ!
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleCloseSuccess}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsExecutionForm;
