import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../../../config";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Avatar, Box, Grid, Menu, MenuItem, Typography } from "@mui/material";

// project imports
import MainCard from "../../../../../ui-component/cards/MainCard";
import SkeletonEarningCard from "../../../../../ui-component/cards/Skeleton/EarningCard";

// assets
import EarningIcon from "../../../../../assets/images/icons/earning.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppOutlined";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyOutlined";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveOutlined";

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: "#353030",
  color: "#fff",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: "#febf44",
    borderRadius: "50%",
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    // background: theme.palette.info.dark,
    background: "#febf44",
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const LoanCase = ({ isLoading, count }) => {
  const theme = useTheme();
  const [user, setUser] = useState();
  // const [count, setCount] = useState(); // จำนวนรายการทั้งหมด

  useEffect(() => {
    const userData = localStorage.getItem("user_data");
    setUser(JSON.parse(userData));
    // getData();
  }, []);

  // function getData() {
  //     const type = 'สินเชื่อ';
  //     axios
  //         .get(`${config.mainAPI}/count-case/${type}`)
  //         .then((response) => {
  //             console.log('response', response);
  //             let count = response.data.data[0].count;
  //             console.log('count', count);
  //             setCount(count);
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //         });
  // }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isLoading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: "#febf44",
                        mt: 1,
                      }}
                    >
                      <img src={EarningIcon} alt="Notification" />
                    </Avatar>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: "2.125rem",
                        fontWeight: 500,
                        mr: 1,
                        mt: 1.75,
                        mb: 0.75,
                      }}
                    >
                      {count} คดี
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mb: 1.25 }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  สินเชื่อทั้งหมด
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

LoanCase.propTypes = {
  isLoading: PropTypes.bool,
};

export default LoanCase;
