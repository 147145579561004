import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import config from "../../../config";
import api from "../../../api";
import moment from "moment";
import {
  createTheme,
  ThemeProvider,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
// import useAuth from "../../function/useAuth";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";

const TimeSheet = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [idCard, setIdCard] = useState("");
  const [gsbNumber, setGsbNumber] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openFirstTimeLogin, setOpenFirstTimeLogin] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  //   const localizer = momentLocalizer(moment);
  const [eventCalendar, setEventCalendar] = useState([]);
  const [textCalendar, setTextCalendar] = useState("");

  const [openCreate, setOpenCreate] = useState(false);
  const [dateWork, setDateWork] = useState(new Date());
  const [detailWork, setDetailWork] = useState("");
  const [hourWork, setHourWork] = useState(null);
  const [timeStart, setTimeStart] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);

  //   handleClickOpenCreate

  const initialHoursList = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minute = i % 2 === 0 ? "00" : "30";
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    return {
      name: `${formattedHour}:${minute}`,
      value: `${formattedHour}:${minute}`,
    };
  });

  const initialMinutesList = Array.from({ length: 60 }, (_, i) => {
    const minute = i < 10 ? `0${i}` : `${i}`;
    return { name: minute, value: minute };
  });
  const [hoursList, sethoursList] = useState(initialHoursList);
  const [minutesList, setminutesList] = useState(initialMinutesList);
  const columns = [
    { id: "date", label: "วันที่", minWidth: 100 },
    { id: "detail_work", label: "รายละเอียดการทำงาน", minWidth: 100 },
    { id: "time_start", label: "เวลาเริ่มต้น", minWidth: 100 },
    { id: "time_end", label: "เวลาสิ้นสุด", minWidth: 100 },
    { id: "count", label: "จำนวน", minWidth: 100 },
    { id: "date_sent", label: "วันที่ยื่น", minWidth: 100 },
    { id: "status", label: "สถานะ", minWidth: 100 },
    {
      id: "detail",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckForm(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "edit",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleEditForm(row)}>
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          {/* <IconButton aria-label="approve" onClick={(() => navigate('/loan/form'), { state: { params: row.code } })}> */}
          <IconButton onClick={() => handleClickOpenDelete(row)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleCloseOpenWarning = () => {
    setOpenWarning(false);
  };

  const handleCloseOpenFirstTimeLogin = () => {
    setOpenFirstTimeLogin(false);
  };

  const handleSubmitSetNewPassword = () => {
    console.log("eiei");
    setIsLoading(true);
    console.log("user.username", user.username);
    console.log("newPassword", newPassword);
    api
      .post(`${config.mainAPI}/updateNewPasswordFirstTimeLogin`, {
        username: user.username,
        password: newPassword,
      })
      .then(function (response) {
        setOpenFirstTimeLogin(false);
        setIsLoading(false);
        localStorage.removeItem("user_data");
        localStorage.removeItem("userData");
        localStorage.removeItem("isLoggedIn");
        window.location.reload();
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // useAuth();

 

  function searchData() {
    if (type) {
      axios
        .post(`${config.mainAPI}/searchDataInformation`, {
          name: name,
          idCard: idCard,
          type: type,
          gsbNumber: gsbNumber,
          role: user.role,
          debtCenter: user.debtCenter,
        })
        .then(function (response) {
          const value = response.data.data;
          console.log("value searchData", value);
          setList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.defendant_fullname,
                item.defendant_type,
                item.document_code_gen
              )
            )
          );
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    } else {
      // Error
      setOpenWarning(true);
    }
  }

  function clearDataSearch() {
    setName("");
    setIdCard("");
    setGsbNumber("");
    setType("");
    setList([]);
  }

  const addWorkTime = () => {
    setOpenCreate(false);
  };

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(order, list, type, code) {
    return {
      order,
      list,
      type,
      code,
    };
  }

  function handleCheckDetail(row) {
    if (row.type == "สินเชื่อ") {
      navigate("/loan/detail", { state: { params: row.code } });
    } else if (row.type == "ขับไล่") {
      navigate("/expal/detail", { state: { params: row.code } });
    } else if (row.type == "บัตรเครดิต") {
      navigate("/credit/detail", { state: { params: row.code } });
    }
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setUser(userData);
    }
  }, []);

  const handleClickOpenCreate = () => {};

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? "red" : event.color,
    };
    return {
      style,
    };
  };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setData(christDate);
  };

  // Custom styles for the table
  const useStyles = makeStyles({
    table: {
      border: "0.5px solid black",
    },
    cell: {
      border: "0.5px solid black",
    },
  });

  const classes = useStyles();

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", mt: 3 }}
        >
          เวลาการทำงาน
        </Typography>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>Date</TableCell>
                  <TableCell className={classes.cell}>Start</TableCell>
                  <TableCell className={classes.cell}>End</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell}>Data 1</TableCell>
                  <TableCell className={classes.cell}>Data 2</TableCell>
                  <TableCell className={classes.cell}>Data 3</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell}>Data 4</TableCell>
                  <TableCell className={classes.cell}>Data 5</TableCell>
                  <TableCell className={classes.cell}>Data 6</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Card>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default TimeSheet;
