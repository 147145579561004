import React from 'react';
import Chart from 'react-apexcharts';

const ChartPieExpal = ({ finish, unfinish }) => {
    const pieData = {
        series: [finish, unfinish],
        options: {
            chart: {
                type: 'donut'
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ],
            labels: ['กำลังดำเนินงาน', 'เสร็จสิ้น'], // Add the labels property here
            colors: ['#febf44', '#00e396']
        }
    };
    return <Chart options={pieData.options} series={pieData.series} height={300} type="donut" />;
};

export default ChartPieExpal;
