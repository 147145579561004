import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import config from "../../config";
import api from "../../api";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
import useAuth from "../../function/useAuth";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";

const Home = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [idCard, setIdCard] = useState("");
  const [gsbNumber, setGsbNumber] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openFirstTimeLogin, setOpenFirstTimeLogin] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const localizer = momentLocalizer(moment);
  const [eventCalendar, setEventCalendar] = useState([]);
  const [textCalendar, setTextCalendar] = useState("");
  const [searchBigLoan, setSearchBigLoan] = useState(false);

  const typeList = [
    { id: 0, name: "สินเชื่อ", value: "loan" },
    { id: 1, name: "ขับไล่", value: "expal" },
    { id: 2, name: "บัตรเครดิต", value: "credit" },
    { id: 3, name: "บังคับคดี", value: "led" },
    { id: 4, name: "ล้มละลาย", value: "lose" },
    { id: 5, name: "ฟื้นฟูกิจการ", value: "heal" },
  ];

  const handleCloseOpenWarning = () => {
    setOpenWarning(false);
  };

  const handleCloseOpenFirstTimeLogin = () => {
    setOpenFirstTimeLogin(false);
  };

  const handleSubmitSetNewPassword = () => {
    console.log("eiei");
    setIsLoading(true);
    console.log("user.username", user.username);
    console.log("newPassword", newPassword);
    api
      .post(`${config.mainAPI}/updateNewPasswordFirstTimeLogin`, {
        username: user.username,
        password: newPassword,
      })
      .then(function (response) {
        setOpenFirstTimeLogin(false);
        setIsLoading(false);
        localStorage.removeItem("user_data");
        localStorage.removeItem("userData");
        localStorage.removeItem("isLoggedIn");
        window.location.reload();
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  useAuth();

  function searchData() {
    if (type) {
      axios
        .post(`${config.mainAPI}/searchDataInformation`, {
          name: name,
          idCard: idCard,
          type: type,
          gsbNumber: gsbNumber,
          role: user.role,
          debtCenter: user.debtCenter,
        })
        .then(function (response) {
          const value = response.data.data;
          console.log("value searchData", value);
          setList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.defendant_fullname,
                item.defendant_type,
                item.debt_center,
                item.document_code_gen
              )
            )
          );
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    } else {
      // Error
      setOpenWarning(true);
    }
  }

  function clearDataSearch() {
    setName("");
    setIdCard("");
    setGsbNumber("");
    setType("");
    setList([]);
  }

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(order, list, type, debt_center, code) {
    return {
      order,
      list,
      type,
      debt_center,
      code,
    };
  }

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    { id: "list", label: "ลูกหนี้", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "debt_center", label: "ศูนย์หนี้", minWidth: 100 },
    {
      id: "detail",
      label: "รายละเอียด",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckDetail(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
  ];

  function handleCheckDetail(row) {
    if (row.type == "สินเชื่อ") {
      navigate("/loan/detail", { state: { params: row.code } });
    } else if (row.type == "ขับไล่") {
      navigate("/expal/detail", { state: { params: row.code } });
    } else if (row.type == "บัตรเครดิต") {
      navigate("/credit/detail", { state: { params: row.code } });
    }
  }
  const getDataIntoCalendar = () => {
    axios
      .get(`${config.mainAPI}/setDataIntoCalendar`)
      .then((response) => {
        const value = response?.data.data;
        console.log("value", value);
        if (value) {
          value.map((item) => {
            const date = new Date(item.go_court_date);
            const timeParts = item.go_court_time.split(":");
            // Extract hours and minutes from the time string
            const hours = parseInt(timeParts[0], 10);
            const minutes = parseInt(timeParts[1], 10);

            // Set the hours and minutes on the existing date object
            date.setHours(hours);
            date.setMinutes(minutes);

            let sum = {
              title: `${item.go_court_type} ${item.go_court_place}`,
              start: date,
              end: date,
              details: `${item.go_court_detail}`,
            };
            setEventCalendar((item1) => [...item1, sum]);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getUserData() {
    setIsLoading(true);
    const getUserString = localStorage.getItem("userData");
    if (getUserString) {
      const getUser = JSON.parse(getUserString);
      setUser(getUser);
      getUser.firstTimeLogin == 0
        ? setOpenFirstTimeLogin(true)
        : setOpenFirstTimeLogin(false);
      console.log("getUser", getUser);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      localStorage.removeItem("userData");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      window.location.href = `${config.mainURL}/login`;
    }
  }

  // const getUserData = () => {
  //   // window.location.reload();
  //   setIsLoading(true);
  //   const token = localStorage.getItem("token");
  //   axios
  //     .get(`${config.mainAPI}/authen`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //     })
  //     .then(function (response) {
  //       setIsLoading(false);
  //       if (response.data.success == true) {
  //         localStorage.setItem("userData", JSON.stringify(response.data.data));
  //         setUser(response.data.data);
  //         response.data.data.firstTimeLogin == 0
  //           ? setOpenFirstTimeLogin(true)
  //           : setOpenFirstTimeLogin(false);
  //       } else {
  //         localStorage.removeItem("isLoggedIn");
  //         localStorage.removeItem("token");
  //         setOpenFailure(true);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       setIsLoading(true);
  //     });
  // };

  useEffect(() => {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    // console.log("usereieina", userData);
    // if (userData) {
    //   setUser(userData);
    //   userData.firstTimeLogin == 0
    //     ? setOpenFirstTimeLogin(true)
    //     : setOpenFirstTimeLogin(false);
    // }
    getUserData();
    // getDataIntoCalendar();
  }, []);

  const openCalendarEvent = (event) => {
    setOpenCalendar(true);
    const text = `คดี${event.title} วันที่: ${moment(event.start)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm:ss")} ${
      event.detail ? `รายละเอียด: ${event.detail}` : ``
    }`;
    setTextCalendar(text);
  };

  const closeCalendarEvent = () => {
    setOpenCalendar(false);
    setTextCalendar("");
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? "red" : event.color,
    };
    return {
      style,
    };
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", mt: 3 }}
        >
          ข้อมูลคดี
        </Typography>
        <Box sx={{ padding: "30px" }}>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ค้นหาข้อมูล"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", alignSelf: "center" }}
                      >
                        ชื่อลูกหนี้:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        color="dark"
                        value={name}
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        size="small"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          alignSelf: "center",
                          placeSelf: "center",
                        }}
                      >
                        เลขบัตรประชาชน:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        color="dark"
                        value={idCard}
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        size="small"
                        inputProps={{ maxLength: 13 }}
                        onChange={(e) => setIdCard(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", alignSelf: "center" }}
                      >
                        ประเภทคดี:<span style={{ color: "red" }}> *</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        size="small"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {typeList &&
                          typeList.map((column) => (
                            <MenuItem key={column.value} value={column.value}>
                              {column.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          alignSelf: "center",
                          placeSelf: "center",
                        }}
                      >
                        เลขสัญญา:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        color="dark"
                        value={gsbNumber}
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        size="small"
                        onChange={(e) => setGsbNumber(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      sx={{
                        alignSelf: "center",
                        placeSelf: "center",
                        textAlign: "right",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "16px", alignSelf: "center" }}
                      >
                        ลูกหนี้รายใหญ่:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Checkbox
                        checked={searchBigLoan}
                        onChange={() => setSearchBigLoan}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
              </Grid> */}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={clearDataSearch}
                    sx={{ marginRight: 2 }}
                  >
                    ล้างค่า
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={searchData}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        {list.length > 0 ? (
          <>
            <Box sx={{ padding: "30px" }}>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลลูกหนี้"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "30px",
                        }}
                      >
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align="center"
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredRows
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                  <TableRow key={row.order}>
                                    {columns.map((column) => (
                                      <TableCell key={column.id} align="center">
                                        {column.render
                                          ? column.render(row)
                                          : row[column.id]}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </>
        ) : null}
        {/* {user.role != "bank" ? (
          <>
            <Box sx={{ padding: 5 }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  alignSelf: "center",
                  placeSelf: "center",
                  textAlign: "center",
                  fontWeight: 700,
                  mb: 3,
                }}
              >
                ปฏิทินการปฏิบัติงาน
              </Typography>
              <Calendar
                localizer={localizer}
                events={eventCalendar}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                eventPropGetter={eventStyleGetter}
                onSelectEvent={(event) => openCalendarEvent(event)}
              />
            </Box>
          </>
        ) : null} */}
      </Card>

      <Dialog open={openWarning} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            การแจ้งเตือน
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            กรุณาระบุประเภทคดีที่ต้องการที่จะค้นหาข้อมูล
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseOpenWarning}
                sx={{ marginRight: 2 }}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openCalendar} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            รายละเอียด
          </Typography>
          <Typography sx={{ mb: 3, fontSize: 16 }} textAlign="center">
            {textCalendar}
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={closeCalendarEvent}
                sx={{ marginRight: 2 }}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openFirstTimeLogin} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            การแจ้งเตือน
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            เข้าใช้งานระบบครั้งแรกกรุณาอัปเดตรหัสผ่าน
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevents the default form submission behavior
              handleSubmitSetNewPassword();
            }}
          >
            <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px" }}>
                  รหัสผ่าน<span style={{ color: "red" }}> *</span>
                </Typography>
                <TextField
                  type="password"
                  fullWidth
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px" }}>
                  ยืนยันรหัสผ่าน
                  <span style={{ color: "red" }}> *</span>
                </Typography>
                {/* <TextField
                  type="password"
                  fullWidth
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setNewPassword1(e.target.value)}
                  error={newPassword1 && newPassword !== newPassword1}
                  helperText={
                    newPassword1 && newPassword !== newPassword1
                      ? "รหัสผ่านไม่ตรงกัน กรุณากรอกรหัสผ่านให้ตรงกัน"
                      : ""
                  }
                  required
                /> */}
                <TextField
                  type="password"
                  fullWidth
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setNewPassword1(e.target.value)}
                  error={Boolean(newPassword1) && newPassword !== newPassword1} // Coerce to boolean
                  helperText={
                    newPassword1 && newPassword !== newPassword1
                      ? "รหัสผ่านไม่ตรงกัน กรุณากรอกรหัสผ่านให้ตรงกัน"
                      : ""
                  }
                  required
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  // onSubmit={handleSubmitSetNewPassword}
                  // onClick={handleSubmitSetNewPassword}
                >
                  อัปเดตรหัสผ่าน
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Home;
