import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import { FileUploader } from "react-drag-drop-files";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import config from "../../../config";
import api from "../../../api";
import path from "path-browserify";

import {
  Card,
  Typography,
  Paper,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import provincesData from "../../../components/province.json";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PaidIcon from "@mui/icons-material/Paid";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import useAuth from "function/useAuth";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "rc-time-picker";
import ClearIcon from "@mui/icons-material/Clear";
import "rc-time-picker/assets/index.css";

const DocumentsDetailLoan = () => {
  const [user, setUser] = useState();
  const [userCompany, setUserCompany] = useState(null);
  const { state } = useLocation();
  const params = state ? state.params : null;
  const genCode =
    params != null
      ? params
      : `Thanic-Loan-${moment().format("YYMMDDHHmmssSS")}`;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [deadline, setDeadline] = useState(null); // วันกำหนดส่ง
  const [prefix, setPrefix] = useState("");
  const [defendantFirstName, setDefendantFirstName] = useState("");
  const [defendantLastName, setDefendantLastName] = useState("");
  const [defendantIdCard, setDefendantIdCard] = useState("");
  const [defendantAccountNumber, setDefendantAccountNumber] = useState("");
  const [defendantAccountNumber1, setDefendantAccountNumber1] = useState("");
  const [defendantAccount, setDefendantAccount] = useState("");
  const [defendantHouseNumber, setDefendantHouseNumber] = useState("");
  const [defendantGroup, setDefendantGroup] = useState("");
  const [defendantRoad, setDefendantRoad] = useState("");
  const [defendantSoi, setDefendantSoi] = useState("");
  const [defendantAddress, setDefendantAddress] = useState("");
  const [defendantTambon, setDefendantTambon] = useState("");
  const [defendantAmphoe, setDefendantAmphoe] = useState("");
  const [defendantProvince, setDefendantProvince] = useState("");
  const [defendantTambonName, setDefendantTambonName] = useState("");
  const [defendantAmphoeName, setDefendantAmphoeName] = useState("");
  const [defendantProvinceName, setDefendantProvinceName] = useState("");
  const [defendantZipcode, setDefendantZipcode] = useState("");
  const [debtCenter, setDebtCenter] = useState("");
  const [branchAgency, setBranchAgency] = useState(""); // หน่วยงานสาขา
  const [districtAgency, setDistrictAgency] = useState(""); // หน่วยงานเขต
  const [sectorAgency, setSectorAgency] = useState(""); // หน่วยงานภาค
  const [loanType, setLoanType] = useState(""); // ประเภทสินเชื่อ
  const [typeOfCollateral, setTypeOfCollateral] = useState(""); // ประเภทหลักประกัน
  const [sueDay, setSueDay] = useState(null); // วันฟ้อง
  const [courtDate, setCourtDate] = useState(null); // วันขึ้นศาล
  const [courtTime, setCourtTime] = useState(null); // เวลาขึ้นศาล
  const [noticeDayGet, setNoticeDayGet] = useState(null);
  const [noticeDayOut, setNoticeDayOut] = useState(null);
  const [noticeInvestigate, setNoticeInvestigate] = useState(""); // สอบสวนโนติส
  const [principalCapital, setPrincipalCapital] = useState(""); // ทุนทรัพย์เงินต้น
  const [interestCapital, setInterestCapital] = useState(""); // ทุนทรัพย์ดอกเบี้ย
  const [court, setCourt] = useState(""); // ศาล
  const [blackCaseNumber, setBlackCaseNumber] = useState(""); // เลขคดีดำเลขที่
  const [blackCaseYear, setBlackCaseYear] = useState(""); // เลขคดีดำปี
  const [redCaseNumber, setRedCaseNumber] = useState(""); // เลขคดีแดงเลขที่
  const [redCaseYear, setRedCaseYear] = useState(""); // เลขคดีดำปี
  const [witnessExaminationDate, setWitnessExaminationDate] = useState(null); // นัดสืบพยานวันที่
  const [judgmentDay, setJudgmentDay] = useState(null); // วันพิพากษา
  const [judgmentUnilateral, setJudgmentUnilateral] = useState(null); // พิพากษา(ฝ่ายเดียว)
  const [judgmentAccordingToConsent, setJudgmentAccordingToConsent] =
    useState(null); // พิพากษา(ตามยอม)
  const [detailsJudgment, setDetailsJudgment] = useState(""); // รายละเอียด คำพิพากษา
  const [returnFile, setReturnFile] = useState(null); // ส่งแฟ้มคืน
  const [returnFileReason, setReturnFileReason] = useState(""); // ส่งแฟ้มคืนเหตุผล
  const [decree, setDecree] = useState(null); // ออกคำบังคับ
  const [issueAWarranOfExecution, setIssueAWarranOfExecution] = useState(null); // ออกคำบังคับ
  const [withdrawOneCase, setWithdrawOneCase] = useState(null); // ถอนฟ้อง
  const [withdrawOneCaseDueTo, setWithdrawOneCaseDueTo] = useState(""); // เนื่องจาก
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [checkFile, setCheckFile] = useState(false);
  const [countDefendant, setCountDefendant] = useState(0); // จำนวนผู้ค้ำประกัน
  const [coDefendantPrefix, setCoDefendantPrefix] = useState([]); // คำนำหน้าผู้ค้ำประกัน
  const [coDefendantName, setCoDefendantName] = useState([]); // ชื่อผู้ค้ำประกัน
  const [coDefendantLastName, setCoDefendantLastName] = useState([]); // นามสกุลผู้ค้ำประกัน
  const [coDefendantAddress, setCoDefendantAddress] = useState([]); // ที่อยู่ผู้ค้ำประกัน
  const [coDefendantIdCard, setCoDefendantIdCard] = useState([]); // บัตรประชาชนผู้ค้ำประกัน
  const [coDefendantStatus, setCoDefendantStatus] = useState([]); // สถานะผู้ค้ำประกัน
  const [statusDefendant, setStatusDefendant] = useState([]);
  const [addressCoDefendant, setAddressCoDefendant] = useState([]);
  const [prefixDefendantAgent, setPrefixDefendantAgent] = useState([]); // ชื่อตัวแทนผู้ค้ำประกัน
  const [nameDefendantAgent, setNameDefendantAgent] = useState([]); // ชื่อตัวแทนผู้ค้ำประกัน
  const [lastNameDefendantAgent, setLastNameDefendantAgent] = useState([]); // นามสกุลตัวแทนผู้ค้ำประกัน
  const [idCardDefendantAgent, setIdCardDefendantAgent] = useState([]); // รหัสบัตรประชาชนตัวแทนผู้ค้ำประกัน
  const [addressDefendantAgent, setAddressDefendantAgent] = useState([]); // ที่อยู่ตัวแทนผู้ค้ำประกัน
  const [statusDefendantAgent, setStatusDefendantAgent] = useState([]); // สถานะตัวแทนผู้ค้ำประกัน
  const [note, setNote] = useState(""); // หมายเหตุ
  const [companyCode, setCompanyCode] = useState(""); // เลขคดีบริษัท
  const [courtCode, setCourtCode] = useState(""); // เลขคดีศาล
  const [chooseMemberCompany, setChooseMemberCompany] = useState(""); // ไอดีผู้รับผิดชอบคดี
  const [chooseMemberCompanyName, setChooseMemberCompanyName] = useState(""); // ผู้รับผิดชอบคดี
  const [appealDueDate, setAppealDueDate] = useState(null); // วันครบกำหนดอุทธรณ์
  const [phone, setPhone] = useState(""); // โทรศัพท์
  const [phone1, setPhone1] = useState(""); // โทรสาร
  const [fileNumber, setFileNumber] = useState(""); // เลขแฟ้ม
  const [other, setOther] = useState("");
  const [debtCenterList, setDebtCenterList] = useState([]);
  const [countCourtEtc, setCountCourtEtc] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedThaiDate, setSelectedThaiDate] = useState(null);
  const [dateGoCourtEtc, setDateGoCourtEtc] = useState([]); // วันที่ขึ้นศาลครั้งนั้น
  const [timeGoCourtEtc, setTimeGoCourtEtc] = useState([]); // เวลาขึ้นศาลครั้งนั้น
  const [lawyerGoCourtEtc, setLawyerGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [lawyerNameGoCourtEtc, setLawyerNameGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [detailGoCourtEtc, setDetailGoCourtEtc] = useState([]); // รายละเอียดขึ้นศาลครั้งนั้น
  const [countCodefendant, setCountCodefendant] = useState(0);
  const [countDefendantAgent, setCountDefendantAgent] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [courtList, setCourtList] = useState([]);
  const [prefixList, setPrefixList] = useState([]);
  const [caseStatus, setCaseStatus] = useState(""); // สถานะคดีปัจจุบัน
  const [closeDate, setCloseDate] = useState(new Date());
  const [typeDocsUpload, setTypeDocsUpload] = useState("");
  const [topicDocsUpload, setTopicDocsUpload] = useState("");
  //   const [typeDocsList, setTypeDocsList] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);
  //   const fileTypes = ["JPEG", "PNG", "GIF"];
  const fileTypes = ["PDF"];
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fileList, setFileList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [executionCase, setExecutionCase] = useState("");
  const [executionCode, setExecutionCode] = useState("");
  const [executionStatus, setExecutionStatus] = useState("");
  const [executionDateStart, setExecutionDateStart] = useState(null);
  const [executionDeadline, setExecutionDeadline] = useState(null);
  const [executionCourtDate, setExecutionCourtDate] = useState(null);
  const [executionCourtTime, setExecutionCourtTime] = useState(null);
  const [executionCourtNumber, setExecutionCourtNumber] = useState("");
  const [executionWorkBy, setExecutionWorkBy] = useState("");
  const [executionWorkByName, setExecutionWorkByName] = useState("");
  const [bringTheRegulations, setBringTheRegulations] = useState(null);
  const [writOfExecutionDate, setWritOfExecutionDate] = useState(null);
  const [gsbNumber, setGsbNumber] = useState("");


  const [countLoan, setCountLoan] = useState(0);
  const [loanTypeList, setLoanTypeList] = useState([]); // ประเภทสินเชื่อ
  const [typeOfCollateralList, setTypeOfCollateralList] = useState([]); // ประเภทหลักประกัน
  const [noticeDayOutList, setNoticeDayOutList] = useState([]); // วันออกโนติส
  const [noticeDayGetList, setNoticeDayGetList] = useState([]); // วันรับโนติส
  const [noticeInvestigateList, setNoticeInvestigateList] = useState([]); // สอบสวนโนติส
  const [principalList, setPrincipalList] = useState([]); // เงินต้น
  const [interestList, setInterestList] = useState([]); // ดอกเบี้ย
  const [gsbNumberList, setGsbNumberList] = useState([]); // เลขสัญญา
  const [dateCalInterestSinceList, setDateCalInterestSinceList] = useState([]); //
  const [countDateList, setCountDateList] = useState([]); //

  const [countCoBorrower, setCountCoBorrower] = useState(0); // จำนวนผู้กู้ร่วม
  const [coBorrowerPrefix, setCoBorrowerPrefix] = useState([]); // คำนำหน้าผู้กู้ร่วม
  const [coBorrowerName, setCoBorrowerName] = useState([]); // ชื่อผู้กู้ร่วม
  const [coBorrowerLastName, setCoBorrowerLastName] = useState([]); // นามสกุลผู้กู้ร่วม
  const [coBorrowerAddress, setCoBorrowerAddress] = useState([]); // ที่อยู่ผู้กู้ร่วม
  const [coBorrowerIdCard, setCoBorrowerIdCard] = useState([]); // บัตรประชาชนผู้กู้ร่วม
  const [coBorrowerStatus, setCoBorrowerStatus] = useState([]); // สถานะผู้กู้ร่วม

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const handleDownload = (row) => {
    const file_path = row.path;
    const fileName = path.basename(file_path);
    saveActivityLog(`Click download file: ${fileName}`);
    const download_url = `${config.mainAPI}/download-file?path=${file_path}`;
    window.location.href = download_url;
  };

  function createData(order, type, date, path) {
    const formattedDate = moment(date)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY");
    return {
      order,
      // list,
      type,
      date: date ? formattedDate : null,
      path,
    };
  }

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    // { id: "list", label: "รายการ", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
    {
      id: "file",
      label: "ไฟล์เอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleDownload(row)} disabled={!row.path}>
            <PictureAsPdfIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  function getDdlPrefix() {
    axios
      .get(`${config.mainAPI}/prefix-list`)
      .then((response) => {
        console.log("value", response.data.data);
        setPrefixList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getListFile() {
    axios
      .get(`${config.mainAPI}/file-list/${params}`)
      .then((response) => {
        console.log("value filelist", response.data.data);
        setFileList(response.data.data);
        const value = response.data.data;
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              // item.file_topic,
              item.file_type,
              item.created_at,
              item.file_path,
              item.update_at
            )
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDdlDebtCenter() {
    api
      .get(`${config.mainAPI}/debtCenter`)
      .then((response) => {
        console.log("value", response.data.data);
        setDebtCenterList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Owner เห็นเอกสารทั้งหมด
  // User เห็นเอกสารเฉพาะหน้าที่ของตัวเอง

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log("datana", JSON.parse(userData));
    setUser(JSON.parse(userData));
    getUserCompany();
    getDdlDebtCenter();
    getDdlPrefix();
    getCourtList();
    if (params) {
      getDataForm();
      getListFile();
    }
  }, []);
  const navigate = useNavigate();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getDataForm = () => {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/documents/${params}`)
      .then((response) => {
        console.log("response.data.data", response.data.data);
        setData(response.data.data[0]);
        const value = response.data.data[0];
        setDateStart(value.date_start);
        setDeadline(value.deadline);
        setFileNumber(value.file_number);
        setPrefix(value.defendant_prefix);
        setDefendantFirstName(value.defendant_firstname);
        setDefendantLastName(value.defendant_lastname);
        setDefendantIdCard(value.defendant_idcard);
        setDefendantAccountNumber(value.defendant_account_number);
        setDefendantAccountNumber1(value.defendant_account_number1);
        setDefendantHouseNumber(value.defendant_house_number); // (value.defendantHouseNumber) บ้านเลขที่
        setDefendantGroup(value.defendant_group); // หมู่
        setDefendantRoad(value.defendant_road); // ถนน
        setDefendantSoi(value.defendant_soi); // ซอย
        setDefendantAddress(value.defendant_address);
        setDefendantTambon(parseInt(value.defendant_tambon));
        setDefendantTambonName(value.defendant_tambon_show);
        setDefendantAmphoe(parseInt(value.defendant_amphoe));
        setDefendantAmphoeName(value.defendant_amphoe_show);
        setDefendantProvince(parseInt(value.defendant_province));
        setDefendantProvinceName(value.defendant_province_show);
        if (value.defendant_province) {
          console.log("value.defendant_province", value.defendant_province);
          getAmphoesByProvinceId(parseInt(value.defendant_province));
        }
        if (value.defendant_amphoe) {
          console.log("value.defendant_amphoe", value.defendant_amphoe);
          getTambonsByAmphoeId(parseInt(value.defendant_amphoe));
        }
        setDefendantZipcode(parseInt(value.defendant_zipcode));
        setPhone(value.defendant_phone);
        setPhone1(value.defendant_phone1);
        setDebtCenter(value.debt_center);
        setBranchAgency(value.branch_agency);
        setDistrictAgency(value.district_agency);
        setSectorAgency(value.sector_agency);


        if (value.contract) {
          setCountLoan(value.contract.length)
          value.contract.map((item, index) => {
            setLoanTypeList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.contract_type;
              return updatedState;
            });

            setGsbNumberList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.contract_number;
              return updatedState;
            });

            setTypeOfCollateralList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.type_of_collateral;
              return updatedState;
            });

            setNoticeDayOutList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.notic_out_day;
              return updatedState;
            });

            setNoticeDayGetList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.notic_get_day;
              return updatedState;
            });

            setNoticeInvestigateList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.notic_description;
              return updatedState;
            });

            setPrincipalList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.principal;
              return updatedState;
            });

            setInterestList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.interest;
              return updatedState;
            });

            setDateCalInterestSinceList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.date_of_interest;
              return updatedState;
            });

            setCountDateList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.count_day;
              return updatedState;
            });
          })
        }

        setCountCoBorrower(value.subdefendant_count);
        if (value.subDefendant) {
          value.subDefendant.map((item, index) => {
            setCoBorrowerStatus((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_status;
              return updatedState;
            });

            setCoBorrowerPrefix((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_prefix;
              return updatedState;
            });

            setCoBorrowerName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_firstname;
              return updatedState;
            });

            setCoBorrowerLastName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_lastname;
              return updatedState;
            });

            setCoBorrowerIdCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_idcard;
              return updatedState;
            });

            setCoBorrowerAddress((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_address;
              return updatedState;
            });
          })
        }


        setLoanType(value.loan_type);
        setTypeOfCollateral(value.type_of_collateral);
        setSueDay(value.sue_day);
        setNoticeDayGet(value.notic_get);
        setNoticeDayOut(value.notic_out);
        setNoticeInvestigate(value.notice_investigate);
        setPrincipalCapital(value.principal_capital);
        setInterestCapital(value.interest_capital);
        setCourt(value.court);
        setCourtDate(
          value.court_date ? moment(value.court_date).toDate() : null
        );
        setCourtTime(value.court_time);
        setCountCourtEtc(value.court_count - 1);
        if (value.court_count > 1 && value.goCourtEtc) {
          // มันต้อง + 1
          value.goCourtEtc.forEach((item, index) => {
            // console.log("index", index);

            setDateGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_date;
              return updatedState;
            });

            setTimeGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_time;
              return updatedState;
            });

            setLawyerGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by;
              return updatedState;
            });

            setLawyerNameGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by_name;
              return updatedState;
            });

            setDetailGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_detail;
              return updatedState;
            });
            // }
          });
        }
        setBlackCaseNumber(value.black_case_number);
        setBlackCaseYear(value.black_case_year);
        setRedCaseNumber(value.red_case_number);
        setRedCaseYear(value.red_case_year);
        setWitnessExaminationDate(
          value.witness_examination_date
            ? moment(value.witness_examination_date).toDate()
            : null
        );
        setJudgmentDay(
          value.judgment_day ? moment(value.judgment_day).toDate() : null
        );
        setAppealDueDate(
          value.appeal_due_date ? moment(value.appeal_due_date).toDate() : null
        );
        setJudgmentUnilateral(
          value.judgment_unilateral
            ? moment(value.judgment_unilateral).toDate()
            : null
        );
        setJudgmentAccordingToConsent(
          value.judgment_according_to_consent
            ? moment(value.judgment_according_to_consent).toDate()
            : null
        );
        setDetailsJudgment(value.judgment_details);
        setReturnFile(
          value.return_file ? moment(value.return_file).toDate() : null
        );
        setReturnFileReason(value.return_file_reason);
        setDecree(value.decree ? moment(value.decree).toDate() : null);
        setIssueAWarranOfExecution(
          value.issue_a_warran_of_execution
            ? moment(value.issue_a_warran_of_execution).toDate()
            : null
        );
        setWithdrawOneCase(
          value.withdraw_one_case
            ? moment(value.withdraw_one_case).toDate()
            : null
        );
        setWithdrawOneCaseDueTo(value.withdraw_one_case_due_to);
        if (value.file_name && value.file_path) {
          setCheckFile(true);
        }
        setFileName(value.file_name);
        setFilePath(value.file_path);
        setNote(value.note);
        setChooseMemberCompany(value.work_by);
        setChooseMemberCompanyName(value.work_by_name);
        setCountDefendant(value.codefendant_count);
        setCountCodefendant(value.codefendant_count);
        if (value.codefendant_count > 0) {
          value.coDefendant.forEach((item, index) => {
            setCoDefendantPrefix((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_prefix;
              return updatedState;
            });

            setCoDefendantName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_firstname;
              return updatedState;
            });

            setCoDefendantLastName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_lastname;
              return updatedState;
            });

            setCoDefendantAddress((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_address;
              return updatedState;
            });

            setCoDefendantIdCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_idcard;
              return updatedState;
            });

            setCoDefendantStatus((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_status;
              return updatedState;
            });
          });
        }

        // setCountDefendantAgent()
        if (value.defendantAgent) {
          let countDefendantAgentShow = value.defendantAgent.filter(
            (item) =>
              item.defendant_agent_status &&
              item.defendant_agent_firstname &&
              item.defendant_agent_lastname &&
              item.defendant_agent_prefix
          );
        }
        setCountDefendantAgent(
          value.defendantAgent ? value.defendantAgent.length : 0
        );
        if (value.defendantAgent && value.defendantAgent.length > 0) {
          value.defendantAgent.forEach((item, index) => {
            setPrefixDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_prefix;
              return updatedState;
            });

            setNameDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_firstname;
              return updatedState;
            });

            setLastNameDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_lastname;
              return updatedState;
            });

            setIdCardDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_idcard;
              return updatedState;
            });

            setAddressDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_address;
              return updatedState;
            });

            setStatusDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_status;
              return updatedState;
            });
          });
        }
        setOther(value.other);
        setCourtCode(value.court_code);
        setCompanyCode(value.company_code);
        setExecutionCase(value.execution_case);
        if (value.execution_case) {
          setExecutionStatus(value.execution_status);
          setExecutionCode(value.execution_code);
          setExecutionDeadline(value.led_deadline);
          setExecutionDateStart(value.led_date_start);
          setExecutionCourtDate(value.court_date_led);
          setExecutionCourtTime(value.court_time_led);
          setBringTheRegulations(value.bring_the_regulations);
          setWritOfExecutionDate(value.writ_of_execution_date);
          setExecutionWorkBy(value.work_by_led);
          setExecutionWorkByName(value.work_by_led_name);
        }
        setGsbNumber(value.gsb_number);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const disabledHours = () => {
    const disabledHoursArray = [];
    for (let i = 0; i < 8; i++) {
      disabledHoursArray.push(i);
    }
    for (let i = 19; i <= 23; i++) {
      disabledHoursArray.push(i);
    }
    return disabledHoursArray;
  };

  function getCourtList() {
    axios
      .get(`${config.mainAPI}/court-list`)
      .then((response) => {
        console.log("getCourtList", response.data.data);
        setCourtList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleCoDefendantStatusChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantStatus((prevStatusDefendant) => {
      const newStatusDefendant = [...prevStatusDefendant];
      newStatusDefendant[index] = value;
      return newStatusDefendant;
    });
  };

  const handleCoDefendantPrefixChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantPrefix((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantLastNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantLastName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantAddressChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantAddress((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  const handleCoDefendantIdCardChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantIdCard((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  //   setPrefixDefendantAgent;

  const handleCloseFailure = () => {
    setOpenFailure(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate("/loan");
  };

  const handlePrefixDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setPrefixDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleLastNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setLastNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleIdCardDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setIdCardDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleAddressDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setAddressDefendantAgent((prevStatusDefendant) => {
      const newAddressDefendantAgent = [...prevStatusDefendant];
      newAddressDefendantAgent[index] = value;
      return newAddressDefendantAgent;
    });
  };

  const handleStatusDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setStatusDefendantAgent((prevStatusDefendant) => {
      const newStatusDefendantAgent = [...prevStatusDefendant];
      newStatusDefendantAgent[index] = value;
      return newStatusDefendantAgent;
    });
  };

  // const handleDateGoCourtEtcChange = (event, index) => {
  //   // const { value } = event.target;
  //   const { value } = event;
  //   console.log(value);
  //   setDateGoCourtEtc((prevStatusDefendant) => {
  //     const data = [...prevStatusDefendant];
  //     data[index] = value;
  //     return data;
  //   });
  // };

  // const handleDateGoCourtEtcChange = (item, index) => {
  //   // const { value } = event.target;
  //   const { value } = item;
  //   console.log(value);
  //   setDateGoCourtEtc((prevStatusDefendant) => {
  //     const data = [...prevStatusDefendant];
  //     data[index] = value;
  //     return data;
  //   });
  // };

  const handleDateGoCourtEtcChange = (item, index) => {
    setDateGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      console.log("item", item);
      data[index] = item;
      console.log("data", data);
      return data;
    });
  };

  const handleDateGoCourtEtcChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleTimeGoCourtEtcChange = (date, index) => {
    const formattedDate = date;
    setTimeGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = formattedDate;
      return data;
    });
  };

  const handleLawyerGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    console.log("workByName", workByName);
    console.log("workById", workById);
    setLawyerGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workById;
      return data;
    });
    setLawyerNameGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workByName;
      return data;
    });
  };

  const handleDetailGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    setDetailGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const goCourtEtc = dateGoCourtEtc.map((item, index) => ({
    date: item ? moment(item).format("YYYY-MM-DD") : null,
    time: timeGoCourtEtc[index] ? timeGoCourtEtc[index] : null,
    lawyer: lawyerGoCourtEtc[index] ? lawyerGoCourtEtc[index] : null,
    lawyerName: lawyerNameGoCourtEtc[index]
      ? lawyerNameGoCourtEtc[index]
      : null,
    detail: detailGoCourtEtc[index] ? detailGoCourtEtc[index] : null,
    round: index + 1,
  }));

  const coDefendant = coDefendantName.map((item, index) => ({
    prefix: coDefendantPrefix[index],
    name: item,
    lastname: coDefendantLastName[index],
    status: coDefendantStatus[index],
    address: coDefendantAddress[index],
    idCard: coDefendantIdCard[index],
    role: `ผู้ค้ำประกันที่${[index + 1]}`,
    order: index + 1,
  }));

  const defendantAgent = nameDefendantAgent.map((item, index) => ({
    prefix: prefixDefendantAgent[index] || null,
    name: item || null,
    lastname: lastNameDefendantAgent[index] || null,
    address: addressDefendantAgent[index] || null,
    idCard: idCardDefendantAgent[index] || null,
    status: statusDefendantAgent[index] || null,
    role: `ตัวแทนผู้ค้ำประกันที่${[index + 1]}` || null,
    order: index + 1,
  }));

  const handleSubmit = (row) => {
    const caseData = params != null ? "edit" : "add";
    console.log("caseData", caseData);
    if (
      dateStart &&
      defendantFirstName &&
      defendantLastName &&
      defendantIdCard &&
      defendantAccountNumber &&
      chooseMemberCompany
    ) {
      const payload = {
        dateStart: dateStart,
        deadline: deadline,
        fileNumber: fileNumber,
        prefix: prefix,
        defendantFirstName: defendantFirstName,
        defendantLastName: defendantLastName,
        defendantIdCard: defendantIdCard,
        defendantAccountNumber: defendantAccountNumber,
        defendantAccountNumber1: defendantAccountNumber1,
        defendantAccount: defendantAccount,
        defendantHouseNumber: defendantHouseNumber,
        defendantGroup: defendantGroup,
        defendantRoad: defendantRoad,
        defendantSoi: defendantSoi,
        defendantAddress: defendantAddress,
        defendantTambonName: defendantTambonName,
        defendantAmphoeName: defendantAmphoeName,
        defendantProvinceName: defendantProvinceName,
        defendantTambon: defendantTambon,
        defendantAmphoe: defendantAmphoe,
        defendantProvince: defendantProvince,
        defendantZipcode: defendantZipcode,
        phone: phone,
        phone1: phone1,
        debtCenter: debtCenter,
        branchAgency: branchAgency,
        districtAgency: districtAgency,
        sectorAgency: sectorAgency,
        loanType: loanType,
        typeOfCollateral: typeOfCollateral,
        noticeDayGet: noticeDayGet
          ? moment(noticeDayGet).format("YYYY-MM-DD")
          : null,
        noticeDayOut: noticeDayOut
          ? moment(noticeDayOut).format("YYYY-MM-DD")
          : null,
        noticeInvestigate: noticeInvestigate,
        sueDay: sueDay ? moment(sueDay).format("YYYY-MM-DD") : null,
        principalCapital: principalCapital,
        interestCapital: interestCapital,
        court: court,
        courtDate: courtDate ? moment(courtDate).format("YYYY-MM-DD") : null,
        courtTime: courtTime,
        countCourtEtc: parseInt(countCourtEtc),
        goCourtEtc: goCourtEtc,
        blackCaseNumber: blackCaseNumber,
        blackCaseYear: blackCaseYear,
        redCaseNumber: redCaseNumber,
        redCaseYear: redCaseYear,
        witnessExaminationDate: witnessExaminationDate
          ? moment(witnessExaminationDate).format("YYYY-MM-DD")
          : null,
        judgmentUnilateral: judgmentUnilateral
          ? moment(judgmentUnilateral).format("YYYY-MM-DD")
          : null,
        judgmentAccordingToConsent: judgmentAccordingToConsent
          ? moment(judgmentAccordingToConsent).format("YYYY-MM-DD")
          : null,
        judgmentDay: judgmentDay
          ? moment(judgmentDay).format("YYYY-MM-DD")
          : null,
        detailsJudgment: detailsJudgment,
        withdrawOneCase: withdrawOneCase
          ? moment(withdrawOneCase).format("YYYY-MM-DD")
          : null,
        withdrawOneCaseDueTo: withdrawOneCaseDueTo,
        returnFile: returnFile ? moment(returnFile).format("YYYY-MM-DD") : null,
        returnFileReason: returnFileReason,
        decree: decree ? moment(decree).format("YYYY-MM-DD") : null,
        issueAWarranOfExecution: issueAWarranOfExecution
          ? moment(issueAWarranOfExecution).format("YYYY-MM-DD")
          : null,
        fileName: fileName,
        filePath: filePath,
        coDefendant: coDefendant,
        countDefendant: parseInt(countDefendant),
        defendantAgent: defendantAgent,
        companyCode: companyCode,
        courtCode: courtCode,
        chooseMemberCompany: chooseMemberCompany,
        chooseMemberCompanyName: chooseMemberCompanyName,
        appealDueDate: appealDueDate
          ? moment(appealDueDate).format("YYYY-MM-DD")
          : null,
        genCode: genCode,
        caseData: caseData,
        note: note,
        other: other,
        user: user,
        countCodefendentDB: caseData == "edit" ? countCodefendant : null,
        countDefendentAgentDB: caseData == "edit" ? countDefendantAgent : null,
        countGoCourtDB:
          caseData == "edit" ? countCourtEtc + 1 : courtDate ? 1 : 0,
        // if(caseData === "edit"){

        // }
      };
      console.log("payload", payload);
      axios
        .post(`${config.mainAPI}/documents`, {
          ...payload,
        })
        .then(function (response) {
          setOpenSuccess(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setOpenFailure(true);
    }
  };

  const handleBack = () => {
    navigate("/loan");
  };

  const handleFileChange = (event) => {
    const fileData = event.target.files[0];
    setFile(fileData);
    setFile1(fileData);
  };

  const uploadFile = async () => {
    if (file) {
      console.log("file", file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      try {
        const res = await axios.post(`${config.mainAPI}/upload-file`, formData);
        console.log(res);
        setFileName(res.data.name);
        setFilePath(res.data.path);
        setCheckFile(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteFile = () => {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/delete-file`, {
        filePath,
      })
      .then(function (response) {
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  // function handleDownload() {
  //   const file_path = filePath;
  //   const download_url = `${config.mainAPI}/download-file?file_path=${file_path}`;
  //   window.location.href = download_url;
  // }

  const handleProvinceChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantProvinceName = event.currentTarget.getAttribute("name");
      setDefendantProvince(value);
      setDefendantProvinceName(defendantProvinceName);
      setDefendantAmphoe("");
      setDefendantTambon("");
    }
  };

  const handleAmphoeChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantAmphoeName = event.currentTarget.getAttribute("name");
      setDefendantAmphoe(value);
      setDefendantAmphoeName(defendantAmphoeName);
      setDefendantTambon("");
    }
  };

  const handleTambonChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantTambonName = event.currentTarget.getAttribute("name");
      const defendantTambonZipcode = event.currentTarget.getAttribute("code");
      setDefendantZipcode(defendantTambonZipcode);
      setDefendantTambon(value);
      setDefendantTambonName(defendantTambonName);
    }
  };

  const getAmphoesByProvinceId = (provinceId) => {
    if (provinceId) {
      return provincesData.find((province) => province.id === provinceId)
        .amphure;
    }
  };

  const getTambonsByAmphoeId = (amphoeId) => {
    if (amphoeId) {
      return provincesData
        .flatMap((province) => province.amphure)
        .find((amphoe) => amphoe.id === amphoeId).tambon;
    }
  };

  function getUserCompany() {
    axios
      .get(`${config.mainAPI}/users-company`)
      .then((response) => {
        setUserCompany(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleHistoryWithDraw = () => {
    const id = params;
    navigate("/draw", { state: { params: id } });
  };

  const handleHistoryGetMoney = () => {
    const id = params;
    navigate("/money", { state: { params: id } });
  };

  const handleSetNotification = () => {
    setOpenSetNotifition(true);
  };
  const handleCloseSetNotification = () => {
    setOpenSetNotifition(false);
  };

  const handleWorkByChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setChooseMemberCompany(workById);
    setChooseMemberCompanyName(workByName);
  };

  function updateStatusFinish() {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/document-success`, {
        genCode: genCode,
        type: "loan",
      })
      .then(function (response) {
        setIsLoading(false);
        setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setOpenFailure(true);
      });
  }

  const handleSetOpenCloseCase = () => {
    setOpenCloseCase(true);
  };

  const handleCloseSetOpenCloseCase = () => {
    setOpenCloseCase(false);
  };

  const clearDate = () => {
    setStartDate(null);
  };

  const updateCountCourtEtc = (event) => {
    setCountCourtEtc(event);
    console.log("CountCourtEtc", event);

    // const handleWorkByChange = (event) => {
    //   const value = parseInt(event.target.dataset.value);
    //   const workByName = event.currentTarget.getAttribute("name");
    //   const workById = event.currentTarget.getAttribute("id");
    //   setChooseMemberCompany(workById);
    //   setChooseMemberCompanyName(workByName);
    // };

    // Splice data from arrays if their lengths are greater than countCourtEtc
    // อนาคตต้องมีการดักว่าต้องกรอกนะ
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
        >
          ข้อมูลคดีสินเชื่อ
        </Typography>
        <Box sx={{ padding: "30px" }}>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ข้อมูลลูกหนี้"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำเลย:{" "}
                    <span style={{ marginLeft: 5 }}>
                      {prefix}
                      {defendantFirstName} {defendantLastName}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    บัตรประชาชน:
                    <span style={{ marginLeft: 5 }}>{defendantIdCard}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    บัญชีหลัก:
                    <span style={{ marginLeft: 5 }}>
                      {defendantAccountNumber}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    บัญชีสำรอง:
                    <span style={{ marginLeft: 5 }}>
                      {defendantAccountNumber1}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำนวนสินเชื่อ:
                    <span style={{ marginLeft: 5 }}>{countLoan}</span>
                  </Typography>
                </Grid>
              </Grid>

              {countLoan > 0 && (
                <>
                  {[...Array(parseInt(countLoan))].map((_, i) => (
                    <React.Fragment key={i}>
                      <div class="separator">สินเชื่อลำดับที่ {i + 1}</div>
                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เลขสัญญา:
                            <span style={{ marginLeft: 5 }}>{gsbNumberList[i]}</span>
                          </Typography>
                          {/* <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              id={`gsbNumberList${i}`}
                              name={`gsbNumberList${i}`}
                              value={gsbNumberList[i] || ""}
                              onChange={(e) =>
                                onChangeMultipleValue(setGsbNumberList, e, i)
                              }
                            /> */}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ประเภทสินเชื่อ:
                            <span style={{ marginLeft: 5 }}>{loanTypeList[i]}</span>
                          </Typography>
                          {/* <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              id={`loanTypeList${i}`}
                              name={`loanTypeList${i}`}
                              value={loanTypeList[i] || ""}
                              onChange={(e) =>
                                onChangeMultipleValue(setLoanTypeList, e, i)
                              }
                            /> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ประเภทหลักประกัน:
                            <span style={{ marginLeft: 5 }}>{typeOfCollateralList[i]}</span>
                          </Typography>
                          {/* <Select
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                width: "100%",
                              }}
                              color="dark"
                              id={`typeOfCollateralList${i}`}
                              name={`typeOfCollateralList${i}`}
                              value={typeOfCollateralList[i] || ""}
                              onChange={(e) =>
                                onChangeMultipleValue(
                                  setTypeOfCollateralList,
                                  e,
                                  i
                                )
                              }
                            >
                              {listTypeOfCollateral.map((column) => (
                                <MenuItem key={column.id} value={column.value}>
                                  {column.value}
                                </MenuItem>
                              ))}
                            </Select> */}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เงินต้น:
                            <span style={{ marginLeft: 5 }}>{principalList[i]}</span>
                          </Typography>
                          {/* <TextField
                              type="number"
                              color="dark"
                              fullWidth
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              InputProps={{ inputProps: { min: 0 } }}
                              id={`principalList${i}`}
                              name={`principalList${i}`}
                              value={principalList[i] || ""}
                              onChange={(e) =>
                                onChangeMultipleValue(setPrincipalList, e, i)
                              }
                            /> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ดอกเบี้ย:
                            <span style={{ marginLeft: 5 }}>{interestList[i]}</span>
                          </Typography>
                          {/* <TextField
                              type="number"
                              color="dark"
                              fullWidth
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              InputProps={{ inputProps: { min: 0 } }}
                              id={`interestList${i}`}
                              name={`interestList${i}`}
                              value={interestList[i] || ""}
                              disabled={!principalList[i]}
                              onChange={(e) =>
                                onChangeMultipleValue(setInterestList, e, i)
                              }
                            /> */}
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ยอดรวม:
                            <span style={{ marginLeft: 5 }}>{principalList[i]
                              ? parseInt(principalList[i]) +
                              (interestList[i]
                                ? parseInt(interestList[i])
                                : 0)
                              : ""}</span>
                          </Typography>
                          {/* <TextField
                              type="number"
                              color="dark"
                              fullWidth
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              InputProps={{ inputProps: { min: 0 } }}
                              disabled
                              value={
                                principalList[i]
                                  ? parseInt(principalList[i]) +
                                  (interestList[i]
                                    ? parseInt(interestList[i])
                                    : 0)
                                  : ""
                              }
                            /> */}
                        </Grid>
                      </Grid>

                      {/* <Grid container spacing={2} sx={{ mb:5 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              วันที่คำนวณดอกเบี้ยถึง :
                            </Typography>
                            <WatDatePicker
                              className="custom-date-picker"
                              value={dateCalInterestSinceList[i]}
                              onChange={(christDate, buddhistDate) =>
                                onChangeDateValue(
                                  christDate,
                                  buddhistDate,
                                  i,
                                  setDateCalInterestSinceList
                                )
                              }
                              dateFormat={"yyyy-MM-dd"}
                              displayFormat={"DD/MM/YYYY"}
                              clearable={true}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              จำนวนวัน:
                            </Typography>
                            <TextField
                              type="number"
                              color="dark"
                              fullWidth
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              InputProps={{ inputProps: { min: 0 } }}
                              id={`countDateList${i}`}
                              name={`countDateList${i}`}
                              value={countDateList[i] || ""}
                              onChange={(e) =>
                                onChangeMultipleValue(setCountDateList, e, i)
                              }
                            />
                          </Grid>
                        </Grid> */}

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันออกโนติส:
                            {/* <span style={{ marginLeft: 5 }}>{interestList[i]}</span> */}
                          </Typography>
                          {/* <WatDatePicker
                              className="custom-date-picker"
                              value={noticeDayOutList[i]}
                              onChange={(christDate, buddhistDate) =>
                                onChangeDateValue(
                                  christDate,
                                  buddhistDate,
                                  i,
                                  setNoticeDayOutList
                                )
                              }
                              dateFormat={"yyyy-MM-dd"}
                              displayFormat={"DD/MM/YYYY"}
                              clearable={true}
                            /> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันรับโนติส:
                            {/* <span style={{ marginLeft: 5 }}>{interestList[i]}</span> */}
                          </Typography>
                          {/* <WatDatePicker
                              className="custom-date-picker"
                              value={noticeDayGetList[i]}
                              onChange={(christDate, buddhistDate) =>
                                onChangeDateValue(
                                  christDate,
                                  buddhistDate,
                                  i,
                                  setNoticeDayGetList
                                )
                              }
                              dateFormat={"yyyy-MM-dd"}
                              displayFormat={"DD/MM/YYYY"}
                              clearable={true}
                            /> */}
                        </Grid>
                      </Grid>

                      <Grid container sx={{ mb: 5 }}>
                        <Grid item xs={12}>
                          <Typography sx={{ fontSize: "16px" }}>
                            สอบสวนโนติส:
                            {/* <span style={{ marginLeft: 5 }}>{interestList[i]}</span> */}
                          </Typography>
                          {/* <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              id={`noticeInvestigateList${i}`}
                              name={`noticeInvestigateList${i}`}
                              value={noticeInvestigateList[i] || ""}
                              onChange={(e) =>
                                onChangeMultipleValue(
                                  setNoticeInvestigateList,
                                  e,
                                  i
                                )
                              }
                            /> */}
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              )}



              {/* <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ประเภทสินเชื่อ:
                    <span style={{ marginLeft: 5 }}>{loanType}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ประเภทหลักประกัน:
                    <span style={{ marginLeft: 5 }}>{typeOfCollateral}</span>
                  </Typography>
                </Grid>
              </Grid> */}

              {/* <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เงินต้น:
                    <span style={{ marginLeft: 5 }}>
                      {principalCapital
                        ? principalCapital.toLocaleString()
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ดอกเบี้ย:
                    <span style={{ marginLeft: 5 }}>
                      {interestCapital ? interestCapital.toLocaleString() : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid> */}

              {/* <Grid container spacing={2} sx={{mb:5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ที่อยู่:
                    <span style={{ marginLeft: 5 }}>
                      {defendantHouseNumber} หมู่: {defendantGroup} ถนน:{" "}
                      {defendantRoad} ซอย: {defendantSoi} ใกล้เคียง:{" "}
                      {defendantAddress} เขต/ตำบล: {defendantTambonName}{" "}
                      แขวง/อำเภอ: {defendantAmphoeName} จังหวัด:{" "}
                      {defendantProvinceName} รหัสไปรษณีย์: {defendantZipcode}
                    </span>
                  </Typography>
                </Grid>
              </Grid> */}
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ข้อมูลผู้กู้ร่วม"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำนวนผู้กู้ร่วม: {countCoBorrower}
                  </Typography>
                </Grid>
              </Grid>
              {countCoBorrower > 0 && (
                <>
                  {[...Array(parseInt(countCoBorrower))].map((_, i) => (
                    <React.Fragment key={i}>
                      <Box sx={{ mb: 10 }}>
                        {/* <Typography
                          sx={{ fontSize: "16px", mb: 3, fontWeight: 700 }}
                        >
                          ผู้ค้ำประกันที่{i + 1}
                        </Typography> */}
                        <div class="separator">ผู้กู้ร่วมที่ {i + 1}</div>

                        <Grid container spacing={2} sx={{ mb: 5 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              สถานะ:
                              <span style={{ marginLeft: 5 }}>
                                {coBorrowerStatus[i] || ""}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mb: 5 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ชื่อ:
                              <span style={{ marginLeft: 5 }}>
                                {coBorrowerPrefix[i] +
                                  coBorrowerName[i] + " " +
                                  coBorrowerLastName[i]}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              บัตรประชาชน:
                              <span style={{ marginLeft: 5 }}>
                                {coBorrowerIdCard[i] || ""}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mb: 5 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ที่อยู่:
                              <span style={{ marginLeft: 5 }}>
                                {coBorrowerAddress[i] || ""}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </React.Fragment>
                  ))}
                </>
              )}
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ข้อมูลผู้ค้ำประกัน"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำนวนผู้ค้ำประกัน: {countDefendant}
                  </Typography>
                </Grid>
              </Grid>

              {countDefendant > 0 && (
                <>
                  {[...Array(parseInt(countDefendant))].map((_, i) => (
                    <React.Fragment key={i}>
                      <Box sx={{ mb: 10 }}>
                        {/* <Typography
                          sx={{ fontSize: "16px", mb: 3, fontWeight: 700 }}
                        >
                          ผู้ค้ำประกันที่{i + 1}
                        </Typography> */}
                        <div class="separator">ผู้ค้ำประกันที่ {i + 1}</div>

                        <Grid container spacing={2} sx={{ mb: 5 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              สถานะ:
                              <span style={{ marginLeft: 5 }}>
                                {coDefendantStatus[i] || ""}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mb: 5 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ชื่อ:
                              <span style={{ marginLeft: 5 }}>
                                {coDefendantPrefix[i] +
                                  coDefendantName[i] + " " +
                                  coDefendantLastName[i]}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              บัตรประชาชน:
                              <span style={{ marginLeft: 5 }}>
                                {coDefendantIdCard[i] || ""}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mb: 5 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ที่อยู่:
                              <span style={{ marginLeft: 5 }}>
                                {coDefendantAddress[i] || ""}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        {coDefendantStatus[i] == "เสียชีวิต" ? (
                          <>
                            <Typography
                              sx={{ fontSize: "16px", mb: 3, fontWeight: 700 }}
                            >
                              ตัวแทน
                            </Typography>

                            <Grid container spacing={2} sx={{ mb: 5 }}>
                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  ชื่อตัวแทน:
                                  <span style={{ marginLeft: 5 }}>
                                    {prefixDefendantAgent[i] +
                                      nameDefendantAgent[i] + " " +
                                      lastNameDefendantAgent[i]}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  บัตรประชาชนตัวแทน:
                                  <span style={{ marginLeft: 5 }}>
                                    {idCardDefendantAgent[i] || ""}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ mb: 5 }}>
                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  ที่อยู่ตัวแทน:
                                  <span style={{ marginLeft: 5 }}>
                                    {addressDefendantAgent[i]}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  สถานะตัวแทน:
                                  <span style={{ marginLeft: 5 }}>
                                    {statusDefendantAgent[i]}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        ) : null}
                      </Box>
                    </React.Fragment>
                  ))}
                </>
              )}
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardHeader sx={{ backgroundColor: "#181414" }} title="ข้อมูลคดี" />
            <CardContent sx={{ border: "0.5px solid" }}>
              {/* <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขสัญญา: <span style={{ marginLeft: 5 }}>{gsbNumber}</span>
                  </Typography>
                </Grid>
              </Grid> */}
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ศูนย์หนี้:
                    <span style={{ marginLeft: 5 }}>{debtCenter}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    สาขา:
                    <span style={{ marginLeft: 5 }}>{branchAgency}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เขต:
                    <span style={{ marginLeft: 5 }}>{districtAgency}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ภาค:
                    <span style={{ marginLeft: 5 }}>{sectorAgency}</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีดำ:
                    <span style={{ marginLeft: 5 }}>
                      {blackCaseNumber && blackCaseYear
                        ? `${blackCaseNumber}/${blackCaseYear}`
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีแดง:
                    <span style={{ marginLeft: 5 }}>
                      {redCaseNumber && redCaseYear
                        ? `${redCaseNumber}/${redCaseYear}`
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  {/* <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีศาล:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography sx={{ fontSize: "16px" }}>
                        {courtCode}
                      </Typography>
                    </Grid>
                  </Grid> */}
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีศาล:
                    <span style={{ marginLeft: 5 }}>{courtCode}</span>
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันออกโนติส:{" "}
                    {noticeDayOut
                      ? moment(noticeDayOut)
                        .locale("th")
                        .add(543, "year")
                        .format("DD/MM/YYYY")
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันรับโนติส:{" "}
                    {noticeDayGet
                      ? moment(noticeDayGet)
                        .locale("th")
                        .add(543, "year")
                        .format("DD/MM/YYYY")
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    สอบสวนโนติส: {noticeInvestigate}
                  </Typography>
                </Grid>
              </Grid> */}
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันฟ้อง:{" "}
                    {sueDay
                      ? moment(sueDay)
                        .locale("th")
                        .add(543, "year")
                        .format("DD/MM/YYYY")
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ศาล: {court}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันขึ้นศาล:{" "}
                    {courtDate
                      ? moment(courtDate)
                        .locale("th")
                        .add(543, "year")
                        .format("DD/MM/YYYY")
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เวลาขึ้นศาล:{" "}
                    {courtTime
                      ? moment(courtTime, "HH:mm:ss").format("HH:mm")
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>

              {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำนวนวันนัดขึ้นศาลเพิ่มเติม:
                  </Typography>
                  <TextField
                    type="number"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    value={countCourtEtc}
                    disabled={!courtDate}
                    onChange={(e) => updateCountCourtEtc(e.target.value)}
                  />
                </Grid>
              </Grid>
              {countCourtEtc > 0 && (
                <>
                  {[...Array(parseInt(countCourtEtc))].map((_, i) => (
                    <React.Fragment key={i}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันขึ้นศาลครั้งที่{i + 2}: {dateGoCourtEtc[i]}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เวลาขึ้นศาลครั้งที่{i + 2}: {timeGoCourtEtc[i]}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ผู้รับผิดชอบวันขึ้นศาลครั้งที่ {i + 2}: {lawyerGoCourtEtc[i] }
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รายละเอียดวันขึ้นศาลครั้งที่ {i + 2}: {detailGoCourtEtc[i]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              )} */}

              {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันที่สืบพยาน: {witnessExaminationDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    พิพากษา(ฝ่ายเดียว): {judgmentUnilateral}
                  </Typography>
                
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    พิพากษา(ตามยอม): {judgmentAccordingToConsent}
                  </Typography>
                 
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>วันพิพากษา: {judgmentDay}</Typography>
               
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันครบกำหนดอุทรณ์: {appealDueDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>ถอนฟ้อง: {withdrawOneCase}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>เนื่องจาก: {withdrawOneCaseDueTo}</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    รายละเอียด คำพิพากษา(โดยย่อ): {detailsJudgment}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>ส่งแฟ้มคืน: {returnFile}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>เหตุผล: {returnFileReason}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ออกคำบังคับ: {decree}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ออกหมายบังคับคดี: {issueAWarranOfExecution}
                  </Typography>
                </Grid>
              </Grid> */}

              {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำนวนผู้ค้ำประกัน: {countDefendant}
                  </Typography>
                </Grid>

                {countDefendant > 0 && (
                  <>
                    {[...Array(parseInt(countDefendant))].map((_, i) => (
                      <React.Fragment key={i}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            สถานะผู้ค้ำประกันที่ {i + 1}: {coDefendantStatus[i]}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            คำนำหน้าผู้ค้ำประกันที่ {i + 1}: {coDefendantPrefix[i]}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ชื่อผู้ค้ำประกันที่ {i + 1}: {coDefendantName[i]}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            นามสกุลผู้ค้ำประกันที่ {i + 1}:{" "}
                            {coDefendantLastName[i]}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ที่อยู่ผู้ค้ำประกันที่ {i + 1}: {coDefendantAddress[i]}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            บัตรประชาชนผู้ค้ำประกันที่ {i + 1}:{" "}
                            {coDefendantIdCard[i]}
                          </Typography>
                        </Grid>
                        {coDefendantStatus[i] == "เสียชีวิต" ? (
                          <>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                สถานะตัวแทนผู้ค้ำประกันที่ {i + 1}:{" "}
                                {statusDefendantAgent[i]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                คำนำหน้าตัวแทนผู้ค้ำประกันที่ {i + 1}:{" "}
                                {prefixDefendantAgent[i]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ชื่อตัวแทนผู้ค้ำประกันที่ {i + 1}:{" "}
                                {nameDefendantAgent[i]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                นามสกุลตัวแทนผู้ค้ำประกันที่ {i + 1}:{" "}
                                {lastNameDefendantAgent[i]}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ที่อยู่ตัวแทนผู้ค้ำประกันที่ {i + 1}:{" "}
                                {addressDefendantAgent[i]}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                บัตรประชาชนตัวแทนผู้ค้ำประกันที่ {i + 1}:{" "}
                                {idCardDefendantAgent[i]}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Grid> */}
              <Grid container sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    หมายเหตุ: {note}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="รายการเอกสารในสำนวนคดี"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "30px",
                      }}
                    >
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredRows
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row) => (
                                <TableRow key={row.order}>
                                  {columns.map((column) => (
                                    <TableCell key={column.id} align="center">
                                      {column.render
                                        ? column.render(row)
                                        : row[column.id]}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {executionCase ? (
            <Card sx={{ mb: 3 }}>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลบังคับคดี"
              />
              <CardContent sx={{ border: "0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีศาล:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {executionCode ? executionCode : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันที่รับเรื่อง:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {executionDateStart
                          ? moment(executionDateStart)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันกำหนดส่ง:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {executionDeadline
                          ? moment(executionDeadline)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      นำคำบังคับ:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {bringTheRegulations
                          ? moment(bringTheRegulations)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ออกหมายตั้งเจ้าพนักงานบังคับคดี:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {writOfExecutionDate
                          ? moment(writOfExecutionDate)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันขึ้นศาล:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {executionCourtDate
                          ? moment(executionCourtDate)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เวลาขึ้นศาล:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {executionCourtTime
                          ? moment(executionCourtTime, "HH:mm:ss").format(
                            "HH:mm"
                          )
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ผู้รับผิดชอบคดี:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {executionWorkByName ? executionWorkByName : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : null}

          {user && user.role != "bank" ? (
            <Card sx={{ mb: 3 }}>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลบริษัท"
              />
              <CardContent sx={{ border: "0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขแฟ้ม:
                      <span style={{ marginLeft: 5 }}>
                        {fileNumber ? fileNumber : "-"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีบริษัท:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {companyCode ? companyCode : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันรับเรื่อง:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {dateStart
                          ? moment(dateStart)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันกำหนดส่ง:
                      <span style={{ marginLeft: 5 }}>
                        {deadline
                          ? moment(deadline)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ผู้รับผิดชอบคดี:
                      <span style={{ marginLeft: 5 }}>
                        {chooseMemberCompanyName
                          ? chooseMemberCompanyName
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      อื่นๆ(สำหรับโน้ต): {other ? other : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : null}
        </Box>
      </Card>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsDetailLoan;
