import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import PropTypes from "prop-types";
import {
  TextField,
  Card,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import UnpublishedRoundedIcon from "@mui/icons-material/UnpublishedRounded";
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useAuth from "function/useAuth";
import config from "../../../config";
import api from "../../../api";
import { FileUploader } from "react-drag-drop-files";

const Template = () => {
  const [user, setUser] = useState([]);
  const [document, setDocument] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [province, setProvince] = useState("");
  const [listProvince, setListProvince] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [checkFile, setCheckFile] = useState(false);
  const [fileExport, setFileExport] = useState("");
  const [code, setCode] = useState("");
  const [nameCreate, setNameCreate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //   Template
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [nameTemplate, setnameTemplate] = useState("");
  const [descTemplate, setdescTemplate] = useState("");
  const [value2, setValue2] = useState("");
  const [desc2, setDesc2] = useState("");
  const [fileUpload, setFileUpload] = useState(null);
  const [fileTemplateUpload, setFileTemplateUpload] = useState(null);

  // Search
  const [findDefendent, setFindDefendent] = useState("");
  const [findDate, setFindDate] = useState("");
  const [findProvince, setFindProvince] = useState("");
  const [findDebtCenter, setFindDebtCenter] = useState("");
  const [findCourtCode, setFindCourtCode] = useState("");
  const [findCompanyCode, setFindCompanyCode] = useState("");
  const fileUploadTypes = ["PDF", "xlsx", "csv"];

  useAuth();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setUser(userData);
      getDocument(userData);
    }
  }, []);
  const navigate = useNavigate();

  function handleDownload() {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/export-excel-expal`)
      .then((response) => {
        const download_url = `${config.mainAPI}/download-documents-excel-expal`;
        window.location.href = download_url;
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function getDocument(userData) {
    setIsLoading(true);
    const payload = {
      role: userData.role,
    };
    api
      .post(`${config.mainAPI}/listTemplate`, {
        ...payload,
      })
      .then(function (response) {
        const value = response.data.data;
        if (value) {
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.id,
                item.name,
                item.type,
                item.desc1,
                item.value2,
                item.desc2,
                item.updated_at,
                item.path,
                item.id
              )
            )
          );
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }

  const handleUploadChange = (file) => {
    setFileUpload(file);
    const formData = new FormData();
    formData.append("fileImageUpload", file);
    formData.append("fileName", file.name);
    formData.append("topic", "imagePlace");
    formData.append("type", "image");
    formData.append("type_img", file.type);
    formData.append("type_docs", "expal");
    formData.append("user", user.username);
    // formData.append("id", params);
    console.log("formData", formData);
    setFileTemplateUpload(formData);

    console.log(file);
  };

  function searchDocument() {}
  const columns = [
    { id: "order", label: "ลำดับที่", minWidth: 100 },
    { id: "id", label: "เทมเพลท", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "desc1", label: "คำอธิบาย", minWidth: 100 },
    { id: "value2", label: "ผลลัพธ์", minWidth: 100 },
    { id: "desc2", label: "คำอธิบาย2", minWidth: 100 },
    { id: "updated", label: "แก้ไขล่าสุด", minWidth: 100 },
    {
      id: "detail",
      label: "ตรวจสอบ",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckForm(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "edit",
      label: "แก้ไข",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleEditForm(row)}>
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleClickOpenDelete(row)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  function createData(id, name, type, desc1, value2, desc2, updated_at, path) {
    const formattedDate = updated_at
      ? moment(updated_at).locale("th").add(543, "year").format("DD/MM/YYYY")
      : null;

    return {
      order,
      id,
      type,
      desc1,
      value2,
      desc2,
      updated: formattedDate,
      id,
      path,
      id,
    };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProvinceChange = (event) => {
    const value = event.target.value;
    console.log(value);
    setProvince(value);
  };

  const handleClickOpenSearch = (row) => {
    setOpenSearch(true);
  };

  const handleCloseSearch = (row) => {
    setFindDefendent("");
    setFindDate("");
    setFindProvince("");
    setFindDebtCenter("");
    setFindCourtCode("");
    setFindCompanyCode("");
    setOpenSearch(false);
  };

  const handleClickOpenDelete = (row) => {
    console.log(row.codegen);
    setCode(row.codegen);
    setOpenDelete(true);
  };

  const handleCreate = () => {
    setOpenCreate(true);
  };

  const handleSubmitCreate = () => {
    // setOpenCreate(false);
    console.log("file", fileTemplateUpload);
    const payload = {
      id: id,
      type: type,
      nameTemplate: nameTemplate,
      descTemplate: descTemplate,
      value2: value2,
      desc2: desc2,
      fileUpload: fileUpload,
      fileTemplateUpload: fileTemplateUpload,
      mode: "add",
    };
    console.log("payload", payload);
    // api
    //   .post(`${config.mainAPI}/listTemplate`, {
    //     ...payload,
    //   })
    //   .then(function (response) {
    //     const value = response.data.data;
    //     if (value) {
    //       setRows(
    //         value.map((item, index) =>
    //           createData(
    //             index + 1,
    //             item.id,
    //             item.name,
    //             item.type,
    //             item.desc1,
    //             item.value2,
    //             item.desc2,
    //             item.updated_at,
    //             item.path,
    //             item.id
    //           )
    //         )
    //       );
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //     setIsLoading(false);
    //   });

    // setFileTemplateUpload(null);
    // setFileUpload(null);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleCloseDelete = (row) => {
    setCode("");
    setOpenDelete(false);
  };

  const handleClickOpenExport = (row) => {
    setOpenExport(true);
  };

  const handleCloseExport = (row) => {
    setOpenExport(false);
  };

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const handleCheckForm = (row) => {
    navigate("/expal/detail", { state: { params: row.codegen } });
  };

  const handleEditForm = (row) => {
    navigate("/expal/form", { state: { params: row.codegen } });
  };

  const handleDeleteForm = () => {
    setIsLoading(true);
    const userData = JSON.parse(localStorage.getItem("userData"));
    axios
      .post(`${config.mainAPI}/document-delete`, {
        genCode: code,
      })
      .then(function (response) {
        if (response.data.success) {
          setCode("");
          setOpenDelete(false);
          getDocument(userData);
        }
      })
      .catch(function (error) {
        setOpenDelete(false);
        setIsLoading(false);
        console.log(error);
      });
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleClickCreateDefaultForm = () => {
    const genCode = `Thanic-Evication-${moment().format("YYMMDDHHmmssSS")}`;
    const payload = {
      genCode: genCode,
      user: user.username,
    };
    saveActivityLog(`Click create default form evication`);
    axios
      .post(`${config.mainAPI}/createDefaultTemplateEvication`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;
        navigate("/expal/form", { state: { params: genCode } });
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "10px" }}
        >
          รายการเทมเพลท
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
            float: "right",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCreate}
            color="primary"
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มเทมเพลท
          </Button>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Dialog open={openSearch} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseSearch}
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              ค้นหาเทมเพลท
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>จำเลย</Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindDefendent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>วันที่</Typography>
                <TextField
                  type="date"
                  color="dark"
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setFindDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>จังหวัด</Typography>
                <Select
                  value={province}
                  onChange={handleProvinceChange}
                  variant="outlined"
                  fullWidth
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {listProvince &&
                    listProvince.map((column) => (
                      <MenuItem key={column.id} value={column.name_th}>
                        {column.name_th}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เขตศูนย์หนี้</Typography>
                <TextField
                  type="text"
                  color="dark"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => setFindDebtCenter(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เลขคดีของศาล</Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindCourtCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>
                  เลขคดีของบริษัท
                </Typography>
                <TextField
                  type="text"
                  color="dark"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => setFindCompanyCode(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseSearch}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={searchDocument}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={openDelete} fullWidth maxWidth={"xs"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDelete}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box textAlign="center">
              <ErrorIcon sx={{ color: "#ff2929", fontSize: 100 }} />
            </Box>
            <Typography
              variant="h1"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              คำเตือน
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#1f1f1f",
              }}
            >
              หากลบแล้วเอกสารจะหายไป คุณต้องการที่จะลบเอกสารนี้ใช่หรือไม่?
            </Typography>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ mr: 3 }}
                onClick={handleDeleteForm}
              >
                ลบ
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseDelete}
              >
                ปิด
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={openCreate} fullWidth maxWidth={"sm"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseCreate}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              สร้างเทมเพลท
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>รหัสเทมเพลท:</Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setId(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>
                  ประเภทเทมเพลท:
                </Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setType(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px" }}>ชื่อเทมเพลท:</Typography>
                <TextField
                  type="text"
                  color="dark"
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setnameTemplate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px" }}>คำอธิบาย:</Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setdescTemplate(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px", mb: 2 }}>
                  ผลลัพธ์2:
                </Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setValue2(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px", mb: 2 }}>
                  คำอธิบาย2:
                </Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setDesc2(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px", mb: 2 }}>
                  เทมเพลท:
                </Typography>
                <FileUploader
                  multiple={false}
                  handleChange={handleUploadChange}
                  name="fileUpload"
                  types={fileUploadTypes}
                />
                <p style={{ marginTop: 20 }}>
                  {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                </p>
              </Grid>
            </Grid>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ mr: 3 }}
                onClick={handleCloseCreate}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmitCreate}
              >
                สร้างเทมเพลท
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Card>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Template;
