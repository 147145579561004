import React, { useState, useEffect } from "react";
import {
  IconFileDescription,
  IconBoxSeam,
  IconHome,
  IconUsers,
  IconFileX,
  IconFileCheck,
  IconFileImport,
  IconFileText,
  IconUserExclamation,
  IconClipboardText,
  IconCar,
  IconFlask,
  IconHomeSignal,
  IconFileOff,
  IconId,
  IconHomeCancel,
  IconCashBanknote,
  IconGavel,
  IconBookUpload,
  IconCalendar,
  IconPencilPlus,
  IconEdit,
} from "@tabler/icons";

const icons = {
  IconFileDescription,
  IconBoxSeam,
  IconHome,
  IconUsers,
  IconFileX,
  IconFileCheck,
  IconFileImport,
  IconFileText,
  IconUserExclamation,
  IconClipboardText,
  IconCar,
  IconFlask,
  IconHomeSignal,
  IconFileOff,
  IconId,
  IconHomeCancel,
  IconCashBanknote,
  IconGavel,
  IconBookUpload,
  IconCalendar,
  IconPencilPlus,
  IconEdit,
};

const userData = localStorage.getItem("userData");
let user;
if (userData && userData !== "undefined") {
  try {
    user = JSON.parse(userData);
    console.log("usereiei", user);
  } catch (error) {
    console.error("Error parsing user data: ", error);
  }
}
const role = user?.role;
let pages = {
  id: "pages",
  title: "หน้าเพจ",
  caption: "",
  type: "group",
  children: [],
};
if (role === "user") {
  pages.children = [
    {
      id: "home",
      title: "หน้าหลัก",
      type: "item",
      url: "/home",
      icon: icons.IconHome,
      target: true,
    },
    {
      id: "calendar",
      title: "ปฏิทิน",
      type: "item",
      url: "/calendar",
      icon: icons.IconCalendar,
      target: true,
    },
    {
      id: "documents",
      title: "จัดการเอกสาร",
      type: "collapse",
      icon: icons.IconFileImport,
      children: [
        {
          id: "dashboard-document",
          title: "Dashboard",
          type: "item",
          icon: icons.IconHomeSignal,
          IconFileOff,
          url: "/dashboard",
          target: true,
        },
        {
          id: "document",
          title: "จัดการเอกสาร",
          type: "item",
          icon: icons.IconFileDescription,
          IconFileOff,
          url: "/documents",
          target: true,
        },
      ],
    },
    {
      id: "work",
      title: "การทำงาน",
      type: "collapse",
      icon: icons.IconEdit,
      children: [
        {
          id: "working-time",
          title: "ลงเวลาการทำงาน",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/time-working",
          target: true,
        },
        {
          id: "leave",
          title: "ลา",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/leave",
          target: true,
        },
      ],
    },
  ];
} else if (role === "owner") {
  pages.children = [
    {
      id: "home",
      title: "หน้าหลัก",
      type: "item",
      url: "/home",
      icon: icons.IconHome,
      target: true,
    },
    {
      id: "calendar",
      title: "ปฏิทิน",
      type: "item",
      url: "/calendar",
      icon: icons.IconCalendar,
      target: true,
    },
    {
      id: "documents",
      title: "เอกสาร",
      type: "collapse",
      icon: icons.IconFileImport,
      children: [
        {
          id: "dashboard-document",
          title: "Dashboard",
          type: "item",
          icon: icons.IconHomeSignal,
          IconFileOff,
          url: "/dashboard",
          target: true,
        },
        {
          id: "loan",
          title: "สินเชื่อ",
          type: "item",
          icon: icons.IconCashBanknote,
          IconFileOff,
          url: "/loan",
          target: true,
        },
        {
          id: "expal",
          title: "ขับไล่",
          type: "item",
          icon: icons.IconHomeCancel,
          IconFileOff,
          url: "/expal",
          target: true,
        },
        {
          id: "credit",
          title: "บัตรเครดิต",
          type: "item",
          icon: icons.IconId,
          IconFileOff,
          url: "/credit",
          target: true,
        },
        {
          id: "led",
          title: "บังคับคดี",
          type: "item",
          icon: icons.IconBookUpload,
          IconBookUpload,
          url: "/led",
          target: true,
        },
        {
          id: "execution",
          title: "สืบทรัพย์บังคับคดี",
          type: "item",
          icon: icons.IconGavel,
          IconGavel,
          url: "/execution",
          target: true,
        },
      ],
    },
    {
      id: "others",
      title: "จัดการ",
      type: "collapse",
      icon: icons.IconEdit,
      children: [
        // {
        //   id: "user-edit",
        //   title: "สมาชิก",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/users",
        //   target: true,
        // },
        {
          id: "debt-center",
          title: "ศูนย์หนี้",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/debt-center",
          target: true,
        },
        // {
        //   id: "prefix",
        //   title: "คำนำหน้า",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/prefix",
        //   target: true,
        // },
        // {
        //   id: "typeOfLoan",
        //   title: "ประเภทสินเชื่อ",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/prefix",
        //   target: true,
        // },
        // {
        //   id: "typeOfCredit",
        //   title: "ประเภทบัตรเครดิต",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/prefix",
        //   target: true,
        // },
        // {
        //   id: "template",
        //   title: "เทมเพลท",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/template",
        //   target: true,
        // },
        {
          id: "manageTimeWorking",
          title: "จัดการการลงเวลา",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/manage-timeworking",
          target: true,
        },
        // {
        //   id: "managePetition",
        //   title: "จัดการคำร้อง",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/manage-petition",
        //   target: true,
        // },
        {
          id: "manageLeave",
          title: "จัดการการลา",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/manage-leave",
          target: true,
        },
      ],
    },
    {
      id: "work",
      title: "การทำงาน",
      type: "collapse",
      icon: icons.IconEdit,
      children: [
        {
          id: "working-time",
          title: "ลงเวลาการทำงาน",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/time-working",
          target: true,
        },
        {
          id: "leave",
          title: "ลา",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/leave",
          target: true,
        },
      ],
    },
  ];
} else if (role === "bank") {
  pages.children = [
    {
      id: "home",
      title: "หน้าหลัก",
      type: "item",
      url: "/home",
      icon: icons.IconHome,
      target: true,
    },
  ];
} else if (role === "admin" || role == "administrative") {
  pages.children = [
    {
      id: "home",
      title: "หน้าหลัก",
      type: "item",
      url: "/home",
      icon: icons.IconHome,
      target: true,
    },
    {
      id: "calendar",
      title: "ปฏิทิน",
      type: "item",
      url: "/calendar",
      icon: icons.IconCalendar,
      target: true,
    },
    {
      id: "documents",
      title: "เอกสาร",
      type: "collapse",
      icon: icons.IconFileImport,
      children: [
        {
          id: "dashboard-document",
          title: "Dashboard",
          type: "item",
          icon: icons.IconHomeSignal,
          IconFileOff,
          url: "/dashboard",
          target: true,
        },
        {
          id: "loan",
          title: "สินเชื่อ",
          type: "item",
          icon: icons.IconCashBanknote,
          IconFileOff,
          url: "/loan",
          target: true,
        },
        {
          id: "expal",
          title: "ขับไล่",
          type: "item",
          icon: icons.IconHomeCancel,
          IconFileOff,
          url: "/expal",
          target: true,
        },
        {
          id: "credit",
          title: "บัตรเครดิต",
          type: "item",
          icon: icons.IconId,
          IconFileOff,
          url: "/credit",
          target: true,
        },
        {
          id: "led",
          title: "บังคับคดี",
          type: "item",
          icon: icons.IconBookUpload,
          IconBookUpload,
          url: "/led",
          target: true,
        },
        {
          id: "execution",
          title: "สืบทรัพย์บังคับคดี",
          type: "item",
          icon: icons.IconGavel,
          IconGavel,
          url: "/execution",
          target: true,
        },
      ],
    },
    {
      id: "others",
      title: "จัดการ",
      type: "collapse",
      icon: icons.IconEdit,
      children: [
        {
          id: "user-edit",
          title: "สมาชิก",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/users",
          target: true,
        },
        {
          id: "debt-center",
          title: "ศูนย์หนี้",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/debt-center",
          target: true,
        },
        // {
        //   id: "prefix",
        //   title: "คำนำหน้า",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/prefix",
        //   target: true,
        // },
        // {
        //   id: "typeOfLoan",
        //   title: "ประเภทสินเชื่อ",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/prefix",
        //   target: true,
        // },
        // {
        //   id: "typeOfCredit",
        //   title: "ประเภทบัตรเครดิต",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/prefix",
        //   target: true,
        // },
        // {
        //   id: "template",
        //   title: "เทมเพลท",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/template",
        //   target: true,
        // },
        {
          id: "manageTimeWorking",
          title: "จัดการการลงเวลา",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/manage-timeworking",
          target: true,
        },
        // {
        //   id: "managePetition",
        //   title: "จัดการคำร้อง",
        //   type: "item",
        //   icon: icons.IconPencilPlus,
        //   url: "/manage-petition",
        //   target: true,
        // },
        {
          id: "manageLeave",
          title: "จัดการการลา",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/manage-leave",
          target: true,
        },
        {
          id: "config",
          title: "จัดการทั้งหมด",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/config",
          target: true,
        },
      ],
    },
    {
      id: "work",
      title: "การทำงาน",
      type: "collapse",
      icon: icons.IconEdit,
      children: [
        {
          id: "working-time",
          title: "ลงเวลาการทำงาน",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/time-working",
          target: true,
        },
        {
          id: "petition",
          title: "คำร้อง",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/petition",
          target: true,
        },
        {
          id: "leave",
          title: "ลา",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/leave",
          target: true,
        },
      ],
    },
    {
      id: "account",
      title: "บัญชี",
      type: "collapse",
      icon: icons.IconEdit,
      children: [
        {
          id: "dashboard-account",
          title: "หน้าหลัก",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/account",
          target: true,
        },
        {
          id: "petition",
          title: "จัดการบัญชี",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/account-manage",
          target: true,
        },
        {
          id: "salary-manage",
          title: "จัดการเงินเดือน",
          type: "item",
          icon: icons.IconPencilPlus,
          url: "/salary-manage",
          target: true,
        },
      ],
    },
  ];
}
export default pages;
