import { useState, useRef, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

// assets
import { IconBell } from "@tabler/icons";
import api from "../../../../api";
import config from "../../../../config";

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [openWarning, setOpenWarning] = useState(false);
  // const [openWarning, setOpenWarning] = useState(true);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */

  const handleNotification = () => {
    navigate("/notification");
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const countNotificationByUser = (user) => {
    api
      .get(`${config.mainAPI}/countNotificationByUser/${user.username}`)
      .then((response) => {
        let value = response.data.data[0].count;
        setNotificationCount(value);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const checkPermission = () => {
    api
      .post(`${config.mainAPI}/listTemplate`, {
        ...payload,
      })
      .then(function (response) {
        const value = response.data.data;
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              item.id,
              item.name,
              item.type,
              item.desc1,
              item.value2,
              item.desc2,
              item.updated_at,
              item.path,
              item.id
            )
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
      countNotificationByUser(JSON.parse(userData));
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        {user.role != "bank" ? (
          <ButtonBase
            sx={{ borderRadius: "12px" }}
            onClick={handleNotification}
          >
            {notificationCount > 0 ? (
              <Badge color="error" badgeContent={notificationCount}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: "all .2s ease-in-out",
                    background: theme.palette.thanic.dark,
                    color: "#fff",
                    '&[aria-controls="menu-list-grow"],&:hover': {
                      background: theme.palette.thanic.dark,
                      color: theme.palette.thanic.yellow,
                    },
                  }}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleNotification}
                  color="inherit"
                >
                  <IconBell stroke={1.5} size="1.3rem" />
                </Avatar>
              </Badge>
            ) : (
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  transition: "all .2s ease-in-out",
                  background: theme.palette.thanic.dark,
                  color: "#fff",
                  '&[aria-controls="menu-list-grow"],&:hover': {
                    background: theme.palette.thanic.darkHover,
                  },
                }}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleNotification}
                color="inherit"
              >
                <IconBell stroke={1.5} size="1.3rem" />
              </Avatar>
            )}
          </ButtonBase>
        ) : null}
      </Box>

      {/* <Dialog open={openWarning} fullWidth maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <ErrorIcon sx={{ color: "#ff0c34", fontSize: 180 }} />
          </Box>
          <Typography
            variant="h3"
            sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
          >
            คำเตือนหมดเวลาในการเข้าใช้งาน กรุณาเข้าสู่ระบบใหม่อีกครั้ง
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseWarning}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog> */}
    </>
  );
};

export default NotificationSection;
