import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import config from "../../../config";
import api from "../../../api";
import moment from "moment";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
// import useAuth from "../../function/useAuth";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const Petition = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [idCard, setIdCard] = useState("");
  const [gsbNumber, setGsbNumber] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openFirstTimeLogin, setOpenFirstTimeLogin] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  //   const localizer = momentLocalizer(moment);
  const [eventCalendar, setEventCalendar] = useState([]);
  const [textCalendar, setTextCalendar] = useState("");
  const [fileUpload, setFileUpload] = useState(null);

  const [openCreate, setOpenCreate] = useState(false);
  const [dateWork, setDateWork] = useState(new Date());
  const [detailWork, setDetailWork] = useState("");
  const [hourWork, setHourWork] = useState(null);
  const [timeStart, setTimeStart] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);
  const [topic, setTopic] = useState("");
  const [error, setError] = useState(null);
  const [typeDocsUpload, setTypeDocsUpload] = useState("");
  const [topicDocsUpload, setTopicDocsUpload] = useState("");
  //   handleClickOpenCreate

  //   const hoursList = [
  //     { name: "00", value: "00" },
  //     { name: "01", value: "01" },
  //     { name: "02", value: "02" },
  //     { name: "03", value: "03" },
  //     { name: "04", value: "04" },
  //     { name: "05", value: "05" },
  //     { name: "06", value: "06" },
  //     { name: "07", value: "07" },
  //     { name: "08", value: "08" },
  //     { name: "09", value: "09" },
  //     { name: "10", value: "10" },
  //     { name: "11", value: "11" },
  //     { name: "12", value: "12" },
  //     { name: "13", value: "13" },
  //     { name: "14", value: "14" },
  //     { name: "15", value: "15" },
  //     { name: "16", value: "16" },
  //     { name: "17", value: "17" },
  //     { name: "18", value: "18" },
  //     { name: "19", value: "19" },
  //     { name: "20", value: "20" },
  //     { name: "21", value: "21" },
  //     { name: "22", value: "22" },
  //     { name: "23", value: "23" },
  //   ];
  //   const initialHoursList = Array.from({ length: 24 }, (_, i) => {
  //     const hour = i < 10 ? `0${i}` : `${i}`;
  //     return { name: hour, value: hour };
  //   });
  const fileTypes = ["PDF"];

  const initialHoursList = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minute = i % 2 === 0 ? "00" : "30";
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    return {
      name: `${formattedHour}:${minute}`,
      value: `${formattedHour}:${minute}`,
    };
  });

  const initialMinutesList = Array.from({ length: 60 }, (_, i) => {
    const minute = i < 10 ? `0${i}` : `${i}`;
    return { name: minute, value: minute };
  });
  const [hoursList, sethoursList] = useState(initialHoursList);
  const [minutesList, setminutesList] = useState(initialMinutesList);
  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    { id: "topic", label: "topic", minWidth: 100 },
    { id: "detail", label: "รายละเอียด", minWidth: 100 },
    { id: "date_sent", label: "วันที่ยื่น", minWidth: 100 },
    { id: "status", label: "สถานะ", minWidth: 100 },
    {
      id: "detail",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckForm(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "edit",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleEditForm(row)}>
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          {/* <IconButton aria-label="approve" onClick={(() => navigate('/loan/form'), { state: { params: row.code } })}> */}
          <IconButton onClick={() => handleClickOpenDelete(row)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleCloseOpenWarning = () => {
    setOpenWarning(false);
  };

  const handleCloseOpenFirstTimeLogin = () => {
    setOpenFirstTimeLogin(false);
  };

  const handleSubmitSetNewPassword = () => {
    console.log("eiei");
    setIsLoading(true);
    console.log("user.username", user.username);
    console.log("newPassword", newPassword);
    api
      .post(`${config.mainAPI}/updateNewPasswordFirstTimeLogin`, {
        username: user.username,
        password: newPassword,
      })
      .then(function (response) {
        setOpenFirstTimeLogin(false);
        setIsLoading(false);
        localStorage.removeItem("user_data");
        localStorage.removeItem("userData");
        localStorage.removeItem("isLoggedIn");
        window.location.reload();
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  // useAuth();

  function searchData() {
    if (type) {
      axios
        .post(`${config.mainAPI}/searchDataInformation`, {
          name: name,
          idCard: idCard,
          type: type,
          gsbNumber: gsbNumber,
          role: user.role,
          debtCenter: user.debtCenter,
        })
        .then(function (response) {
          const value = response.data.data;
          console.log("value searchData", value);
          setList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.defendant_fullname,
                item.defendant_type,
                item.document_code_gen
              )
            )
          );
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    } else {
      // Error
      setOpenWarning(true);
    }
  }

  const closePopupCreate = () => {
    setError(null);
    setDateWork(new Date());
    setTimeStart(null);
    setTimeEnd(null);
    setDetailWork(null);
    setOpenCreate(false);
  };

  const addWorkTime = () => {
    setOpenCreate(false);
  };

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(order, list, type, code) {
    return {
      order,
      list,
      type,
      code,
    };
  }

  function handleCheckDetail(row) {
    if (row.type == "สินเชื่อ") {
      navigate("/loan/detail", { state: { params: row.code } });
    } else if (row.type == "ขับไล่") {
      navigate("/expal/detail", { state: { params: row.code } });
    } else if (row.type == "บัตรเครดิต") {
      navigate("/credit/detail", { state: { params: row.code } });
    }
  }

  const saveData = () => {
    const dateWorkShow = moment(dateWork).format("YYYY-MM-DD");
    const timeStartShow = timeStart;
    const timeEndShow = timeEnd;
    let body = {
      date: dateWork
        ? moment(dateWorkShow, "YYYY-MM-DD").format("YYYY-MM-DD")
        : null,
      topic: topic,
      detail: detailWork,
      user: user.username,
    };
    api
      .post(`${config.mainAPI}/createtWorkingTime`, body)
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success) {
          closePopupCreate();
        } else {
          setError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setUser(userData);
    }
  }, []);

  const handleClickOpenCreate = () => {};

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? "red" : event.color,
    };
    return {
      style,
    };
  };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setError(null);
    setData(christDate);
  };

  
  const handleUploadChange = (file) => {
    setFileUpload(file);
    console.log(file);
  };

  function handleClearFileUpload() {
    setFileUpload(null);
    setTypeDocsUpload("");
    setTopicDocsUpload("");
  }

  const handleSubmitFileUpload = async () => {
    if (fileUpload) {
      console.log("fileUpload", fileUpload);
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("type", typeDocsUpload);
      formData.append("type_docs", "loan");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileUpload.name} Success`);

        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
      }
    }
  };


  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", mt: 3 }}
        >
          คำร้อง
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
            float: "right",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            color="primary"
            startIcon={<FileOpenIcon />}
          >
            Export
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenCreate(true)}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มคำร้อง
          </Button>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>

      <Dialog open={openCreate} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            ข้อมูลคำร้อง
          </Typography>
          <Typography sx={{ mb: 3, fontSize: 16, mt: 3 }} textAlign="center">
            {textCalendar}
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>วันที่:</Typography>

              <WatDatePicker
                className="custom-date-picker"
                value={dateWork}
                onChange={(christDate, buddhistDate) =>
                  handleDatePickerChange(setDateWork, christDate, buddhistDate)
                }
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD/MM/YYYY"}
                clearable={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>หัวข้อ:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              />
            </Grid>
          </Grid>
          {/* <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>เวลาเริ่ม:</Typography>
              <Select
                value={timeStart}
                onChange={(e) => setTimeStart(e.target.value)}
                variant="outlined"
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                {hoursList &&
                  hoursList.map((column) => (
                    <MenuItem key={column.value} value={column.value}>
                      {column.value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>เวลาสิ้นสุด:</Typography>
              <Select
                value={timeEnd}
                onChange={(e) => setTimeEnd(e.target.value)}
                variant="outlined"
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                {hoursList &&
                  hoursList.map((column) => (
                    <MenuItem key={column.value} value={column.value}>
                      {column.value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid> */}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "16px" }}>รายละเอียด:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={detailWork}
                onChange={(e) => setDetailWork(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "16px" }}>เอกสารแนบ:</Typography>
              <FileUploader
                multiple={false}
                handleChange={handleUploadChange}
                name="fileUpload"
                types={fileTypes}
              />
              <p style={{ marginTop: "1rem" }}>
                {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                {fileUpload ? (
                  <>
                    <IconButton onClick={handleClearFileUpload}>
                      <DeleteForeverIcon color="error" />
                    </IconButton>
                    <IconButton onClick={handleSubmitFileUpload}>
                      <UploadFileIcon color="success" />
                    </IconButton>
                  </>
                ) : null}
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => closePopupCreate()}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                disabled={error}
                onClick={() => saveData()}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Petition;
