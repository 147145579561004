import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';


// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

// function PrivateRoute({ component: Component, ...rest }) {
//     const isLoggedIn = localStorage.getItem('isLoggedIn') === true;

//     return <Routes {...rest} render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />)} />;
// }

// const App = () => {
//     const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

//     return (
//         <StyledEngineProvider injectFirst>
//             <ThemeProvider theme={themes(customization)}>
//                 <CssBaseline />
//                 <NavigationScroll>{isLoggedIn ? <Routes /> : <Login />}</NavigationScroll>
//             </ThemeProvider>
//         </StyledEngineProvider>
//     );
// };

export default App;
