import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import styles from "./styles/styles.css";
import provincesData from "../../../components/province.json";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Paper,
  Box,
  Grid,
  IconButton,
  Select,
  MenuItem,
  GridDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
  Collapse,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Divider,
} from "@mui/material";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { FileUploader } from "react-drag-drop-files";
import ClearIcon from "@mui/icons-material/Clear";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// import { TimePicker } from "@mui/lab";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import useAuth from "function/useAuth";
import config from "../../../config";
const DocumentsDetailCredit = () => {
  const [user, setUser] = useState();
  const [userCompany, setUserCompany] = useState(null);
  const { state } = useLocation();
  const params = state ? state.params : null;
  const genCode =
    params != null
      ? params
      : `Thanic-Credit-${moment().format("YYMMDDHHmmssSS")}`;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  // Form
  const [dateStart, setDateStart] = useState(null);
  const [prefix, setPrefix] = useState("");
  const [prefixList, setPrefixList] = useState([]);
  const [defendantFirstName, setDefendantFirstName] = useState("");
  const [defendantLastName, setDefendantLastName] = useState("");
  const [defendantIdCard, setDefendantIdCard] = useState("");
  const [defendantAccountNumber, setDefendantAccountNumber] = useState("");
  const [defendantAccountNumber1, setDefendantAccountNumber1] = useState("");
  const [defendantAccountMoneyNumber, setDefendantAccountMoneyNumber] =
    useState("");
  const [defendantAccountMoneyNumber1, setDefendantAccountMoneyNumber1] =
    useState("");
  const [defendantAccount, setDefendantAccount] = useState("");
  const [defendantAge, setDefendantAge] = useState(null);
  const [defendantJob, setDefendantJob] = useState(null);
  const [defendantHouseNumber, setDefendantHouseNumber] = useState("");
  const [defendantGroup, setDefendantGroup] = useState("");
  const [defendantRoad, setDefendantRoad] = useState("");
  const [defendantSoi, setDefendantSoi] = useState("");
  const [agency, setAgency] = useState("");
  const [defendantAddress, setDefendantAddress] = useState("");
  const [defendantTambon, setDefendantTambon] = useState("");
  const [defendantAmphoe, setDefendantAmphoe] = useState("");
  const [defendantProvince, setDefendantProvince] = useState("");
  const [defendantTambonName, setDefendantTambonName] = useState("");
  const [defendantAmphoeName, setDefendantAmphoeName] = useState("");
  const [defendantProvinceName, setDefendantProvinceName] = useState("");
  const [defendantZipcode, setDefendantZipcode] = useState("");
  const [debtCenter, setDebtCenter] = useState("");
  const [branchAgency, setBranchAgency] = useState(""); // หน่วยงานสาขา
  const [districtAgency, setDistrictAgency] = useState(""); // หน่วยงานเขต
  const [sectorAgency, setSectorAgency] = useState(""); // หน่วยงานภาค
  const [loanType, setLoanType] = useState(""); // ประเภทสินเชื่อ
  const [typeOfCollateral, setTypeOfCollateral] = useState(""); // ประเภทหลักประกัน
  const [noticeDay, setNoticeDay] = useState(null); // วันบอกกล่าว
  const [sueDay, setSueDay] = useState(null); // วันฟ้อง
  const [courtDate, setCourtDate] = useState(null); // วันขึ้นศาล
  const [courtTime, setCourtTime] = useState(null); // เวลาขึ้นศาล
  const [noticeDayGet, setNoticeDayGet] = useState("");
  const [noticeDayOut, setNoticeDayOut] = useState("");
  const [noticeInvestigate, setNoticeInvestigate] = useState(""); // สอบสวนโนติส
  const [principalCapital, setPrincipalCapital] = useState(""); // ทุนทรัพย์เงินต้น
  const [interestCapital, setInterestCapital] = useState(""); // ทุนทรัพย์ดอกเบี้ย
  const [court, setCourt] = useState(""); // ศาล
  const [blackCaseNumber, setBlackCaseNumber] = useState(""); // เลขคดีดำเลขที่
  const [blackCaseYear, setBlackCaseYear] = useState(""); // เลขคดีดำปี
  const [redCaseNumber, setRedCaseNumber] = useState(""); // เลขคดีแดงเลขที่
  const [redCaseYear, setRedCaseYear] = useState(""); // เลขคดีดำปี
  const [witnessExaminationDate, setWitnessExaminationDate] = useState(null); // นัดสืบพยานวันที่
  const [judgmentDay, setJudgmentDay] = useState(null); // วันพิพากษา
  const [judgmentUnilateral, setJudgmentUnilateral] = useState(null); // พิพากษา(ฝ่ายเดียว)
  const [judgmentAccordingToConsent, setJudgmentAccordingToConsent] =
    useState(null); // พิพากษา(ฝ่ายเดียว)
  const [detailsJudgment, setDetailsJudgment] = useState(""); // รายละเอียด คำพิพากษา
  const [returnFile, setReturnFile] = useState(null); // ส่งแฟ้มคืน
  const [returnFileReason, setReturnFileReason] = useState(""); // ส่งแฟ้มคืนเหตุผล
  const [decree, setDecree] = useState(""); // ออกคำบังคับ
  const [issueAWarranOfExecution, setIssueAWarranOfExecution] = useState(""); // ออกคำบังคับ
  const [withdrawOneCase, setWithdrawOneCase] = useState(null); // ถอนฟ้อง
  const [withdrawOneCaseDueTo, setWithdrawOneCaseDueTo] = useState(""); // เนื่องจาก
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [checkFile, setCheckFile] = useState(false);
  const [countDefendant, setCountDefendant] = useState(0); // จำนวนจำเลยร่วม
  const [coDefendantPrefix, setCoDefendantPrefix] = useState([]); // คำนำหน้าจำเลยร่วม
  const [coDefendantName, setCoDefendantName] = useState([]); // ชื่อจำเลยร่วม
  const [coDefendantLastName, setCoDefendantLastName] = useState([]); // นามสกุลจำเลยร่วม
  const [coDefendantAddress, setCoDefendantAddress] = useState([]); // ที่อยู่จำเลยร่วม
  const [coDefendantIdCard, setCoDefendantIdCard] = useState([]); // บัตรประชาชนจำเลยร่วม
  const [coDefendantStatus, setCoDefendantStatus] = useState([]); // สถานะจำเลยร่วม
  const [statusDefendant, setStatusDefendant] = useState([]);
  const [addressCoDefendant, setAddressCoDefendant] = useState([]);
  const [prefixDefendantAgent, setPrefixDefendantAgent] = useState([]); // ชื่อตัวแทนจำเลยร่วม
  const [nameDefendantAgent, setNameDefendantAgent] = useState([]); // ชื่อตัวแทนจำเลยร่วม
  const [lastNameDefendantAgent, setLastNameDefendantAgent] = useState([]); // นามสกุลตัวแทนจำเลยร่วม
  const [idCardDefendantAgent, setIdCardDefendantAgent] = useState([]); // รหัสบัตรประชาชนตัวแทนจำเลยร่วม
  const [addressDefendantAgent, setAddressDefendantAgent] = useState([]); // ที่อยู่ตัวแทนจำเลยร่วม
  const [statusDefendantAgent, setStatusDefendantAgent] = useState([]); // สถานะตัวแทนจำเลยร่วม
  const [note, setNote] = useState(""); // หมายเหตุ
  const [companyCode, setCompanyCode] = useState(""); // เลขคดีบริษัท
  const [courtCode, setCourtCode] = useState(""); // เลขคดีศาล
  const [chooseMemberCompany, setChooseMemberCompany] = useState(""); // ไอดีผู้รับผิดชอบคดี
  const [chooseMemberCompanyName, setChooseMemberCompanyName] = useState(""); // ผู้รับผิดชอบคดี
  const [appealDueDate, setAppealDueDate] = useState(""); // วันครบกำหนดอุทธรณ์
  const [phone, setPhone] = useState(""); // โทรศัพท์
  const [phone1, setPhone1] = useState(""); // โทรสาร
  const [fileNumber, setFileNumber] = useState(""); // เลขแฟ้ม
  const [other, setOther] = useState("");
  const dafaultAgency = "สำนักงานใหญ่";
  const [dayProcess, setDayProcess] = useState("");
  const [open, setOpen] = useState(false);
  const [courtList, setCourtList] = useState([]);
  const [countCourtEtc, setCountCourtEtc] = useState(0);
  // ขึ้นศาลเพิ่มเติม
  const [dateGoCourtEtc, setDateGoCourtEtc] = useState([]); // วันที่ขึ้นศาลครั้งนั้น
  const [timeGoCourtEtc, setTimeGoCourtEtc] = useState([]); // เวลาขึ้นศาลครั้งนั้น
  const [lawyerGoCourtEtc, setLawyerGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [lawyerNameGoCourtEtc, setLawyerNameGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [detailGoCourtEtc, setDetailGoCourtEtc] = useState([]); // รายละเอียดขึ้นศาลครั้งนั้น

  const [selectedTime, setSelectedTime] = useState(null);
  const [creditType, setCreditType] = useState("");
  const [moneyType, setMoneyType] = useState("");
  const [interestCapitalCredit, setInterestCapitalCredit] = useState("");
  const [interestCapitalMoney, setInterestCapitalMoney] = useState("");
  const [principalCapitalCredit, setPrincipalCapitalCredit] = useState("");
  const [principalCapitalMoney, setPrincipalCapitalMoney] = useState("");
  const [taxCapitalCredit, setTaxCapitalCredit] = useState("");
  const [taxCapitalMoney, setTaxCapitalMoney] = useState("");
  const [countCreditCard, setCountCreditCard] = useState(0);
  const [countMoneyCard, setCountMoneyCard] = useState(0);
  let sumCredit =
    principalCapitalCredit + interestCapitalCredit + taxCapitalCredit;
  let sumMoney = principalCapitalMoney + interestCapitalMoney + taxCapitalMoney;

  const [dateRegisCreditCard, setDateRegisCreditCard] = useState([]);
  const [creditCardName, setCreditCardName] = useState([]);
  const [creditCardNumber, setCreditCardNumber] = useState([]);
  const [creditCardLimit, setCreditCardLimit] = useState([]);
  const [creditCardInterest, setCreditCardInterest] = useState([]);
  const [creditCardPrincipal, setCreditCardPrincipal] = useState([]);
  const [creditCardFees, setCreditCardFees] = useState([]);
  const [creditCardInterestRates, setCreditCardInterestRates] = useState([]);
  const [creditCardTotal, setCreditCardTotal] = useState([]);

  const [dateRegisMoneyCard, setDateRegisMoneyCard] = useState([]);
  const [moneyCardName, setMoneyCardName] = useState([]);
  const [moneyCardNumber, setMoneyCardNumber] = useState([]);
  const [moneyCardLimit, setMoneyCardLimit] = useState([]);
  const [moneyCardInterest, setMoneyCardInterest] = useState([]);
  const [moneyCardPrincipal, setMoneyCardPrincipal] = useState([]);
  const [moneyCardFees, setMoneyCardFees] = useState([]);
  const [moneyCardInterestRates, setMoneyCardInterestRates] = useState([]);
  const [masteroneyCardTotal, setMoneyCardTotal] = useState([]);

  const [typeDocsUpload, setTypeDocsUpload] = useState("");
  const [topicDocsUpload, setTopicDocsUpload] = useState("");
  const [fileUpload, setFileUpload] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fileData, setFileData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fileTypes = ["PDF"];

  useAuth();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log("datana", JSON.parse(userData));
    setUser(JSON.parse(userData));
    getUserCompany();
    if (params) {
      getDataForm();
      getListFile();
    }
    getDdlPrefix();
    getCourtList();
  }, []);
  const navigate = useNavigate();

  const useStyles = makeStyles({
    formControlRoot: {
      margin: "8px", // Set your desired margin value
      padding: "8px", // Set your desired padding value
    },
  });

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  function getDdlPrefix() {
    axios
      .get(`${config.mainAPI}/prefix-list`)
      .then((response) => {
        setPrefixList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getCourtList() {
    axios
      .get(`${config.mainAPI}/court-list`)
      .then((response) => {
        setCourtList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleUploadChange = (file) => {
    setFileUpload(file);
    console.log(file);
  };

  const typeDocsList = [
    { id: 0, value: "คำร้อง" },
    { id: 1, value: "คำขอ" },
    { id: 2, value: "คำแถลง" },
    { id: 3, value: "คำขอถอนฟ้อง" },
    { id: 4, value: "คำร้องขาดนัดยื่นคำให้การ" },
    { id: 5, value: "คำแถลงขอเลื่อนนัด" },
  ];

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    { id: "list", label: "รายการ", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
    { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
    {
      id: "file",
      label: "ไฟล์เอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleDownload(row)} disabled={!row.path}>
            <PictureAsPdfIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(order, type, date, path) {
    const formattedDate = moment(date)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY");
    return {
      order,
      // list,
      type,
      date: date ? formattedDate : null,
      path,
    };
  }

  function getListFile() {
    axios
      .get(`${config.mainAPI}/file-list/${params}`)
      .then((response) => {
        console.log("value filelist", response.data.data);
        setFileList(response.data.data);
        const value = response.data.data;
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              // item.file_topic,
              item.file_type,
              item.created_at,
              item.file_path,
              item.update_at
            )
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleClearFileUpload() {
    setFileUpload(null);
    setTypeDocsUpload("");
    setTopicDocsUpload("");
  }

  const handleSumbitFileUpload = async () => {
    if (fileUpload) {
      console.log("fileUpload", fileUpload);
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("type", typeDocsUpload);
      formData.append("type_docs", "credit");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileUpload.name} Success`);

        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
      }
    }
  };

  const getDataForm = () => {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/credit-check/${params}`)
      .then((response) => {
        console.log(response);
        console.log("response.data.data", response.data.data);
        setData(response.data.data[0]);
        const value = response.data.data[0];
        setDateStart(value.date_start);
        // setDateStart(moment(value.date_start).toDate());
        setFileNumber(value.file_number);
        setPrefix(value.defendant_prefix);
        setDefendantFirstName(value.defendant_firstname);
        setDefendantLastName(value.defendant_lastname);
        setDefendantIdCard(value.defendant_idcard);
        setDefendantAge(value.defendant_age);
        setDefendantAccountNumber(value.defendant_account_number);
        setDefendantAccountMoneyNumber(value.defendant_account_money_number);
        setDefendantAccountNumber1(value.defendant_account_number1);
        setDefendantAccountMoneyNumber1(value.defendant_account_money_number1);
        setDefendantAddress(value.defendant_address);
        setDefendantTambon(parseInt(value.defendant_tambon));
        setDefendantTambonName(value.defendant_tambon_show);
        setDefendantAmphoe(parseInt(value.defendant_amphoe));
        setDefendantAmphoeName(value.defendant_amphoe_show);
        setDefendantProvince(parseInt(value.defendant_province));
        setDefendantProvinceName(value.defendant_province_show);
        if (value.defendant_province) {
          console.log("value.defendant_province", value.defendant_province);
          getAmphoesByProvinceId(parseInt(value.defendant_province));
        }
        if (value.defendant_amphoe) {
          console.log("value.defendant_amphoe", value.defendant_amphoe);
          getTambonsByAmphoeId(parseInt(value.defendant_amphoe));
        }
        setAgency(value.agency);
        setDefendantZipcode(parseInt(value.defendant_zipcode));
        setPhone(value.defendant_phone);
        setPhone1(value.defendant_phone1);
        setDebtCenter(value.debt_center);
        setBranchAgency(value.branch_agency);
        setDistrictAgency(value.district_agency);
        setSectorAgency(value.sector_agency);
        setLoanType(value.loan_type);
        setTypeOfCollateral(value.type_of_collateral);
        setNoticeDay(value.notice_day);
        setNoticeDayGet(value.notic_get);
        setNoticeDayOut(value.notic_out);
        setNoticeInvestigate(value.notice_investigate);
        setPrincipalCapital(value.principal_capital);
        setInterestCapital(value.interest_capital);
        setCourt(value.court);

        setDayProcess(value.days_process);
        setBlackCaseNumber(value.black_case_number);
        setBlackCaseYear(value.black_case_year);
        setRedCaseNumber(value.red_case_number);
        setRedCaseYear(value.red_case_year);
        setWitnessExaminationDate(value.witness_examination_date);
        setJudgmentDay(value.judgment_day);
        setJudgmentUnilateral(value.judgment_unilateral);
        setJudgmentAccordingToConsent(value.judgment_according_to_consent);
        setDetailsJudgment(value.judgment_details);
        setReturnFile(value.return_file);
        setReturnFileReason(value.return_file_reason);
        setWithdrawOneCase(value.withdraw_one_case);
        setWithdrawOneCaseDueTo(value.withdraw_one_case_due_to);
        if (value.file_name && value.file_path) {
          setCheckFile(true);
        }
        setFileName(value.file_name);
        setFilePath(value.file_path);
        setNote(value.note);
        setChooseMemberCompany(value.work_by);
        setChooseMemberCompanyName(value.work_by_name);
        setCourtCode(value.court_code);
        setCompanyCode(value.company_code);
        setCountCourtEtc(value.goCourtEtc.length);
        setCourtDate(
          value.court_date ? moment(value.court_date).toDate() : null
        );

        // setCourtDate(value.court_date);
        // setCourtTime(value.court_time);
        if (value.court_count > 1 && value.goCourtEtc) {
          // มันต้อง + 1
          value.goCourtEtc.forEach((item, index) => {
            console.log("index", index);

            setDateGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_date;
              return updatedState;
            });

            setTimeGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_time;
              return updatedState;
            });

            setLawyerGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by;
              return updatedState;
            });

            setLawyerNameGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by_name;
              return updatedState;
            });

            setDetailGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_detail;
              return updatedState;
            });
            // }
          });
        }
        // setInterestCapitalCredit(value.interest_capital_credit);
        // setInterestCapitalMoney(value.interest_capital_money);
        // setPrincipalCapitalCredit(value.principal_capital_credit);
        // setPrincipalCapitalMoney(value.principal_capital_money);
        // setTaxCapitalCredit(value.tax_capital_credit);
        // setTaxCapitalMoney(value.tax_capital_money);
        // setCreditType(value.type_credit_card);
        // setMoneyType(value.type_money_card);

        setCountCreditCard(value.creditData.length);
        setCountMoneyCard(value.moneyData.length);

        if (value.creditData.length > 0) {
          value.creditData.forEach((item, index) => {
            console.log("index", index);

            setDateRegisCreditCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_start_date;
              return updatedState;
            });

            setCreditCardName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_name;
              return updatedState;
            });

            setCreditCardNumber((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_number;
              return updatedState;
            });

            setCreditCardLimit((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_limit;
              return updatedState;
            });

            setCreditCardInterest((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_interest;
              return updatedState;
            });

            setCreditCardPrincipal((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_principal;
              return updatedState;
            });

            setCreditCardFees((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_fee;
              return updatedState;
            });

            setCreditCardInterestRates((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_interest_rate;
              return updatedState;
            });

            // setCreditCardTotal((prevState) => {
            //   const updatedState = [...prevState];
            //   updatedState[index] = item.credit_total;
            //   return updatedState;
            // });
            // }
          });
        }
        console.log("value.moneyData.length", value.moneyData.length);
        if (value.moneyData.length > 0) {
          value.moneyData.forEach((item, index) => {
            console.log("index", index);

            setDateRegisMoneyCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_start_date;
              return updatedState;
            });

            setMoneyCardName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_name;
              return updatedState;
            });

            setMoneyCardNumber((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_number;
              return updatedState;
            });

            setMoneyCardLimit((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_limit;
              return updatedState;
            });

            setMoneyCardInterest((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_interest;
              return updatedState;
            });

            setMoneyCardPrincipal((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_principal;
              return updatedState;
            });

            setMoneyCardFees((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_fee;
              return updatedState;
            });

            setMoneyCardInterestRates((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_interest_rate;
              return updatedState;
            });

            // setMoneyCardTotal((prevState) => {
            //   const updatedState = [...prevState];
            //   updatedState[index] = item.money_total;
            //   return updatedState;
            // });
            // }
          });
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloseFailure = () => {
    setOpenFailure(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate("/credit");
  };

  const handleSubmit = (row) => {
    const caseData = params != null ? "edit" : "add";
    if (
      dateStart &&
      defendantFirstName &&
      defendantLastName &&
      defendantIdCard
    ) {
      const payload = {
        genCode: genCode,
        caseData: caseData,
        courtCode: courtCode,
        companyCode: companyCode,
        dateStart: dateStart,
        fileNumber: fileNumber,
        prefix: prefix,
        defendantFirstName: defendantFirstName,
        defendantLastName: defendantLastName,
        defendantIdCard: defendantIdCard,
        defendantAccountNumber: defendantAccountNumber,
        defendantAccountMoneyNumber: defendantAccountMoneyNumber,
        defendantAccountNumber1: defendantAccountNumber1,
        defendantAccountMoneyNumber1: defendantAccountMoneyNumber1,
        agency: agency,
        loanType: loanType,
        typeOfCollateral: typeOfCollateral,
        noticeDay: noticeDay,
        principalCapitalCredit: principalCapitalCredit,
        interestCapitalCredit: interestCapitalCredit,
        taxCapitalCredit: taxCapitalCredit,
        principalCapitalMoney: principalCapitalMoney,
        interestCapitalMoney: interestCapitalMoney,
        taxCapitalMoney: taxCapitalMoney,
        moneyType: moneyType,
        creditType: creditType,
        creditCard: creditCard,
        moneyCard: moneyCard,
        court: court,
        courtDate: courtDate ? moment(courtDate).format("YYYY-MM-DD") : null,
        courtTime: courtTime,
        blackCaseNumber: blackCaseNumber,
        blackCaseYear: blackCaseYear,
        redCaseNumber: redCaseNumber,
        redCaseYear: redCaseYear,
        goCourtEtc: goCourtEtc,
        witnessExaminationDate: witnessExaminationDate
          ? moment(witnessExaminationDate).format("YYYY-MM-DD")
          : null,
        judgmentUnilateral: judgmentUnilateral
          ? moment(judgmentUnilateral).format("YYYY-MM-DD")
          : null,
        judgmentAccordingToConsent: judgmentAccordingToConsent
          ? moment(judgmentAccordingToConsent).format("YYYY-MM-DD")
          : null,
        detailsJudgment: detailsJudgment,
        dayProcess: dayProcess,
        withdrawOneCase: withdrawOneCase
          ? moment(withdrawOneCase).format("YYYY-MM-DD")
          : null,
        withdrawOneCaseDueTo: withdrawOneCaseDueTo,
        returnFile: returnFile,
        returnFileReason: returnFileReason,
        note: note,
        fileName: fileName,
        filePath: filePath,
        chooseMemberCompany: chooseMemberCompany,
        chooseMemberCompanyName: chooseMemberCompanyName,
        other: other,
        user: user,
        countGoCourtDB:
          caseData == "edit" ? countCourtEtc + 1 : courtDate ? 1 : 0,
      };
      console.log(payload);
      axios
        .post(`${config.mainAPI}/credit`, {
          ...payload,
        })
        .then(function (response) {
          setOpenSuccess(true);
        })
        .catch(function (error) {
          console.log(error);
          setOpenFailure(true);
        });
    } else {
      setOpenFailure(true);
    }
  };

  const handleBack = () => {
    navigate("/credit");
  };

  const handleFileChange = (event) => {
    const fileData = event.target.files[0];
    setFile(fileData);
    setFile1(fileData);
  };

  const uploadFile = async () => {
    if (file) {
      console.log("file", file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      try {
        const res = await axios.post(`${config.mainAPI}/upload-file`, formData);
        console.log(res);
        setFileName(res.data.name);
        setFilePath(res.data.path);
        setCheckFile(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteFile = () => {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/delete-file`, {
        filePath,
      })
      .then(function (response) {
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleDownload = (row) => {
    const file_path = row.path;
    const fileName = path.basename(file_path);
    saveActivityLog(`Click download file: ${fileName}`);
    const download_url = `${config.mainAPI}/download-file?path=${file_path}`;
    window.location.href = download_url;
  };

  const handleProvinceChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantProvinceName = event.currentTarget.getAttribute("name");
      setDefendantProvince(value);
      setDefendantProvinceName(defendantProvinceName);
      setDefendantAmphoe("");
      setDefendantTambon("");
    }
  };

  const handleAmphoeChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantAmphoeName = event.currentTarget.getAttribute("name");
      setDefendantAmphoe(value);
      setDefendantAmphoeName(defendantAmphoeName);
      setDefendantTambon("");
    }
  };

  const handleTambonChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantTambonName = event.currentTarget.getAttribute("name");
      const defendantTambonZipcode = event.currentTarget.getAttribute("code");
      setDefendantZipcode(defendantTambonZipcode);
      setDefendantTambon(value);
      setDefendantTambonName(defendantTambonName);
    }
  };

  const getAmphoesByProvinceId = (provinceId) => {
    if (provinceId) {
      return provincesData.find((province) => province.id === provinceId)
        .amphure;
    }
  };

  const getTambonsByAmphoeId = (amphoeId) => {
    if (amphoeId) {
      return provincesData
        .flatMap((province) => province.amphure)
        .find((amphoe) => amphoe.id === amphoeId).tambon;
    }
  };

  function getUserCompany() {
    axios
      .get(`${config.mainAPI}/users-company`)
      .then((response) => {
        setUserCompany(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleHistoryWithDraw = () => {
    const id = params;
    navigate("/draw", { state: { params: id } });
  };

  const handleHistoryGetMoney = () => {
    const id = params;
    navigate("/money", { state: { params: id } });
  };

  const handleWorkByChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setChooseMemberCompany(workById);
    setChooseMemberCompanyName(workByName);
  };

  const goCourtEtc = dateGoCourtEtc.map((item, index) => ({
    date: item ? moment(item).format("YYYY-MM-DD") : null,
    time: timeGoCourtEtc[index] ? timeGoCourtEtc[index] : null,
    lawyer: lawyerGoCourtEtc[index] ? lawyerGoCourtEtc[index] : null,
    lawyerName: lawyerNameGoCourtEtc[index]
      ? lawyerNameGoCourtEtc[index]
      : null,
    detail: detailGoCourtEtc[index] ? detailGoCourtEtc[index] : null,
    round: index + 1,
  }));

  const creditCard = dateRegisCreditCard.map((item, index) => ({
    order: index + 1,
    date_start: dateRegisCreditCard[index] ? dateRegisCreditCard[index] : null,
    name: creditCardName[index] ? creditCardName[index] : null,
    id: creditCardNumber[index] ? creditCardNumber[index] : null,
    limit: creditCardLimit[index] ? creditCardLimit[index] : null,
    principal: creditCardPrincipal[index] ? creditCardPrincipal[index] : null,
    interest: creditCardInterest[index] ? creditCardInterest[index] : null,
    fee: creditCardFees[index] ? creditCardFees[index] : null,
    interest_rate: creditCardInterestRates[index]
      ? creditCardInterestRates[index]
      : null,
  }));

  const moneyCard = dateRegisMoneyCard.map((item, index) => ({
    order: index + 1,
    date_start: dateRegisMoneyCard[index] ? dateRegisMoneyCard[index] : null,
    name: moneyCardName[index] ? moneyCardName[index] : null,
    id: moneyCardNumber[index] ? moneyCardNumber[index] : null,
    limit: moneyCardLimit[index] ? moneyCardLimit[index] : null,
    principal: moneyCardPrincipal[index] ? moneyCardPrincipal[index] : null,
    interest: moneyCardInterest[index] ? moneyCardInterest[index] : null,
    fee: moneyCardFees[index] ? moneyCardFees[index] : null,
    interest_rate: moneyCardInterestRates[index]
      ? moneyCardInterestRates[index]
      : null,
  }));

  const updateCountCreditCard = (event) => {
    setCountCreditCard(event);
    console.log("CountCourtEtc", event);

    // const handleWorkByChange = (event) => {
    //   const value = parseInt(event.target.dataset.value);
    //   const workByName = event.currentTarget.getAttribute("name");
    //   const workById = event.currentTarget.getAttribute("id");
    //   setChooseMemberCompany(workById);
    //   setChooseMemberCompanyName(workByName);
    // };

    // Splice data from arrays if their lengths are greater than countCourtEtc
    // อนาคตต้องมีการดักว่าต้องกรอกนะ
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  const updateCountMoneyCard = (event) => {
    setCountMoneyCard(event);
    console.log("CountCourtEtc", event);
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  function updateStatusFinish() {
    setIsLoading(true);
    axios
      .post("${config.mainAPI}/credit-success", {
        genCode: genCode,
        type: "credit",
      })
      .then(function (response) {
        setIsLoading(false);
        setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setOpenFailure(true);
      });
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const updateCountCourtEtc = (event) => {
    setCountCourtEtc(event);
    console.log("CountCourtEtc", event);

    // const handleWorkByChange = (event) => {
    //   const value = parseInt(event.target.dataset.value);
    //   const workByName = event.currentTarget.getAttribute("name");
    //   const workById = event.currentTarget.getAttribute("id");
    //   setChooseMemberCompany(workById);
    //   setChooseMemberCompanyName(workByName);
    // };

    // Splice data from arrays if their lengths are greater than countCourtEtc
    // อนาคตต้องมีการดักว่าต้องกรอกนะ
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  //   const handleDatePickerChange = (christDate, buddhistDate) => {
  //     console.log({ christDate, buddhistDate });
  //     setSelectedDate(christDate);
  //     // setSelectedThaiDate(buddhistDate);
  //   };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setData(christDate);
  };

  const handleDateGoCourtEtcChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleTimeGoCourtEtcChange = (date, index) => {
    const formattedDate = date;
    setTimeGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = formattedDate;
      return data;
    });
  };

  const disabledHours = () => {
    const disabledHoursArray = [];
    for (let i = 0; i < 8; i++) {
      disabledHoursArray.push(i);
    }
    for (let i = 19; i <= 23; i++) {
      disabledHoursArray.push(i);
    }
    return disabledHoursArray;
  };

  const handleLawyerGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    console.log("workByName", workByName);
    console.log("workById", workById);
    setLawyerGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workById;
      return data;
    });
    setLawyerNameGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workByName;
      return data;
    });
  };

  const handleDetailGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    setDetailGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleDateCreditCardStartDateChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateRegisCreditCard((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleCreditCardNameChange = (event, index) => {
    const { value } = event.target;
    setCreditCardName((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardNumberChange = (event, index) => {
    const { value } = event.target;
    setCreditCardNumber((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardLimitChange = (event, index) => {
    const { value } = event.target;
    setCreditCardLimit((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardInterestChange = (event, index) => {
    const { value } = event.target;
    setCreditCardInterest((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardPrincipalChange = (event, index) => {
    const { value } = event.target;
    setCreditCardPrincipal((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardFeesChange = (event, index) => {
    const { value } = event.target;
    setCreditCardFees((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardInterestRatesChange = (event, index) => {
    const { value } = event.target;
    setCreditCardInterestRates((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardTotalChange = (event, index) => {
    const { value } = event.target;
    setCreditCardTotal((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleDateMoneyCardStartDateChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateRegisMoneyCard((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleMoneyCardNameChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardName((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardNumberChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardNumber((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardLimitChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardLimit((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardInterestChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardInterest((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardPrincipalChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardPrincipal((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardFeesChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardFees((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardInterestRatesChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardInterestRates((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardTotalChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardTotal((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
        >
          ข้อมูลคดีบัตรเครดิต
        </Typography>
        <Box sx={{ padding: "30px" }}>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ข้อมูลลูกหนี้"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำเลย:{" "}
                    <span style={{ marginLeft: 5 }}>
                    {defendantFirstName ? prefix + defendantFirstName + " " + defendantLastName : '-'}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    บัตรประชาชน:
                    <span style={{ marginLeft: 5 }}>{defendantIdCard ? defendantIdCard : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    อายุ:
                    <span style={{ marginLeft: 5 }}>{defendantAge ? defendantAge : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    อาชีพ:
                    <span style={{ marginLeft: 5 }}>{defendantJob ? defendantJob : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    บ้านเลขที่:
                    <span style={{ marginLeft: 5 }}>
                      {defendantHouseNumber ? defendantHouseNumber : '-'}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    หมู่:
                    <span style={{ marginLeft: 5 }}>{defendantGroup ? defendantGroup : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ถนน:
                    <span style={{ marginLeft: 5 }}>{defendantRoad ? defendantRoad : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ซอย:
                    <span style={{ marginLeft: 5 }}>{defendantSoi ? defendantSoi : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ใกล้เคียง:
                    <span style={{ marginLeft: 5 }}>{defendantAddress ? defendantAddress : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เขต/ตำบล:
                    <span style={{ marginLeft: 5 }}>{defendantRoad ? defendantRoad : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    แขวง/อำเภอ:
                    <span style={{ marginLeft: 5 }}>{defendantSoi ? defendantSoi : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จังหวัด:
                    <span style={{ marginLeft: 5 }}>{defendantRoad ? defendantRoad : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    รหัสไปรษณี:
                    <span style={{ marginLeft: 5 }}>{defendantSoi ? defendantSoi : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    โทรสาร:
                    <span style={{ marginLeft: 5 }}>{phone1 ? phone1 : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    โทรศัพท์:
                    <span style={{ marginLeft: 5 }}>{phone ? phone : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ข้อมูลบัตรเครดิต"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำนวนบัตรเครดิต:
                    <span style={{ marginLeft: 5 }}>{countCreditCard ? countCreditCard : 0}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ยอดเงินรวมบัตรเครดิต:
                    <span style={{ marginLeft: 5 }}>{}</span>
                  </Typography>
                </Grid>
              </Grid>
              {countCreditCard > 0 && (
                <>
                  {[...Array(parseInt(countCreditCard))].map((_, i) => (
                    <React.Fragment key={i}>
                      <Divider sx={{ mb: 5 }} />
                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันยื่นขอสมัครบัตรเครดิต({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {dateRegisCreditCard[i]
                                ? moment(dateRegisCreditCard[i])
                                    .locale("th")
                                    .add(543, "year")
                                    .format("DD/MM/YYYY")
                                : "-"}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ชื่อบัตรเครดิต({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {creditCardName[i]}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            หมายเลขบัตรเครดิต({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {creditCardNumber[i]}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วงเงินบัตรเครดิต({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {creditCardLimit[i]}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เงินต้นบัตรเครดิต({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {creditCardPrincipal[i]}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ดอกเบี้ยบัตรเครดิต({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {creditCardInterest[i]}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าธรรมเนียมบัตรเครดิต({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {creditCardFees[i]}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            อัตราดอกเบี้ยบัตรเครดิต({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {creditCardInterestRates[i]}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ยอดรวมบัตรเครดิต({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {parseInt(creditCardPrincipal[i]) +
                                parseInt(creditCardFees[i]) +
                                parseInt(creditCardInterest[i])}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              )}
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ข้อมูลบัตรเงินสด"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำนวนบัตรเงินสด:
                    <span style={{ marginLeft: 5 }}>{countMoneyCard}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ยอดเงินรวมบัตรเงินสด:
                    <span style={{ marginLeft: 5 }}>{}</span>
                  </Typography>
                </Grid>
              </Grid>
              {countMoneyCard > 0 && (
                <>
                  {[...Array(parseInt(countMoneyCard))].map((_, i) => (
                    <React.Fragment key={i}>
                      <Divider sx={{ mb: 5 }} />
                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันยื่นขอสมัครบัตรเงินสด({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>{}</span>
                            {/* dateRegisMoneyCard[i] */}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ชื่อบัตรเงินสด({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {moneyCardName[i]}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            หมายเลขบัตรเงินสด({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {moneyCardNumber[i]}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วงเงินบัตรเงินสด({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {moneyCardLimit[i]}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เงินต้นบัตรเงินสด({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {moneyCardPrincipal[i]}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ดอกเบี้ยบัตรเงินสด({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {moneyCardInterest[i]}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าธรรมเนียมบัตรเงินสด({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {moneyCardFees[i]}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            อัตราดอกเบี้ยบัตรเงินสด({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {moneyCardInterestRates[i]}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ยอดรวมบัตรเงินสด({i + 1}):{" "}
                            <span style={{ marginLeft: 5 }}>
                              {parseInt(moneyCardPrincipal[i]) +
                                parseInt(moneyCardFees[i]) +
                                parseInt(moneyCardInterest[i])}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              )}
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardHeader sx={{ backgroundColor: "#181414" }} title="ข้อมูลคดี" />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    หน่วยงาน:
                    <span style={{ marginLeft: 5 }}>{agency}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันบอกกล่าว:
                    <span style={{ marginLeft: 5 }}>{phone}</span>
                    {/* noticeDay */}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ศาล:
                    <span style={{ marginLeft: 5 }}>{court}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีศาล:
                    <span style={{ marginLeft: 5 }}>{courtCode}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันขึ้นศาล:{" "}
                    {courtDate
                      ? moment(courtDate)
                          .locale("th")
                          .add(543, "year")
                          .format("DD/MM/YYYY")
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เวลาขึ้นศาล:{" "}
                    {courtTime
                      ? moment(courtTime, "HH:mm:ss").format("HH:mm")
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีดำ:
                    <span style={{ marginLeft: 5 }}>
                      {blackCaseNumber}/{blackCaseYear}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีแดง:
                    <span style={{ marginLeft: 5 }}>
                      {redCaseNumber}/{redCaseYear}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันนัดสืบพยาน:
                    <span style={{ marginLeft: 5 }}>
                      {witnessExaminationDate
                        ? moment(witnessExaminationDate)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    พิพากษา(ฝ่ายเดียว):
                    <span style={{ marginLeft: 5 }}>
                      {judgmentUnilateral
                        ? moment(judgmentUnilateral)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    พิพากษา(ตามยอม):
                    <span style={{ marginLeft: 5 }}>
                      {judgmentAccordingToConsent
                        ? moment(judgmentAccordingToConsent)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จำนวนวันที่ดำเนินการ:
                    <span style={{ marginLeft: 5 }}>{dayProcess}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันที่ถอนฟ้อง:
                    <span style={{ marginLeft: 5 }}>{phone1}</span>
                    {/* withdrawOneCase */}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เนื่องจาก:
                    <span style={{ marginLeft: 5 }}>
                      {withdrawOneCaseDueTo}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    รายละเอียดคำพิพากษา(โดยย่อ):
                    <span style={{ marginLeft: 5 }}>{detailsJudgment}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ส่งแฟ้มคืนวันที่:
                    <span style={{ marginLeft: 5 }}>
                      {returnFile
                        ? moment(returnFile)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เหตุผล:
                    <span style={{ marginLeft: 5 }}>{returnFileReason}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    หมายเหตุ:
                    <span style={{ marginLeft: 5 }}>{note}</span>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="รายการเอกสารในสำนวนคดี"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "30px",
                    }}
                  >
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align="center"
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredRows
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                              <TableRow key={row.order}>
                                {columns.map((column) => (
                                  <TableCell key={column.id} align="center">
                                    {column.render
                                      ? column.render(row)
                                      : row[column.id]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      /> */}
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {user && user.role != "bank" ? (
            <Card sx={{ mb: 3 }}>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลบริษัท"
              />
              <CardContent sx={{ border: "0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขแฟ้ม:
                      <span style={{ marginLeft: 5 }}>
                        {fileNumber ? fileNumber : "-"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีบริษัท:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {companyCode ? companyCode : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันรับเรื่อง:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {dateStart
                          ? moment(dateStart)
                              .locale("th")
                              .add(543, "year")
                              .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันกำหนดส่ง:
                      {/* <span style={{ marginLeft: 5 }}>
                      {deadline
                        ? moment(deadline)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                        : "-"}
                    </span> */}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ผู้รับผิดชอบคดี:
                      <span style={{ marginLeft: 5 }}>
                        {chooseMemberCompanyName
                          ? chooseMemberCompanyName
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      อื่นๆ(สำหรับโน้ต): {other ? other : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : null}
        </Box>
      </Card>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsDetailCredit;
