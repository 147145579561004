import MainLayout from "layout/MainLayout";
import { useSelector } from "react-redux";
import DashboardDefault from "views/dashboard/Default";
import Home from "views/pages/Home";
import CalendarView from "views/pages/CalendarView";
import DashboardDocument from "views/dashboard/Default/DashboardDocument";
import Documents from "views/pages/documents/Documents";
// import DocumentsForm from "views/pages/documents/DocumentsForm";
import DocumentsFormLoan from "views/pages/documents/DocumentsFormLoan";
import DocumentsFormExpel from "views/pages/documents/DocumentsFormExpel";
import DocumentsFormCreadit from "views/pages/documents/DocumentsFormCreadit";
import DocumentsFormMoney from "views/pages/documents/DocumentsFormMoney";
import DocumentsFormDraw from "views/pages/documents/DocumentsFormDraw";
import DocumentsLoan from "views/pages/documents/DocumentsLoan";
import DocumentsCreadit from "views/pages/documents/DocumentsCreadit";
import DocumentsExpal from "views/pages/documents/DocumentsExpal";
import DocumentsLegalExecutionDepartment from "views/pages/documents/DocumentsLegalExecutionDepartment";
import DocumentsFormLegalExecutionDepartment from "views/pages/documents/DocumentsFormLegalExecutionDepartment";
import Notification from "views/pages/notification/Notification";
import Users from "views/pages/admin/Users";
import Prefix from "views/pages/others/Prefix";
import DeptCenter from "views/pages/others/DeptCenter";
import Login from "views/pages/auth/Login";
import Profile from "views/pages/profile/Profile";
import PageNotFound from "views/pages/others/PageNotFound";

import DocumentsDetailCredit from "views/pages/documents/DocumentsDetailCredit";
import DocumentsDetailLoan from "views/pages/documents/DocumentsDetailLoan";
import DocumentsDetailExpal from "views/pages/documents/DocumentsDetailExpal";
import DocumentsDetailLed from "views/pages/documents/DocumentsDetailLed";
import DocumentsExecution from "views/pages/documents/DocumentsExecution";
import DocumentsExecutionForm from "views/pages/documents/DocumentsExecutionForm";
import CheckTokenExpiration from "./CheckTokenExpiration";
import MasterConfig from "views/pages/others/MasterConfig";
import TemplatePdf from "views/pages/template/TemplatePdf";
import WorkingTime from "views/pages/company/WorkingTime";
import TimeSheet from "views/pages/company/TimeSheet";
import Petition from "views/pages/company/Petition";
import Leave from "views/pages/company/Leave";

import Template from "views/pages/template/Template";
import TemplateForm from "views/pages/template/TemplateForm";

// Manage
import ManageLeave from "views/pages/manage/ManageLeave";
import ManageTimeWorking from "views/pages/manage/ManageTimeWorking";
import ManagePetition from "views/pages/manage/ManagePetition";

// Account
import Account from "views/pages/account/Account";
import AccountManage from "views/pages/account/AccountManage";
import SalaryManage from "views/pages/account/SalaryManage";
import SalaryDetail from "views/pages/account/SalaryDetail";

const isLogin = window.localStorage.getItem("isLogin");
const MainRoutes = {
  path: "/",
  // element: isLogin ? <MainLayout /> : <Login />,
  // element: <MainLayout />,
  element: (
    <CheckTokenExpiration>
      <MainLayout />
    </CheckTokenExpiration>
  ),
  children: [
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/calendar",
      element: <CalendarView />,
    },
    {
      path: "/dashboards",
      element: <DashboardDefault />,
    },
    {
      path: "/loan",
      element: <DocumentsLoan />,
    },
    {
      path: "/credit",
      element: <DocumentsCreadit />,
    },
    {
      path: "/expal",
      element: <DocumentsExpal />,
    },
    {
      path: "/led",
      element: <DocumentsLegalExecutionDepartment />,
    },
    {
      path: "/led/form",
      element: <DocumentsFormLegalExecutionDepartment />,
    },
    {
      path: "/loan/form",
      element: <DocumentsFormLoan />,
    },
    {
      path: "/expal/form",
      element: <DocumentsFormExpel />,
    },
    {
      path: "/credit/form",
      element: <DocumentsFormCreadit />,
    },
    {
      path: "/draw",
      element: <DocumentsFormDraw />,
    },
    {
      path: "/money",
      element: <DocumentsFormMoney />,
    },
    {
      path: "/dashboard",
      element: <DashboardDocument />,
    },
    {
      path: "/users",
      element: <Users />,
    },
    {
      path: "/notification",
      element: <Notification />,
    },
    {
      path: "/prefix",
      element: <Prefix />,
    },
    {
      path: "/debt-center",
      element: <DeptCenter />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/loan/detail",
      element: <DocumentsDetailLoan />,
    },
    {
      path: "/expal/detail",
      element: <DocumentsDetailExpal />,
    },
    {
      path: "/credit/detail",
      element: <DocumentsDetailCredit />,
    },
    {
      path: "/led/detail",
      element: <DocumentsDetailLed />,
    },
    {
      path: "/execution",
      element: <DocumentsExecution />,
    },
    {
      path: "/execution/form",
      element: <DocumentsExecutionForm />,
    },
    {
      path: "/config",
      element: <MasterConfig />,
    },
    {
      path: "/template",
      element: <Template />,
    },
    {
      path: "/template/form",
      element: <TemplateForm />,
    },
    {
      path: "/template-pdf",
      element: <TemplatePdf />,
    },
    {
      path: "/time-working",
      element: <WorkingTime />,
    },
    {
      path: "/timesheet",
      element: <TimeSheet />,
    },
    {
      path: "/petition",
      element: <Petition />,
    },
    {
      path: "/leave",
      element: <Leave />,
    },
    {
      path: "/manage-leave",
      element: <ManageLeave />,
    },
    {
      path: "/manage-petition",
      element: <ManagePetition />,
    },
    {
      path: "/manage-timeworking",
      element: <ManageTimeWorking />,
    },
    {
      path: "/account",
      element: <Account />,
    },
    {
      path: "/account-manage",
      element: <AccountManage />,
    },
    {
      path: "/salary-manage",
      element: <SalaryManage />,
    },
    {
      path: "/salary-detail",
      element: <SalaryDetail />,
    },
  ],
};

export default MainRoutes;
