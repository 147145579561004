import MinimalLayout from 'layout/MinimalLayout';

const PublicRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        
    ]
};

export default PublicRoutes;
