import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";

// material-ui
import { Grid, Typography } from "@mui/material";

import AllCard from "./components/Documents/AllCard";
import FinishCard from "./components/Documents/FinishCard";
import ProcessCard from "./components/Documents/ProcressCard";
import WaitingCard from "./components/Documents/WaitingCard";
import TotalGrowthBarChart from "./components/Documents/TotalGrowthBarChart";

import ChartPieLoan from "./components/Documents/ChartPieLoan";
import ChartPieExpal from "./components/Documents/ChartPieExpal";
import ChartPieCreadit from "./components/Documents/ChartPieCreadit";
import ChartPieLed from "./components/Documents/ChartPieLed";

// All Case
import AllCase from "./components/Documents/AllCase";
import LoanCase from "./components/Documents/LoanCase";
import ExpalCase from "./components/Documents/ExpalCase";
import CreaditCase from "./components/Documents/CreaditCase";
import LedCase from "./components/Documents/LedCase";
import moment from "moment";

import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import Card from "@mui/material/Card";
import { gridSpacing } from "../../../store/constant";
import useAuth from "function/useAuth";

const DashboardDocument = () => {
  const [isLoading, setLoading] = useState(true);
  const [loan, setLoan] = useState([]);
  const [credit, setCredit] = useState([]);
  const [expal, setExpal] = useState([]);
  const [led, setLed] = useState([]);
  const [countAll, setCountAll] = useState([]);
  const [countDocs, setCountDocs] = useState([]);
  const [countLoan, setCountLoan] = useState(0);
  const [countExpal, setCountExpal] = useState(0);
  const [countCredit, setCountCredit] = useState(0);
  const [countLed, setCountLed] = useState(0);

  const [loanStatusFinish, setLoanStatusFinish] = useState(0);
  const [loanStatusUnFinish, setLoanStatusUnFinish] = useState(0);
  const [creditStatusFinish, setCreditStatusFinish] = useState(0);
  const [creditStatusUnFinish, setCreditStatusUnFinish] = useState(0);
  const [expalStatusFinish, setExpalStatusFinish] = useState(0);
  const [expalStatusUnFinish, setExpalStatusUnFinish] = useState(0);
  const [ledStatusFinish, setLedStatusFinish] = useState(0);
  const [ledStatusUnFinish, setLedStatusUnFinish] = useState(0);


  useEffect(() => {
    getCountData();
    getChart();
    getStatus();
  }, []);

  function getStatus() {
    axios
      .get(`${config.mainAPI}/getStatusDocs`)
      .then((response) => {
        const value = response.data.data;
        setLoanStatusFinish(value.loan.finish);
        setLoanStatusUnFinish(value.loan.unfinish);
        setCreditStatusFinish(value.credit.finish);
        setCreditStatusUnFinish(value.credit.unfinish);
        setExpalStatusFinish(value.expal.finish);
        setExpalStatusUnFinish(value.expal.unfinish);
        setLedStatusFinish(value.led.finish);
        setLedStatusUnFinish(value.led.unfinish);

      })
      .catch((error) => {
        console.error(error);
      });
  }


  function getCountData() {
    axios
      .get(`${config.mainAPI}/count-case`)
      .then((response) => {
        const value = response.data.data[0];
        setCountLoan(value.count_loan);
        setCountCredit(value.count_credit);
        setCountExpal(value.count_expal);
        setCountLed(value.count_led);
        setCountAll(value.total_count);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getChart() {
    axios
      .get(`${config.mainAPI}/getCountChartByMonth`)
      .then((response) => {
        const value = response.data.data;
        setLoan(value[0].map((item) => item.count));
        setCredit(value[1].map((item) => item.count));
        setExpal(value[2].map((item) => item.count));
        setLed(value[3].map((item) => item.count));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useAuth();

  const pieData = {
    series: [0, 0],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: ["กำลังดำเนินงาน", "เสร็จสิ้น"], // Add the labels property here
      colors: ["#febf44", "#00e396"],
    },
  };

  const chartData = {
    series: [
      {
        name: "สินเชื่อ",
        data: loan,
      },
      {
        name: "ขับไล่",
        data: expal,
      },
      {
        name: "บัตรเครดิต",
        data: credit,
      },
      {
        name: "บังคับคดี",
        data: led,
      },
    ],

    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "ม.ค.",
          "ก.พ.",
          "มี.ค",
          "เม.ย",
          "พ.ค.",
          "มิ.ย",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ],
      },
      yaxis: {
        title: {
          text: "จำนวนงานทั้งหมดประจำปี",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " เคส";
          },
        },
      },
    },
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <AllCase isLoading={isLoading} count={countAll} />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <LoanCase isLoading={isLoading} count={countLoan} />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <ExpalCase isLoading={isLoading} count={countExpal} />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <CreaditCase isLoading={isLoading} count={countCredit} />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <LedCase isLoading={isLoading} count={countLed} />
          </Grid>
        </Grid>
      </Grid>

      {/* Pie */}
      <Grid item xs={12} md={6}>
        <Card>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "10px",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            รายการสินเชื่อ
          </Typography>
          <ChartPieLoan finish={loanStatusFinish} unfinish={loanStatusUnFinish} />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "10px",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            รายการขับไล่
          </Typography>
          <ChartPieExpal finish={expalStatusFinish} unfinish={expalStatusUnFinish} />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "10px",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            รายการบัตรเครดิต
          </Typography>
          <ChartPieCreadit finish={creditStatusFinish} unfinish={creditStatusUnFinish} />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "10px",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            รายการบังคับคดี
          </Typography>
          <ChartPieLed finish={ledStatusFinish} unfinish={ledStatusUnFinish} />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={12}>
            {/* <TotalGrowthBarChart isLoading={isLoading} /> */}
            <Card>
              <Typography
                variant="h2"
                sx={{ marginBottom: "20px", textAlign: "center", mt: 3 }}
              >
                ข้อมูลรายการคดีทั้งหมด ประจำปี
                {moment().locale("th").add(543, "year").format("YYYY")}
              </Typography>
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardDocument;
