import React, { useState, useRef, useEffect } from "react";
import useAuth from "function/useAuth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import User1 from "assets/images/users/businesswoman.png";
import administrative from "assets/images/users/businesswoman.png";
import ownerAvatar from "assets/images/users/politician.png";
import lawyerAvatar from "assets/images/users/lawyer.png";

import axios from "axios";

// assets
import {
  IconLogout,
  IconUserCircle,
  IconSearch,
  IconSettings,
  IconUser,
} from "@tabler/icons";

// ==============================|| PROFILE MENU ||============================== //

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState([]);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    localStorage.removeItem("user_data");
    localStorage.removeItem("userData");
    localStorage.removeItem("isLoggedIn");
    navigate("/login");
  };

  const handleProfile = async () => {
    navigate("/profile");
  };

  const readAll = () => {
    const hospital = user.hospital_id;
    const role = user.role_status;
    axios
      .put("http://localhost:6969/notification/read-all", {
        hospital: hospital,
        role: role,
      })
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  useAuth();

  const prevOpen = useRef(open);
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      console.log("JSON.parse(userData) =>", JSON.parse(userData));
      setUser(JSON.parse(userData));
    }
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.thanic.dark,
          backgroundColor: theme.palette.thanic.dark,
          '&[aria-controls="menu-list-grow"], &:hover': {
            background: `${theme.palette.thanic.darkHover}!important`,
            color: theme.palette.thanic.dark,
            "& svg": {
              stroke: "#fff",
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            // src={User1}
            src={
              user.role == "bank"
                ? User1
                : user.role == "lawyer"
                ? lawyerAvatar
                : user.role == "owner"
                ? ownerAvatar
                : user.role == "administrative"
                ? administrative
                : User1
            }
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={"#fff"} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">
                          {user.firstname} {user.lastname} ({user.position})
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        {user.role != "bank" ? (
                          <>
                            <ListItemButton
                              sx={{
                                borderRadius: `${customization.borderRadius}px`,
                              }}
                              selected={selectedIndex === 4}
                              onClick={handleProfile}
                            >
                              <ListItemIcon>
                                <IconUserCircle stroke={1.5} size="1.3rem" />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography variant="body2">
                                    โปรไฟล์
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </>
                        ) : null}

                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                ออกจากระบบ
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
