import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";
import api from "../api";
import { jwtDecode } from "jwt-decode"; // Ensure this import is correct
import moment from "moment";
import "moment-timezone";
import "moment/locale/th"; // Import Thai locale
import ErrorIcon from "@mui/icons-material/Error";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";

const useAuth = () => {
  const navigate = useNavigate();
  const [openWarning, setOpenWarning] = useState(false);

  useEffect(() => {
    const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    if (isLoggedIn != true) {
      localStorage.removeItem("userData");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      navigate("/login");
    }
    checkTimeOut();
  }, []);

  const checkTimeOut = () => {
    const token = localStorage.getItem("token");
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      const expirationTime1 = moment(decoded.exp * 1000).tz("Asia/Bangkok"); // Convert exp to milliseconds, create Moment object, and set to Bangkok time zone
      console.log(
        "Token expiration time (Thai):",
        expirationTime1.format("LLLL")
      );
      if (decoded.exp < currentTime) {
        setOpenWarning(true);
        localStorage.removeItem("userData");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("token");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  };

  
  const handleClose = () => {
    setOpenWarning(false);
  };

  // <Dialog open={openWarning} fullWidth maxWidth={"xs"}>
  //   {/* <BootstrapDialogTitle
  //     id="customized-dialog-title"
  //     onClose={handleClose}
  //   ></BootstrapDialogTitle> */}
  //   <DialogContent>
  //     <Box textAlign="center">
  //       <ErrorIcon sx={{ color: "#ff2929", fontSize: 100 }} />
  //     </Box>
  //     <Typography
  //       variant="h1"
  //       sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
  //     >
  //       ข้อความแจ้งเตือน
  //     </Typography>
  //     <Typography
  //       variant="h4"
  //       sx={{
  //         fontWeight: 500,
  //         textAlign: "center",
  //         marginTop: "20px",
  //         marginBottom: "20px",
  //         color: "#1f1f1f",
  //       }}
  //     >
  //       หมดเวลาในการเข้าใช้งาน กรุณาเข้าสู่ระบบใหม่อีกครั้ง
  //     </Typography>
  //     <Box textAlign="center" sx={{ marginTop: "20px", marginBottom: "20px" }}>
  //       <Button variant="outlined" color="inherit" onClick={handleClose}>
  //         ปิด
  //       </Button>
  //     </Box>
  //   </DialogContent>
  // </Dialog>;
};

export default useAuth;
