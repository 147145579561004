import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  TextField,
  Card,
  Button,
  Typography,
  Paper,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardHeader,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useAuth from "function/useAuth";
import "moment/locale/th";
import "react-datepicker/dist/react-datepicker.css";
import { WatDatePicker } from "thaidatepicker-react";
import config from "../../../config";

const DocumentsFormMoney = () => {
  const [user, setUser] = useState();
  const { state } = useLocation();
  const params = state ? state.params : null;
  const [firstGetMoney, setFirstGetMoney] = useState("");
  const [secondGetMoney, setSecondGetMoney] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  useAuth();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log(JSON.parse(userData));
    setUser(JSON.parse(userData));
    if (params && userData) {
      getDataForm();
    }
  }, []);
  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log("firstGetMoney", firstGetMoney);
    console.log("secondGetMoney", secondGetMoney);
    // setIsLoading(true);
    axios
      .post(`${config.mainAPI}/money`, {
        genCode: params,
        firstDate: firstGetMoney,
        secondDate: secondGetMoney,
        user: user.username,
      })
      .then(function (response) {
        setIsLoading(false);
        setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setOpenFailure(true);
      });
  };

  const getDataForm = () => {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/money/${params}`)
      .then((response) => {
        console.log("response.data.data", response.data.data[0]);
        const value = response.data.data[0];
        setFirstGetMoney(value.first_date_money);
        setSecondGetMoney(value.second_date_money);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleDocumentForm = () => {
    const id = params;
    navigate("/loan/form", { state: { params: id } });
  };

  const handleHistoryWithDraw = () => {
    const id = params;
    navigate("/draw", { state: { params: id } });
  };

  const handleCloseFailure = () => {
    setOpenFailure(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate("/documents");
  };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setData(christDate);
  };

  return (
    <div>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
          <Typography
            variant="h3"
            sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
          >
            รายละเอียดประวัติการได้รับค่าจ้าง
          </Typography>
          <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 3,
                marginTop: 3,
                float: "right",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                  color: "#000",
                  borderColor: "#000",
                }}
                color="primary"
                onClick={handleDocumentForm}
                startIcon={<AssignmentIcon />}
              >
                รายละเอียดเอกสาร
              </Button>
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                  color: "#000",
                  borderColor: "#000",
                }}
                color="primary"
                onClick={handleHistoryWithDraw}
                startIcon={<RequestQuoteIcon />}
              >
                ประวัติการเบิกเงิน
              </Button>
            </Box>
          </Card>

          <Box sx={{ padding: "30px" }}>
            <Card sx={{ mb: 3 }}>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="รายละเอียด"
              />
              <CardContent sx={{ border: "0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      งวดที่ 1 วันที่:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={firstGetMoney}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setFirstGetMoney,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      งวดที่ 2 วันที่:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={secondGetMoney}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setSecondGetMoney,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => navigate("/documents")}
                      sx={{ marginRight: 2 }}
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleSubmit}
                    >
                      บันทึก
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Card>
      )}
      <Dialog open={openFailure} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <ErrorIcon sx={{ color: "#ff0c34", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#ff0c34",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseFailure}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openSuccess} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <CheckCircleOutlineIcon sx={{ color: "#52c41a", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h2"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#1f1f1f",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลสำเร็จ!
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleCloseSuccess}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DocumentsFormMoney;
