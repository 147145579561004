import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../documents/styles/styles.css";
import api from "../../../api";
import lawImage from "../../../assets/images/mark-law.jpg";
import noimage from "../../../assets/images/users/noimg.png";
import config from "../../../config";
import { FileUploader } from "react-drag-drop-files";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Paper,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import moment from "moment/moment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
// import useAuth from "function/useAuth";

const Profile = () => {
  const [user, setUser] = useState();
  const [userName, setUserName] = useState(null);
  const [prefix, setPrefix] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [id, setId] = useState(null);
  const [code, setCode] = useState(null);
  const [role, setRole] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUploadUser, setFileUploadUser] = useState(null);
  const [fileUploadSign, setFileUploadSign] = useState(null);
  const fileTypes = ["PNG", "JPG", "JPEG"];

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    setUser(JSON.parse(userData));
    if (userData) {
      setId(JSON.parse(userData).id);
      setFirstName(JSON.parse(userData).firstname);
      setLastName(JSON.parse(userData).lastname);
      setRole(JSON.parse(userData).position);
      setUserName(JSON.parse(userData).username);
    }
  }, []);

  function handleClearFileUpload() {
    setFileUpload(null);
    setFileUploadUser(null);
    setFileUploadSign(null);
  }

  const handleUploadChange = (file) => {
    setFileUpload(file);
    console.log(file);
  };

  const handleUploadChangeUser = (file) => {
    setFileUploadUser(file);
    console.log(file);
  };

  const handleUploadChangeSign = (file) => {
    setFileUploadSign(file);
    console.log(file);
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleSubmit = () => {
    const payload = {
      id: id,
      userName: userName,
      prefix: prefix,
      firstName: firstName,
      lastName: lastName,
      idCard: idCard,
      code: code,
      role: role,
      phone: phone,
      email: email,
    };
    api
      .post(`${config.mainAPI}/user-edit`, {
        ...payload,
      })
      .then(function (response) {
        // setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        // setOpenFailure(true);
      });
  };

  const handleSubmitFileUpload = async (type) => {
    let fileUse =
      type == "user" ? fileUploadUser : type == "sign" ? fileUploadSign : null;
    if (fileUse) {
      console.log("fileUse", fileUse);
      const formData = new FormData();
      formData.append("fileUpload", fileUse);
      formData.append("fileName", fileUse.name);
      formData.append("type", type);
      formData.append("type_docs", type);
      formData.append("user", user.username);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileUse.name} Success`);
        setFileUpload(null);
        setFileUploadSign(null);
        setFileUploadUser(null);
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUse.name} Failure`);
      }
    }
  };

  return (
    <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
      <Box sx={{ padding: "30px" }}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <img src={noimage} style={{ width: "178.78px", height: "210px" }} />
            <Typography sx={{ fontSize: "16px", mt: 3 }}>
              ชื่อผู้ใช้ : {userName}
            </Typography>
          </Grid>
        </Grid>
        <Card>
          <CardHeader>
            <Typography sx={{ fontSize: "16px" }}>ข้อมูลส่วนตัว:</Typography>
          </CardHeader>
          <Box>
            <Card>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลส่วนตัว"
              />
              <CardContent sx={{ border: " 0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                  <Grid item sm={6} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      รหัสพนักงาน:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={code}
                      disabled
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>ตำแหน่ง:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      disabled
                      value={role}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      คำนำหน้าชื่อ:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={prefix}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>ชื่อ:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={firstName}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>นามสกุล:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={lastName}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขบัตรประชาชน:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={idCard}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เบอร์ติดต่อ:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={phone}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>อีเมล์:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={email}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Card>

        <Card>
          <CardHeader>
            <Typography sx={{ fontSize: "16px" }}>
              รายละเอียดเพิ่มเติม:
            </Typography>
          </CardHeader>
          <Box>
            <Card>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลภาพ"
              />
              <CardContent sx={{ border: " 0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px", mb: 1 }}>
                      ภาพประจำตัว:
                    </Typography>
                    <FileUploader
                      multiple={false}
                      handleChange={handleUploadChangeUser}
                      name="fileUploadUser"
                      types={fileTypes}
                    />
                    <p style={{ marginTop: "1rem" }}>
                      {fileUploadUser ? `ชื่อไฟล์: ${fileUploadUser.name}` : ""}
                      {fileUploadUser ? (
                        <>
                          <IconButton onClick={handleClearFileUpload}>
                            <DeleteForeverIcon color="error" />
                          </IconButton>
                          <IconButton
                            onClick={() => handleSubmitFileUpload("user")}
                          >
                            <UploadFileIcon color="success" />
                          </IconButton>
                        </>
                      ) : null}
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px", mb: 1 }}>
                      ลายเซ็นต์:
                    </Typography>
                    <FileUploader
                      multiple={false}
                      handleChange={handleUploadChangeSign}
                      name="fileUploadSign"
                      types={fileTypes}
                    />
                    <p style={{ marginTop: "1rem" }}>
                      {fileUploadSign ? `ชื่อไฟล์: ${fileUploadSign.name}` : ""}
                      {fileUploadSign ? (
                        <>
                          <IconButton onClick={handleClearFileUpload}>
                            <DeleteForeverIcon color="error" />
                          </IconButton>
                          <IconButton
                            onClick={() => handleSubmitFileUpload("sign")}
                          >
                            <UploadFileIcon color="success" />
                          </IconButton>
                        </>
                      ) : null}
                    </p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Card>

        <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                marginRight: "20px",
                marginTop: "10px",
                marginBottom: "20px",
              }}
              color="success"
              onClick={handleSubmit}
              // startIcon={<DoneAllIcon />}
            >
              อัปเดตข้อมูล
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default Profile;
