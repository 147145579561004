import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import config from "../../../config";
import api from "../../../api";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
// import useAuth from "../../function/useAuth";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";

const Leave = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [idCard, setIdCard] = useState("");
  const [gsbNumber, setGsbNumber] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openFirstTimeLogin, setOpenFirstTimeLogin] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  //   const localizer = momentLocalizer(moment);
  const [eventCalendar, setEventCalendar] = useState([]);
  const [textCalendar, setTextCalendar] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [dateWork, setDateWork] = useState(new Date());
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [detailWork, setDetailWork] = useState("");
  const [hourWork, setHourWork] = useState(null);
  const [timeStart, setTimeStart] = useState(null);
  const [leave, setLeave] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);
  const [error, setError] = useState(null);
  const leaveList = [
    { name: "ลาป่วย", value: "ลาป่วย" },
    { name: "ลาพักร้อน", value: "ลาพักร้อน" },
    { name: "ลากิจ", value: "ลากิจ" },
    { name: "ลาคลอด", value: "ลาคลอด" },
  ];

  const initialHoursList = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minute = i % 2 === 0 ? "00" : "30";
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    return {
      name: `${formattedHour}:${minute}`,
      value: `${formattedHour}:${minute}`,
    };
  });

  const initialMinutesList = Array.from({ length: 60 }, (_, i) => {
    const minute = i < 10 ? `0${i}` : `${i}`;
    return { name: minute, value: minute };
  });

  const [hoursList, sethoursList] = useState(initialHoursList);
  const [minutesList, setminutesList] = useState(initialMinutesList);
  const columns = [
    {
      id: "action",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckForm(row)}>
            <VisibilityIcon />
          </IconButton>

          <IconButton
            disabled={row.status == "APPROVE"}
            onClick={() => handleEditForm(row)}
          >
            <EditIcon color="warning" />
          </IconButton>
        </>
      ),
    },
    { id: "order", label: "ลำดับที่", minWidth: 100 },
    { id: "start", label: "เวลาเริ่มต้น", minWidth: 100 },
    { id: "end", label: "เวลาสิ้นสุด", minWidth: 100 },
    { id: "reason", label: "สาเหตุ", minWidth: 100 },
    { id: "detail", label: "รายละเอียด", minWidth: 100 },
    { id: "status", label: "สถานะ", minWidth: 100 },
    { id: "date_sent", label: "วันที่ยื่น", minWidth: 100 },
  ];

  const handleCloseOpenWarning = () => {
    setOpenWarning(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // useAuth();

  //   function searchData() {
  //     if (type) {
  //       axios
  //         .post(`${config.mainAPI}/searchDataInformation`, {
  //           name: name,
  //           idCard: idCard,
  //           type: type,
  //           gsbNumber: gsbNumber,
  //           role: user.role,
  //           debtCenter: user.debtCenter,
  //         })
  //         .then(function (response) {
  //           const value = response.data.data;
  //           console.log("value searchData", value);
  //           setList(value);
  //           setRows(
  //             value.map((item, index) =>
  //               createData(
  //                 index + 1,
  //                 item.defendant_fullname,
  //                 item.defendant_type,
  //                 item.document_code_gen
  //               )
  //             )
  //           );
  //           setIsLoading(false);
  //         })
  //         .catch(function (error) {
  //           setIsLoading(false);
  //         });
  //     } else {
  //       // Error
  //       setOpenWarning(true);
  //     }
  //   }

  const handleEditForm = (row) => {
    setOpenCreate(true);
    setIsEdit(true);
  };
  const handleCheckForm = (row) => {
    let value = list.find((item) => (item.id = row.id));
    setOpenDetail(true);
    setDateStart(row.start);
    setDateEnd(row.end);
    setDetailWork(row.detail);
    setIsEdit(false);
    setLeave(row.reason);
  };
  const handleClickOpenDelete = (row) => {};

  const closePopup = () => {
    setError(null);
    setDateWork(new Date());
    setDateStart(new Date());
    setDateEnd(new Date());
    setTimeStart(null);
    setTimeEnd(null);
    setDetailWork(null);
    setOpenCreate(false);
    setOpenEdit(false);
    setOpenDetail(false);
    setIsEdit(false);
    setLeave(null);
  };

  const addWorkTime = () => {
    setOpenCreate(false);
  };

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  //   function createData(order, list, type, code) {
  //     return {
  //       order,
  //       list,
  //       type,
  //       code,
  //     };
  //   }
  function createData(
    order,
    start,
    end,
    reason,
    detail,
    status,
    date,
    owner,
    approver,
    id
  ) {
    const formattedDateStart = moment(start)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm");
    const formattedDateEnd = moment(end)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm");
    const formattedDate = moment(date)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm");

    if (
      user.role == "admin" ||
      user.role == "administrative" ||
      user.role == "owner"
    ) {
      return {
        order,
        start: start ? formattedDateStart : "-",
        end: end ? formattedDateEnd : "-",
        reason,
        detail,
        status,
        date: date ? formattedDate : "-",
        owner,
        approver,
        id,
      };
    } else {
      return {
        order,
        start: start ? formattedDateStart : "-",
        end: end ? formattedDateEnd : "-",
        reason,
        detail,
        status,
        date: date ? formattedDate : "-",
        approver,
        id,
      };
    }
  }

  function handleCheckDetail(row) {}

  const saveData = () => {
    if (dateStart && dateEnd && timeStart && timeEnd) {
      const dateStartShow = moment(dateStart).format("YYYY-MM-DD");
      const dateEndShow = moment(dateEnd).format("YYYY-MM-DD");

      const timeStartShow = timeStart;
      const timeEndShow = timeEnd;
      let body = {
        mode: isEdit ? "edit" : "add",
        dateStart: timeStart
          ? moment(
              `${dateStartShow}T${timeStartShow}`,
              "YYYY-MM-DDTHH:mm"
            ).format("YYYY-MM-DD HH:mm")
          : null,
        timeStart: timeStart
          ? moment(
              `${dateStartShow}T${timeStartShow}`,
              "YYYY-MM-DDTHH:mm"
            ).format("YYYY-MM-DD HH:mm")
          : null,
        dateEnd: timeEnd
          ? moment(`${dateEndShow}T${timeEndShow}`, "YYYY-MM-DDTHH:mm").format(
              "YYYY-MM-DD HH:mm"
            )
          : null,
        timeEnd: timeEnd
          ? moment(`${dateEndShow}T${timeEndShow}`, "YYYY-MM-DDTHH:mm").format(
              "YYYY-MM-DD HH:mm"
            )
          : null,
        leave: leave,
        detail: detailWork,
        username: user.username,
      };
      api
        .post(`${config.mainAPI}/createLeave`, body)
        .then(function (response) {
          setIsLoading(false);
          if (response.data.success) {
            closePopup();
            getInitialData(userData);
          } else {
            setError(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      setError("Error can not do it.");
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      console.log("userData", userData);
      setUser(userData);
      getInitialData(userData);
    }
  }, []);

  const getInitialData = (userData) => {
    setIsLoading(true);
    api
      .get(
        `${config.mainAPI}/listLeaveByUser/${userData.role}/${userData.username}`
      )
      .then((response) => {
        console.log(response.data.data);
        const value = response.data.data;
        console.log("value", value);
        setList(value);
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              item.time_start,
              item.time_end,
              item.leave_reason,
              item.leave_detail,
              item.status,
              item.created_date,
              item.created_by,
              item.approver,
              item.id
            )
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleClickOpenCreate = () => {};

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? "red" : event.color,
    };
    return {
      style,
    };
  };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setError(null);
    setData(christDate);
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", mt: 3 }}
        >
          ระบบการลา
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
            float: "right",
          }}
        >
          {/* <Button
            variant="outlined"
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            color="primary"
            startIcon={<FileOpenIcon />}
          >
            Export
          </Button> */}

          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenCreate(true)}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มการลา
          </Button>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>

      <Dialog open={openCreate} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            แบบฟอร์มการลา
          </Typography>
          <Typography sx={{ mb: 3, fontSize: 16, mt: 3 }} textAlign="center">
            {textCalendar}
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                วันที่เริ่มต้น: <span style={{ color: "red" }}> *</span>
              </Typography>

              <WatDatePicker
                className="custom-date-picker"
                value={dateStart}
                onChange={(christDate, buddhistDate) =>
                  handleDatePickerChange(setDateStart, christDate, buddhistDate)
                }
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD/MM/YYYY"}
                clearable={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                เวลา: <span style={{ color: "red" }}> *</span>
              </Typography>
              <Select
                value={timeStart}
                onChange={(e) => setTimeStart(e.target.value)}
                variant="outlined"
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                {hoursList &&
                  hoursList.map((column) => (
                    <MenuItem key={column.value} value={column.value}>
                      {column.value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                วันที่สิ้นสุด: <span style={{ color: "red" }}> *</span>
              </Typography>

              <WatDatePicker
                className="custom-date-picker"
                value={dateEnd}
                onChange={(christDate, buddhistDate) =>
                  handleDatePickerChange(setDateEnd, christDate, buddhistDate)
                }
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD/MM/YYYY"}
                clearable={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                เวลา: <span style={{ color: "red" }}> *</span>
              </Typography>
              <Select
                value={timeEnd}
                onChange={(e) => setTimeEnd(e.target.value)}
                variant="outlined"
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                {hoursList &&
                  hoursList.map((column) => (
                    <MenuItem key={column.value} value={column.value}>
                      {column.value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                สาเหตุการลา: <span style={{ color: "red" }}> *</span>
              </Typography>
              <Select
                value={leave}
                onChange={(e) => setLeave(e.target.value)}
                variant="outlined"
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                {leaveList &&
                  leaveList.map((column) => (
                    <MenuItem key={column.value} value={column.value}>
                      {column.value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>รายละเอียด:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={detailWork}
                onChange={(e) => setDetailWork(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => closePopup()}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                disabled={error}
                onClick={() => saveData()}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Dialog open={openDetail} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 20,
              marginBottom: 5,
              textAlign: "center",
            }}
          >
            ข้อมูลการลา
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันที่เริ่มต้น:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography sx={{ fontSize: "16px" }}>
                    {dateStart.toString()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันที่สิ้นสุด:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography sx={{ fontSize: "16px" }}>
                    {dateEnd.toString()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px" }}>
                    สาเหตุการลา:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography sx={{ fontSize: "16px" }}>{leave}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography sx={{ fontSize: "16px" }}>รายละเอียด:</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography sx={{ fontSize: "16px" }}>
                    {detailWork}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => closePopup()}
                sx={{ textAlign: "center" }}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Leave;
