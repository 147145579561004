import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import path from "path-browserify";
import styles from "./styles/styles.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import provincesData from "../../../components/province.json";
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Paper,
  Box,
  Grid,
  IconButton,
  Select,
  MenuItem,
  GridDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
  Collapse,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { FileUploader } from "react-drag-drop-files";
import ClearIcon from "@mui/icons-material/Clear";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// import { TimePicker } from "@mui/lab";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import useAuth from "function/useAuth";
import config from "../../../config";
const DocumentsFormCreadit = () => {
  const [user, setUser] = useState();
  const [userCompany, setUserCompany] = useState(null);
  const { state } = useLocation();
  const params = state ? state.params : null;
  const genCode =
    params != null
      ? params
      : `Thanic-Credit-${moment().format("YYMMDDHHmmssSS")}`;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  // Form
  const [dateStart, setDateStart] = useState(null);
  const [prefix, setPrefix] = useState("");
  const [prefixList, setPrefixList] = useState([]);
  const [defendantFirstName, setDefendantFirstName] = useState("");
  const [defendantLastName, setDefendantLastName] = useState("");
  const [defendantIdCard, setDefendantIdCard] = useState("");
  const [defendantAccountNumber, setDefendantAccountNumber] = useState("");
  const [defendantAccountNumber1, setDefendantAccountNumber1] = useState("");
  const [defendantAccountMoneyNumber, setDefendantAccountMoneyNumber] =
    useState("");
  const [defendantAccountMoneyNumber1, setDefendantAccountMoneyNumber1] =
    useState("");
  const [defendantAccount, setDefendantAccount] = useState("");
  const [defendantAge, setDefendantAge] = useState(null);
  const [defendantJob, setDefendantJob] = useState(null);
  const [defendantHouseNumber, setDefendantHouseNumber] = useState("");
  const [defendantGroup, setDefendantGroup] = useState("");
  const [defendantRoad, setDefendantRoad] = useState("");
  const [defendantSoi, setDefendantSoi] = useState("");
  const [agency, setAgency] = useState("");
  const [defendantAddress, setDefendantAddress] = useState("");
  const [defendantTambon, setDefendantTambon] = useState("");
  const [defendantAmphoe, setDefendantAmphoe] = useState("");
  const [defendantProvince, setDefendantProvince] = useState("");
  const [defendantTambonName, setDefendantTambonName] = useState("");
  const [defendantAmphoeName, setDefendantAmphoeName] = useState("");
  const [defendantProvinceName, setDefendantProvinceName] = useState("");
  const [defendantZipcode, setDefendantZipcode] = useState("");
  const [debtCenter, setDebtCenter] = useState("");
  const [branchAgency, setBranchAgency] = useState(""); // หน่วยงานสาขา
  const [districtAgency, setDistrictAgency] = useState(""); // หน่วยงานเขต
  const [sectorAgency, setSectorAgency] = useState(""); // หน่วยงานภาค
  const [loanType, setLoanType] = useState(""); // ประเภทสินเชื่อ
  const [typeOfCollateral, setTypeOfCollateral] = useState(""); // ประเภทหลักประกัน
  const [noticeDay, setNoticeDay] = useState(null); // วันบอกกล่าว
  const [sueDay, setSueDay] = useState(null); // วันฟ้อง
  const [courtDate, setCourtDate] = useState(null); // วันขึ้นศาล
  const [courtTime, setCourtTime] = useState(null); // เวลาขึ้นศาล
  const [noticeDayGet, setNoticeDayGet] = useState("");
  const [noticeDayOut, setNoticeDayOut] = useState("");
  const [noticeInvestigate, setNoticeInvestigate] = useState(""); // สอบสวนโนติส
  const [principalCapital, setPrincipalCapital] = useState(""); // ทุนทรัพย์เงินต้น
  const [interestCapital, setInterestCapital] = useState(""); // ทุนทรัพย์ดอกเบี้ย
  const [court, setCourt] = useState(""); // ศาล
  const [blackCaseNumber, setBlackCaseNumber] = useState(""); // เลขคดีดำเลขที่
  const [blackCaseYear, setBlackCaseYear] = useState(""); // เลขคดีดำปี
  const [redCaseNumber, setRedCaseNumber] = useState(""); // เลขคดีแดงเลขที่
  const [redCaseYear, setRedCaseYear] = useState(""); // เลขคดีดำปี
  const [witnessExaminationDate, setWitnessExaminationDate] = useState(null); // นัดสืบพยานวันที่
  const [judgmentDay, setJudgmentDay] = useState(null); // วันพิพากษา
  const [judgmentUnilateral, setJudgmentUnilateral] = useState(null); // พิพากษา(ฝ่ายเดียว)
  const [judgmentAccordingToConsent, setJudgmentAccordingToConsent] =
    useState(null); // พิพากษา(ฝ่ายเดียว)
  const [detailsJudgment, setDetailsJudgment] = useState(""); // รายละเอียด คำพิพากษา
  const [returnFile, setReturnFile] = useState(null); // ส่งแฟ้มคืน
  const [returnFileReason, setReturnFileReason] = useState(""); // ส่งแฟ้มคืนเหตุผล
  const [decree, setDecree] = useState(""); // ออกคำบังคับ
  const [issueAWarranOfExecution, setIssueAWarranOfExecution] = useState(""); // ออกคำบังคับ
  const [withdrawOneCase, setWithdrawOneCase] = useState(null); // ถอนฟ้อง
  const [withdrawOneCaseDueTo, setWithdrawOneCaseDueTo] = useState(""); // เนื่องจาก
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [checkFile, setCheckFile] = useState(false);
  const [countDefendant, setCountDefendant] = useState(0); // จำนวนจำเลยร่วม
  const [coDefendantPrefix, setCoDefendantPrefix] = useState([]); // คำนำหน้าจำเลยร่วม
  const [coDefendantName, setCoDefendantName] = useState([]); // ชื่อจำเลยร่วม
  const [coDefendantLastName, setCoDefendantLastName] = useState([]); // นามสกุลจำเลยร่วม
  const [coDefendantAddress, setCoDefendantAddress] = useState([]); // ที่อยู่จำเลยร่วม
  const [coDefendantIdCard, setCoDefendantIdCard] = useState([]); // บัตรประชาชนจำเลยร่วม
  const [coDefendantStatus, setCoDefendantStatus] = useState([]); // สถานะจำเลยร่วม
  const [statusDefendant, setStatusDefendant] = useState([]);
  const [addressCoDefendant, setAddressCoDefendant] = useState([]);
  const [prefixDefendantAgent, setPrefixDefendantAgent] = useState([]); // ชื่อตัวแทนจำเลยร่วม
  const [nameDefendantAgent, setNameDefendantAgent] = useState([]); // ชื่อตัวแทนจำเลยร่วม
  const [lastNameDefendantAgent, setLastNameDefendantAgent] = useState([]); // นามสกุลตัวแทนจำเลยร่วม
  const [idCardDefendantAgent, setIdCardDefendantAgent] = useState([]); // รหัสบัตรประชาชนตัวแทนจำเลยร่วม
  const [addressDefendantAgent, setAddressDefendantAgent] = useState([]); // ที่อยู่ตัวแทนจำเลยร่วม
  const [statusDefendantAgent, setStatusDefendantAgent] = useState([]); // สถานะตัวแทนจำเลยร่วม
  const [note, setNote] = useState(""); // หมายเหตุ
  const [companyCode, setCompanyCode] = useState(""); // เลขคดีบริษัท
  const [courtCode, setCourtCode] = useState(""); // เลขคดีศาล
  const [chooseMemberCompany, setChooseMemberCompany] = useState(""); // ไอดีผู้รับผิดชอบคดี
  const [chooseMemberCompanyName, setChooseMemberCompanyName] = useState(""); // ผู้รับผิดชอบคดี
  const [appealDueDate, setAppealDueDate] = useState(""); // วันครบกำหนดอุทธรณ์
  const [phone, setPhone] = useState(""); // โทรศัพท์
  const [phone1, setPhone1] = useState(""); // โทรสาร
  const [fileNumber, setFileNumber] = useState(""); // เลขแฟ้ม
  const [other, setOther] = useState("");
  const dafaultAgency = "สำนักงานใหญ่";
  const [dayProcess, setDayProcess] = useState("");
  const [open, setOpen] = useState(false);
  const [courtList, setCourtList] = useState([]);
  const [countCourtEtc, setCountCourtEtc] = useState(0);
  // ขึ้นศาลเพิ่มเติม
  const [dateGoCourtEtc, setDateGoCourtEtc] = useState([]); // วันที่ขึ้นศาลครั้งนั้น
  const [timeGoCourtEtc, setTimeGoCourtEtc] = useState([]); // เวลาขึ้นศาลครั้งนั้น
  const [lawyerGoCourtEtc, setLawyerGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [lawyerNameGoCourtEtc, setLawyerNameGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [detailGoCourtEtc, setDetailGoCourtEtc] = useState([]); // รายละเอียดขึ้นศาลครั้งนั้น

  const [selectedTime, setSelectedTime] = useState(null);
  const [creditType, setCreditType] = useState("");
  const [moneyType, setMoneyType] = useState("");
  const [interestCapitalCredit, setInterestCapitalCredit] = useState("");
  const [interestCapitalMoney, setInterestCapitalMoney] = useState("");
  const [principalCapitalCredit, setPrincipalCapitalCredit] = useState("");
  const [principalCapitalMoney, setPrincipalCapitalMoney] = useState("");
  const [taxCapitalCredit, setTaxCapitalCredit] = useState("");
  const [taxCapitalMoney, setTaxCapitalMoney] = useState("");
  const [countCreditCard, setCountCreditCard] = useState(0);
  const [countMoneyCard, setCountMoneyCard] = useState(0);
  let sumCredit =
    principalCapitalCredit + interestCapitalCredit + taxCapitalCredit;
  let sumMoney = principalCapitalMoney + interestCapitalMoney + taxCapitalMoney;

  const [dateRegisCreditCard, setDateRegisCreditCard] = useState([]);
  const [creditCardName, setCreditCardName] = useState([]);
  const [creditCardNumber, setCreditCardNumber] = useState([]);
  const [creditCardLimit, setCreditCardLimit] = useState([]);
  const [creditCardInterest, setCreditCardInterest] = useState([]);
  const [creditCardPrincipal, setCreditCardPrincipal] = useState([]);
  const [creditCardFees, setCreditCardFees] = useState([]);
  const [creditCardInterestRates, setCreditCardInterestRates] = useState([]);
  const [creditCardTotal, setCreditCardTotal] = useState([]);
  const [creditCardGsbNumber, setCreditCardGsbNumber] = useState([]);

  const [dateRegisMoneyCard, setDateRegisMoneyCard] = useState([]);
  const [moneyCardName, setMoneyCardName] = useState([]);
  const [moneyCardNumber, setMoneyCardNumber] = useState([]);
  const [moneyCardLimit, setMoneyCardLimit] = useState([]);
  const [moneyCardInterest, setMoneyCardInterest] = useState([]);
  const [moneyCardPrincipal, setMoneyCardPrincipal] = useState([]);
  const [moneyCardFees, setMoneyCardFees] = useState([]);
  const [moneyCardInterestRates, setMoneyCardInterestRates] = useState([]);
  const [masteroneyCardTotal, setMoneyCardTotal] = useState([]);
  const [moneyCardGsbNumber, setMoneyCardGsbNumber] = useState([]);
  const [judgmentdebtLedList, setJudgmentdebtLedList] = useState([]); //

  const [typeDocsUpload, setTypeDocsUpload] = useState("");
  const [topicDocsUpload, setTopicDocsUpload] = useState("");
  const [fileUpload, setFileUpload] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows1, setRows1] = useState([]);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(20);
  const [fileData, setFileData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [gsbNumber, setGsbNumber] = useState("");

  const [loanTypeList, setLoanTypeList] = useState([]); // ประเภทสินเชื่อ
  const [typeOfCollateralList, setTypeOfCollateralList] = useState([]); // ประเภทหลักประกัน
  const [noticeDayOutList, setNoticeDayOutList] = useState([]); // วันออกโนติส
  const [noticeDayGetList, setNoticeDayGetList] = useState([]); // วันรับโนติส
  const [noticeInvestigateList, setNoticeInvestigateList] = useState([]); // สอบสวนโนติส
  const [principalList, setPrincipalList] = useState([]); // เงินต้น
  const [interestList, setInterestList] = useState([]); // ดอกเบี้ย
  const [gsbNumberList, setGsbNumberList] = useState([]); // เลขสัญญา
  const [dateCalInterestSinceList, setDateCalInterestSinceList] = useState([]); //
  const [countDateList, setCountDateList] = useState([]); //
  const [countLoan, setCountLoan] = useState(1);

  // led
  const [executionId, setExecutionId] = useState("");
  const [executionCase, setExecutionCase] = useState("");
  const [executionStatus, setExecutionStatus] = useState("");
  const [executionCode, setExecutionCode] = useState("");
  const [bringTheRegulations, setBringTheRegulations] = useState(null);
  const [writOfExecutionDate, setWritOfExecutionDate] = useState(null);
  const [listFileUpdateSelected, setListFileUpdateSelected] = useState(null);
  const [typeSelected, setTypeSelected] = useState("");
  const [openUploadFile, setOpenUploadFile] = useState(false);

  // Led
  const [executionDateStart, setExecutionDateStart] = useState(null); // วันกำหนดส่ง
  const [executionDeadline, setExecutionDeadline] = useState(null); // วันที่รับเรื่อง
  const [executionCourtDate, setExecutionCourtDate] = useState(null); // วันขึ้นศาล
  const [executionCourtTime, setExecutionCourtTime] = useState(null); // เวลาขึ้นศาล
  const [executionCourtNumber, setExecutionCourtNumber] = useState(""); // วันขึ้นศาล
  const [executionWorkBy, setExecutionWorkBy] = useState(""); // ผู้รับผิดชอบคดี
  const [executionWorkByName, setExecutionWorkByName] = useState(""); // ผู้รับผิดชอบคดี
  const [propertyStatus, setPropertyStatus] = useState("");
  // ===================================
  const [spouseCheckDate, setSpouseCheckDate] = useState(null); // วันที่ส่งตรวจสอบคู่สมรส
  const [spouseName, setSpouseName] = useState(""); // ชื่อคู่สมรส
  const [spouseIdCard, setSpouseIdCard] = useState(""); // หมายเลขบัตรประชาชน
  const [spouseNote, setSpouseNote] = useState(""); // หมายเหตุ
  const [spouseIncomeDate, setSpouseIncomeDate] = useState(null); // วันที่ส่งตรวจสอบรายได้
  const [spouseStatus, setSpouseStatus] = useState(""); // สถานะ
  // ===================================
  const [workPlaceIncomeDate, setWorkPlaceIncomeDate] = useState(null); // วันที่ส่งตรวจสอบรายได้
  const [workPlaceName, setWorkPlaceName] = useState(""); // สถานที่ทำงาน
  const [workPlaceIncome, setWorkPlaceIncome] = useState(""); // จำนวนเงิน
  const [workPlaceDateSendIncome, setWorkPlaceDateSendIncome] = useState(null); // วันที่ส่งอายัดเงิน
  const [workPlaceDetailIncome, setWorkPlaceDetailIncome] = useState(""); // เหตุที่อายัดไม่ได้(ขัดข้องการส่งเงิน)
  const [workPlaceStatusIncome, setWorkPlaceStatusIncome] = useState(""); // สถานะ
  // ===================================
  const [dltDateCheck, setDltDateCheck] = useState(null); // วันที่ส่งตรวจสอบกรมขนส่ง
  const [dltDetail, setDltDetail] = useState(""); // หมายเหตุ / รายละเอียดทรัพย์
  const [dltStatus, setDltStatus] = useState(""); // สถานะ
  // ===================================
  const [dolDateCheckGround, setDolDateCheckGround] = useState(null); // วันที่ส่งตรวจสอบกรมที่ดิน
  const [dolStatusObject, setDolStatusObject] = useState(""); // สถานะจำนองทรัพย์
  const [dolDetail, setDolDetail] = useState(""); // หมายเหตุ / รายละเอียดทรัพย์
  const [dolFailureDetail, setDolFailureDetail] = useState(""); // เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน)
  const [dolStatus, setDolStatus] = useState(""); // สถานะ
  // ===================================
  const [dolSell, setDolSell] = useState(""); // ประกาศขายทอดตลาด
  const [dolMoneySell, setDolMoneySell] = useState(""); // เงินส่วนได้จากการขายทอดตลาด
  const [dolCostReturn, setDolCostReturn] = useState(""); // กรณีรับค่าใช้จ่ายเหลือคืน
  const [dolFailureDetail1, setDolFailureDetail1] = useState(""); // เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน)
  const [dolDateGetSend, setDolDateGetSend] = useState(null); // วันที่รับบัญชีรับจ่าย
  // ===================================

  const [ledCostCourt, setLedCostCourt] = useState(""); // ค่าขึ้นศาล
  const [ledCostCall, setLedCostCall] = useState(""); // ค่านำหมายเรียก
  const [ledCostForce, setLedCostForce] = useState(""); // ค่านำหมายคำบังคับ
  const [ledCostTell, setLedCostTell] = useState(""); // ค่าบอกกล่าว
  const [ledCostAll, setLedCostAll] = useState(""); // ค่าเหมาจ่าย
  const [ledCostRequest, setLedCostRequest] = useState(""); // ค่าคำร้อง/รับรองเอกสาร
  const [ledCostLawyer1, setLedCostLawyer1] = useState(""); // ค่าจ้างทนายความ(งวดที่1)
  const [ledCostLawyer2, setLedCostLawyer2] = useState(""); // ค่าจ้างทนายความ(งวดที่2)
  const [ledInvestigete1Date, setLedInvestigete1Date] = useState(null); // วันที่เบิก งวด 1 (ฟ้อง)
  const [ledBankPaid1Date, setLedBankPaid1Date] = useState(null); // วันที่ธนาคารจ่ายเงิน(งวดที่1)
  const [ledInvestigete2Date, setLedInvestigete2Date] = useState(null); // วันที่เบิก งวด 2 (ฟ้อง)
  const [ledBankPaid2Date, setLedBankPaid2Date] = useState(null); // วันที่ธนาคารจ่ายเงิน(งวดที่2)
  // ===================================
  const [cojCostCourt, setCojCostCourt] = useState(""); // ค่าขึ้นศาลชั้นอุทธรณ์
  const [cojCostGo, setCojCostGo] = useState(""); // ค่านำหมายชั้นอุทธรณ์
  const [cojCostSumAll, setCojCostSumAll] = useState(""); // ค่าเหมาจ่ายชั้นอุทธรณ์
  const [cojCostLawyer, setCojCostLawyer] = useState(""); // ค่าจ้างทนายชั้นอุทธรณ์
  const [cojCostAll, setCojCostAll] = useState(""); // รวมค่าใช้จ่ายในการดำเนินคดีชั้นอุทธรณ์
  const [supCostCourt, setSupCostCourt] = useState(""); // ค่าขึ้นศาลชั้นฎีกา
  const [supCostGo, setSupCostGo] = useState(""); // ค่านำหมายชั้นฎีกา
  const [supCostSumAll, setSupCostSumAll] = useState(""); // ค่าเหมาจ่ายชั้นฎีกา
  const [supCostLawyer, setSupCostLawyer] = useState(""); // ค่าจ้างทนายชั้นฎีกา
  const [supCostAll, setSupCostAll] = useState(""); // รวมค่าใช้จ่ายในการดำเนินคดีชั้นฎีกา
  const [supDateReq, setSupDateReq] = useState(null); // วันที่เบิกชั้นฎีกา
  const [supDatePaid, setSupDatePaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  // ===================================
  const [costCheckRoleInvestigate, setCostCheckRoleInvestigate] = useState(""); // ค่าตรวจกรรมสิทธิ์ชั้นสืบทรัพย์
  const [costCheckCarInvestigate, setCostCheckCarInvestigate] = useState(""); // ค่าตรวจยานพาหนะชั้นสืบทรัพย์
  const [costEtcInvestigate, setCostEtcInvestigate] = useState(""); // ค่าใช้จ่ายอื่นๆชั้นสืบทรัพย์
  const [costAllInvestigate, setCostAllInvestigate] = useState(""); // รวมค่าใช้จ่ายในการสืบทรัพย์
  const [costCheckInvestigate, setCostCheckInvestigate] = useState(""); // ค่าจ้างสืบทรัพย์
  const [costCheckMapInvestigate, setCostCheckMapInvestigate] = useState(""); // ค่าตรวจสอบระวางแผนที่
  const [costCheckAllInvestigate, setCostCheckAllInvestigate] = useState(""); // รวมค่าจ้างสืบทรัพย์
  const [costWithdrawInvestigate, setCostWithdrawInvestigate] = useState(null); // วันที่เบิกสืบทรัพย์
  const [datePaidInvestigate, setDatePaidInvestigate] = useState(null); // วันที่ธนาคารจ่ายเงิน
  // ===================================
  // ค่าใช้จ่ายในการดำเนินคดีชั้นบังคับคดี
  // Seized
  // ===================================

  const [sequestrationFee, setSequestrationFee] = useState(""); // ค่าตั้งเรื่องอายัด
  const [lumpSum, setLumpSum] = useState(""); // ค่าเหมาจ่าย
  const [ledCost, setLedCost] = useState(""); // ค่าจ้างอายัด
  const [ledAll, setLedAll] = useState(""); // รวม
  const [ledDate0, setledDate0] = useState(null); // วันที่เบิกอายัด
  const [ledDateBankPaid, setLedDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [led20, setLed20] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)
  const [led20Date1, setLed20Date1] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 1)
  const [ledDateBankPaid1, setLedDateBankPaid1] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 1)
  const [led20R1, setLed20R1] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่ 1)
  const [led20DateR2, setLed20DateR2] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 2)
  const [ledDateBankPaid2, setLedDateBankPaid2] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 2)
  const [led20R2, setLed20R2] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่ 2)
  const [led20DateR3, setLed20DateR3] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 3)
  const [ledDateBankPaid3, setLedDateBankPaid3] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 3)
  const [led20R3, setLed20R3] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่ 3)
  const [led20DateR4, setLed20DateR4] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 4)
  const [ledDateBankPaid4, setLedDateBankPaid4] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 4)
  const [ledFess, setLedFess] = useState(""); // ค่าธรรมเนียม
  const [ledFollow, setLedFollow] = useState(""); // ค่านำหมาย
  const [ledSubmit, setLedSubmit] = useState(""); // ค่ารับรอง
  const [ledCostWagesRequest, setLedCostWagesRequest] = useState(""); // รวมค่าใช้จ่ายในการยื่นคำร้อง
  const [ledInvestigateDate, setLedInvestigateDate] = useState(null); // วันที่เบิก
  const [ledInvestigateDateBankPaid, setLedInvestigateDateBankPaid] =
    useState(null); // วันที่ธนาคารจ่ายเงิน
  const [ledWagesRequest, setLedWagesRequest] = useState(""); // ค่าจ้างยื่นคำร้อง
  const [ledWagesDateBankPaid, setLedWagesDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [ledWagesInvestigateDate, setLedWagesInvestigateDate] = useState(null); // วันที่เบิก
  const [createLedCost, setCreateLedCost] = useState(""); // ค่าตั้งเรื่องบังคับคดีแทน(ยึดทรัพย์)
  const [costCreateSequester, setCostCreateSequester] = useState(""); // ค่าตั้งเรื่องยึดทรัพย์
  const [costAllSequester, setCostAllSequester] = useState(""); // ค่าเหมาจ่าย(ยึดทรัพย์)
  const [costSequester, setCostSequester] = useState(""); // ค่าจ้างยึดทรัพย์
  const [costAllLedSequester, setCostAllLedSequester] = useState(""); // รวมค่าใช้จ่ายในการบังคับคดี(ยึดทรัพย์)
  const [sequesterDate, setSequesterDate] = useState(null); // วันที่เบิกยึดทรัพย์
  const [sequesterDateBankPaid, setSequesterDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [seizedWages, setSeizedWages] = useState(""); // ค่าวางเงินค่าใช้จ่ายเพิ่ม
  const [costSequesterCheck, setCostSequesterCheck] = useState(""); // ค่าตรวจรับรองบัญชีรับ-จ่าย
  const [costSequesterEtc, setCostSequesterEtc] = useState(""); // ค่าใช้จ่ายอื่น ๆ
  const [costAllSequesterEtc, setCostAllSequesterEtc] = useState(""); // รวมค่าใช้จ่ายอื่น ๆ
  const [dateSequesterInvestigate, setDateSequesterInvestigate] =
    useState(null); // วันที่เบิก
  const [
    sequesterInvestigateDateBankPaid,
    setSequesterInvestigateDateBankPaid,
  ] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [cost2Per, setCost2Per] = useState(""); // ค่าจ้าง 2 %(ได้รับชำระหนี้)ยึดอสังหาฯ(แต่ไม่เกิน100,000บาท)
  const [cost2PerInverstigate, setCost2PerInverstigate] = useState(null); // วันที่เบิก
  const [cost2PerDateBankPaid, setCost2PerDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [cost50Per, setCost50Per] = useState(""); // ค่าจ้าง 50 %(ได้รับชำระหนี้)
  const [cost50PerInverstigate, setCost50PerInverstigate] = useState(null); // วันที่เบิก
  const [cost50PerDateBankPaid, setCost50PerDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [cost1Per, setCost1Per] = useState(""); // ค่าจ้าง 1%(ได้รับชำระหนี้ปิดบัญชีหรือปรับปรุงหนี้ระหว่างประกาศขาย)(แต่ไม่เกิน 10,000 บาท
  const [cost1PerInverstigate, setCost1PerInverstigate] = useState(null); // วันที่เบิก
  const [cost1PerDateBankPaid, setCost1PerDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  // ===================================
  // ค่าใช้จ่ายในการดำเนินคดีชั้นยื่นขอรับชำระหนี้ในคดีล้มละลาย
  // ===================================
  const [lawFees, setLawFees] = useState(""); // ค่าฤชากร/ค่ารับรอง
  const [bankruptGetCostAll, setBankruptGetCostAll] = useState(""); // ค่าเหมาจ่าย
  const [bankruptRound1, setBankruptRound1] = useState(""); // งวด 1
  const [bankruptRound1All, setBankruptRound1All] = useState(""); // รวม
  const [bankruptRound1Investigate, setBankruptRound1Investigate] =
    useState(null); // วันที่เบิก
  const [bankruptRound1DateBankPaid, setBankruptRound1DateBankPaid] =
    useState(null); // วันที่ธนาคารจ่าย
  const [bankruptRound1LawFrees, setBankruptRound1LawFrees] = useState(""); // ค่าฤชากร/ค่ารับรอง
  const [bankruptRound2, setBankruptRound2] = useState(""); // งวด 2
  const [bankruptRound2Investigate, setBankruptRound2Investigate] =
    useState(null); // วันที่เบิก
  const [bankruptDateBankPaid, setBankruptDateBankPaid] = useState(null); // วันที่ธนาคารจ่าย
  const [bankruptCostAll, setBankruptCostAll] = useState(""); // รวมค่าใช้จ่ายทั้งสิ้น
  // ==============end

  // =========================================
  const [bankruptNumber1, setBankruptNumber1] = useState(""); // หมายเลขบัตรเครดิต
  const [bankruptJudge1, setBankruptJudge1] = useState(""); // พิพากษา(ยอดหนี้)
  const [bankruptMoney1, setBankruptMoney1] = useState(""); // เงินต้น
  const [bankruptInterest1, setBankruptInterest1] = useState(""); // ดอกเบี้ย(%)
  const [bankruptSince1, setBankruptSince1] = useState(null); // นับแต่วันที่
  const [bankruptNumber2, setBankruptNumber2] = useState(""); // หมายเลขบัตรเงินสด
  const [bankruptJudge2, setBankruptJudge2] = useState(""); // พิพากษา(ยอดหนี้)
  const [bankruptMoney2, setBankruptMoney2] = useState(""); // เงินต้น
  const [bankruptInterest2, setBankruptInterest2] = useState(""); // ดอกเบี้ย(%)
  const [bankruptSince2, setBankruptSince2] = useState(null); // นับแต่วันที่
  const [bankruptCourt, setBankruptCourt] = useState(""); // ค่าขึ้นศาล
  const [bankruptFollow, setBankruptFollow] = useState(""); // ค่านำหมาย
  const [bankruptCostLawyer, setBankruptCostLawyer] = useState(""); // ค่าทนายความ
  const [bankruptCostSend, setBankruptCostSend] = useState(""); // ค่าส่งคำบังคับ
  const [bankruptAll, setBankruptAll] = useState(""); // รวม
  // ===================================
  const [dateCalInterestSince, setDateCalInterestSince] = useState(null); // วันที่คำนวณดอกเบียถึง
  const [countDate, setCountDate] = useState(""); // จำนวนวัน
  const [interest, setInterest] = useState(""); // ดอกเบี้ย
  // ===================================
  const [dateCalInterestSince1, setDateCalInterestSince1] = useState(null); // วันที่คำนวณดอกเบียถึง
  const [countDate1, setCountDate1] = useState(""); // จำนวนวัน
  const [interest1, setInterest1] = useState(""); // ดอกเบี้ย
  // ===================================

  const [debtAllByJudgment, setDebtAllByJudgment] = useState(""); // รวมภาระหนี้ตามคำพิพากษา
  const [costLedAll, setCostLedAll] = useState(""); // รวมค่าใช้จ่ายในการดำเนินคดีและบังคับคดี
  const [sumReturnAll, setSumReturnAll] = useState(""); // รวมเงินที่ได้รับคืนแล้ว
  const [calDebtSince, setCalDebtSince] = useState(null); // คำนวณภาระหนี้ถึงวันที่
  const [costWorking, setCostWorking] = useState(""); // ค่าใช้จ่ายในการดำเนินคดีฯ,ค่าขึ้นศาลที่ศาลสั่งคืน

  const fileTypes = ["PDF"];
  const [steps, setSteps] = useState([
    "ข้อมูลลูกหนี้",
    "ข้อมูลคดี",
    "รายการเอกสารในสำนวนคดี",
    "ข้อมูลบริษัท",
    // "ข้อมูลบังคับคดี",
  ]);
  const [completed, setCompleted] = useState(Array(steps.length).fill(false));
  const [textFail, setTextFail] = useState([]);

  useAuth();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log("datana", JSON.parse(userData));
    setUser(JSON.parse(userData));
    getUserCompany();
    if (params) {
      getDataForm();
      getListFile();
    }
    getDdlPrefix();
    getCourtList();
  }, []);
  const navigate = useNavigate();

  const useStyles = makeStyles({
    formControlRoot: {
      margin: "8px", // Set your desired margin value
      padding: "8px", // Set your desired padding value
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  function getDdlPrefix() {
    axios
      .get(`${config.mainAPI}/prefix-list`)
      .then((response) => {
        setPrefixList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getListFileExecution(executionId) {
    axios
      .get(`${config.mainAPI}/file-list/${executionId}`)
      .then((response) => {
        console.log("value filelistLED", response.data.data);
        const value = response.data.data;
        if (value) {
          setFileList1(value);
          setRows1(
            value.map((item, index) =>
              createData(
                index + 1,
                item.file_uuid,
                item.file_topic,
                item.file_type,
                item.created_at,
                item.created_by,
                item.file_path,
                item.file_main,
                item.update_at
              )
            )
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getCourtList() {
    axios
      .get(`${config.mainAPI}/court-list`)
      .then((response) => {
        setCourtList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleUploadChange = (file) => {
    setFileUpload(file);
    console.log(file);
  };

  const typeDocsList = [
    { id: 0, value: "คำร้อง" },
    { id: 1, value: "คำขอ" },
    { id: 2, value: "คำแถลง" },
    { id: 3, value: "คำขอถอนฟ้อง" },
    { id: 4, value: "คำร้องขาดนัดยื่นคำให้การ" },
    { id: 5, value: "คำแถลงขอเลื่อนนัด" },
  ];

  const columns1 = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    // { id: "list", label: "รายการ", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
    { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
    {
      id: "uploadFile",
      label: "อัพโหลดเอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleUploadFileExecution(row)}>
            <UploadFileIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "file",
      label: "เอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          {row.path ? (
            <IconButton
              disabled={!row.path}
              onClick={() => handleDownload(row)}
            >
              <PictureAsPdfIcon color={row.path ? "error" : "inherit"} />
            </IconButton>
          ) : null}
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={!row.path}
            onClick={() => handleDeleteFileExecution(row)}
          >
            <DeleteForeverIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  const handleDeleteFileExecution = (row) => {
    const payload = {
      main: row.main,
      fileName: row.path,
      uuid: row.uuid,
      id: executionId,
      type: "execution",
    };
    console.log(payload);
    setIsLoading(true);
    const fileName = path.basename(row.path);
    saveActivityLog(`Click Delete file: ${fileName}`);
    axios
      .post(`${config.mainAPI}/deleteFileByPath`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;

        getListFile();
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  // const columns = [
  //   { id: "order", label: "ลำดับ", minWidth: 100 },
  //   { id: "list", label: "รายการ", minWidth: 100 },
  //   { id: "type", label: "ประเภท", minWidth: 100 },
  //   { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
  //   { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
  //   {
  //     id: "file",
  //     label: "ไฟล์เอกสาร",
  //     minWidth: 100,
  //     render: (row) => (
  //       <>
  //         <IconButton onClick={() => handleDownload(row)}>
  //           <PictureAsPdfIcon color="error" />
  //         </IconButton>
  //       </>
  //     ),
  //   },
  //   {
  //     id: "delete",
  //     label: "",
  //     minWidth: 100,
  //     render: (row) => (
  //       <>
  //         <IconButton onClick={() => handleDeleteFile(row)}>
  //           <DeleteForeverIcon color="error" />
  //         </IconButton>
  //       </>
  //     ),
  //   },
  // ];

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const filteredRows1 = rows1.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function handleClearFileUpload() {
    setFileUpload(null);
    setTypeDocsUpload("");
    setTopicDocsUpload("");
  }

  const handleSumbitFileUpload = async () => {
    if (fileUpload) {
      console.log("fileUpload", fileUpload);
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("type", typeDocsUpload);
      formData.append("type_docs", "credit");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileUpload.name} Success`);

        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
      }
    }
  };

  const getDataForm = () => {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/credit-check/${params}`)
      .then((response) => {
        console.log(response);
        console.log("response.data.data", response.data.data);
        setData(response.data.data[0]);
        const value = response.data.data[0];
        setDateStart(value.date_start);
        // setDateStart(moment(value.date_start).toDate());
        setFileNumber(value.file_number);
        setPrefix(value.defendant_prefix);
        setDefendantFirstName(value.defendant_firstname);
        setDefendantLastName(value.defendant_lastname);
        setDefendantIdCard(value.defendant_idcard);
        setDefendantAge(value.defendant_age);
        setDefendantJob(value.defendant_job);
        setDefendantAccountNumber(value.defendant_account_number);
        setDefendantAccountMoneyNumber(value.defendant_account_money_number);
        setDefendantAccountNumber1(value.defendant_account_number1);
        setDefendantAccountMoneyNumber1(value.defendant_account_money_number1);
        setDefendantAddress(value.defendant_address);
        setDefendantTambon(parseInt(value.defendant_tambon));
        setDefendantTambonName(value.defendant_tambon_show);
        setDefendantAmphoe(parseInt(value.defendant_amphoe));
        setDefendantAmphoeName(value.defendant_amphoe_show);
        setDefendantProvince(parseInt(value.defendant_province));
        setDefendantProvinceName(value.defendant_province_show);
        if (value.defendant_province) {
          console.log("value.defendant_province", value.defendant_province);
          getAmphoesByProvinceId(parseInt(value.defendant_province));
        }
        if (value.defendant_amphoe) {
          console.log("value.defendant_amphoe", value.defendant_amphoe);
          getTambonsByAmphoeId(parseInt(value.defendant_amphoe));
        }
        setAgency(value.agency);
        setDefendantZipcode(parseInt(value.defendant_zipcode));
        setPhone(value.defendant_phone);
        setPhone1(value.defendant_phone1);
        setDebtCenter(value.debt_center);
        setBranchAgency(value.branch_agency);
        setDistrictAgency(value.district_agency);
        setSectorAgency(value.sector_agency);
        setLoanType(value.loan_type);
        setTypeOfCollateral(value.type_of_collateral);
        setNoticeDay(value.notice_day);
        setNoticeDayGet(value.notic_get);
        setNoticeDayOut(value.notic_out);
        setNoticeInvestigate(value.notice_investigate);
        setPrincipalCapital(value.principal_capital);
        setInterestCapital(value.interest_capital);
        setCourt(value.court);
        // setGsbNumber(value.gsb_number);
        setDayProcess(value.days_process);
        setBlackCaseNumber(value.black_case_number);
        setBlackCaseYear(value.black_case_year);
        setRedCaseNumber(value.red_case_number);
        setRedCaseYear(value.red_case_year);
        setWitnessExaminationDate(value.witness_examination_date);
        setJudgmentDay(value.judgment_day);
        setJudgmentUnilateral(value.judgment_unilateral);
        setJudgmentAccordingToConsent(value.judgment_according_to_consent);
        setDetailsJudgment(value.judgment_details);
        setReturnFile(value.return_file);
        setReturnFileReason(value.return_file_reason);
        setWithdrawOneCase(value.withdraw_one_case);
        setWithdrawOneCaseDueTo(value.withdraw_one_case_due_to);
        if (value.file_name && value.file_path) {
          setCheckFile(true);
        }
        setFileName(value.file_name);
        setFilePath(value.file_path);
        setNote(value.note);
        setChooseMemberCompany(value.work_by);
        setChooseMemberCompanyName(value.work_by_name);
        setCourtCode(value.court_code);
        setCompanyCode(value.company_code);
        console.log(" value.goCourtEt5555c", value.goCourtEtc);
        console.log("value.goCourtEtc.length", value.goCourtEtc.length);
        setCountCourtEtc(value.goCourtEtc.length);
        setCourtDate(
          value.court_date ? moment(value.court_date).toDate() : null
        );

        // setCourtDate(value.court_date);
        // setCourtTime(value.court_time);
        setCourtTime(
          value.court_time ? moment(value.court_time, "HH:mm") : null
        );
        if (value.court_count > 1 && value.goCourtEtc) {
          // มันต้อง + 1
          value.goCourtEtc.forEach((item, index) => {
            console.log("index", index);

            setDateGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_date;
              return updatedState;
            });

            setTimeGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_time;
              return updatedState;
            });

            setLawyerGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by;
              return updatedState;
            });

            setLawyerNameGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by_name;
              return updatedState;
            });

            setDetailGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_detail;
              return updatedState;
            });
            // }
          });
        }
        // setInterestCapitalCredit(value.interest_capital_credit);
        // setInterestCapitalMoney(value.interest_capital_money);
        // setPrincipalCapitalCredit(value.principal_capital_credit);
        // setPrincipalCapitalMoney(value.principal_capital_money);
        // setTaxCapitalCredit(value.tax_capital_credit);
        // setTaxCapitalMoney(value.tax_capital_money);
        // setCreditType(value.type_credit_card);
        // setMoneyType(value.type_money_card);

        setCountCreditCard(value.creditData.length);
        setCountMoneyCard(value.moneyData.length);

        if (value.creditData.length > 0) {
          value.creditData.forEach((item, index) => {
            console.log("index", index);

            setDateRegisCreditCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_start_date;
              return updatedState;
            });

            setCreditCardGsbNumber((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.gsb_number;
              return updatedState;
            });

            setCreditCardName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_name;
              return updatedState;
            });

            setCreditCardNumber((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_number;
              return updatedState;
            });

            setCreditCardLimit((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_limit;
              return updatedState;
            });

            setCreditCardInterest((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_interest;
              return updatedState;
            });

            setCreditCardPrincipal((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_principal;
              return updatedState;
            });

            setCreditCardFees((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_fee;
              return updatedState;
            });

            setCreditCardInterestRates((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_interest_rate;
              return updatedState;
            });

            // setCreditCardTotal((prevState) => {
            //   const updatedState = [...prevState];
            //   updatedState[index] = item.credit_total;
            //   return updatedState;
            // });
            // }
          });
        }
        console.log("value.moneyData.length", value.moneyData.length);
        if (value.moneyData.length > 0) {
          value.moneyData.forEach((item, index) => {
            console.log("index", index);

            setDateRegisMoneyCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_start_date;
              return updatedState;
            });

            setMoneyCardGsbNumber((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.gsb_number;
              return updatedState;
            });

            setMoneyCardName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_name;
              return updatedState;
            });

            setMoneyCardNumber((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_number;
              return updatedState;
            });

            setMoneyCardLimit((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_limit;
              return updatedState;
            });

            setMoneyCardInterest((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_interest;
              return updatedState;
            });

            setMoneyCardPrincipal((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_principal;
              return updatedState;
            });

            setMoneyCardFees((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_fee;
              return updatedState;
            });

            setMoneyCardInterestRates((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.card_interest_rate;
              return updatedState;
            });

            // setMoneyCardTotal((prevState) => {
            //   const updatedState = [...prevState];
            //   updatedState[index] = item.money_total;
            //   return updatedState;
            // });
            // }
          });
        }

        setExecutionCase(value.execution_case);
        console.log("value.executionData", value.executionData);

        if (value.execution_case) {
          getListFileExecution(value.execution_id);
          setExecutionId(value.execution_id);
          setExecutionCode(value.executionData[0].execution_status);
          // setExecutionStatus(value.executionData[0].execution_status);
          setExecutionDeadline(value.executionData[0].led_deadline);
          setExecutionDateStart(value.executionData[0].led_date_start);
          setExecutionCourtDate(value.executionData[0].court_date_led);
          setExecutionCourtTime(value.executionData[0].court_time_led);
          setBringTheRegulations(value.executionData[0].bring_the_regulations);
          setWritOfExecutionDate(value.executionData[0].writ_of_execution_date);
          setExecutionWorkBy(value.executionData[0].work_by_led);
          setExecutionWorkByName(value.executionData[0].work_by_led_name);

          // eieina
          // table: execution_investigate
          setSpouseCheckDate(value.executionData[0].spouse_check_date);
          setSpouseName(value.executionData[0].spouse_name);
          setSpouseIdCard(value.executionData[0].spouseId_card);
          setSpouseNote(value.executionData[0].spouse_note);
          setSpouseIncomeDate(value.executionData[0].spouse_income_date);
          setSpouseStatus(value.executionData[0].spouse_status);
          setWorkPlaceIncomeDate(value.executionData[0].spouse_income_date);
          setWorkPlaceName(value.executionData[0].work_place_income_date);
          setWorkPlaceIncome(value.executionData[0].work_place_name);
          setWorkPlaceDateSendIncome(
            value.executionData[0].work_place_date_send_income
          );
          setWorkPlaceDetailIncome(
            value.executionData[0].work_place_detail_income
          );
          setWorkPlaceStatusIncome(
            value.executionData[0].work_place_status_income
          );
          setDltDateCheck(value.executionData[0].dlt_date_check);
          setDltDetail(value.executionData[0].dlt_detail);
          setDltStatus(value.executionData[0].dlt_status);
          setDolDateCheckGround(value.executionData[0].dol_date_check_ground);
          setDolStatusObject(value.executionData[0].dol_status_object);
          setDolDetail(value.executionData[0].dol_detail);
          setDolFailureDetail(value.executionData[0].dol_failure_detail);
          setDolStatus(value.executionData[0].dol_status);
          setDolSell(value.executionData[0].dol_sell);
          setDolMoneySell(value.executionData[0].dol_money_sell);
          setDolCostReturn(value.executionData[0].dol_cost_return);
          setDolFailureDetail1(value.executionData[0].dol_failure_detail1);
          setDolDateGetSend(value.executionData[0].dol_date_get_send);

          // table: execution_cost
          setLedCostCourt(value.executionData[0].led_cost_court);
          setLedCostCall(value.executionData[0].led_cost_call);
          setLedCostForce(value.executionData[0].led_cost_force);
          setLedCostTell(value.executionData[0].led_cost_tell);
          setLedCostAll(value.executionData[0].led_cost_all);
          setLedCostRequest(value.executionData[0].led_cost_request);
          setLedCostLawyer1(value.executionData[0].led_cost_lawyer1);
          setLedCostLawyer2(value.executionData[0].led_cost_lawyer_2);
          setLedInvestigete1Date(value.executionData[0].led_investigete1_date);
          setLedBankPaid1Date(value.executionData[0].led_bank_paid1_date);
          setLedInvestigete2Date(value.executionData[0].led_investigete2_date);
          setLedBankPaid2Date(value.executionData[0].led_bank_paid2_date);
          setCojCostCourt(value.executionData[0].coj_cost_court);
          setCojCostGo(value.executionData[0].coj_cost_go);
          setCojCostSumAll(value.executionData[0].coj_cost_sum_all);
          setCojCostLawyer(value.executionData[0].coj_cost_lawyer);
          setCojCostAll(value.executionData[0].coj_cost_all);
          setSupCostCourt(value.executionData[0].sup_cost_court);
          setSupCostGo(value.executionData[0].sup_cost_go);
          setSupCostSumAll(value.executionData[0].sup_cost_sum_all);
          setSupCostLawyer(value.executionData[0].sup_cost_lawyer);
          setSupCostAll(value.executionData[0].sup_cost_all);
          setSupDateReq(value.executionData[0].sup_date_req);
          setSupDatePaid(value.executionData[0].sup_date_paid);
          setCostCheckRoleInvestigate(
            value.executionData[0].cost_check_role_investigate
          );
          setCostCheckCarInvestigate(
            value.executionData[0].cost_check_car_investigate
          );
          setCostEtcInvestigate(value.executionData[0].cost_etc_investigate);
          setCostAllInvestigate(value.executionData[0].cost_all_investigate);
          setCostCheckInvestigate(
            value.executionData[0].cost_check_investigate
          );
          setCostCheckMapInvestigate(
            value.executionData[0].cost_check_map_investigate
          );
          setCostCheckAllInvestigate(
            value.executionData[0].cost_check_all_investigate
          );
          setCostWithdrawInvestigate(
            value.executionData[0].cost_withdraw_investigate
          );
          setDatePaidInvestigate(value.executionData[0].date_paid_investigate);

          // execution_cost
          setSequestrationFee(value.executionData[0].sequestration_fee);
          setLumpSum(value.executionData[0].lump_sum);
          setLedCost(value.executionData[0].led_cost);
          setLedAll(value.executionData[0].led_all);
          setledDate0(value.executionData[0].led_date0);
          setLedDateBankPaid(value.executionData[0].led_date_bank_paid);
          setLed20(value.executionData[0].led20);
          setLed20Date1(value.executionData[0].led20_date1);
          setLedDateBankPaid1(value.executionData[0].led_date_bank_paid1);
          setLed20R1(value.executionData[0].led20_r1);
          setLed20DateR2(value.executionData[0].led20_date_r2);
          setLedDateBankPaid2(value.executionData[0].led_date_bank_paid2);
          setLed20R2(value.executionData[0].led20_r2);
          setLed20DateR3(value.executionData[0].led20_date_r3);
          setLedDateBankPaid3(value.executionData[0].led_date_bank_paid3);
          setLed20R3(value.executionData[0].led20_r3);
          setLed20DateR4(value.executionData[0].led20_date_r4);
          setLedDateBankPaid4(value.executionData[0].led_date_bank_paid4);
          setLedFess(value.executionData[0].led_fess);
          setLedFollow(value.executionData[0].led_follow);
          setLedSubmit(value.executionData[0].led_submit);
          setLedCostWagesRequest(value.executionData[0].led_cost_wages_request);
          setLedInvestigateDate(value.executionData[0].led_investigate_date);
          setLedInvestigateDateBankPaid(
            value.executionData[0].led_investigate_date_bank_paid
          );
          setLedWagesRequest(value.executionData[0].led_wages_request);
          setLedWagesDateBankPaid(
            value.executionData[0].led_wages_date_bank_paid
          );
          setLedWagesInvestigateDate(
            value.executionData[0].led_wages_investigate_date
          );
          setCreateLedCost(value.executionData[0].create_led_cost);
          setCostCreateSequester(value.executionData[0].cost_create_sequester);
          setCostAllSequester(value.executionData[0].cost_all_sequester);
          setCostSequester(value.executionData[0].cost_sequester);
          setCostAllLedSequester(value.executionData[0].cost_all_led_sequester);
          setSequesterDate(value.executionData[0].sequester_date);
          setSequesterDateBankPaid(
            value.executionData[0].sequester_date_bank_paid
          );
          setSeizedWages(value.executionData[0].seized_wages);
          setCostSequesterCheck(value.executionData[0].cost_sequester_check);
          setCostSequesterEtc(value.executionData[0].cost_sequester_etc);
          setCostAllSequesterEtc(value.executionData[0].cost_all_sequester_etc);
          setDateSequesterInvestigate(
            value.executionData[0].date_sequester_investigate
          );
          setSequesterInvestigateDateBankPaid(
            value.executionData[0].sequester_investigate_date_bank_paid
          );
          setCost2Per(value.executionData[0].cost2_per);
          setCost2PerInverstigate(
            value.executionData[0].cost2_per_inverstigate
          );
          setCost2PerDateBankPaid(
            value.executionData[0].cost2_per_date_bank_paid
          );
          setCost50Per(value.executionData[0].cost50_per);
          setCost50PerInverstigate(
            value.executionData[0].cost50_per_inverstigate
          );
          setCost50PerDateBankPaid(
            value.executionData[0].cost50_per_date_bank_paid
          );
          setCost1Per(value.executionData[0].cost1_per);
          setCost1PerInverstigate(
            value.executionData[0].cost1_per_inverstigate
          );
          setCost1PerDateBankPaid(
            value.executionData[0].cost1_per_date_bank_paid
          );
          setLawFees(value.executionData[0].law_fees);
          setBankruptGetCostAll(value.executionData[0].bankrupt_get_cost_all);
          setBankruptRound1(value.executionData[0].bankrupt_round1);
          setBankruptRound1All(value.executionData[0].bankrupt_round1_all);
          setBankruptRound1Investigate(
            value.executionData[0].bankrupt_round1_investigate
          );
          setBankruptRound1DateBankPaid(
            value.executionData[0].bankrupt_round1_date_bank_paid
          );
          setBankruptRound1LawFrees(
            value.executionData[0].bankrupt_round1_law_frees
          );
          setBankruptRound2(value.executionData[0].bankrupt_round2);
          setBankruptRound2Investigate(
            value.executionData[0].bankrupt_round2_investigate
          );
          setBankruptDateBankPaid(
            value.executionData[0].bankrupt_date_bank_paid
          );
          setBankruptCostAll(value.executionData[0].bankrupt_cost_all);

          // execution_judgment_result
          setBankruptNumber1(value.executionData[0].bankrupt_number1);
          setBankruptJudge1(value.executionData[0].bankrupt_judge1);
          setBankruptMoney1(value.executionData[0].bankrupt_money1);
          setBankruptInterest1(value.executionData[0].bankrupt_interest1);
          setBankruptSince1(value.executionData[0].bankrupt_since1);
          setBankruptNumber2(value.executionData[0].bankrupt_number2);
          setBankruptJudge2(value.executionData[0].bankrupt_judge2);
          setBankruptMoney2(value.executionData[0].bankrupt_money2);
          setBankruptInterest2(value.executionData[0].bankrupt_interest2);
          setBankruptSince2(value.executionData[0].bankrupt_since2);
          setBankruptCourt(value.executionData[0].bankrupt_court);
          setBankruptFollow(value.executionData[0].bankrupt_follow);
          setBankruptCostLawyer(value.executionData[0].bankrupt_cost_lawyer);
          setBankruptCostSend(value.executionData[0].bankrupt_cost_send);
          setBankruptAll(value.executionData[0].bankrupt_all);
          setDateCalInterestSince(
            value.executionData[0].date_cal_interest_since
          );
          setCountDate(value.executionData[0].count_date);
          setInterest(value.executionData[0].interest);
          setDateCalInterestSince1(
            value.executionData[0].date_cal_interest_since1
          );
          setCountDate1(value.executionData[0].count_date1);
          setInterest1(value.executionData[0].interest1);

          // execution_summary
          setDebtAllByJudgment(value.executionData[0].debt_all_by_judgment);
          setCostLedAll(value.executionData[0].cost_led_all);
          setSumReturnAll(value.executionData[0].sum_return_all);
          setCalDebtSince(value.executionData[0].cal_debt_since);
          setCostWorking(value.executionData[0].cost_working);

          // add object เข้าไปใน step
          // steps.push("บังคับคดี");
          const updatedSteps = [
            ...steps,
            "ข้อมูลบังคับคดี",
            "สืบทรัพย์บังคับคดี",
            "ค่าใช้จ่ายบังคับคดี",
            "ผลคำพิพากษาบังคับคดี",
            // "สรุปภาระหนี้",
            "เอกสารบังคับคดี",
          ];
          // steps = updatedSteps;
          setSteps((prevSteps) => [
            ...prevSteps,
            "ข้อมูลบังคับคดี",
            "สืบทรัพย์บังคับคดี",
            "ค่าใช้จ่ายบังคับคดี",
            "ผลคำพิพากษาบังคับคดี",
            // "สรุปภาระหนี้",
            "เอกสารบังคับคดี",
          ]);
          console.log("updatedSteps", updatedSteps);
          setCompleted(Array(updatedSteps.length).fill(false));
          console.log("steps", steps);

          // console.log('steps555',steps);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloseFailure = () => {
    setOpenFailure(false);
    setTextFail([]);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate("/credit");
  };

  const handleSubmit = (row) => {
    const caseData = params != null ? "edit" : "add";
    if (
      dateStart &&
      defendantFirstName &&
      defendantLastName &&
      defendantIdCard &&
      chooseMemberCompany &&
      (countCreditCard != 0) | (countMoneyCard != 0)
    ) {
      const payload = {
        genCode: genCode,
        caseData: caseData,
        courtCode: courtCode,
        companyCode: companyCode,
        // gsbNumber: gsbNumber,
        dateStart: dateStart,
        fileNumber: fileNumber,
        prefix: prefix,
        defendantFirstName: defendantFirstName,
        defendantLastName: defendantLastName,
        defendantIdCard: defendantIdCard,
        defendantAccountNumber: defendantAccountNumber,
        defendantAccountMoneyNumber: defendantAccountMoneyNumber,
        defendantAccountNumber1: defendantAccountNumber1,
        defendantAccountMoneyNumber1: defendantAccountMoneyNumber1,
        defendantJob: defendantJob,
        defendantAge: defendantAge,
        agency: agency,
        loanType: loanType,
        typeOfCollateral: typeOfCollateral,
        noticeDay: noticeDay,
        principalCapitalCredit: principalCapitalCredit,
        interestCapitalCredit: interestCapitalCredit,
        taxCapitalCredit: taxCapitalCredit,
        principalCapitalMoney: principalCapitalMoney,
        interestCapitalMoney: interestCapitalMoney,
        taxCapitalMoney: taxCapitalMoney,
        moneyType: moneyType,
        creditType: creditType,
        creditCard: creditCard,
        moneyCard: moneyCard,
        countMoneyCard: countMoneyCard,
        countCreditCard: countCreditCard,
        court: court,
        courtDate: courtDate ? moment(courtDate).format("YYYY-MM-DD") : null,
        courtTime: combinedTimeShow(courtTime),
        blackCaseNumber: blackCaseNumber,
        blackCaseYear: blackCaseYear,
        redCaseNumber: redCaseNumber,
        redCaseYear: redCaseYear,
        goCourtEtc: goCourtEtc,
        witnessExaminationDate: witnessExaminationDate
          ? moment(witnessExaminationDate).format("YYYY-MM-DD")
          : null,
        judgmentUnilateral: judgmentUnilateral
          ? moment(judgmentUnilateral).format("YYYY-MM-DD")
          : null,
        judgmentAccordingToConsent: judgmentAccordingToConsent
          ? moment(judgmentAccordingToConsent).format("YYYY-MM-DD")
          : null,
        detailsJudgment: detailsJudgment,
        dayProcess: dayProcess,
        withdrawOneCase: withdrawOneCase
          ? moment(withdrawOneCase).format("YYYY-MM-DD")
          : null,
        withdrawOneCaseDueTo: withdrawOneCaseDueTo,
        returnFile: returnFile,
        returnFileReason: returnFileReason,
        note: note,
        fileName: fileName,
        filePath: filePath,
        chooseMemberCompany: chooseMemberCompany,
        chooseMemberCompanyName: chooseMemberCompanyName,
        other: other,
        user: user,
        countGoCourtDB:
          caseData == "edit" ? countCourtEtc + 1 : courtDate ? 1 : 0,
      };
      console.log(payload);
      axios
        .post(`${config.mainAPI}/credit`, {
          ...payload,
        })
        .then(function (response) {
          setTextFail([]);
          setOpenSuccess(true);
        })
        .catch(function (error) {
          console.log(error);
          setOpenFailure(true);
        });
    } else {
      if (!dateStart) {
        setTextFail((prevTextFail) => [...prevTextFail, "วันที่รับเรื่อง"]);
      }
      if (!defendantFirstName) {
        setTextFail((prevTextFail) => [...prevTextFail, "ชื่อจำเลย"]);
      }
      if (!defendantLastName) {
        setTextFail((prevTextFail) => [...prevTextFail, "นามสกุลจำเลย"]);
      }
      if (!defendantIdCard) {
        setTextFail((prevTextFail) => [...prevTextFail, "เลขที่บัตรประชาชน"]);
      }
      if (countCreditCard == 0 && countMoneyCard == 0) {
        setTextFail((prevTextFail) => [
          ...prevTextFail,
          "บัตรเครดิตและบัตรกดเงินสด",
        ]);
      }
      if (!chooseMemberCompany) {
        setTextFail((prevTextFail) => [...prevTextFail, "ผู้รับผิดชอบคดี"]);
      }
      // if (!debtCenter) {
      //   setTextFail((prevTextFail) => [...prevTextFail, "ศูนย์หนี้"]);
      // }
      console.log("textFail", textFail);
      setOpenFailure(true);
    }
  };

  const handleBack = () => {
    navigate("/credit");
  };

  const handleFileChange = (event) => {
    const fileData = event.target.files[0];
    setFile(fileData);
    setFile1(fileData);
  };

  const uploadFile = async () => {
    if (file) {
      console.log("file", file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      try {
        const res = await axios.post(`${config.mainAPI}/upload-file`, formData);
        console.log(res);
        setFileName(res.data.name);
        setFilePath(res.data.path);
        setCheckFile(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const combinedTimeShow = (time) => {
    if (time) {
      const combinedDateTime = moment(time, "YYYY-MM-DD HH:mm:ss");
      const formattedDate = moment(combinedDateTime).toDate();

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.toLocaleString("en-US", { month: "short" });
      const day = currentDate.getDate();
      const dateString = `${day} ${month} ${year} ${time} GMT+0700 (Indochina Time)`;
      return formattedDate instanceof Date && !isNaN(formattedDate)
        ? formattedDate
        : dateString;
    } else {
      return null;
    }
  };

  const handleTimeGoCourtChange = (date) => {
    if (date) {
      const combinedDateTime = moment(date, "YYYY-MM-DD HH:mm:ss");
      const formattedDate = moment(combinedDateTime).toDate();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.toLocaleString("en-US", { month: "short" });
      const day = currentDate.getDate();
      const dateString = `${day} ${month} ${year} ${date} GMT+0700 (Indochina Time)`;
      console.log(
        "formattedDate instanceof Date && !isNaN(formattedDate) ? formattedDate : dateString",
        formattedDate instanceof Date && !isNaN(formattedDate)
          ? formattedDate
          : dateString
      );
      setCourtTime(
        formattedDate instanceof Date && !isNaN(formattedDate)
          ? formattedDate
          : dateString
      );
    } else {
      setCourtTime(null);
    }
  };

  const deleteFile = () => {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/delete-file`, {
        filePath,
      })
      .then(function (response) {
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  // function handleDownload() {
  //   const file_path = filePath;
  //   const download_url = `${config.mainAPI}/download-file?file_path=${file_path}`;
  //   window.location.href = download_url;
  // }

  const handleProvinceChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantProvinceName = event.currentTarget.getAttribute("name");
      setDefendantProvince(value);
      setDefendantProvinceName(defendantProvinceName);
      setDefendantAmphoe("");
      setDefendantTambon("");
    }
  };

  const handleAmphoeChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantAmphoeName = event.currentTarget.getAttribute("name");
      setDefendantAmphoe(value);
      setDefendantAmphoeName(defendantAmphoeName);
      setDefendantTambon("");
    }
  };

  const handleTambonChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantTambonName = event.currentTarget.getAttribute("name");
      const defendantTambonZipcode = event.currentTarget.getAttribute("code");
      setDefendantZipcode(defendantTambonZipcode);
      setDefendantTambon(value);
      setDefendantTambonName(defendantTambonName);
    }
  };

  const getAmphoesByProvinceId = (provinceId) => {
    if (provinceId) {
      return provincesData.find((province) => province.id === provinceId)
        .amphure;
    }
  };

  const getTambonsByAmphoeId = (amphoeId) => {
    if (amphoeId) {
      return provincesData
        .flatMap((province) => province.amphure)
        .find((amphoe) => amphoe.id === amphoeId).tambon;
    }
  };

  function getUserCompany() {
    axios
      .get(`${config.mainAPI}/users-company`)
      .then((response) => {
        setUserCompany(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleHistoryWithDraw = () => {
    const id = params;
    navigate("/draw", { state: { params: id } });
  };

  const handleHistoryGetMoney = () => {
    const id = params;
    navigate("/money", { state: { params: id } });
  };

  const handleWorkByChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setChooseMemberCompany(workById);
    setChooseMemberCompanyName(workByName);
  };

  // const goCourtEtc = dateGoCourtEtc.map((item, index) => ({
  //   date: item ? moment(item).format("YYYY-MM-DD") : null,
  //   time: timeGoCourtEtc[index] ? timeGoCourtEtc[index] : null,
  //   lawyer: lawyerGoCourtEtc[index] ? lawyerGoCourtEtc[index] : null,
  //   lawyerName: lawyerNameGoCourtEtc[index]
  //     ? lawyerNameGoCourtEtc[index]
  //     : null,
  //   detail: detailGoCourtEtc[index] ? detailGoCourtEtc[index] : null,
  //   round: index + 1,
  // }));

  const goCourtEtc = dateGoCourtEtc.map((item, index) => {
    const combinedDateTime = moment(
      timeGoCourtEtc[index],
      "YYYY-MM-DD HH:mm:ss"
    );
    const formattedDate = moment(combinedDateTime).toDate();

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString("en-US", { month: "short" });
    const day = currentDate.getDate();
    const dateString = `${day} ${month} ${year} ${timeGoCourtEtc[index]} GMT+0700 (Indochina Time)`;

    return {
      date: item ? moment(item).format("YYYY-MM-DD") : null,
      time:
        formattedDate instanceof Date && !isNaN(formattedDate)
          ? formattedDate
          : dateString,
      lawyer: lawyerGoCourtEtc[index] ? lawyerGoCourtEtc[index] : null,
      lawyerName: lawyerNameGoCourtEtc[index]
        ? lawyerNameGoCourtEtc[index]
        : null,
      detail: detailGoCourtEtc[index] ? detailGoCourtEtc[index] : null,
      round: index + 1,
    };
  });

  const creditCard = dateRegisCreditCard.map((item, index) => ({
    order: index + 1,
    date_start: dateRegisCreditCard[index] ? dateRegisCreditCard[index] : null,
    name: creditCardName[index] ? creditCardName[index] : null,
    id: creditCardNumber[index] ? creditCardNumber[index] : null,
    limit: creditCardLimit[index] ? creditCardLimit[index] : null,
    principal: creditCardPrincipal[index] ? creditCardPrincipal[index] : null,
    interest: creditCardInterest[index] ? creditCardInterest[index] : null,
    fee: creditCardFees[index] ? creditCardFees[index] : null,
    interest_rate: creditCardInterestRates[index]
      ? creditCardInterestRates[index]
      : null,
    gsbNumber: creditCardGsbNumber[index] ? creditCardGsbNumber[index] : null,
  }));

  const moneyCard = dateRegisMoneyCard.map((item, index) => ({
    order: index + 1,
    date_start: dateRegisMoneyCard[index] ? dateRegisMoneyCard[index] : null,
    name: moneyCardName[index] ? moneyCardName[index] : null,
    id: moneyCardNumber[index] ? moneyCardNumber[index] : null,
    limit: moneyCardLimit[index] ? moneyCardLimit[index] : null,
    principal: moneyCardPrincipal[index] ? moneyCardPrincipal[index] : null,
    interest: moneyCardInterest[index] ? moneyCardInterest[index] : null,
    fee: moneyCardFees[index] ? moneyCardFees[index] : null,
    interest_rate: moneyCardInterestRates[index]
      ? moneyCardInterestRates[index]
      : null,
    gsbNumber: moneyCardGsbNumber[index] ? moneyCardGsbNumber[index] : null,
  }));

  const updateCountCreditCard = (event) => {
    setCountCreditCard(event);
    console.log("CountCourtEtc", event);

    // const handleWorkByChange = (event) => {
    //   const value = parseInt(event.target.dataset.value);
    //   const workByName = event.currentTarget.getAttribute("name");
    //   const workById = event.currentTarget.getAttribute("id");
    //   setChooseMemberCompany(workById);
    //   setChooseMemberCompanyName(workByName);
    // };

    // Splice data from arrays if their lengths are greater than countCourtEtc
    // อนาคตต้องมีการดักว่าต้องกรอกนะ
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  const updateCountMoneyCard = (event) => {
    setCountMoneyCard(event);
    console.log("CountCourtEtc", event);
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  function updateStatusFinish() {
    setIsLoading(true);
    axios
      .post("${config.mainAPI}/credit-success", {
        genCode: genCode,
        type: "credit",
      })
      .then(function (response) {
        setIsLoading(false);
        setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setOpenFailure(true);
      });
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const updateCountCourtEtc = (event) => {
    console.log("event", event);
    setCountCourtEtc(event);
    console.log("CountCourtEtc", event);

    // const handleWorkByChange = (event) => {
    //   const value = parseInt(event.target.dataset.value);
    //   const workByName = event.currentTarget.getAttribute("name");
    //   const workById = event.currentTarget.getAttribute("id");
    //   setChooseMemberCompany(workById);
    //   setChooseMemberCompanyName(workByName);
    // };

    // Splice data from arrays if their lengths are greater than countCourtEtc
    // อนาคตต้องมีการดักว่าต้องกรอกนะ
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  //   const handleDatePickerChange = (christDate, buddhistDate) => {
  //     console.log({ christDate, buddhistDate });
  //     setSelectedDate(christDate);
  //     // setSelectedThaiDate(buddhistDate);
  //   };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setData(christDate);
  };

  const handleDateGoCourtEtcChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleTimeGoCourtEtcChange = (date, index) => {
    const formattedDate = date;
    setTimeGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = formattedDate;
      return data;
    });
  };

  const disabledHours = () => {
    const disabledHoursArray = [];
    for (let i = 0; i < 8; i++) {
      disabledHoursArray.push(i);
    }
    for (let i = 19; i <= 23; i++) {
      disabledHoursArray.push(i);
    }
    return disabledHoursArray;
  };

  const handleLawyerGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    console.log("workByName", workByName);
    console.log("workById", workById);
    setLawyerGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workById;
      return data;
    });
    setLawyerNameGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workByName;
      return data;
    });
  };

  const handleDetailGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    setDetailGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleDateCreditCardStartDateChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateRegisCreditCard((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const onChangeMultipleValue = (setData, event, index) => {
    const { value } = event.target;
    setData((prevStatusDefendant) => {
      const newStatusDefendant = [...prevStatusDefendant];
      newStatusDefendant[index] = value;
      return newStatusDefendant;
    });
  };

  const onChangeDateValue = (christDate, buddhistDate, index, setData) => {
    setData((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleCreditCardNameChange = (event, index) => {
    const { value } = event.target;
    setCreditCardName((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardNumberChange = (event, index) => {
    const { value } = event.target;
    setCreditCardNumber((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardLimitChange = (event, index) => {
    const { value } = event.target;
    setCreditCardLimit((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardInterestChange = (event, index) => {
    const { value } = event.target;
    setCreditCardInterest((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardPrincipalChange = (event, index) => {
    const { value } = event.target;
    setCreditCardPrincipal((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardFeesChange = (event, index) => {
    const { value } = event.target;
    setCreditCardFees((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardInterestRatesChange = (event, index) => {
    const { value } = event.target;
    setCreditCardInterestRates((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleCreditCardTotalChange = (event, index) => {
    const { value } = event.target;
    setCreditCardTotal((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleDateMoneyCardStartDateChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateRegisMoneyCard((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleMoneyCardNameChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardName((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardNumberChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardNumber((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardLimitChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardLimit((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardInterestChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardInterest((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardPrincipalChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardPrincipal((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardFeesChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardFees((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardInterestRatesChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardInterestRates((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const handleMoneyCardTotalChange = (event, index) => {
    const { value } = event.target;
    setMoneyCardTotal((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleUploadFile = (row) => {
    console.log("row", row);
    setListFileUpdateSelected(row);
    setTypeSelected(row.type);
    setOpenUploadFile(true);
  };

  const handleDownload = (row) => {
    const file_path = row.path;
    const fileName = path.basename(file_path);
    saveActivityLog(`Click download file: ${fileName}`);
    const download_url = `${config.mainAPI}/download-file?path=${file_path}`;
    window.location.href = download_url;
  };

  const handleDeleteFile = (row) => {
    const payload = {
      main: row.main,
      fileName: row.path,
      uuid: row.uuid,
      id: params,
      type: "credit",
    };
    console.log(payload);
    setIsLoading(true);
    const fileName = path.basename(row.path);
    saveActivityLog(`Click Delete file: ${fileName}`);
    axios
      .post(`${config.mainAPI}/deleteFileByPath`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;

        getListFile();
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSubmitFileUpload = async () => {
    if (fileUpload) {
      console.log("fileUpload", fileUpload);
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("type", typeDocsUpload);
      formData.append("type_docs", "credit");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileUpload.name} Success`);

        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
      }
    }
  };

  function createData(order, uuid, list, type, date, work_by, path, main) {
    const formattedDate = date
      ? moment(date).locale("th").add(543, "year").format("DD/MM/YYYY")
      : null;
    const newList = list ? list : "-";
    return {
      order,
      uuid,
      // list: newList,
      type,
      date: formattedDate,
      work_by,
      path,
      main,
    };
  }

  const handleCloseOpenUploadFile = () => {
    setTypeSelected("");
    setFileUpload("");
    setListFileUpdateSelected(null);
    setOpenUploadFile(false);
  };

  const handleClickUploadFile = async () => {
    console.log("ListFileUpdateSelected", listFileUpdateSelected);
    if (fileUpload) {
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("order", listFileUpdateSelected.order);
      formData.append("type", listFileUpdateSelected.type);
      formData.append("type_docs", "credit");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/updateUploadFileDocs`,
          formData
        );
        saveActivityLog(`Update File:${fileUpload.name}  Success`);
        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
        setListFileUpdateSelected(null);
        setOpenUploadFile(false);
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
        setListFileUpdateSelected(null);
        setOpenUploadFile(false);
      }
    }
  };

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    // { id: "list", label: "รายการ", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
    { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
    {
      id: "uploadFile",
      label: "อัพโหลดเอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleUploadFile(row)}>
            <UploadFileIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "file",
      label: "เอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          {row.path ? (
            <IconButton
              disabled={!row.path}
              onClick={() => handleDownload(row)}
            >
              <PictureAsPdfIcon color={row.path ? "error" : "inherit"} />
            </IconButton>
          ) : null}
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={!row.path}
            onClick={() => handleDeleteFile(row)}
          >
            <DeleteForeverIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  function getListFile() {
    axios
      .get(`${config.mainAPI}/file-list/${params}`)
      .then((response) => {
        console.log("value filelist", response.data.data);
        const value = response.data.data;
        if (value) {
          setFileList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.file_uuid,
                item.file_topic,
                item.file_type,
                item.created_at,
                item.created_by,
                item.file_path,
                item.file_main,
                item.update_at
              )
            )
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const contract = gsbNumberList.map((item, index) => ({
    order: index + 1,
    id: item,
    description: "",
    type: loanTypeList[index],
    typeOfCollateral: typeOfCollateralList[index],
    typeOf: "credit",
    pricipal: principalList[index],
    interest: interestList[index],
    noticeGetDay: noticeDayGetList[index],
    noticeOutDay: noticeDayOutList[index],
    noticeDescription: noticeInvestigateList[index],
    bankruptJudge: "",
    countDay: countDateList[index],
    dateOfInterest: "",
    calInterest: dateCalInterestSinceList[index],
  }));

  function getListFile() {
    axios
      .get(`${config.mainAPI}/file-list/${params}`)
      .then((response) => {
        console.log("value filelist", response.data.data);
        const value = response.data.data;
        if (value) {
          setFileList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.file_uuid,
                item.file_topic,
                item.file_type,
                item.created_at,
                item.created_by,
                item.file_path,
                item.file_main,
                item.update_at
              )
            )
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
        >
          แบบฟอร์มบัตรเครดิต
        </Typography>
        <Card>
          {params ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 3,
                marginTop: 3,
                float: "right",
              }}
            >
              {/* <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="success"
                onClick={updateStatusFinish}
                startIcon={<DoneAllIcon />}
              >
                ปิดคดี
              </Button> */}
            </Box>
          ) : null}
        </Card>

        {/* <Box sx={{ padding: "30px" }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box> */}

        <Box
          sx={{
            padding: "30px",
            "@media (max-width: 950px)": {
              display: "none", // Show on mobile
            },
            "@media (min-width: 951px)": {
              display: "block", // Hide on larger screens
            },
          }}
        >
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box
          sx={{
            padding: "30px",
            "@media (max-width: 950px)": {
              display: "block", // Show on mobile
            },
            "@media (min-width: 951px)": {
              display: "none", // Hide on larger screens
            },
          }}
        >
          <Typography sx={{ fontSize: "16px" }}>รายการ:</Typography>
          <Select
            value={activeStep}
            onChange={(e) => setActiveStep(e.target.value)}
            variant="outlined"
            color="dark"
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            {steps.map((label, index) => (
              <MenuItem value={index}>{label}</MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ padding: "30px" }}>
          {activeStep == 0 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลลูกหนี้"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  {/* <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                <Grid item xs={12}>
                  <div className="sub-heading">
                    <span>ข้อมูลลูกหนี้</span>
                  </div>
                </Grid>
              </Grid> */}

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        คำนำหน้า<span style={{ color: "red" }}> *</span>
                      </Typography>
                      <Select
                        value={prefix}
                        onChange={(e) => setPrefix(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {prefixList &&
                          prefixList.map((column) => (
                            <MenuItem
                              key={column.prefix_id}
                              value={column.prefix_name_th}
                            >
                              {column.prefix_name_th}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ชื่อจำเลย<span style={{ color: "red" }}> *</span>
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={defendantFirstName}
                        onChange={(e) => setDefendantFirstName(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        นามสกุลจำเลย<span style={{ color: "red" }}> *</span>
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={defendantLastName}
                        onChange={(e) => setDefendantLastName(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขที่บัตรประชาชน
                        <span style={{ color: "red" }}> *</span>
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={defendantIdCard}
                        onChange={(e) => setDefendantIdCard(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>อายุ:</Typography>
                      <TextField
                        type="number"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={defendantAge}
                        onChange={(e) => setDefendantAge(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>อาชีพ:</Typography>
                      <TextField
                        type="text"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={defendantJob}
                        onChange={(e) => setDefendantJob(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        บ้านเลขที่:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={defendantHouseNumber}
                        onChange={(e) =>
                          setDefendantHouseNumber(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>หมู่:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 12 }}
                        value={defendantGroup}
                        onChange={(event) =>
                          setDefendantGroup(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>ถนน:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={defendantRoad}
                        onChange={(e) => setDefendantRoad(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>ซอย:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 12 }}
                        value={defendantSoi}
                        onChange={(event) =>
                          setDefendantSoi(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  {/* บ้านเลขที่ หมู่ ถนน ซอย ใกล้เคียง โทรศัพท์ โทรสาร */}
                  <Grid container sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ใกล้เคียง:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={defendantAddress}
                        onChange={(event) =>
                          setDefendantAddress(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จังหวัด:
                      </Typography>
                      <Select
                        value={defendantProvince || ""}
                        // onChange={(event) => setDefendantProvince(event.target.value)}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="">โปรดระบุจังหวัด</MenuItem>
                        {provincesData.map((province) => (
                          <MenuItem
                            key={province.id}
                            value={province.id}
                            name={province.name_th}
                            onClick={(event) => handleProvinceChange(event)}
                          >
                            {province.name_th}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    {defendantProvince ? (
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>
                          อำเภอ:
                        </Typography>
                        <Select
                          value={defendantAmphoe || ""}
                          fullWidth
                          color="dark"
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <MenuItem value="">โปรดระบุอำเภอ</MenuItem>
                          {getAmphoesByProvinceId(defendantProvince).map(
                            (amphoe) => (
                              <MenuItem
                                key={amphoe.id}
                                value={amphoe.id}
                                name={amphoe.name_th}
                                onClick={(event) => handleAmphoeChange(event)}
                              >
                                {amphoe.name_th}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </Grid>
                    ) : null}

                    {defendantAmphoe ? (
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>ตำบล:</Typography>
                        <Select
                          value={defendantTambon || ""}
                          fullWidth
                          color="dark"
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <MenuItem value="">โปรดระบุตำบล</MenuItem>
                          {getTambonsByAmphoeId(defendantAmphoe).map(
                            (tambon) => (
                              <MenuItem
                                key={tambon.id}
                                value={tambon.id}
                                name={tambon.name_th}
                                code={tambon.zip_code}
                                onClick={(event) => handleTambonChange(event)}
                              >
                                {tambon.name_th}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รหัสไปรษณี:
                      </Typography>
                      <TextField
                        id="comment"
                        name="comment"
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        disabled
                        value={defendantZipcode || ""}
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        โทรศัพท์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 12 }}
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>โทรสาร:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={phone1}
                        onChange={(e) => setPhone1(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 1 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หน่วยงาน:
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={agency}
                        onChange={(e) => setAgency(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันบอกกล่าว:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={noticeDay}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setNoticeDay,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนบัตรเครดิต
                      </Typography>
                      <TextField
                        type="number"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0, max: 4 } }}
                        defaultValue={0}
                        value={countCreditCard}
                        onChange={(e) => updateCountCreditCard(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                  {countCreditCard > 0 && (
                    <>
                      {[...Array(parseInt(countCreditCard))].map((_, i) => (
                        <React.Fragment key={i}>
                          <div class="separator">บัตรเครดิตใบที่ {i + 1}</div>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เลขที่สัญญาบัตรเครดิต ({i + 1})
                              </Typography>
                              <TextField
                                type="text"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={creditCardGsbNumber[i] || null}
                                onChange={(e) =>
                                  onChangeMultipleValue(
                                    setCreditCardGsbNumber,
                                    e,
                                    i
                                  )
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วันยื่นขอสมัครบัตรเครดิต ({i + 1})
                              </Typography>
                              <WatDatePicker
                                className="custom-date-picker"
                                value={dateRegisCreditCard[i] || null}
                                onChange={(christDate, buddhistDate) =>
                                  handleDateCreditCardStartDateChangeByIndex(
                                    christDate,
                                    buddhistDate,
                                    i
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ชื่อบัตรเครดิต ({i + 1})
                                <span style={{ color: "red" }}> *</span>
                              </Typography>
                              <TextField
                                type="text"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={creditCardName[i] || null}
                                onChange={(e) =>
                                  handleCreditCardNameChange(e, i)
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                หมายเลขบัตรเครดิต ({i + 1})
                                <span style={{ color: "red" }}> *</span>
                              </Typography>
                              <TextField
                                type="text"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={creditCardNumber[i] || null}
                                inputProps={{ maxLength: 16 }}
                                onChange={(e) =>
                                  handleCreditCardNumberChange(e, i)
                                }
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วงเงินบัตรเครดิต ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={creditCardLimit[i] || null}
                                onChange={(e) =>
                                  handleCreditCardLimitChange(e, i)
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เงินต้นบัตรเครดิต ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={creditCardPrincipal[i] || null}
                                onChange={(e) =>
                                  handleCreditCardPrincipalChange(e, i)
                                }
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ดอกเบี้ยบัตรเครดิต ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={creditCardInterest[i] || null}
                                onChange={(e) =>
                                  handleCreditCardInterestChange(e, i)
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ค่าธรรมเนียมบัตรเครดิต ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={creditCardFees[i] || null}
                                onChange={(e) =>
                                  handleCreditCardFeesChange(e, i)
                                }
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                อัตราดอกเบี้ยบัตรเครดิต ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={creditCardInterestRates[i] || null}
                                onChange={(e) =>
                                  handleCreditCardInterestRatesChange(e, i)
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ยอดรวมบัตรเครดิต ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={
                                  parseInt(creditCardPrincipal[i]) +
                                  parseInt(creditCardFees[i]) +
                                  parseInt(creditCardInterest[i])
                                }
                                disabled
                                onChange={(e) =>
                                  handleCreditCardTotalChange(e, i)
                                }
                              />
                            </Grid>
                            {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              วันออกโนติส:
                            </Typography>
                            <WatDatePicker
                              className="custom-date-picker"
                              value={noticeDayOutList[i]}
                              onChange={(christDate, buddhistDate) =>
                                onChangeDateValue(
                                  christDate,
                                  buddhistDate,
                                  i,
                                  setNoticeDayOutList
                                )
                              }
                              dateFormat={"yyyy-MM-dd"}
                              displayFormat={"DD/MM/YYYY"}
                              clearable={true}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              วันรับโนติส:
                            </Typography>
                            <WatDatePicker
                              className="custom-date-picker"
                              value={noticeDayGetList[i]}
                              onChange={(christDate, buddhistDate) =>
                                onChangeDateValue(
                                  christDate,
                                  buddhistDate,
                                  i,
                                  setNoticeDayGetList
                                )
                              }
                              dateFormat={"yyyy-MM-dd"}
                              displayFormat={"DD/MM/YYYY"}
                              clearable={true}
                            />
                          </Grid>
                        </Grid>

                        <Grid container sx={{ mb: 2 }}>
                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: "16px" }}>
                              สอบสวนโนติส:
                            </Typography>
                            <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              id={`noticeInvestigateList${i}`}
                              name={`noticeInvestigateList${i}`}
                              value={noticeInvestigateList[i] || ""}
                              onChange={(e) =>
                                onChangeMultipleValue(
                                  setNoticeInvestigateList,
                                  e,
                                  i
                                )
                              }
                            />
                          </Grid>
                        </Grid> */}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  )}

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนบัตรเงินสด
                      </Typography>
                      <TextField
                        type="number"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0, max: 4 } }}
                        defaultValue={0}
                        value={countMoneyCard}
                        onChange={(e) => updateCountMoneyCard(e.target.value)}
                        required
                      />
                    </Grid>
                  </Grid>
                  {countMoneyCard > 0 && (
                    <>
                      {[...Array(parseInt(countMoneyCard))].map((_, i) => (
                        <React.Fragment key={i}>
                          <div class="separator">บัตรเงินสดใบที่ {i + 1}</div>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เลขที่สัญญาบัตรเงินสด ({i + 1})
                              </Typography>
                              <TextField
                                type="text"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={moneyCardGsbNumber[i] || null}
                                onChange={(e) =>
                                  onChangeMultipleValue(
                                    setMoneyCardGsbNumber,
                                    e,
                                    i
                                  )
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วันยื่นขอสมัครบัตรเงินสด ({i + 1})
                              </Typography>
                              {/* <TextField
                            type="text"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={dateRegisMoneyCard[i]}
                            onChange={(christDate, buddhistDate) =>
                              handleDateMoneyCardStartDateChangeByIndex(
                                christDate,
                                buddhistDate,
                                i
                              )
                            }
                            required
                          /> */}

                              <WatDatePicker
                                className="custom-date-picker"
                                value={dateRegisMoneyCard[i] || null}
                                onChange={(christDate, buddhistDate) =>
                                  handleDateMoneyCardStartDateChangeByIndex(
                                    christDate,
                                    buddhistDate,
                                    i
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ชื่อบัตรเงินสด ({i + 1})
                                <span style={{ color: "red" }}> *</span>
                              </Typography>
                              <TextField
                                type="text"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={moneyCardName[i] || null}
                                onChange={(e) =>
                                  handleMoneyCardNameChange(e, i)
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                หมายเลขบัตรเงินสด ({i + 1})
                                <span style={{ color: "red" }}> *</span>
                              </Typography>
                              <TextField
                                type="text"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={moneyCardNumber[i] || null}
                                inputProps={{ maxLength: 16 }}
                                onChange={(e) =>
                                  handleMoneyCardNumberChange(e, i)
                                }
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วงเงินบัตรเงินสด ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={moneyCardLimit[i] || null}
                                onChange={(e) =>
                                  handleMoneyCardLimitChange(e, i)
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เงินต้นบัตรเงินสด ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={moneyCardPrincipal[i] || null}
                                onChange={(e) =>
                                  handleMoneyCardPrincipalChange(e, i)
                                }
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ดอกเบี้ยบัตรเงินสด ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={moneyCardInterest[i] || null}
                                onChange={(e) =>
                                  handleMoneyCardInterestChange(e, i)
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ค่าธรรมเนียมบัตรเงินสด ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={moneyCardFees[i] || null}
                                onChange={(e) =>
                                  handleMoneyCardFeesChange(e, i)
                                }
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                อัตราดอกเบี้ยบัตรเงินสด ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={moneyCardInterestRates[i] || null}
                                onChange={(e) =>
                                  handleMoneyCardInterestRatesChange(e, i)
                                }
                                required
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ยอดรวมบัตรเงินสด ({i + 1})
                              </Typography>
                              <TextField
                                type="number"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={
                                  parseInt(moneyCardPrincipal[i]) +
                                  parseInt(moneyCardFees[i]) +
                                  parseInt(moneyCardInterest[i])
                                }
                                disabled
                                onChange={(e) =>
                                  handleMoneyCardTotalChange(e, i)
                                }
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  )}

                  {/* <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ประเภทสินเชื่อ:
                  </Typography>
                  <Select
                    variant="outlined"
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                      width: "100%",
                    }}
                    value={loanType || ""}
                    onChange={(e) => setLoanType(e.target.value)}
                  >
                    <MenuItem value=""></MenuItem>
                    {listLoan.map((column) => (
                      <MenuItem key={column.id} value={column.value}>
                        {column.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ประเภทหลักประกัน:
                  </Typography>
                  <Select
                    variant="outlined"
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                      width: "100%",
                    }}
                    value={typeOfCollateral || ""}
                    onChange={(e) => setTypeOfCollateral(e.target.value)}
                  >
                    <MenuItem value=""></MenuItem>
                    {listTypeOfCollateral.map((column) => (
                      <MenuItem key={column.id} value={column.value}>
                        {column.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid> */}
                  {/* <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ทุนทรัพย์เงินต้นบัตรเครดิต:
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    value={principalCapitalCredit}
                    onChange={(e) => setPrincipalCapitalCredit(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ทุนทรัพย์ดอกเบี้ยบัตรเครดิต:
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    value={interestCapitalCredit}
                    onChange={(e) => setInterestCapitalCredit(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ค่าธรรมเนียมบัตรเครดิต:
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    value={taxCapitalCredit}
                    onChange={(e) => setTaxCapitalCredit(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ยอดรวมบัตรเครดิต:
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    value={sumCredit ? sumCredit.toFixed(2) : sumCredit}
                    disabled
                  />
                </Grid>
              </Grid> */}
                  {/* <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ทุนทรัพย์เงินต้นบัตรเงินสด:
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    value={principalCapitalMoney}
                    onChange={(e) => setPrincipalCapitalMoney(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ทุนทรัพย์ดอกเบี้ยบัตรเงินสด:
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    value={interestCapitalMoney}
                    onChange={(e) => setInterestCapitalMoney(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ค่าธรรมเนียมบัตรเงินสด:
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    value={taxCapitalMoney}
                    onChange={(e) => setTaxCapitalMoney(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ยอดรวมบัตรเงินสด:
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    value={sumMoney ? sumMoney.toFixed(2) : sumMoney}
                    disabled
                  />
                </Grid>
              </Grid> */}
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>ศาล:</Typography>
                      {/* <Autocomplete
                options={courtList}
                value={court}
                onChange={(event, newValue) => {
                  setCourt(newValue);
                }}
                getOptionLabel={(option) => option.court_name} // Display court_name in the dropdown
                renderInput={(params) => <TextField {...params} color="dark" />}
              /> */}
                      <Select
                        value={court}
                        onChange={(e) => setCourt(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {courtList &&
                          courtList.map((column) => (
                            <MenuItem
                              key={column.court_name}
                              value={column.court_name}
                            >
                              {column.court_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันขึ้นศาล:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={courtDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCourtDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เวลาขึ้นศาล:
                      </Typography>
                      <TimePicker
                        className="custom-date-picker"
                        value={courtTime ? moment(courtTime, "HH:mm:ss") : null}
                        // onChange={(date) => setCourtTime(date)}
                        onChange={(date) => handleTimeGoCourtChange(date)}
                        showSecond={false}
                        allowEmpty
                        disabledHours={disabledHours}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนวันนัดขึ้นศาลเพิ่มเติม:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={countCourtEtc}
                        disabled={!courtDate}
                        onChange={(e) => updateCountCourtEtc(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  {countCourtEtc > 0 && (
                    <>
                      {[...Array(parseInt(countCourtEtc))].map((_, i) => (
                        <React.Fragment key={i}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วันขึ้นศาลครั้งที่{i + 2}:
                              </Typography>
                              <WatDatePicker
                                className="custom-date-picker"
                                value={dateGoCourtEtc[i]}
                                onChange={(christDate, buddhistDate) =>
                                  handleDateGoCourtEtcChangeByIndex(
                                    christDate,
                                    buddhistDate,
                                    i
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เวลาขึ้นศาลครั้งที่{i + 2}:
                              </Typography>
                              <TimePicker
                                className="custom-date-picker"
                                value={
                                  timeGoCourtEtc[i]
                                    ? moment(timeGoCourtEtc[i], "HH:mm:ss")
                                    : null
                                }
                                onChange={(date) =>
                                  handleTimeGoCourtEtcChange(date, i)
                                }
                                showSecond={false}
                                allowEmpty
                                disabledHours={disabledHours}
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            sx={{ mb: 2, padding: 2 }}
                          >
                            {/* <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ผู้รับผิดชอบวันขึ้นศาลครั้งที่ {i + 2}:
                              </Typography>

                              <Select
                                id={`lawyerGoCourtEtc${i}`}
                                name={`lawyerGoCourtEtc${i}`}
                                value={lawyerGoCourtEtc[i] || ""} // Set the default value to an empty string ('') instead of null
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                required
                              >
                                {userCompany
                                  ? userCompany.map((item) => (
                                      <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        name={item.fullname}
                                        id={item.id}
                                        onClick={(e) =>
                                          handleLawyerGoCourtEtcChange(e, i)
                                        }
                                      >
                                        {item.fullname}
                                      </MenuItem>
                                    ))
                                  : null}
                              </Select>
                            </Grid> */}

                            <Grid item xs={12}>
                              <Typography sx={{ fontSize: "16px" }}>
                                รายละเอียดวันขึ้นศาลครั้งที่ {i + 2}:
                              </Typography>
                              <TextField
                                id={`detailGoCourtEtc${i}`}
                                name={`detailGoCourtEtc${i}`}
                                value={detailGoCourtEtc[i] || ""}
                                onChange={(e) =>
                                  handleDetailGoCourtEtcChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  )}

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีดำเลขที่:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{ marginTop: "10px", marginBottom: "10px" }}
                        value={blackCaseNumber}
                        onChange={(e) => setBlackCaseNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีดำปี:
                      </Typography>
                      <TextField
                        type="number"
                        fullWidth
                        color="dark"
                        sx={{ marginTop: "10px", marginBottom: "10px" }}
                        value={blackCaseYear}
                        onChange={(e) => setBlackCaseYear(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีแดงเลขที่:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{ marginTop: "10px", marginBottom: "10px" }}
                        value={redCaseNumber}
                        onChange={(e) => setRedCaseNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีแดงปี:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{ marginTop: "10px", marginBottom: "10px" }}
                        value={redCaseYear}
                        onChange={(e) => setRedCaseYear(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันนัดสืบพยาน:
                      </Typography>
                      {/* <TextField
                                type="date"
                                fullWidth
                                sx={{ marginTop: '10px', marginBottom: '10px' }}
                                value={witnessExaminationDate || null}
                                onChange={(event) => setWitnessExaminationDate(event.target.value)}
                            /> */}
                      {/* <DatePicker
                selected={witnessExaminationDate}
                locale={th}
                dateFormat="dd/MM/yyyy"
                placeholderText="ระบุวันที่"
                wrapperClassName="datePicker"
                className="custom-date-picker"
                value={witnessExaminationDate}
                onChange={(date) => {
                  setWitnessExaminationDate(date);
                }}
              /> */}
                      <WatDatePicker
                        className="custom-date-picker"
                        value={witnessExaminationDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setWitnessExaminationDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        พิพากษา(ฝ่ายเดียว):
                      </Typography>
                      {/* <TextField
                                type="date"
                                fullWidth
                                sx={{ marginTop: '10px', marginBottom: '10px' }}
                                InputLabelProps={{ shrink: true }}
                                value={judgmentUnilateral || null}
                                onChange={(e) => setJudgmentUnilateral(e.target.value)}
                            /> */}
                      {/* <DatePicker
                selected={judgmentUnilateral}
                locale={th}
                dateFormat="dd/MM/yyyy"
                placeholderText="ระบุวันที่"
                wrapperClassName="datePicker"
                className="custom-date-picker"
                value={judgmentUnilateral}
                onChange={(date) => {
                  setJudgmentUnilateral(date);
                }}
              /> */}
                      <WatDatePicker
                        className="custom-date-picker"
                        value={judgmentUnilateral}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setJudgmentUnilateral,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        พิพากษา(ตามยอม):
                      </Typography>
                      {/* <TextField
                                id="date"
                                name="date"
                                type="date"
                                fullWidth
                                sx={{ marginTop: '10px', marginBottom: '10px' }}
                                InputLabelProps={{ shrink: true }}
                                value={judgmentAccordingToConsent}
                                onChange={(e) => setJudgmentAccordingToConsent(e.target.value)}
                            /> */}
                      {/* <DatePicker
                selected={judgmentAccordingToConsent}
                locale={th}
                dateFormat="dd/MM/yyyy"
                placeholderText="ระบุวันที่"
                wrapperClassName="datePicker"
                className="custom-date-picker"
                value={judgmentAccordingToConsent}
                onChange={(date) => {
                  setJudgmentAccordingToConsent(date);
                }}
              /> */}

                      <WatDatePicker
                        className="custom-date-picker"
                        value={judgmentAccordingToConsent}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setJudgmentAccordingToConsent,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนวันที่ดำเนินการ:
                      </Typography>
                      <TextField
                        type="number"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={dayProcess}
                        onChange={(e) => setDayProcess(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ถอนฟ้อง:
                      </Typography>
                      {/* <TextField
                                type="date"
                                fullWidth
                                sx={{ marginTop: '10px', marginBottom: '10px' }}
                                value={withdrawOneCase}
                                onChange={(e) => setWithdrawOneCase(e.target.value)}
                            /> */}
                      {/* <DatePicker
                selected={withdrawOneCase}
                locale={th}
                dateFormat="dd/MM/yyyy"
                placeholderText="ระบุวันที่"
                wrapperClassName="datePicker"
                className="custom-date-picker"
                value={withdrawOneCase}
                onChange={(date) => {
                  setWithdrawOneCase(date);
                }}
              /> */}

                      <WatDatePicker
                        className="custom-date-picker"
                        value={withdrawOneCase}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setWithdrawOneCase,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เนื่องจาก:
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        disabled={!withdrawOneCase}
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={withdrawOneCaseDueTo}
                        onChange={(e) =>
                          setWithdrawOneCaseDueTo(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รายละเอียดคำพิพากษา(โดยย่อ):
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={detailsJudgment}
                        onChange={(e) => setDetailsJudgment(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ส่งแฟ้มคืนวันที่:
                      </Typography>
                      {/* <TextField
                                type="date"
                                fullWidth
                                sx={{ marginTop: '10px', marginBottom: '10px' }}
                                InputProps={{ inputProps: { min: 0 } }}
                                value={returnFile}
                                onChange={(e) => setReturnFile(e.target.value)}
                            /> */}
                      {/* <DatePicker
                selected={returnFile}
                locale={th}
                dateFormat="dd/MM/yyyy"
                placeholderText="ระบุวันที่"
                wrapperClassName="datePicker"
                className="custom-date-picker"
                value={returnFile}
                onChange={(date) => setReturnFile(date)}
              /> */}

                      <WatDatePicker
                        className="custom-date-picker"
                        value={returnFile}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setReturnFile,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>เหตุผล:</Typography>
                      <TextField
                        type="text"
                        fullWidth
                        disabled={!returnFile}
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={returnFileReason}
                        onChange={(e) => setReturnFileReason(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ:
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={note}
                        onChange={(event) => setNote(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {/* {activeStep == 2 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="รายการเอกสารในสำนวนคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ประเภทเอกสาร:
                          </Typography>
                          <Select
                            value={typeDocsUpload}
                            onChange={(e) => setTypeDocsUpload(e.target.value)}
                            variant="outlined"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {typeDocsList &&
                              typeDocsList.map((column) => (
                                <MenuItem value={column.value}>
                                  {column.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เรื่อง:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={topicDocsUpload}
                            onChange={(e) => setTopicDocsUpload(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px", mb: 1 }}>
                            อัพโหลดเอกสาร:
                          </Typography>
                          <FileUploader
                            multiple={false}
                            handleChange={handleUploadChange}
                            name="fileUpload"
                            types={fileTypes}
                          />
                          <p>
                            {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            startIcon={<ClearIcon />}
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              marginRight: 2,
                            }}
                            onClick={handleClearFileUpload}
                          >
                            ล้างค่า
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<FileUploadIcon />}
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                            onClick={handleSumbitFileUpload}
                          >
                            อัปโหลด
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "30px",
                          }}
                        >
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="center"
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredRows
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((row) => (
                                    <TableRow key={row.order}>
                                      {columns.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align="center"
                                        >
                                          {column.render
                                            ? column.render(row)
                                            : row[column.id]}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null} */}
          {activeStep == 2 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="รายการเอกสารในสำนวนคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "30px",
                          }}
                        >
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="center"
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredRows
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((row) => (
                                    <TableRow key={row.order}>
                                      {columns.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align="center"
                                        >
                                          {column.render
                                            ? column.render(row)
                                            : row[column.id]}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows1.length}
                            rowsPerPage={rowsPerPage1}
                            page={page1}
                            onPageChange={handleChangePage1}
                            onRowsPerPageChange={handleChangeRowsPerPage1}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="อัพโหลดเอกสารเพิ่มเติม"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ประเภทเอกสาร:
                          </Typography>
                          <Select
                            value={typeDocsUpload}
                            onChange={(e) => setTypeDocsUpload(e.target.value)}
                            variant="outlined"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {typeDocsList &&
                              typeDocsList.map((column) => (
                                <MenuItem value={column.value}>
                                  {column.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เรื่อง:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={topicDocsUpload}
                            onChange={(e) => setTopicDocsUpload(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px", mb: 1 }}>
                            อัพโหลดเอกสาร:
                          </Typography>
                          <FileUploader
                            multiple={false}
                            handleChange={handleUploadChange}
                            name="fileUpload"
                            types={fileTypes}
                          />
                          <p style={{ marginTop: "1rem" }}>
                            {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                            {fileUpload ? (
                              <>
                                <IconButton onClick={handleClearFileUpload}>
                                  <DeleteForeverIcon color="error" />
                                </IconButton>
                                <IconButton onClick={handleSubmitFileUpload}>
                                  <UploadFileIcon color="success" />
                                </IconButton>
                              </>
                            ) : null}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 3 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลบริษัท"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่รับเรื่อง<span style={{ color: "red" }}> *</span>
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dateStart}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDateStart,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขแฟ้ม:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        InputProps={{ inputProps: { min: 1 } }}
                        fullWidth
                        sx={{ marginTop: "10px", marginBottom: "10px" }}
                        value={fileNumber}
                        onChange={(event) => setFileNumber(event.target.value)}
                        PopperProps={{
                          sx: {
                            ".MuiPaper-root": {
                              // padding: 2,
                              backgroundColor: "#172438",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีศาล:
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={courtCode}
                        onChange={(e) => setCourtCode(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีบริษัท:
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={companyCode}
                        onChange={(e) => setCompanyCode(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ผู้รับผิดชอบคดี<span style={{ color: "red" }}> *</span>
                      </Typography>
                      <Select
                        value={chooseMemberCompany}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        required
                      >
                        {userCompany
                          ? userCompany.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                name={item.fullname}
                                id={item.id}
                                onClick={(event) => handleWorkByChange(event)}
                              >
                                {item.fullname}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>
                        อื่นๆ(สำหรับโน้ต):
                      </Typography>
                      <TextField
                        type="text"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={other}
                        onChange={(e) => setOther(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 4 && executionCase ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลบังคับคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีศาล:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={executionCode}
                        onChange={(e) => setExecutionCode(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่รับเรื่อง<span style={{ color: "red" }}> *</span>:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={executionDateStart}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setExecutionDateStart,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันกำหนดส่ง:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={executionDeadline}
                        // onChange={(christDate, buddhistDate) =>
                        //   handleDatePickerChange(
                        //     setExecutionDeadline,
                        //     christDate,
                        //     buddhistDate
                        //   )
                        // }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันขึ้นศาล:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={executionCourtDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setExecutionCourtDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เวลาขึ้นศาล:
                      </Typography>
                      <TimePicker
                        className="custom-date-picker"
                        value={
                          executionCourtTime
                            ? moment(executionCourtTime, "HH:mm:ss")
                            : null
                        }
                        onChange={(date) => setExecutionCourtTime(date)}
                        showSecond={false}
                        allowEmpty
                        disabledHours={disabledHours}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        สถานะทรัพย์:
                      </Typography>
                      <Select
                        value={propertyStatus}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        onChange={(e) => setPropertyStatus(e.target.value)}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="อายัด">อายัด</MenuItem>
                        <MenuItem value="ยึดทรัพย์">ยึดทรัพย์</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ผู้รับผิดชอบคดี:
                      </Typography>
                      <Select
                        value={executionWorkBy}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        required
                      >
                        {userCompany
                          ? userCompany.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                name={item.fullname}
                                id={item.id}
                                onClick={(event) =>
                                  handleWorkByLedChange(event)
                                }
                              >
                                {item.fullname}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 5 && executionCase ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="คู่สมรส"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบคู่สมรส:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={spouseCheckDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSpouseCheckDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ชื่อคู่สมรส:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={spouseName}
                        onChange={(e) => setSpouseName(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเลขบัตรประชาชนคู่สมรส:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={spouseIdCard}
                        // onChange={(e) => setSpouseIdCard(e.target.value)}
                        onChange={(e) =>
                          onChangeValueWithNumberAndSetLimit(
                            setSpouseIdCard,
                            13,
                            e
                          )
                        }
                        // onChangeValueWithNumberAndSetLimit
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={spouseNote}
                        onChange={(e) => setSpouseNote(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบรายได้:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={spouseIncomeDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSpouseIncomeDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={spouseStatus}
                        onChange={(e) => setSpouseStatus(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ประกันสังคม"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบรายได้:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={workPlaceIncomeDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setWorkPlaceIncomeDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={workPlaceStatusIncome}
                        onChange={(e) =>
                          setWorkPlaceStatusIncome(e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        สถานที่ทำงาน:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={workPlaceName}
                        onChange={(e) => setWorkPlaceName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เหตุที่อายัดไม่ได้(ขัดข้องการส่งเงิน):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={workPlaceDetailIncome}
                        onChange={(e) =>
                          setWorkPlaceDetailIncome(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งอายัดเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={workPlaceDateSendIncome}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setWorkPlaceDateSendIncome,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนเงิน:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={workPlaceIncome}
                        onChange={(e) => setWorkPlaceIncome(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="กรมขนส่ง"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบกรมขนส่ง:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dltDateCheck}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDltDateCheck,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={dltStatus}
                        onChange={(e) => setDltStatus(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ / รายละเอียดทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dltDetail}
                        onChange={(e) => setDltDetail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="กรมที่ดิน"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบกรมที่ดิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dolDateCheckGround}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDolDateCheckGround,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={dolStatusObject}
                        onChange={(e) => setDolStatusObject(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ / รายละเอียดทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolDetail}
                        onChange={(e) => setDolDetail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        สถานะจำนองทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolStatus}
                        onChange={(e) => setDolStatus(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolFailureDetail}
                        onChange={(e) => setDolFailureDetail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ขายทอดตลาด"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolFailureDetail1}
                        onChange={(e) => setDolFailureDetail1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ประกาศขายทอดตลาด:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolSell}
                        onChange={(e) => setDolSell(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        กรณีรับค่าใช้จ่ายเหลือคืน:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolCostReturn}
                        onChange={(e) => setDolCostReturn(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินส่วนได้จากการขายทอดตลาด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={dolMoneySell}
                        onChange={(e) => setDolMoneySell(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่รับบัญชีรับจ่าย:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dolDateGetSend}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDolDateGetSend,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}
            </>
          ) : null}

          {activeStep == 6 && executionCase ? (
            <>
              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีขายทอดตลาด"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาล:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostCourt}
                        onChange={(e) => setLedCostCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายเรียก:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostCall}
                        onChange={(e) => setLedCostCall(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายคำบังคับ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostForce}
                        onChange={(e) => setLedCostForce(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าบอกกล่าว:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostTell}
                        onChange={(e) => setLedCostTell(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่าย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostAll}
                        onChange={(e) => setLedCostAll(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าคำร้อง/รับรองเอกสาร:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostRequest}
                        onChange={(e) => setLedCostRequest(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายความ(งวดที่1):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostLawyer1}
                        onChange={(e) => setLedCostLawyer1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายความ(งวดที่2):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostLawyer2}
                        onChange={(e) => setLedCostLawyer2(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก งวด 1 (ฟ้อง):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigete1Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigete1Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(งวดที่1):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledBankPaid1Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedBankPaid1Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก งวด 2 (ฟ้อง):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigete2Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigete2Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(งวดที่2):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledBankPaid2Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedBankPaid2Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}

              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีชั้นอุทธรณ์ / ฎีกา"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาลชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostCourt}
                        onChange={(e) => setCojCostCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostGo}
                        onChange={(e) => setCojCostGo(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่ายชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostSumAll}
                        onChange={(e) => setCojCostSumAll(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostLawyer}
                        onChange={(e) => setCojCostLawyer(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการดำเนินคดีชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostAll}
                        onChange={(e) => setCojCostAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาลชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostCourt}
                        onChange={(e) => setSupCostCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostGo}
                        onChange={(e) => setSupCostGo(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่ายชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostSumAll}
                        onChange={(e) => setSupCostSumAll(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostLawyer}
                        onChange={(e) => setSupCostLawyer(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการดำเนินคดีชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostAll}
                        onChange={(e) => setSupCostAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกชั้นฎีกา:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={supDateReq}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSupDateReq,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={supDatePaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSupDatePaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีชั้นสืบทรัพย์"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจกรรมสิทธิ์ชั้นสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckRoleInvestigate}
                        onChange={(e) =>
                          setCostCheckRoleInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจยานพาหนะชั้นสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckCarInvestigate}
                        onChange={(e) =>
                          setCostCheckCarInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าใช้จ่ายอื่นๆชั้นสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costEtcInvestigate}
                        onChange={(e) => setCostEtcInvestigate(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costAllInvestigate}
                        onChange={(e) => setCostAllInvestigate(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckInvestigate}
                        onChange={(e) =>
                          setCostCheckInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจสอบระวางแผนที่:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckMapInvestigate}
                        onChange={(e) =>
                          setCostCheckMapInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าจ้างสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckAllInvestigate}
                        onChange={(e) =>
                          setCostCheckAllInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกสืบทรัพย์:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={costWithdrawInvestigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCostWithdrawInvestigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={datePaidInvestigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDatePaidInvestigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีชั้นบังคับคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตั้งเรื่องอายัด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={sequestrationFee}
                        onChange={(e) => setSequestrationFee(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่าย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={lumpSum}
                        onChange={(e) => setLumpSum(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างอายัด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCost}
                        onChange={(e) => setLedCost(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>รวม:</Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledAll}
                        onChange={(e) => setLedAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกอายัด:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledDate0}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setledDate0,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง 20
                        %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={led20}
                        onChange={(e) => setLed20(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก 20 %อายัด(ครั้งที่ 1):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={led20Date1}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLed20Date1,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(ครั้งที่ 1):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledDateBankPaid1}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedDateBankPaid1,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง 20
                        %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่
                        1):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={led20R1}
                        onChange={(e) => setLed20R1(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก 20 %อายัด(ครั้งที่ 2):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={led20DateR2}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLed20DateR2,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(ครั้งที่ 2):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledDateBankPaid2}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedDateBankPaid2,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง 20
                        %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่
                        2):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={led20R2}
                        onChange={(e) => setLed20R2(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก 20 %อายัด(ครั้งที่ 3):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={led20DateR3}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLed20DateR3,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(ครั้งที่ 3):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledDateBankPaid3}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedDateBankPaid3,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง 20
                        %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่
                        3):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={led20R3}
                        onChange={(e) => setLed20R3(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก 20 %อายัด(ครั้งที่ 4):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={led20DateR4}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLed20DateR4,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(ครั้งที่ 4):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledDateBankPaid4}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedDateBankPaid4,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าธรรมเนียม:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledFess}
                        onChange={(e) => setLedFess(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมาย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledFollow}
                        onChange={(e) => setLedFollow(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่ารับรอง:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledSubmit}
                        onChange={(e) => setLedSubmit(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการยื่นคำร้อง:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostWagesRequest}
                        onChange={(e) => setLedCostWagesRequest(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigateDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigateDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigateDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigateDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างยื่นคำร้อง:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledWagesRequest}
                        onChange={(e) => setLedWagesRequest(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledWagesDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedWagesDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledWagesInvestigateDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedWagesInvestigateDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตั้งเรื่องบังคับคดีแทน(ยึดทรัพย์):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={createLedCost}
                        onChange={(e) => setCreateLedCost(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตั้งเรื่องยึดทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCreateSequester}
                        onChange={(e) => setCostCreateSequester(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่าย(ยึดทรัพย์):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costAllSequester}
                        onChange={(e) => setCostAllSequester(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างยึดทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costSequester}
                        onChange={(e) => setCostSequester(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการบังคับคดี(ยึดทรัพย์):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costAllLedSequester}
                        onChange={(e) => setCostAllLedSequester(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกยึดทรัพย์:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={sequesterDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSequesterDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={sequesterDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSequesterDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าวางเงินค่าใช้จ่ายเพิ่ม:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={seizedWages}
                        onChange={(e) => setSeizedWages(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจรับรองบัญชีรับ-จ่าย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costSequesterCheck}
                        onChange={(e) => setCostSequesterCheck(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าใช้จ่ายอื่น ๆ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costSequesterEtc}
                        onChange={(e) => setCostSequesterEtc(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายอื่น ๆ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costAllSequesterEtc}
                        onChange={(e) => setCostAllSequesterEtc(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dateSequesterInvestigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDateSequesterInvestigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={sequesterInvestigateDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSequesterInvestigateDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง 2
                        %(ได้รับชำระหนี้)ยึดอสังหาฯ(แต่ไม่เกิน100,000บาท):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cost2Per}
                        onChange={(e) => setCost2Per(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost2PerInverstigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost2PerInverstigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost2PerDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost2PerDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง 50 %(ได้รับชำระหนี้):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cost50Per}
                        onChange={(e) => setCost50Per(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost50PerInverstigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost50PerInverstigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost50PerDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost50PerDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง
                        1%(ได้รับชำระหนี้ปิดบัญชีหรือปรับปรุงหนี้ระหว่างประกาศขาย)(แต่ไม่เกิน
                        10,000 บาท:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cost1Per}
                        onChange={(e) => setCost1Per(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost1PerInverstigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost1PerInverstigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost1PerDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost1PerDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}

              {propertyStatus == "อายัด" ? (
                <>
                  <Card sx={{ mb: 3 }}>
                    <CardHeader
                      sx={{ backgroundColor: "#181414" }}
                      title="ค่าใช้จ่าย (อายัด)"
                    />
                    <CardContent sx={{ border: "0.5px solid" }}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าตั้งเรื่องอายัด:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={sequestrationFee}
                            onChange={(e) =>
                              setSequestrationFee(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าเหมาจ่าย:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={lumpSum}
                            onChange={(e) => setLumpSum(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าจ้างอายัด:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledCost}
                            onChange={(e) => setLedCost(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รวม:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledAll}
                            onChange={(e) => setLedAll(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่เบิกอายัด:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledDate0}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setledDate0,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setLedDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าธรรมเนียม:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledFess}
                            onChange={(e) => setLedFess(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่านำหมาย:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledFollow}
                            onChange={(e) => setLedFollow(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่ารับรอง:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledSubmit}
                            onChange={(e) => setLedSubmit(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รวมค่าใช้จ่ายในการยื่นคำร้อง:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledCostWagesRequest}
                            onChange={(e) =>
                              setLedCostWagesRequest(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่เบิก:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledInvestigateDate}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setLedInvestigateDate,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledInvestigateDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setLedInvestigateDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าจ้างยื่นคำร้อง:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledWagesRequest}
                            onChange={(e) => setLedWagesRequest(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledWagesDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setLedWagesDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              ) : propertyStatus == "ยึดทรัพย์" ? (
                <>
                  <Card sx={{ mb: 3 }}>
                    <CardHeader
                      sx={{ backgroundColor: "#181414" }}
                      title="ค่าใช้จ่าย (ยึดทรัพย์)"
                    />
                    <CardContent sx={{ border: "0.5px solid" }}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าตั้งเรื่องบังคับคดีแทน(ยึดทรัพย์):
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={createLedCost}
                            onChange={(e) => setCreateLedCost(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าตั้งเรื่องยึดทรัพย์:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costCreateSequester}
                            onChange={(e) =>
                              setCostCreateSequester(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าเหมาจ่าย(ยึดทรัพย์):
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costAllSequester}
                            onChange={(e) =>
                              setCostAllSequester(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าจ้างยึดทรัพย์:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costSequester}
                            onChange={(e) => setCostSequester(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รวมค่าใช้จ่ายในการบังคับคดี(ยึดทรัพย์):
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costAllLedSequester}
                            onChange={(e) =>
                              setCostAllLedSequester(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่เบิกยึดทรัพย์:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={sequesterDate}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setSequesterDate,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={sequesterDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setSequesterDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าวางเงินค่าใช้จ่ายเพิ่ม:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={seizedWages}
                            onChange={(e) => setSeizedWages(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าตรวจรับรองบัญชีรับ-จ่าย:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costSequesterCheck}
                            onChange={(e) =>
                              setCostSequesterCheck(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าใช้จ่ายอื่น ๆ:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costSequesterEtc}
                            onChange={(e) =>
                              setCostSequesterEtc(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รวมค่าใช้จ่ายอื่น ๆ:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costAllSequesterEtc}
                            onChange={(e) =>
                              setCostAllSequesterEtc(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่เบิก:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={dateSequesterInvestigate}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setDateSequesterInvestigate,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={sequesterInvestigateDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setSequesterInvestigateDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              ) : null}
            </>
          ) : null}

          {activeStep == 7 && executionCase ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ผลคำพิพากษา"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  {/*  */}

                  {countLoan > 0 && (
                    <>
                      {[...Array(parseInt(countLoan))].map((_, i) => (
                        <React.Fragment key={i}>
                          <div class="separator">สินเชื่อลำดับที่ {i + 1}</div>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เลขสัญญา:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                id={`gsbNumberList${i}`}
                                name={`gsbNumberList${i}`}
                                value={gsbNumberList[i] || ""}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                พิพากษา(ยอดหนี้):
                              </Typography>
                              {/* <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                // value={bankruptNumber1}
                                // onChange={(e) => setBankruptNumber1(e.target.value)}
                              /> */}
                              <WatDatePicker
                                className="custom-date-picker"
                                value={judgmentdebtLedList[i]}
                                onChange={(christDate, buddhistDate) =>
                                  onChangeDateValue(
                                    christDate,
                                    buddhistDate,
                                    i,
                                    setJudgmentdebtLedList
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เงินต้น:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                id={`principalList${i}`}
                                name={`principalList${i}`}
                                value={principalList[i] || ""}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ดอกเบี้ย:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`interestList${i}`}
                                name={`interestList${i}`}
                                value={interestList[i] || ""}
                                disabled
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ยอดรวม:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                value={
                                  principalList[i]
                                    ? parseInt(principalList[i]) +
                                      (interestList[i]
                                        ? parseInt(interestList[i])
                                        : 0)
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  )}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขสัญญา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={loanType}
                        onChange={(e) => setLoanType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        พิพากษา(ยอดหนี้):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptNumber1}
                        onChange={(e) => setBankruptNumber1(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินต้น:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptMoney1}
                        onChange={(e) => setBankruptMoney1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ดอกเบี้ย(%):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptInterest1}
                        onChange={(e) => setBankruptInterest1(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        นับแต่วันที่:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={bankruptSince1}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setBankruptSince1,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเลขบัตรเงินสด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptNumber2}
                        onChange={(e) => setBankruptNumber2(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        พิพากษา(ยอดหนี้):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptJudge2}
                        onChange={(e) => setBankruptJudge2(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินต้น:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptMoney2}
                        onChange={(e) => setBankruptMoney2(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ดอกเบี้ย(%):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptInterest2}
                        onChange={(e) => setBankruptInterest2(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        นับแต่วันที่:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={bankruptSince2}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setBankruptSince2,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid> */}

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาล:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptCourt}
                        onChange={(e) => setBankruptCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมาย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptFollow}
                        onChange={(e) => setBankruptFollow(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าทนายความ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptCostLawyer}
                        onChange={(e) => setBankruptCostLawyer(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าส่งคำบังคับ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptCostSend}
                        onChange={(e) => setBankruptCostSend(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>รวม:</Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        disabled
                        value={bankruptAll}
                        onChange={(e) => setBankruptAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 8 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="รายการเอกสารในสำนวนคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "30px",
                          }}
                        >
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns1.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="center"
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredRows1
                                  .slice(
                                    page1 * rowsPerPage1,
                                    page1 * rowsPerPage1 + rowsPerPage1
                                  )
                                  .map((row) => (
                                    <TableRow key={row.order}>
                                      {columns1.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align="center"
                                        >
                                          {column.render
                                            ? column.render(row)
                                            : row[column.id]}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="อัพโหลดเอกสารเพิ่มเติม"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ประเภทเอกสาร:
                          </Typography>
                          <Select
                            value={typeDocsUpload}
                            onChange={(e) => setTypeDocsUpload(e.target.value)}
                            variant="outlined"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {typeDocsList &&
                              typeDocsList.map((column) => (
                                <MenuItem value={column.value}>
                                  {column.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เรื่อง:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={topicDocsUpload}
                            onChange={(e) => setTopicDocsUpload(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px", mb: 1 }}>
                            อัพโหลดเอกสาร:
                          </Typography>
                          <FileUploader
                            multiple={false}
                            handleChange={handleUploadChange}
                            name="fileUpload"
                            types={fileTypes}
                          />
                          <p style={{ marginTop: "1rem" }}>
                            {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                            {fileUpload ? (
                              <>
                                <IconButton onClick={handleClearFileUpload}>
                                  <DeleteForeverIcon color="error" />
                                </IconButton>
                                <IconButton onClick={handleSubmitFileUpload}>
                                  <UploadFileIcon color="success" />
                                </IconButton>
                              </>
                            ) : null}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleBack}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>

      <Dialog open={openFailure} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <ErrorIcon sx={{ color: "#ff0c34", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#ff0c34",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#ff0c34",
              fontSize: 16,
            }}
          >
            กรุณากรอก{" "}
            {textFail.map((item, index) =>
              index == textFail.length - 1 ? (
                <span>{item}</span>
              ) : (
                <span>{item}, </span>
              )
            )}
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseFailure}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openSuccess} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <CheckCircleOutlineIcon sx={{ color: "#52c41a", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h2"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#1f1f1f",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลสำเร็จ!
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleCloseSuccess}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openUploadFile} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            อัพโหลดเอกสาร{typeSelected}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <FileUploader
                  multiple={false}
                  handleChange={handleUploadChange}
                  name="fileUpload"
                  types={fileTypes}
                />
                <p style={{ marginTop: "1rem" }}>
                  {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseOpenUploadFile}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleClickUploadFile}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsFormCreadit;
