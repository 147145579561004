// api.js
import axios from "axios";
import config from "./config"; // Your configuration file

// Create an Axios Instance
const api = axios.create({
  baseURL: config.mainAPI, // Your API base URL
});

// Set a request interceptor
api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    if (error) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

// Set a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that is within the range of 2xx causes this function to trigger
    return response;
  },
  function (error) {
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status == 403) {
      // Redirect to login if 403 Forbidden is returned
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
