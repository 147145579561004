import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import PropTypes from "prop-types";
import {
  TextField,
  Card,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import UnpublishedRoundedIcon from "@mui/icons-material/UnpublishedRounded";
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useAuth from "function/useAuth";
import config from "../../../config";

const DocumentsExpal = () => {
  const [user, setUser] = useState([]);
  const [document, setDocument] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [province, setProvince] = useState("");
  const [listProvince, setListProvince] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [checkFile, setCheckFile] = useState(false);
  const [type, setType] = useState("");
  const [fileExport, setFileExport] = useState("");
  const [code, setCode] = useState("");
  const [nameCreate, setNameCreate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Search
  const [findDefendent, setFindDefendent] = useState("");
  const [findDate, setFindDate] = useState("");
  const [findProvince, setFindProvince] = useState("");
  const [findDebtCenter, setFindDebtCenter] = useState("");
  const [findCourtCode, setFindCourtCode] = useState("");
  const [findCompanyCode, setFindCompanyCode] = useState("");
  useAuth();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log("usereieina", userData);
    if (userData) {
      setUser(userData);
      getDocument(userData);
    }
  }, []);
  const navigate = useNavigate();

  function handleDownload() {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/export-excel-expal`)
      .then((response) => {
        const download_url = `${config.mainAPI}/download-documents-excel-expal`;
        window.location.href = download_url;
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function getDocument(userData) {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/expal/${userData.id}/${userData.role}`)
      .then((response) => {
        console.log(response.data.data);
        const value = response.data.data;
        if (value) {
          let getPercent = 0;
          setRows(
            value.map((item, index) => {
              let getPercent = 0;
              if (item.notic_out) {
                getPercent += 14.826;
              }
              if (item.notic_get) {
                getPercent += 14.826;
              }
              if (item.court_date) {
                getPercent += 14.826;
              }
              if (
                item.judgment_unilateral ||
                item.judgment_according_to_consent ||
                item.judgment_day
              ) {
                getPercent += 14.826;
              }
              if (item.decree) {
                getPercent += 14.826;
              }
              if (item.issue_a_warran_of_execution) {
                getPercent += 14.826;
              }

              item.persent = Math.round(getPercent);

              return createData(
                index + 1,
                item.date_start,
                item.company_code,
                `${
                  item.black_case_number && item.black_case_year
                    ? item.black_case_number + "/" + item.black_case_year
                    : ""
                }`,
                // item.document_type,
                item.defendant_fullname,
                item.persent,
                item.update_at,
                item.update_by,
                item.document_code_gen
              );
            })
          );
          // setRows(
          //   value.map((item, index) =>
          //     createData(
          //       index + 1,
          //       item.date_start,
          //       item.company_code,
          //       item.black_case_number,
          //       // item.document_type,
          //       item.defendant_fullname,
          //       item.document_code_gen,
          //       item.update_by,
          //       item.update_at
          //     )
          //   )
          // );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  // function getDocument() {
  //     setIsLoading(true);
  //     axios
  //         .get(`${config.mainAPI}/documents1/${user.user_id}/${user.user_role}`)
  //         .then((response) => {
  //             console.log(response.data.data);
  //             const value = response.data.data;
  //             setRows(
  //                 value.map((item, index) =>
  //                     createData(
  //                         index + 1,
  //                         item.date_start,
  //                         item.company_code,
  //                         item.black_case_number,
  //                         item.document_type,
  //                         item.defendant_fullname,
  //                         item.document_code_gen
  //                     )
  //                 )
  //             );
  //             setIsLoading(false);
  //         })
  //         .catch((error) => {
  //             console.error(error);
  //             setIsLoading(false);
  //         });
  // }

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  function searchDocument() {
    setIsLoading(true);
    const payload = {
      defendent: findDefendent,
      date: findDate,
      province: findProvince,
      debtCenter: findDebtCenter,
      courtCode: findCourtCode,
      companyCode: findCompanyCode,
    };
    axios
      .post(`${config.mainAPI}/document-search`, {
        ...payload,
      })
      .then(function (response) {
        const value = response.data.data;
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              item.date_start,
              item.company_code,
              item.black_case_number,
              item.document_type,
              item.defendant_fullname,
              item.update_by,
              item.update_at,
              item.document_code_gen
            )
          )
        );
        setOpenSearch(false);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setOpenSearch(false);
        setIsLoading(false);
      });

    // axios
    //     .post('${config.mainAPI}/search-documents')
    //     .then((response) => {
    //         console.log(response.data.data);
    //         const value = response.data.data;
    //         setRows(
    //             value.map((item, index) =>
    //                 createData(
    //                     index + 1,
    //                     item.date_start,
    //                     item.company_code,
    //                     item.black_case_number,
    //                     item.document_type,
    //                     item.defendant_fullname,
    //                     item.document_code_gen
    //                 )
    //             )
    //         );
    //         setIsLoading(false);
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     });
  }

  const columns = [
    { id: "order", label: "ลำดับที่", minWidth: 100 },
    { id: "date", label: "วันที่รับเรื่อง", minWidth: 100 },
    { id: "code", label: "เลขคดีบริษัท", minWidth: 100 },
    { id: "black", label: "เลขคดีดำ", minWidth: 100 },
    // { id: 'type', label: 'ประเภท', minWidth: 100 },
    { id: "defendant", label: "จำเลย", minWidth: 100 },
    {
      id: "persent",
      label: "สถานะ",
      minWidth: 100,
      render: (row) => (
        <>
          <CircularProgressWithLabel value={row.persent} />
        </>
      ),
    },
    { id: "updated", label: "แก้ไขล่าสุด", minWidth: 100 },
    { id: "updated_by", label: "ผู้แก้ไข", minWidth: 100 },
    // {
    //     id: 'file',
    //     label: 'ไฟล์',
    //     minWidth: 100,
    //     render: (row) => (
    //         <>
    //             <IconButton aria-label="approve" onClick={() => handleDownload(row.path)}>
    //                 <DownloadIcon />
    //             </IconButton>
    //         </>
    //     )
    // },
    {
      id: "detail",
      label: "รายละเอียด",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckForm(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "edit",
      label: "แก้ไข",
      minWidth: 100,
      render: (row) => (
        <>
          {/* <IconButton aria-label="approve" onClick={(() => navigate('/loan/form'), { state: { params: row.code } })}> */}
          <IconButton onClick={() => handleEditForm(row)}>
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          {/* <IconButton aria-label="approve" onClick={(() => navigate('/loan/form'), { state: { params: row.code } })}> */}
          <IconButton onClick={() => handleClickOpenDelete(row)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];
  // , type
  // , type
  function createData(
    order,
    date,
    code,
    black,
    defendant,
    persent,
    updated,
    updated_by,
    codegen,
    path
  ) {
    const formattedDate =  date ? moment(date)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY") : null;

    const formattedDateUpdate = updated ? moment(updated)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY") : null;
      
    return {
      order,
      date: formattedDate,
      code,
      black,
      defendant,
      persent,
      updated: formattedDateUpdate,
      updated_by,
      codegen,
      path,
    };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProvinceChange = (event) => {
    const value = event.target.value;
    console.log(value);
    setProvince(value);
  };

  // function handleDownload(path) {
  //     const file_path = path;
  //     const download_url = `http://localhost:6969/download-file?file_path=${file_path}`;
  //     window.location.href = download_url;
  // }

  const handleClickOpenSearch = (row) => {
    setOpenSearch(true);
  };

  const handleCloseSearch = (row) => {
    setFindDefendent("");
    setFindDate("");
    setFindProvince("");
    setFindDebtCenter("");
    setFindCourtCode("");
    setFindCompanyCode("");
    setOpenSearch(false);
  };

  const handleCreate = () => {
    // setOpenCreate(true);
    navigate("/expal/form");
  };

  const handleClickOpenDelete = (row) => {
    console.log(row.codegen);
    setCode(row.codegen);
    setOpenDelete(true);
  };

  const handleCloseDelete = (row) => {
    setCode("");
    setOpenDelete(false);
  };

  const handleClickCreate = () => {
    setOpenCreate(false);
    if (type == "สินเชื่อ" || type == "บัตรเครดิต") {
      navigate("/loan/form");
    } else if (type == "ขับไล่") {
      navigate("/expal/form");
    }
  };

  const handleClickOpenExport = (row) => {
    setOpenExport(true);
  };

  const handleCloseExport = (row) => {
    setOpenExport(false);
  };

  const handleClickExport = () => {
    setOpenExport(false);
    if (type == "สินเชื่อ") {
      navigate("/loan/form");
    } else if (type == "ขับไล่") {
      navigate("/expal/form");
    } else if (type == "บัตรเครดิต") {
      navigate("/credit/form");
    }
  };

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const handleCheckForm = (row) => {
    navigate("/expal/detail", { state: { params: row.codegen } });
  };

  const handleEditForm = (row) => {
    navigate("/expal/form", { state: { params: row.codegen } });
  };

  const handleDeleteForm = () => {
    setIsLoading(true);
    const userData = JSON.parse(localStorage.getItem("userData"));
    axios
      .post(`${config.mainAPI}/deleteDataEvication`, {
        genCode: code,
      })
      .then(function (response) {
        if (response.data.success) {
          setCode("");
          setOpenDelete(false);
          getDocument(userData);
        }
      })
      .catch(function (error) {
        setOpenDelete(false);
        setIsLoading(false);
        console.log(error);
      });
  };

  // const uploadFile = async (e) => {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('fileName', fileName);
  //     try {
  //         const res = await axios.post('http://localhost:7000/upload', formData);
  //         console.log(res);
  //         setFileName(res.data.name);
  //         setFilePath(res.data.path);
  //         setCheckFile(true);
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };

  // function handleDownload(path) {
  //     const file_path = path;
  //     const download_url = `http://localhost:7000/download-file?file_path=${file_path}`;
  //     window.location.href = download_url;
  // }

  // https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleClickCreateDefaultForm = () => {
    const genCode = `Thanic-Evication-${moment().format("YYMMDDHHmmssSS")}`;
    const payload = {
      genCode: genCode,
      user: user.username,
    };
    saveActivityLog(`Click create default form evication`);
    axios
      .post(`${config.mainAPI}/createDefaultTemplateEvication`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;
        navigate("/expal/form", { state: { params: genCode } });
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "10px" }}
        >
          รายการขับไล่
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>ค้นหา</Typography>
          <TextField
            margin="dense"
            id="search"
            name="search"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginLeft: 3, width: "75%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
            float: "right",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleDownload}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            color="primary"
            startIcon={<FileOpenIcon />}
          >
            Export Excel
          </Button>
          <Button
            variant="outlined"
            onClick={handleClickOpenSearch}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            color="primary"
            startIcon={<FindInPageIcon />}
          >
            ค้นหา
          </Button>
          {/* {user.role == 'owner' || user.role == 'user' ? (
                        <>
                            <Button
                                variant="outlined"
                                onClick={handleCreate}
                                sx={{ marginRight: '20px', marginTop: '10px', marginBottom: '20px' }}
                                color="primary"
                                startIcon={<AddCircleIcon />}
                            >
                                เพิ่มเอกสาร
                            </Button>
                        </>
                    ) : null} */}
          {/* <Button
            variant="outlined"
            onClick={handleCreate}
            color="primary"
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มเอกสาร
          </Button> */}

<Button
            variant="outlined"
            onClick={handleClickCreateDefaultForm}
            color="primary"
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มเอกสาร
          </Button>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Dialog open={openSearch} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseSearch}
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              ค้นหาเอกสาร
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>จำเลย</Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindDefendent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>วันที่</Typography>
                <TextField
                  type="date"
                  color="dark"
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setFindDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>จังหวัด</Typography>
                <Select
                  value={province}
                  onChange={handleProvinceChange}
                  variant="outlined"
                  fullWidth
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {listProvince &&
                    listProvince.map((column) => (
                      <MenuItem key={column.id} value={column.name_th}>
                        {column.name_th}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เขตศูนย์หนี้</Typography>
                <TextField
                  type="text"
                  color="dark"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => setFindDebtCenter(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เลขคดีของศาล</Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindCourtCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>
                  เลขคดีของบริษัท
                </Typography>
                <TextField
                  type="text"
                  color="dark"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => setFindCompanyCode(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseSearch}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={searchDocument}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={openExport} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseExport}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              Export Excel
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>ประเภทคดี</Typography>
                <Select
                  onChange={(e) => setType(e.target.value)}
                  variant="outlined"
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <MenuItem value="สินเชื่อ">สินเชื่อ</MenuItem>
                  <MenuItem value="ขับไล่">ขับไล่</MenuItem>
                  <MenuItem value="บัตรเครดิต">บัตรเครดิต</MenuItem>
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseExport}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickExport}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={openDelete} fullWidth maxWidth={"xs"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDelete}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box textAlign="center">
              <ErrorIcon sx={{ color: "#ff2929", fontSize: 100 }} />
            </Box>
            <Typography
              variant="h1"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              คำเตือน
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#1f1f1f",
              }}
            >
              หากลบแล้วเอกสารจะหายไป คุณต้องการที่จะลบเอกสารนี้ใช่หรือไม่?
            </Typography>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ mr: 3 }}
                onClick={handleDeleteForm}
              >
                ลบ
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseDelete}
              >
                ปิด
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Card>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsExpal;
