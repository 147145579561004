import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import axios from "axios";
import api from "../../../api";
import config from "../../../config";
import {
  TextField,
  Card,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import useAuth from "function/useAuth";
const Notification = () => {
  const [user, setUser] = useState();
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  useAuth();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    getData(JSON.parse(userData));
  }, []);

  function getData(user) {
    // if (
    //   user.role.toUpperCase() == "ADMIN" ||
    //   user.role.toUpperCase() == "OWNER" ||
    //   user.role.toUpperCase() == "administrative"
    // ) {
    //   let payload = {
    //     role: user.role,
    //     username: user.username,
    //   };
    //   api
    //     .post(`${config.mainAPI}/listNotificationByAdmin`, { payload })
    //     .then((response) => {
    //       const value = response.data.data;
    //       console.log(response);
    //       if (value) {
    //         setRows(
    //           value.map((item, index) =>
    //             createData(
    //               index + 1,
    //               item.notification_date,
    //               item.notification_detail
    //             )
    //           )
    //         );
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // } else {
    //   let payload = {
    //     role: user.role,
    //     username: user.username,
    //   };
    //   api
    //     .post(`${config.mainAPI}/listNotificationByUser`, { payload })
    //     .then((response) => {
    //       const value = response.data.data;
    //       console.log(response);
    //       if (value) {
    //         setRows(
    //           value.map((item, index) =>
    //             createData(
    //               index + 1,
    //               item.notification_date,
    //               item.notification_detail
    //             )
    //           )
    //         );
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }

    let payload = {
      role: user.role,
      username: user.username,
    };
    api
      .post(`${config.mainAPI}/listNotificationByUser`, { ...payload })
      .then((response) => {
        const value = response.data.data;
        console.log(response);
        if (value) {
          setRows(
            value.map((item, index) =>
              createData(index + 1, item.detail, item.created_date)
            )
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const columns = [
    { id: "order", label: "ลำดับที่", maxWidth: 100 },
    { id: "topic", label: "รายละเอียด", minWidth: 100 },
    { id: "date", label: "วันที่", maxWidth: 100 },
  ];

  function createData(order, topic, date) {
    const formattedDate = date
      ? moment(date).locale("th").add(543, "year").format("DD/MM/YYYY HH:mm")
      : null;
    return { order, topic, date: formattedDate };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const readAll = () => {
    const hospital = user.hospital_id;
    const role = user.role_status;
    api
      .post(`${config.mainAPI}/notification/read-all`, {
        hospital: hospital,
        role: role,
      })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
      <Typography
        variant="h3"
        sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
      >
        การแจ้งเตือน
      </Typography>
      <Button
        variant="outlined"
        onClick={readAll}
        sx={{
          float: "right",
          marginRight: "20px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
        color="success"
        endIcon={<DoneAllIcon />}
      >
        อ่านทั้งหมด
      </Button>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.order}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="left">
                      {column.render ? column.render(row) : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Card>
  );
};

export default Notification;
