import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import {
  TextField,
  Card,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ArticleIcon from "@mui/icons-material/Article";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import UnpublishedRoundedIcon from "@mui/icons-material/UnpublishedRounded";
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useAuth from "function/useAuth";
import * as XLSX from "xlsx";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import config from "../../../config";
import api from "../../../api";
import { FileUploader } from "react-drag-drop-files";
import { WatDatePicker } from "thaidatepicker-react";

const DocumentsCreadit = () => {
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [document, setDocument] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [province, setProvince] = useState("");
  const [listProvince, setListProvince] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [checkFile, setCheckFile] = useState(false);
  const [type, setType] = useState("");
  const [fileExport, setFileExport] = useState("");
  const [code, setCode] = useState("");
  const [nameCreate, setNameCreate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [countCard, setCountCard] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [debtCenterList, setDebtCenterList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [exportSelected, setExportSelected] = useState("");
  const [exportDate, setExportDate] = useState("");
  const [listNoticePerson, setListNoticePerson] = useState([]);
  const [selectedNoticePerson, setSelectedNoticePerson] = useState([]);

  // Search
  const [findDefendent, setFindDefendent] = useState("");
  const [findDate, setFindDate] = useState("");
  const [findProvince, setFindProvince] = useState("");
  const [findDebtCenter, setFindDebtCenter] = useState("");
  const [findCourtCode, setFindCourtCode] = useState("");
  const [findCompanyCode, setFindCompanyCode] = useState("");

  const fileTypes = ["XLSX", "XLS"];

  useAuth();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    // console.log("usereieina", userData);
    if (userData) {
      setUser(userData);
      getDocument(userData);
      getListExport();
    }
  }, []);
  const navigate = useNavigate();

  const handleChooseNotice = (event, newValue) => {
    const validItems = newValue.filter((option) => option != null); // Ensure no undefined items
    const newValuesIds = validItems.map((option) => option.id);
    console.log("newValuesIds", newValuesIds);
    setSelectedNoticePerson(newValuesIds);
  };

  function getDdlDebtCenter() {
    setIsLoading(true);
    api
      .get(`${config.mainAPI}/debtCenter`)
      .then((response) => {
        setDebtCenterList(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function handleDownload() {
    setIsLoading(true);
    api
      .get(`${config.mainAPI}/export-excel-creadit`)
      .then((response) => {
        const download_url = `${config.mainAPI}/download-documents-excel-creadit`;
        window.location.href = download_url;
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  const handleUploadChange = (file) => {
    setFileUpload(file);
    console.log(file);
  };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    console.log("christDate", christDate);
    setData(christDate);
  };

  const handleSubmitFileUpload = async () => {
    if (fileUpload) {
      console.log("fileUpload", fileUpload);
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("type", "import");
      formData.append("type_docs", "import");
      formData.append("user", user.username);
      console.log("ttttt");
      try {
        const res = await api.post(
          `${config.mainAPI}/uploadFileImportLoan`,
          formData
        );
        saveActivityLog(`Upload File:${fileUpload.name} Success`);
        setFileUpload(null);
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
      }
    }
  };

  // แก้ไขให้เป็นของบัตรเครดิต
  function getDocument(userData) {
    setIsLoading(true);
    api
      .get(`${config.mainAPI}/credit-working/${userData.id}/${userData.role}`)
      .then((response) => {
        console.log(response.data.data);
        const value = response.data.data;
        if (value) {
          let getPercent = 0;
          setRows(
            value.map((item, index) => {
              let getPercent = 0;
              if (item.notic_out) {
                getPercent += 14.826;
              }
              if (item.notic_get) {
                getPercent += 14.826;
              }
              if (item.court_date) {
                getPercent += 14.826;
              }
              if (
                item.judgment_unilateral ||
                item.judgment_according_to_consent ||
                item.judgment_day
              ) {
                getPercent += 14.826;
              }
              if (item.decree) {
                getPercent += 14.826;
              }
              if (item.issue_a_warran_of_execution) {
                getPercent += 14.826;
              }

              item.persent = Math.round(getPercent);

              return createData(
                index + 1,
                item.date_start,
                item.company_code,
                `${
                  item.black_case_number && item.black_case_year
                    ? item.black_case_number + "/" + item.black_case_year
                    : ""
                }`,
                item.defendant_fullname,
                item.persent,
                item.update_at,
                item.update_by,
                item.document_code_gen
              );
            })
          );
        }
        // setRows(
        //   value.map((item, index) =>
        //     createData(
        //       index + 1,
        //       item.date_start,
        //       item.company_code,
        //       item.black_case_number,
        //       item.defendant,
        //       item.document_code_gen
        //     )
        //   )
        // );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  const getListExport = () => {
    setIsLoading(true);
    api
      .post(`${config.mainAPI}/getConfigValue`, {
        value: "TYPE_OF_EXPORT_CREDIT",
      })
      .then((response) => {
        setExportList(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  function importDocument() {
    setIsLoading(true);
    setOpenImport(false);
  }

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  function searchDocument() {
    setIsLoading(true);
    const payload = {
      defendent: findDefendent,
      // date: findDate,
      // province: findProvince,
      debtCenter: findDebtCenter,
      courtCode: findCourtCode,
      companyCode: findCompanyCode,
      defendantAccountNumber: defendantAccountNumber,
      user: userData,
    };
    axios
      .post(`${config.mainAPI}/document-search`, {
        ...payload,
      })
      .then(function (response) {
        const value = response.data.data;
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              item.date_start,
              item.company_code,
              item.black_case_number,
              item.defendant,
              item.document_code_gen
            )
          )
        );
        setOpenSearch(false);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setOpenSearch(false);
        setIsLoading(false);
      });

    // axios
    //     .post('${config.mainAPI}/search-documents')
    //     .then((response) => {
    //         console.log(response.data.data);
    //         const value = response.data.data;
    //         setRows(
    //             value.map((item, index) =>
    //                 createData(
    //                     index + 1,
    //                     item.date_start,
    //                     item.company_code,
    //                     item.black_case_number,
    //                     item.document_type,
    //                     item.defendant_fullname,
    //                     item.document_code_gen
    //                 )
    //             )
    //         );
    //         setIsLoading(false);
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     });
  }

  const columns = [
    { id: "order", label: "ลำดับที่", minWidth: 100 },
    { id: "date", label: "วันที่รับเรื่อง", minWidth: 100 },
    { id: "code", label: "เลขคดีบริษัท", minWidth: 100 },
    { id: "black", label: "เลขคดีดำ", minWidth: 100 },
    { id: "defendant", label: "จำเลย", minWidth: 100 },
    {
      id: "persent",
      label: "สถานะ",
      minWidth: 100,
      render: (row) => (
        <>
          <CircularProgressWithLabel value={row.persent} />
        </>
      ),
    },
    { id: "updated", label: "แก้ไขล่าสุด", minWidth: 100 },
    { id: "updated_by", label: "ผู้แก้ไข", minWidth: 100 },
    // {
    //     id: 'file',
    //     label: 'ไฟล์',
    //     minWidth: 100,
    //     render: (row) => (
    //         <>
    //             <IconButton aria-label="approve" onClick={() => handleDownload(row.path)}>
    //                 <DownloadIcon />
    //             </IconButton>
    //         </>
    //     )
    // },
    {
      id: "detail",
      label: "รายละเอียด",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckForm(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "edit",
      label: "แก้ไข",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleEditForm(row)}>
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "pdf",
      label: "คำฟ้อง",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            onClick={() => handleGenReport(row)}
            disabled={!row.defendant}
          >
            <PictureAsPdfIcon color={row.defendant ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          {/* <IconButton aria-label="approve" onClick={(() => navigate('/loan/form'), { state: { params: row.code } })}> */}
          <IconButton onClick={() => handleClickOpenDelete(row)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  function createData(
    order,
    date,
    code,
    black,
    defendant,
    persent,
    updated,
    updated_by,
    codegen,
    path
  ) {
    const formattedDate = moment(date)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY");
    const formattedDateUpdate = moment(updated)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY");
    return {
      order,
      date: date ? formattedDate : null,
      code,
      black,
      defendant,
      persent,
      updated: formattedDateUpdate,
      updated_by,
      codegen,
      path,
    };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProvinceChange = (event) => {
    const value = event.target.value;
    console.log(value);
    setProvince(value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      const filterData = parsedData.filter(
        (item) => item.prefix && item.name && item.lastname
      );
      console.log("filterData", filterData);
      setData(filterData);
    };
  };

  function uploadImportExcel() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    axios
      .post(`${config.mainAPI}/importFileCredit`, {
        data,
      })
      .then(function (response) {
        setIsLoading(false);
        setOpenImport(false);
        getDocument(userData);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setOpenImport(false);
      });
  }

  // function handleDownload(path) {
  //     const file_path = path;
  //     const download_url = `http://localhost:6969/download-file?file_path=${file_path}`;
  //     window.location.href = download_url;
  // }

  const handleClickOpenSearch = (row) => {
    setOpenSearch(true);
    getDdlDebtCenter();
  };

  const handleCloseSearch = (row) => {
    setFindDefendent("");
    setFindDate("");
    setFindProvince("");
    setFindDebtCenter("");
    setFindCourtCode("");
    setFindCompanyCode("");
    setOpenSearch(false);
  };

  const handleClickOpenImport = (row) => {
    setOpenImport(true);
    setCountCard(null);
    setFileUpload(null);
  };

  const handleCloseImport = (row) => {
    // setData([]);
    // setOpenImport(false);
    // setCountCard(null);
    // setFileUpload(null);
    setOpenImport(false);
    setFileUpload(null);
  };

  const handleClickOpenCreate = () => {
    navigate("/credit/form");
  };

  const handleCloseCreate = (row) => {
    setOpenCreate(false);
  };

  const handleClickOpenDelete = (row) => {
    console.log(row.codegen);
    setCode(row.codegen);
    setOpenDelete(true);
  };

  const handleCloseDelete = (row) => {
    setCode("");
    setOpenDelete(false);
  };

  const handleClickCreate = () => {
    setOpenCreate(false);
    if (type == "สินเชื่อ" || type == "บัตรเครดิต") {
      navigate("/loan/form");
    } else if (type == "ขับไล่") {
      navigate("/expal/form");
    }
  };

  const handleClickOpenExport = (row) => {
    setOpenExport(true);
    setIsLoading(true);
    api
      .get(`${config.mainAPI}/listNoticePersonCredit`)
      .then((response) => {
        setListNoticePerson(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleCloseExport = (row) => {
    setOpenExport(false);
    setExportSelected("");
    setExportDate("");
    setSelectedNoticePerson([]);
    setOpenExport(false);
  };

  const handleClickExport = () => {
    const payload = {
      selected: selectedNoticePerson,
      date: exportDate,
    };
    setOpenExport(false);
    api
      .post(`${config.mainAPI}/credit/notice/exportNoticeFullModeCredit`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response", response);
        const file_path = response.data.path;
        const download_url = `${config.mainAPI}/downloadFileCheck?path=${file_path}`;
        window.location.href = download_url;
        setExportDate("");
        setExportSelected("");
        setSelectedNoticePerson([]);
        setOpenExport(false);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const handleCheckForm = (row) => {
    navigate("/credit/detail", { state: { params: row.codegen } });
  };

  const handleEditForm = (row) => {
    navigate("/credit/form", { state: { params: row.codegen } });
  };

  const handleGenReport = (row) => {
    console.log("genReport");
    console.log("row.codegen", row.codegen);
    axios
      .get(`${config.mainAPI}/export-credit-pdf/${row.codegen}`)
      .then(function (response) {
        const download_url = `${config.mainAPI}/download-pdf-credit/${row.codegen}`;
        window.location.href = download_url;
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });

    // setIsLoading(true);
    // navigate("/credit/form", { state: { params: row.codegen } });
  };

  const handleDeleteForm = () => {
    setIsLoading(true);
    const userData = JSON.parse(localStorage.getItem("userData"));
    api
      .post(`${config.mainAPI}/deleteDataCredit`, {
        genCode: code,
      })
      .then(function (response) {
        if (response.data.success) {
          setCode("");
          setOpenDelete(false);
          getDocument(userData);
        }
      })
      .catch(function (error) {
        setOpenDelete(false);
        setIsLoading(false);
        console.log(error);
      });
  };

  // const uploadFile = async (e) => {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('fileName', fileName);
  //     try {
  //         const res = await axios.post('http://localhost:7000/upload', formData);
  //         console.log(res);
  //         setFileName(res.data.name);
  //         setFilePath(res.data.path);
  //         setCheckFile(true);
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };

  // function handleDownload(path) {
  //     const file_path = path;
  //     const download_url = `http://localhost:7000/download-file?file_path=${file_path}`;
  //     window.location.href = download_url;
  // }

  // https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  function handleDownloadTemplate(type) {
    if (type == "all") {
      // api
      //   .post(`${config.mainAPI}/upadteTemplateLoan`)
      //   .then((response) => {
      //     if (response.data.success) {
      //       const download_url = `${config.mainAPI}/download-template-loan-update-data`;
      //       // console.log("download_url", download_url);
      //       window.location.href = download_url;
      //       setIsLoading(false);
      //     } else {
      //       console.error("upadteTemplateLoan Fail");
      //       setIsLoading(false);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     setIsLoading(false);
      //   });
      const download_url = `${config.mainAPI}/download-credit-all`;
      window.location.href = download_url;
    } else if (type == "empty") {
      const download_url = `${config.mainAPI}/download-credit-all`;
      window.location.href = download_url;
    }
  }

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleClickCreateDefaultForm = () => {
    const genCode = `Thanic-Credit-${moment().format("YYMMDDHHmmssSS")}`;
    const payload = {
      genCode: genCode,
      user: user.username,
    };
    saveActivityLog(`Click create default form credit`);
    axios
      .post(`${config.mainAPI}/createDefaultTemplateCredit`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;
        navigate("/credit/form", { state: { params: genCode } });
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "10px" }}
        >
          รายการบัตรเครดิต
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>ค้นหา</Typography>
          <TextField
            margin="dense"
            id="search"
            name="search"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginLeft: 3, width: "75%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
            float: "right",
          }}
        >
          {/* <Button
            variant="outlined"
            onClick={handleDownload}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            color="primary"
            startIcon={<FileOpenIcon />}
          >
            Export Excel
          </Button> */}
          <Button
            variant="outlined"
            onClick={handleClickOpenExport}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            color="primary"
            startIcon={<FileOpenIcon />}
          >
            Export
          </Button>

          {/* {user.role == 'owner' || user.role == 'user' ? (
                        <>
                            <Button
                                variant="outlined"
                                onClick={handleClickOpenCreate}
                                sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
          borderColor: "#000",
            }}
                                color="primary"
                                startIcon={<AddCircleIcon />}
                            >
                                เพิ่มเอกสาร
                            </Button>
                        </>
                    ) : null} */}
          <Button
            variant="outlined"
            onClick={handleClickOpenImport}
            color="primary"
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<UploadFileIcon />}
          >
            Import Excel
          </Button>
          <Button
            variant="outlined"
            onClick={handleClickOpenSearch}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            color="primary"
            startIcon={<FindInPageIcon />}
          >
            ค้นหา
          </Button>
          {/* <Button
            variant="outlined"
            onClick={handleClickOpenCreate}
            color="primary"
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มเอกสาร
          </Button> */}
          <Button
            variant="outlined"
            onClick={handleClickCreateDefaultForm}
            color="primary"
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มเอกสาร
          </Button>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        {/* <Dialog open={openSearch} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseSearch}
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              ค้นหาเอกสาร
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>จำเลย</Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindDefendent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>วันที่</Typography>
                <TextField
                  type="date"
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setFindDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>จังหวัด</Typography>
                <Select
                  value={province}
                  onChange={handleProvinceChange}
                  variant="outlined"
                  fullWidth
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {listProvince &&
                    listProvince.map((column) => (
                      <MenuItem key={column.id} value={column.name_th}>
                        {column.name_th}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เขตศูนย์หนี้</Typography>
                <TextField
                  type="text"
                  color="dark"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => setFindDebtCenter(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เลขคดีของศาล</Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindCourtCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>
                  เลขคดีของบริษัท
                </Typography>
                <TextField
                  type="text"
                  color="dark"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => setFindCompanyCode(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseSearch}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={searchDocument}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog> */}

        <Dialog open={openSearch} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseSearch}
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              ค้นหาเอกสาร
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>จำเลย:</Typography>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindDefendent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เลขที่บัญชี:</Typography>
                <TextField
                  type="text"
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDefendantAccountNumber(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>ศูนย์หนี้:</Typography>
                <Select
                  value={findDebtCenter}
                  onChange={(e) => setFindDebtCenter(e.target.value)}
                  variant="outlined"
                  fullWidth
                  // color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {debtCenterList &&
                    debtCenterList.map((column) => (
                      <MenuItem
                        key={column.debt_center_name}
                        value={column.debt_center_name}
                      >
                        {column.debt_center_name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เลขคดีศาล:</Typography>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindCourtCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เลขคดีบริษัท:</Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => setFindCompanyCode(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseSearch}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={searchDocument}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* <Dialog open={openImport} fullWidth maxWidth={"sm"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseImport}
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              อัพโหลดเอกสาร
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <div style={{ float: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadTemplate}
                    sx={{ p: 1 }}
                    endIcon={<CloudDownloadIcon />}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      ดาวน์โหลดเทมเพลต
                    </Typography>
                  </Button>
                </div>
                <Typography sx={{ fontSize: "16px", mb: 3, mt: 2 }}>
                  ระบุเอกสารที่ต้องการอัพโหลด:
                </Typography>
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                />

              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseImport}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={uploadImportExcel}
                  disabled={data.length == 0}
                  endIcon={<CloudUploadIcon />}
                >
                  อัพโหลด
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
          </DialogContent>
        </Dialog> */}

        <Dialog open={openImport} fullWidth maxWidth={"sm"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseImport}
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              อัพโหลดไฟล์
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <div style={{ float: "right" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownloadTemplate("all")}
                        sx={{ p: 1 }}
                        endIcon={<ArticleIcon />}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          ตัวอย่าง
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownloadTemplate("empty")}
                        sx={{ p: 1 }}
                        endIcon={<CloudDownloadIcon />}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          เทมเพลต
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
                <Typography sx={{ fontSize: "16px", mb: 3, mt: 2 }}>
                  ไฟล์อัพโหลด:
                </Typography>
                <FileUploader
                  multiple={false}
                  handleChange={handleUploadChange}
                  name="fileUpload"
                  types={fileTypes}
                />
                <p style={{ marginTop: "1rem" }}>
                  {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                  {fileUpload ? (
                    <>
                      <IconButton onClick={handleClearFileUpload}>
                        <DeleteForeverIcon color="error" />
                      </IconButton>
                    </>
                  ) : null}
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseImport}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSubmitFileUpload}
                  disabled={data.length == 0}
                  endIcon={<CloudUploadIcon />}
                >
                  อัพโหลด
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              ></Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={openCreate} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseCreate}
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              เพิ่มเอกสาร
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>ประเภทคดี</Typography>
                <Select
                  value={type || ""}
                  onChange={(e) => setType(e.target.value)}
                  variant="outlined"
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <MenuItem value="สินเชื่อ">สินเชื่อ</MenuItem>
                  <MenuItem value="ขับไล่">ขับไล่</MenuItem>
                  <MenuItem value="บัตรเครดิต">บัตรเครดิต</MenuItem>
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseCreate}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickCreate}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={openExport} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseExport}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              Export
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent
            sx={{
              height: "50vh",
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>ประเภท:</Typography>
                <Select
                  value={exportSelected}
                  onChange={(e) => setExportSelected(e.target.value)}
                  variant="outlined"
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  {exportList.map((label, index) => (
                    <MenuItem value={label.config_value1}>
                      {label.config_value1}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                {exportSelected ? (
                  <>
                    <Typography sx={{ fontSize: "16px" }}>วันที่:</Typography>

                    <WatDatePicker
                      className="custom-date-picker"
                      value={exportDate}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setExportDate,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </>
                ) : null}
              </Grid>
            </Grid>
            {exportSelected == "โนติส" ? (
              <>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} md={6}>
                    <Typography sx={{ mt: 2 }}>รายชื่อลูกหนี้:</Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      options={listNoticePerson}
                      value={listNoticePerson.filter((option) =>
                        selectedNoticePerson.includes(option.id)
                      )}
                      onChange={handleChooseNotice}
                      renderInput={(params) => (
                        <TextField {...params} size="medium" />
                      )}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}

            <Grid container spacing={2} justifyContent="center">
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseExport}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickExport}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={openDelete} fullWidth maxWidth={"xs"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDelete}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box textAlign="center">
              <ErrorIcon sx={{ color: "#ff2929", fontSize: 100 }} />
            </Box>
            <Typography
              variant="h1"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              คำเตือน
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#1f1f1f",
              }}
            >
              หากลบแล้วเอกสารจะหายไป คุณต้องการที่จะลบเอกสารนี้ใช่หรือไม่?
            </Typography>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ mr: 3 }}
                onClick={handleDeleteForm}
              >
                ลบ
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseDelete}
              >
                ปิด
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Card>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsCreadit;
