// project imports
import MinimalLayout from "layout/MinimalLayout";

// login option 3 routing
import Login from "views/pages/auth/Login";
import PageNotFound from "views/pages/others/PageNotFound";

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "*",
      element: <PageNotFound />, // Replace with your actual "404 Not Found" component
    },
    {
      path: "",
      element: <PageNotFound />, // Replace with your actual "404 Not Found" component
    },
  ],
};

export default LoginRoutes;
