const config = {
  basename: "/",
  defaultPath: "/home",
  fontFamily: `'Sarabun', sans-serif`,
  mainAPI: process.env.REACT_APP_MAIN_API || "http://localhost:6969/api",
  mainURL: process.env.REACT_APP_MAIN_URL || "http://localhost:3000",
  // mainAPI: "http://localhost:6969/api",
  // mainURL: "http://localhost:3000",
  // mainAPI: "https://api.thanic-legal.com/api",
  // mainURL: "https://thanic-legal.com",
};

export default config;
