import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RMIUploader } from "react-multiple-image-uploader";
import styles from "./styles/styles.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import path from "path-browserify";

import Slider from "react-slick";
import { Magnifier } from "react-image-magnifiers";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Paper,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";
import axios from "axios";
import moment from "moment/moment";
import provincesData from "../../../components/province.json";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import useAuth from "function/useAuth";
import MomentUtils from "@date-io/moment";
import "moment/locale/th";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PaidIcon from "@mui/icons-material/Paid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import config from "../../../config";
import api from "../../../api";
import { FileUploader } from "react-drag-drop-files";
import ClearIcon from "@mui/icons-material/Clear";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CollectionsIcon from "@mui/icons-material/Collections";

const DocumentsFormExpel = () => {
  const [user, setUser] = useState();
  const [userCompany, setUserCompany] = useState(null);
  const { state } = useLocation();
  const params = state ? state.params : null;
  const genCode =
    params != null
      ? params
      : `Thanic-Expal-${moment().format("YYMMDDHHmmssSS")}`;
  const [isLoading, setIsLoading] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  // List Data
  const [debtCenterList, setDebtCenterList] = useState([]);
  const [prefixList, setPrefixList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [gsbNumber, setGsbNumber] = useState("");
  const [type, setType] = useState("");
  const [finish, setFinish] = useState("");
  const [prefix, setPrefix] = useState(""); // คำนำหน้า
  const [firstName, setFirstName] = useState(""); // ชื่อจริง
  const [lastName, setLastName] = useState(""); // นามสกุล
  const [idCard, setIdCard] = useState(""); // นามสกุล
  const [nameCompany, setNameCompany] = useState(""); // ชื่อบริษัท
  const [debtCenter, setDebtCenter] = useState(""); // ศูนย์หนี้
  const [court, setCourt] = useState(""); // ศาล
  const [blackCaseNumber, setBlackCaseNumber] = useState(""); // เลขคดีดำเลขที่
  const [blackCaseYear, setBlackCaseYear] = useState(""); // เลขคดีดำเลขที่
  const [redCaseNumber, setRedCaseNumber] = useState(""); // เลขคดีแดงเลขที่
  const [redCaseYear, setRedCaseYear] = useState(""); // เลขคดีแดงเลขที่
  const [tambon, setTambon] = useState("");
  const [amphoe, setAmphoe] = useState("");
  const [province, setProvince] = useState("");
  const [tambonName, setTambonName] = useState("");
  const [amphoeName, setAmphoeName] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [fileNumber, setFileNumber] = useState(""); // เลขแฟ้ม
  const [dateStart, setDateStart] = useState(null); // วันที่รับเรื่อง
  const [deadline, setDeadline] = useState(null); // วันกำหนดส่ง
  const [propertyCode, setPropertyCode] = useState(""); // รหัสทรัพย์
  const [sector, setSector] = useState(""); // ภาค
  const [issueAWarranOfExecutionDate, setIssueAWarranOfExecutionDate] =
    useState(null); // วันขอออกหมายบังคับคดี
  const [executionOfficerDate, setExecutionOfficerDate] = useState(null); // วันนัดเจ้าหน้าที่บังคับคดี
  const [issueAnArrestWarrant, setIssueAnArrestWarrant] = useState(null); // วันขอออกหมายจับ
  const [debtorsAndDependentsDayOut, setDebtorsAndDependentsDayOut] =
    useState(null); // วันลูกหนี้และบริวารออก
  const [licenseDocument, setLicenseDocument] = useState(""); // เอกสารสิทธิ์
  const [licenseDocumentCode, setLicenseDocumentCode] = useState(""); // เอกสารสิทธิ์เลขที่
  const [caseStatus, setCaseStatus] = useState(""); // สถานะคดีปัจจุบัน
  const [note, setNote] = useState("");
  const [chooseMemberCompany, setChooseMemberCompany] = useState(""); // ไอดีผู้รับผิดชอบคดี
  const [chooseMemberCompanyName, setChooseMemberCompanyName] = useState(""); // ผู้รับผิดชอบคดี
  const [chooseMemberCompanyNow, setChooseMemberCompanyNow] = useState(""); // ไอดีผู้รับผิดชอบคดีปัจจุบัน
  const [chooseMemberCompanyNameNow, setChooseMemberCompanyNameNow] =
    useState(""); // ผู้รับผิดชอบคดีปัจจุบัน
  const [countDefendant, setCountDefendant] = useState(0); // จำนวนผู้ค้ำประกัน
  const [coDefendantPrefix, setCoDefendantPrefix] = useState([]); // คำนำหน้าผู้ค้ำประกัน
  const [coDefendantName, setCoDefendantName] = useState([]); // ชื่อผู้ค้ำประกัน
  const [coDefendantLastName, setCoDefendantLastName] = useState([]); // นามสกุลผู้ค้ำประกัน
  const [coDefendantAddress, setCoDefendantAddress] = useState([]); // ที่อยู่ผู้ค้ำประกัน
  const [coDefendantIdCard, setCoDefendantIdCard] = useState([]); // บัตรประชาชนผู้ค้ำประกัน
  const [coDefendantStatus, setCoDefendantStatus] = useState([]); // สถานะผู้ค้ำประกัน
  const [statusDefendant, setStatusDefendant] = useState([]);
  const [addressCoDefendant, setAddressCoDefendant] = useState([]);
  const [prefixDefendantAgent, setPrefixDefendantAgent] = useState([]); // ชื่อตัวแทนผู้ค้ำประกัน
  const [nameDefendantAgent, setNameDefendantAgent] = useState([]); // ชื่อตัวแทนผู้ค้ำประกัน
  const [lastNameDefendantAgent, setLastNameDefendantAgent] = useState([]); // นามสกุลตัวแทนผู้ค้ำประกัน
  const [idCardDefendantAgent, setIdCardDefendantAgent] = useState([]); // รหัสบัตรประชาชนตัวแทนผู้ค้ำประกัน
  const [addressDefendantAgent, setAddressDefendantAgent] = useState([]); // ที่อยู่ตัวแทนผู้ค้ำประกัน
  const [statusDefendantAgent, setStatusDefendantAgent] = useState([]); // สถานะตัวแทนผู้ค้ำประกัน
  const [courtCode, setCourtCode] = useState(""); // เลขคดีศาล
  const [companyCode, setCompanyCode] = useState(""); // รายละเอียดสถานที่
  const [locationDetail, setLocationDetail] = useState(""); // เลขคดีบริษัท
  const [titleDeed, setTitleDeed] = useState(""); // โฉนดที่ดิน
  const [landNumber, setLandNumber] = useState(""); // เลขที่ที่ดิน
  const [explorePage, setExplorePage] = useState(""); // หน้าสำรวจ
  const [googleMap, setGoogleMap] = useState(""); // googleMap
  const [bookingDate, setBookingDate] = useState(null); // วันนัด
  const [bookingTime, setBookingTime] = useState(null); // เวลานัด
  const [staffConnectionName, setStaffConnectionName] = useState(""); // ผู้ที่ติดต่อ
  const [staffConnectionPhone, setStaffConnectionPhone] = useState(""); // เบอร์ติดต่อผู้เกี่ยวข้อง
  const [phone, setPhone] = useState(""); // โทรศัพท์
  const [phone1, setPhone1] = useState(""); // โทรสาร
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [fileImg, setFileImg] = useState("");
  const [checkFile, setCheckFile] = useState(false);
  const [courtDate, setCourtDate] = useState(null);
  const [courtTime, setCourtTime] = useState(null);
  const [courtList, setCourtList] = useState([]);
  const [countCourtEtc, setCountCourtEtc] = useState(0);
  const [countLoan, setCountLoan] = useState(1);
  // ขึ้นศาลเพิ่มเติม
  const [dateGoCourtEtc, setDateGoCourtEtc] = useState([]); // วันที่ขึ้นศาลครั้งนั้น
  const [timeGoCourtEtc, setTimeGoCourtEtc] = useState([]); // เวลาขึ้นศาลครั้งนั้น
  const [lawyerGoCourtEtc, setLawyerGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [lawyerNameGoCourtEtc, setLawyerNameGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [detailGoCourtEtc, setDetailGoCourtEtc] = useState([]); // รายละเอียดขึ้นศาลครั้งนั้น

  // Defendant
  const [defendantHouseNumber, setDefendantHouseNumber] = useState("");
  const [defendantGroup, setDefendantGroup] = useState("");
  const [defendantRoad, setDefendantRoad] = useState("");
  const [defendantSoi, setDefendantSoi] = useState("");
  const [defendantAddress, setDefendantAddress] = useState("");
  const [defendantTambon, setDefendantTambon] = useState("");
  const [defendantAmphoe, setDefendantAmphoe] = useState("");
  const [defendantProvince, setDefendantProvince] = useState("");
  const [defendantTambonName, setDefendantTambonName] = useState("");
  const [defendantAmphoeName, setDefendantAmphoeName] = useState("");
  const [defendantProvinceName, setDefendantProvinceName] = useState("");
  const [defendantZipcode, setDefendantZipcode] = useState("");

  // Place
  const [placeHouseNumber, setPlaceHouseNumber] = useState("");
  const [placeGroup, setPlaceGroup] = useState("");
  const [placeRoad, setPlaceRoad] = useState("");
  const [placeSoi, setPlaceSoi] = useState("");
  const [placeAddress, setPlaceAddress] = useState("");
  const [placeTambon, setPlaceTambon] = useState("");
  const [placeAmphoe, setPlaceAmphoe] = useState("");
  const [placeProvince, setPlaceProvince] = useState("");
  const [placeTambonName, setPlaceTambonName] = useState("");
  const [placeAmphoeName, setPlaceAmphoeName] = useState("");
  const [placeProvinceName, setPlaceProvinceName] = useState("");
  const [placeZipcode, setPlaceZipcode] = useState("");
  const [openSetNotifition, setOpenSetNotifition] = useState(false);
  const [openCloseCase, setOpenCloseCase] = useState(false);

  const [imageList, setImageList] = useState([]);

  const defaultCodeCompany = `TH${fileNumber}`;

  const [typeDocsUpload, setTypeDocsUpload] = useState("");
  const [topicDocsUpload, setTopicDocsUpload] = useState("");
  //   const [typeDocsList, setTypeDocsList] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileImageUpload, setFileImageUpload] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsImages, setRowsImages] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows1, setRows1] = useState([]);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(20);
  const [fileData, setFileData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [listFileUpdateSelected, setListFileUpdateSelected] = useState(null);
  const [typeSelected, setTypeSelected] = useState("");
  const [openUploadFile, setOpenUploadFile] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [countAssets, setCountAssets] = useState(1);
  const [loanTypeList, setLoanTypeList] = useState([]); // ประเภทสินเชื่อ
  const [typeOfCollateralList, setTypeOfCollateralList] = useState([]); // ประเภทหลักประกัน
  const [noticeDayOutList, setNoticeDayOutList] = useState([]); // วันออกโนติส
  const [noticeDayGetList, setNoticeDayGetList] = useState([]); // วันรับโนติส
  const [noticeInvestigateList, setNoticeInvestigateList] = useState([]); // สอบสวนโนติส
  const [principalList, setPrincipalList] = useState([]); // เงินต้น
  const [interestList, setInterestList] = useState([]); // ดอกเบี้ย
  const [gsbNumberList, setGsbNumberList] = useState([]); // เลขสัญญา
  const [dateCalInterestSinceList, setDateCalInterestSinceList] = useState([]); //
  const [countDateList, setCountDateList] = useState([]); //

  // led
  const [executionCase, setExecutionCase] = useState("");
  const [executionId, setExecutionId] = useState("");

  const [bringTheRegulations, setBringTheRegulations] = useState(null);
  const [writOfExecutionDate, setWritOfExecutionDate] = useState(null);
  const [images, setImages] = useState([]);
  // const [completed, setCompleted] = useState({});
  // const steps = [
  //   "ข้อมูลลูกหนี้",
  //   "ข้อมูลคดี",
  //   "ข้อมูลทรัพย์",
  //   "รายการเอกสารในสำนวนคดี",
  //   "ข้อมูลบริษัท",
  //   "ข้อมูลบังคับคดี",
  // ];

  // Led
  const [executionCode, setExecutionCode] = useState(""); // เลขคดีศาล
  const [executionDateStart, setExecutionDateStart] = useState(null); // วันกำหนดส่ง
  const [executionDeadline, setExecutionDeadline] = useState(null); // วันที่รับเรื่อง
  const [executionCourtDate, setExecutionCourtDate] = useState(null); // วันขึ้นศาล
  const [executionCourtTime, setExecutionCourtTime] = useState(null); // เวลาขึ้นศาล
  const [executionCourtNumber, setExecutionCourtNumber] = useState(""); // วันขึ้นศาล
  const [executionWorkBy, setExecutionWorkBy] = useState(""); // ผู้รับผิดชอบคดี
  const [executionWorkByName, setExecutionWorkByName] = useState(""); // ผู้รับผิดชอบคดี

  // ===================================
  const [spouseCheckDate, setSpouseCheckDate] = useState(null); // วันที่ส่งตรวจสอบคู่สมรส
  const [spouseName, setSpouseName] = useState(""); // ชื่อคู่สมรส
  const [spouseIdCard, setSpouseIdCard] = useState(""); // หมายเลขบัตรประชาชน
  const [spouseNote, setSpouseNote] = useState(""); // หมายเหตุ
  const [spouseIncomeDate, setSpouseIncomeDate] = useState(null); // วันที่ส่งตรวจสอบรายได้
  const [spouseStatus, setSpouseStatus] = useState(""); // สถานะ
  // ===================================
  const [workPlaceIncomeDate, setWorkPlaceIncomeDate] = useState(null); // วันที่ส่งตรวจสอบรายได้
  const [workPlaceName, setWorkPlaceName] = useState(""); // สถานที่ทำงาน
  const [workPlaceIncome, setWorkPlaceIncome] = useState(""); // จำนวนเงิน
  const [workPlaceDateSendIncome, setWorkPlaceDateSendIncome] = useState(null); // วันที่ส่งอายัดเงิน
  const [workPlaceDetailIncome, setWorkPlaceDetailIncome] = useState(""); // เหตุที่อายัดไม่ได้(ขัดข้องการส่งเงิน)
  const [workPlaceStatusIncome, setWorkPlaceStatusIncome] = useState(""); // สถานะ
  // ===================================
  const [dltDateCheck, setDltDateCheck] = useState(null); // วันที่ส่งตรวจสอบกรมขนส่ง
  const [dltDetail, setDltDetail] = useState(""); // หมายเหตุ / รายละเอียดทรัพย์
  const [dltStatus, setDltStatus] = useState(""); // สถานะ
  // ===================================
  const [dolDateCheckGround, setDolDateCheckGround] = useState(null); // วันที่ส่งตรวจสอบกรมที่ดิน
  const [dolStatusObject, setDolStatusObject] = useState(""); // สถานะจำนองทรัพย์
  const [dolDetail, setDolDetail] = useState(""); // หมายเหตุ / รายละเอียดทรัพย์
  const [dolFailureDetail, setDolFailureDetail] = useState(""); // เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน)
  const [dolStatus, setDolStatus] = useState(""); // สถานะ
  // ===================================
  const [dolSell, setDolSell] = useState(""); // ประกาศขายทอดตลาด
  const [dolMoneySell, setDolMoneySell] = useState(""); // เงินส่วนได้จากการขายทอดตลาด
  const [dolCostReturn, setDolCostReturn] = useState(""); // กรณีรับค่าใช้จ่ายเหลือคืน
  const [dolFailureDetail1, setDolFailureDetail1] = useState(""); // เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน)
  const [dolDateGetSend, setDolDateGetSend] = useState(null); // วันที่รับบัญชีรับจ่าย
  // ===================================

  const [ledCostCourt, setLedCostCourt] = useState(""); // ค่าขึ้นศาล
  const [ledCostCall, setLedCostCall] = useState(""); // ค่านำหมายเรียก
  const [ledCostForce, setLedCostForce] = useState(""); // ค่านำหมายคำบังคับ
  const [ledCostTell, setLedCostTell] = useState(""); // ค่าบอกกล่าว
  const [ledCostAll, setLedCostAll] = useState(""); // ค่าเหมาจ่าย
  const [ledCostRequest, setLedCostRequest] = useState(""); // ค่าคำร้อง/รับรองเอกสาร
  const [ledCostLawyer1, setLedCostLawyer1] = useState(""); // ค่าจ้างทนายความ(งวดที่1)
  const [ledCostLawyer2, setLedCostLawyer2] = useState(""); // ค่าจ้างทนายความ(งวดที่2)
  const [ledInvestigete1Date, setLedInvestigete1Date] = useState(null); // วันที่เบิก งวด 1 (ฟ้อง)
  const [ledBankPaid1Date, setLedBankPaid1Date] = useState(null); // วันที่ธนาคารจ่ายเงิน(งวดที่1)
  const [ledInvestigete2Date, setLedInvestigete2Date] = useState(null); // วันที่เบิก งวด 2 (ฟ้อง)
  const [ledBankPaid2Date, setLedBankPaid2Date] = useState(null); // วันที่ธนาคารจ่ายเงิน(งวดที่2)
  // ===================================
  const [cojCostCourt, setCojCostCourt] = useState(""); // ค่าขึ้นศาลชั้นอุทธรณ์
  const [cojCostGo, setCojCostGo] = useState(""); // ค่านำหมายชั้นอุทธรณ์
  const [cojCostSumAll, setCojCostSumAll] = useState(""); // ค่าเหมาจ่ายชั้นอุทธรณ์
  const [cojCostLawyer, setCojCostLawyer] = useState(""); // ค่าจ้างทนายชั้นอุทธรณ์
  const [cojCostAll, setCojCostAll] = useState(""); // รวมค่าใช้จ่ายในการดำเนินคดีชั้นอุทธรณ์
  const [supCostCourt, setSupCostCourt] = useState(""); // ค่าขึ้นศาลชั้นฎีกา
  const [supCostGo, setSupCostGo] = useState(""); // ค่านำหมายชั้นฎีกา
  const [supCostSumAll, setSupCostSumAll] = useState(""); // ค่าเหมาจ่ายชั้นฎีกา
  const [supCostLawyer, setSupCostLawyer] = useState(""); // ค่าจ้างทนายชั้นฎีกา
  const [supCostAll, setSupCostAll] = useState(""); // รวมค่าใช้จ่ายในการดำเนินคดีชั้นฎีกา
  const [supDateReq, setSupDateReq] = useState(null); // วันที่เบิกชั้นฎีกา
  const [supDatePaid, setSupDatePaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  // ===================================
  const [costCheckRoleInvestigate, setCostCheckRoleInvestigate] = useState(""); // ค่าตรวจกรรมสิทธิ์ชั้นสืบทรัพย์
  const [costCheckCarInvestigate, setCostCheckCarInvestigate] = useState(""); // ค่าตรวจยานพาหนะชั้นสืบทรัพย์
  const [costEtcInvestigate, setCostEtcInvestigate] = useState(""); // ค่าใช้จ่ายอื่นๆชั้นสืบทรัพย์
  const [costAllInvestigate, setCostAllInvestigate] = useState(""); // รวมค่าใช้จ่ายในการสืบทรัพย์
  const [costCheckInvestigate, setCostCheckInvestigate] = useState(""); // ค่าจ้างสืบทรัพย์
  const [costCheckMapInvestigate, setCostCheckMapInvestigate] = useState(""); // ค่าตรวจสอบระวางแผนที่
  const [costCheckAllInvestigate, setCostCheckAllInvestigate] = useState(""); // รวมค่าจ้างสืบทรัพย์
  const [costWithdrawInvestigate, setCostWithdrawInvestigate] = useState(null); // วันที่เบิกสืบทรัพย์
  const [datePaidInvestigate, setDatePaidInvestigate] = useState(null); // วันที่ธนาคารจ่ายเงิน
  // ===================================
  // ค่าใช้จ่ายในการดำเนินคดีชั้นบังคับคดี
  // Seized
  // ===================================

  const [sequestrationFee, setSequestrationFee] = useState(""); // ค่าตั้งเรื่องอายัด
  const [lumpSum, setLumpSum] = useState(""); // ค่าเหมาจ่าย
  const [ledCost, setLedCost] = useState(""); // ค่าจ้างอายัด
  const [ledAll, setLedAll] = useState(""); // รวม
  const [ledDate0, setledDate0] = useState(null); // วันที่เบิกอายัด
  const [ledDateBankPaid, setLedDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [led20, setLed20] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)
  const [led20Date1, setLed20Date1] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 1)
  const [ledDateBankPaid1, setLedDateBankPaid1] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 1)
  const [led20R1, setLed20R1] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่ 1)
  const [led20DateR2, setLed20DateR2] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 2)
  const [ledDateBankPaid2, setLedDateBankPaid2] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 2)
  const [led20R2, setLed20R2] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่ 2)
  const [led20DateR3, setLed20DateR3] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 3)
  const [ledDateBankPaid3, setLedDateBankPaid3] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 3)
  const [led20R3, setLed20R3] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่ 3)
  const [led20DateR4, setLed20DateR4] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 4)
  const [ledDateBankPaid4, setLedDateBankPaid4] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 4)
  const [ledFess, setLedFess] = useState(""); // ค่าธรรมเนียม
  const [ledFollow, setLedFollow] = useState(""); // ค่านำหมาย
  const [ledSubmit, setLedSubmit] = useState(""); // ค่ารับรอง
  const [ledCostWagesRequest, setLedCostWagesRequest] = useState(""); // รวมค่าใช้จ่ายในการยื่นคำร้อง
  const [ledInvestigateDate, setLedInvestigateDate] = useState(null); // วันที่เบิก
  const [ledInvestigateDateBankPaid, setLedInvestigateDateBankPaid] =
    useState(null); // วันที่ธนาคารจ่ายเงิน
  const [ledWagesRequest, setLedWagesRequest] = useState(""); // ค่าจ้างยื่นคำร้อง
  const [ledWagesDateBankPaid, setLedWagesDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [ledWagesInvestigateDate, setLedWagesInvestigateDate] = useState(null); // วันที่เบิก
  const [createLedCost, setCreateLedCost] = useState(""); // ค่าตั้งเรื่องบังคับคดีแทน(ยึดทรัพย์)
  const [costCreateSequester, setCostCreateSequester] = useState(""); // ค่าตั้งเรื่องยึดทรัพย์
  const [costAllSequester, setCostAllSequester] = useState(""); // ค่าเหมาจ่าย(ยึดทรัพย์)
  const [costSequester, setCostSequester] = useState(""); // ค่าจ้างยึดทรัพย์
  const [costAllLedSequester, setCostAllLedSequester] = useState(""); // รวมค่าใช้จ่ายในการบังคับคดี(ยึดทรัพย์)
  const [sequesterDate, setSequesterDate] = useState(null); // วันที่เบิกยึดทรัพย์
  const [sequesterDateBankPaid, setSequesterDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [seizedWages, setSeizedWages] = useState(""); // ค่าวางเงินค่าใช้จ่ายเพิ่ม
  const [costSequesterCheck, setCostSequesterCheck] = useState(""); // ค่าตรวจรับรองบัญชีรับ-จ่าย
  const [costSequesterEtc, setCostSequesterEtc] = useState(""); // ค่าใช้จ่ายอื่น ๆ
  const [costAllSequesterEtc, setCostAllSequesterEtc] = useState(""); // รวมค่าใช้จ่ายอื่น ๆ
  const [dateSequesterInvestigate, setDateSequesterInvestigate] =
    useState(null); // วันที่เบิก
  const [
    sequesterInvestigateDateBankPaid,
    setSequesterInvestigateDateBankPaid,
  ] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [cost2Per, setCost2Per] = useState(""); // ค่าจ้าง 2 %(ได้รับชำระหนี้)ยึดอสังหาฯ(แต่ไม่เกิน100,000บาท)
  const [cost2PerInverstigate, setCost2PerInverstigate] = useState(null); // วันที่เบิก
  const [cost2PerDateBankPaid, setCost2PerDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [cost50Per, setCost50Per] = useState(""); // ค่าจ้าง 50 %(ได้รับชำระหนี้)
  const [cost50PerInverstigate, setCost50PerInverstigate] = useState(null); // วันที่เบิก
  const [cost50PerDateBankPaid, setCost50PerDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [cost1Per, setCost1Per] = useState(""); // ค่าจ้าง 1%(ได้รับชำระหนี้ปิดบัญชีหรือปรับปรุงหนี้ระหว่างประกาศขาย)(แต่ไม่เกิน 10,000 บาท
  const [cost1PerInverstigate, setCost1PerInverstigate] = useState(null); // วันที่เบิก
  const [cost1PerDateBankPaid, setCost1PerDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  // ===================================
  // ค่าใช้จ่ายในการดำเนินคดีชั้นยื่นขอรับชำระหนี้ในคดีล้มละลาย
  // ===================================
  const [lawFees, setLawFees] = useState(""); // ค่าฤชากร/ค่ารับรอง
  const [bankruptGetCostAll, setBankruptGetCostAll] = useState(""); // ค่าเหมาจ่าย
  const [bankruptRound1, setBankruptRound1] = useState(""); // งวด 1
  const [bankruptRound1All, setBankruptRound1All] = useState(""); // รวม
  const [bankruptRound1Investigate, setBankruptRound1Investigate] =
    useState(null); // วันที่เบิก
  const [bankruptRound1DateBankPaid, setBankruptRound1DateBankPaid] =
    useState(null); // วันที่ธนาคารจ่าย
  const [bankruptRound1LawFrees, setBankruptRound1LawFrees] = useState(""); // ค่าฤชากร/ค่ารับรอง
  const [bankruptRound2, setBankruptRound2] = useState(""); // งวด 2
  const [bankruptRound2Investigate, setBankruptRound2Investigate] =
    useState(null); // วันที่เบิก
  const [bankruptDateBankPaid, setBankruptDateBankPaid] = useState(null); // วันที่ธนาคารจ่าย
  const [bankruptCostAll, setBankruptCostAll] = useState(""); // รวมค่าใช้จ่ายทั้งสิ้น
  // ==============end

  // =========================================
  const [bankruptNumber1, setBankruptNumber1] = useState(""); // หมายเลขบัตรเครดิต
  const [bankruptJudge1, setBankruptJudge1] = useState(""); // พิพากษา(ยอดหนี้)
  const [bankruptMoney1, setBankruptMoney1] = useState(""); // เงินต้น
  const [bankruptInterest1, setBankruptInterest1] = useState(""); // ดอกเบี้ย(%)
  const [bankruptSince1, setBankruptSince1] = useState(null); // นับแต่วันที่
  const [bankruptNumber2, setBankruptNumber2] = useState(""); // หมายเลขบัตรเงินสด
  const [bankruptJudge2, setBankruptJudge2] = useState(""); // พิพากษา(ยอดหนี้)
  const [bankruptMoney2, setBankruptMoney2] = useState(""); // เงินต้น
  const [bankruptInterest2, setBankruptInterest2] = useState(""); // ดอกเบี้ย(%)
  const [bankruptSince2, setBankruptSince2] = useState(null); // นับแต่วันที่
  const [bankruptCourt, setBankruptCourt] = useState(""); // ค่าขึ้นศาล
  const [bankruptFollow, setBankruptFollow] = useState(""); // ค่านำหมาย
  const [bankruptCostLawyer, setBankruptCostLawyer] = useState(""); // ค่าทนายความ
  const [bankruptCostSend, setBankruptCostSend] = useState(""); // ค่าส่งคำบังคับ
  const [bankruptAll, setBankruptAll] = useState(""); // รวม
  // ===================================
  const [dateCalInterestSince, setDateCalInterestSince] = useState(null); // วันที่คำนวณดอกเบียถึง
  const [countDate, setCountDate] = useState(""); // จำนวนวัน
  const [interest, setInterest] = useState(""); // ดอกเบี้ย
  // ===================================
  const [dateCalInterestSince1, setDateCalInterestSince1] = useState(null); // วันที่คำนวณดอกเบียถึง
  const [countDate1, setCountDate1] = useState(""); // จำนวนวัน
  const [interest1, setInterest1] = useState(""); // ดอกเบี้ย
  // ===================================

  const [debtAllByJudgment, setDebtAllByJudgment] = useState(""); // รวมภาระหนี้ตามคำพิพากษา
  const [costLedAll, setCostLedAll] = useState(""); // รวมค่าใช้จ่ายในการดำเนินคดีและบังคับคดี
  const [sumReturnAll, setSumReturnAll] = useState(""); // รวมเงินที่ได้รับคืนแล้ว
  const [calDebtSince, setCalDebtSince] = useState(null); // คำนวณภาระหนี้ถึงวันที่
  const [costWorking, setCostWorking] = useState(""); // ค่าใช้จ่ายในการดำเนินคดีฯ,ค่าขึ้นศาลที่ศาลสั่งคืน

  // const images = [
  //   // require(`../../../../../backend/controllers/uploads/expal/20231228114606_20231228104301_Panorama_of_3mb (1).jpg`),
  //   // require(`../../../../../backend/controllers/uploads/expal/20231228114606_20231228104301_Panorama_of_3mb (1).jpg`)
  // ];

  const [steps, setSteps] = useState([
    "ข้อมูลลูกหนี้",
    "ข้อมูลคดี",
    "ข้อมูลทรัพย์",
    "รายการเอกสารในสำนวนคดี",
    "ข้อมูลบริษัท",
    // "ข้อมูลบังคับคดี",
  ]);
  const [completed, setCompleted] = useState(Array(steps.length).fill(false));
  const [textFail, setTextFail] = useState([]);

  const fileTypes = ["PDF"];
  const fileImageTypes = ["JPEG", "PNG", "JPG"];
  const [judgmentdebtLedList, setJudgmentdebtLedList] = useState([]); //

  const [visible, setVisible] = useState(false);
  const handleSetVisible = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };
  const onUpload = (data) => {
    console.log("Upload files", data);
  };
  const onSelect = (data) => {
    console.log("Select files", data);
  };
  const onRemove = (id) => {
    console.log("Remove image id", id);
  };

  const handleUploadChange = (file) => {
    setFileUpload(file);
    console.log(file);
  };

  const handleUploadImageChange = (file) => {
    setFileImageUpload(file);
    console.log(file);
  };

  const columns1 = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    // { id: "list", label: "รายการ", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
    { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
    {
      id: "uploadFile",
      label: "อัพโหลดเอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleUploadFileExecution(row)}>
            <UploadFileIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "file",
      label: "เอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          {row.path ? (
            <IconButton
              disabled={!row.path}
              onClick={() => handleDownload(row)}
            >
              <PictureAsPdfIcon color={row.path ? "error" : "inherit"} />
            </IconButton>
          ) : null}
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={!row.path}
            onClick={() => handleDeleteFileExecution(row)}
          >
            <DeleteForeverIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  const handleDeleteFileExecution = (row) => {
    const payload = {
      main: row.main,
      fileName: row.path,
      uuid: row.uuid,
      id: executionId,
      type: "execution",
    };
    console.log(payload);
    setIsLoading(true);
    const fileName = path.basename(row.path);
    saveActivityLog(`Click Delete file: ${fileName}`);
    axios
      .post(`${config.mainAPI}/deleteFileByPath`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;

        getListFile();
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const typeDocsList = [
    { id: 0, value: "คำร้อง" },
    { id: 1, value: "คำขอ" },
    { id: 2, value: "คำแถลง" },
    { id: 3, value: "คำขอถอนฟ้อง" },
    { id: 4, value: "คำร้องขาดนัดยื่นคำให้การ" },
    { id: 5, value: "คำแถลงขอเลื่อนนัด" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const columns = [
  //   { id: "order", label: "ลำดับ", minWidth: 100 },
  //   { id: "list", label: "รายการ", minWidth: 100 },
  //   { id: "type", label: "ประเภท", minWidth: 100 },
  //   { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
  //   { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
  //   {
  //     id: "file",
  //     label: "ไฟล์เอกสาร",
  //     minWidth: 100,
  //     render: (row) => (
  //       <>
  //         <IconButton onClick={() => handleDownload(row)}>
  //           <PictureAsPdfIcon color="error" />
  //         </IconButton>
  //       </>
  //     ),
  //   },
  //   {
  //     id: "delete",
  //     label: "",
  //     minWidth: 100,
  //     render: (row) => (
  //       <>
  //         <IconButton onClick={() => handleDeleteFile(row)}>
  //           <DeleteForeverIcon color="error" />
  //         </IconButton>
  //       </>
  //     ),
  //   },
  // ];

  const filteredRows1 = rows1.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const filteredRowsImg = rowsImages.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  useAuth();

  function getCourtList() {
    axios
      .get(`${config.mainAPI}/court-list`)
      .then((response) => {
        setCourtList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getUserCompany() {
    axios
      .get(`${config.mainAPI}/users-company`)
      .then((response) => {
        setUserCompany(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDdlPrefix() {
    axios
      .get(`${config.mainAPI}/prefix-list`)
      .then((response) => {
        setPrefixList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDdlDebtCenter() {
    api
      .get(`${config.mainAPI}/debtCenter`)
      .then((response) => {
        setDebtCenterList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleSumbitFileUpload = async () => {
    if (fileUpload) {
      console.log("fileUpload", fileUpload);
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("type", typeDocsUpload);
      formData.append("type_docs", "expal");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileUpload.name} Success`);

        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
      }
    }
  };

  const handleSumbitFileImageUpload = async () => {
    if (fileImageUpload) {
      console.log("fileImageUpload", fileImageUpload);
      const formData = new FormData();
      formData.append("fileImageUpload", fileImageUpload);
      formData.append("fileName", fileImageUpload.name);
      formData.append("topic", "imagePlace");
      formData.append("type", "image");
      formData.append("type_img", fileImageUpload.type);
      formData.append("type_docs", "expal");
      formData.append("user", user.username);
      formData.append("id", params);
      console.log("formData", formData);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-image`,
          formData
        );
        // saveActivityLog(`Upload File:${fileImageUpload.name} Success`);
        // getListFile();
        getListFileImage();
        setFileImageUpload(null);
      } catch (err) {
        console.log(err);
        // saveActivityLog(`Upload File:${fileImageUpload.name} Failure`);
      }
    }
  };

  function handleClearFileImageUpload() {
    setFileImageUpload(null);
  }

  function handleClearFileUpload() {
    setFileUpload(null);
    setTypeDocsUpload("");
    setTopicDocsUpload("");
  }

  const handleSubmitFileUpload = async () => {
    if (fileUpload) {
      console.log("fileUpload", fileUpload);
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("type", typeDocsUpload);
      formData.append("type_docs", "expal");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileUpload.name} Success`);

        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
      }
    }
  };

  function getListFile() {
    axios
      .get(`${config.mainAPI}/file-list/${params}`)
      .then((response) => {
        console.log("value filelist", response.data.data);
        const value = response.data.data;
        if (value) {
          setFileList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.file_uuid,
                item.file_topic,
                item.file_type,
                item.created_at,
                item.created_by,
                item.file_path,
                item.file_main,
                item.update_at
              )
            )
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getListFileImage() {
    axios
      .get(`${config.mainAPI}/getImagesFileById/${params}`)
      .then((response) => {
        console.log("value imageslist", response.data.data);
        const value = response.data.data;
        if (value) {
          setImageList(value);
          setRowsImages(
            value.map((item, index) =>
              createDataImages(
                index + 1,
                item.file_uuid,
                item.file_topic,
                item.file_type,
                item.created_at,
                item.created_by,
                item.file_path,
                item.file_main,
                item.update_at
              )
            )
          );

          // const backendPath = '../../../../../backend/controllers';

          // const images = [
          //   require('../../../assets/images/Arezzo01.png'),
          //   require('../../../assets/images/Lalin-Town.jpg'),
          // ];

          // setImages
          const newArrays = [];
          value.map((item, index) => {
            const pathImage = `${config.mainAPI}/getImagesFromPathToUrl/${item.file_type_docs}/${item.file_name}`;
            newArrays.push(pathImage);
          });
          console.log("newArrays", newArrays);
          setImages(newArrays);
          console.log("images state:", images); // Check what's in the images state
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const onChangeMultipleValue = (setData, event, index) => {
    const { value } = event.target;
    setData((prevStatusDefendant) => {
      const newStatusDefendant = [...prevStatusDefendant];
      newStatusDefendant[index] = value;
      return newStatusDefendant;
    });
  };

  // const handleDeleteFile = (row) => {
  //   const payload = {
  //     fileName: row.path,
  //     uuid: row.uuid,
  //     id: params,
  //     type: "evication",
  //   };
  //   setIsLoading(true);
  //   axios
  //     .post(`${config.mainAPI}/deleteFileByPath`, {
  //       ...payload,
  //     })
  //     .then(function (response) {
  //       console.log("response"), response;
  //       getListFile();
  //       setIsLoading(false);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       setIsLoading(false);
  //     });
  // };

  const handleBack = () => {
    navigate("/expal");
  };

  // const handleProvinceChange = (event) => {
  //   if (event) {
  //     const value = parseInt(event.target.dataset.value);
  //     const defendantProvinceName = event.currentTarget.getAttribute("name");
  //     setDefendantProvince(value);
  //     setDefendantProvinceName(defendantProvinceName);
  //     setDefendantAmphoe("");
  //     setDefendantTambon("");
  //   }
  // };

  // const handleAmphoeChange = (event) => {
  //   if (event) {
  //     const value = parseInt(event.target.dataset.value);
  //     const defendantAmphoeName = event.currentTarget.getAttribute("name");
  //     setDefendantAmphoe(value);
  //     setDefendantAmphoeName(defendantAmphoeName);
  //     setDefendantTambon("");
  //   }
  // };

  // const handleTambonChange = (event) => {
  //   if (event) {
  //     const value = parseInt(event.target.dataset.value);
  //     const defendantTambonName = event.currentTarget.getAttribute("name");
  //     const defendantTambonZipcode = event.currentTarget.getAttribute("code");
  //     setDefendantZipcode(defendantTambonZipcode);
  //     setDefendantTambon(value);
  //     setDefendantTambonName(defendantTambonName);
  //   }
  // };

  // const getAmphoesByProvinceId = (provinceId) => {
  //   if (provinceId) {
  //     return provincesData.find((province) => province.id === provinceId)
  //       .amphure;
  //   }
  // };

  // const getTambonsByAmphoeId = (amphoeId) => {
  //   if (amphoeId) {
  //     return provincesData
  //       .flatMap((province) => province.amphure)
  //       .find((amphoe) => amphoe.id === amphoeId).tambon;
  //   }
  // };

  const handleProvinceChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const placeProvinceName = event.currentTarget.getAttribute("name");
      setPlaceProvince(value);
      setPlaceProvinceName(placeProvinceName);
      setPlaceAmphoe("");
      setPlaceTambon("");
    }
  };

  const handleAmphoeChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const placeAmphoeName = event.currentTarget.getAttribute("name");
      setPlaceAmphoe(value);
      setPlaceAmphoeName(placeAmphoeName);
      setPlaceTambon("");
    }
  };

  const handleTambonChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const placeTambonName = event.currentTarget.getAttribute("name");
      const placeTambonZipcode = event.currentTarget.getAttribute("code");
      setPlaceZipcode(placeTambonZipcode);
      setPlaceTambon(value);
      setPlaceTambonName(placeTambonName);
    }
  };

  const getAmphoesByProvinceId = (provinceId) => {
    if (provinceId) {
      return provincesData.find((province) => province.id === provinceId)
        .amphure;
    }
  };

  const getTambonsByAmphoeId = (amphoeId) => {
    if (amphoeId) {
      return provincesData
        .flatMap((province) => province.amphure)
        .find((amphoe) => amphoe.id === amphoeId).tambon;
    }
  };

  const disabledHours = () => {
    const disabledHoursArray = [];
    for (let i = 0; i < 8; i++) {
      disabledHoursArray.push(i);
    }
    for (let i = 19; i <= 23; i++) {
      disabledHoursArray.push(i);
    }
    return disabledHoursArray;
  };

  // function handleDownload() {
  //   const file_path = filePath;
  //   const download_url = `${config.mainAPI}/download-file?file_path=${file_path}`;
  //   window.location.href = download_url;
  // }

  const deleteFile = () => {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/delete-file`, {
        filePath,
      })
      .then(function (response) {
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    const caseData = params != null ? "edit" : "add";
    const payload = {
      caseData: caseData,
      genCode: genCode,
      finish: finish,
      type: type,
      prefix: prefix,
      firstName: firstName,
      lastName: lastName,
      idCard: idCard,
      nameCompany: nameCompany,
      debtCenter: debtCenter,
      coDefendant: coDefendant,
      countDefendant: parseInt(countDefendant),
      defendantAgent: defendantAgent,
      court: court,
      courtDate: courtDate,
      courtTime: combinedTimeShow(courtTime),
      countCourtEtc: parseInt(countCourtEtc),
      goCourtEtc: goCourtEtc,
      blackCaseNumber: blackCaseNumber,
      redCaseNumber: redCaseNumber,
      blackCaseYear: blackCaseYear,
      redCaseYear: redCaseYear,
      gsbNumber: gsbNumber,
      tambon: tambon,
      amphoe: amphoe,
      province: province,
      tambonName: tambonName,
      amphoeName: amphoeName,
      provinceName: provinceName,
      zipcode: zipcode,
      defendantHouseNumber: defendantHouseNumber,
      defendantGroup: defendantGroup,
      defendantRoad: defendantRoad,
      defendantSoi: defendantSoi,
      defendantAddress: defendantAddress,
      defendantTambonName: defendantTambonName,
      defendantAmphoeName: defendantAmphoeName,
      defendantProvinceName: defendantProvinceName,
      defendantTambon: defendantTambon,
      defendantAmphoe: defendantAmphoe,
      defendantProvince: defendantProvince,
      defendantZipcode: defendantZipcode,
      placeHouseNumber: placeHouseNumber,
      placeGroup: placeGroup,
      placeRoad: placeRoad,
      placeSoi: placeSoi,
      placeAddress: placeAddress,
      placeTambonName: placeTambonName,
      placeAmphoeName: placeAmphoeName,
      placeProvinceName: placeProvinceName,
      placeTambon: placeTambon,
      placeAmphoe: placeAmphoe,
      placeProvince: placeProvince,
      placeZipcode: placeZipcode,
      phone: phone,
      phone1: phone1,
      fileNumber: fileNumber,
      // dateStart: dateStart,
      dateStart: dateStart,
      deadline: deadline,
      propertyCode: propertyCode,
      sector: sector,
      googleMap: googleMap,
      issueAWarranOfExecutionDate: issueAWarranOfExecutionDate,
      executionOfficerDate: executionOfficerDate,
      issueAnArrestWarrant: issueAnArrestWarrant,
      debtorsAndDependentsDayOut: debtorsAndDependentsDayOut,
      licenseDocument: licenseDocument,
      licenseDocumentCode: licenseDocumentCode,
      caseStatus: caseStatus,
      note: note,
      bookingDate: bookingDate,
      bookingTime: bookingTime,
      staffConnectionName: staffConnectionName,
      staffConnectionPhone: staffConnectionPhone,
      chooseMemberCompany: chooseMemberCompany,
      chooseMemberCompanyName: chooseMemberCompanyName,
      coDefendant: coDefendant,
      courtCode: courtCode,
      companyCode: companyCode,
      titleDeed: titleDeed,
      locationDetail: locationDetail,
      landNumber: landNumber,
      explorePage: explorePage,
      user: user?.username,
      // countGoCourtDB:
      //     caseData == "edit" ? countCourtEtc + 1 : courtDate ? 1 : 0,
    };
    console.log("payload", payload);
    axios
      .post(`${config.mainAPI}/expal`, {
        ...payload,
      })
      .then(function (response) {
        setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFileChange = (event) => {
    const fileData = event.target.files[0];
    setFile(fileData);
    setFile1(fileData);
  };

  const uploadFile = async () => {
    if (file) {
      console.log("file", file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      try {
        const res = await axios.post(`${config.mainAPI}/upload-file`, formData);
        console.log("eoeo", res);
        setFileName(res.data.name);
        setFilePath(res.data.path);

        setFileImg(res.data.pathImg);
        setCheckFile(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleWorkByChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setChooseMemberCompany(workById);
    setChooseMemberCompanyName(workByName);
  };

  const handleWorkByNowChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setChooseMemberCompanyNow(workById);
    setChooseMemberCompanyNameNow(workByName);
  };

  const handleCoDefendantStatusChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantStatus((prevStatusDefendant) => {
      const newStatusDefendant = [...prevStatusDefendant];
      newStatusDefendant[index] = value;
      return newStatusDefendant;
    });
  };

  const handleCoDefendantPrefixChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantPrefix((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantLastNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantLastName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantAddressChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantAddress((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  const handleCoDefendantIdCardChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantIdCard((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  const handlePrefixDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setPrefixDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleLastNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setLastNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleIdCardDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setIdCardDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleAddressDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setAddressDefendantAgent((prevStatusDefendant) => {
      const newAddressDefendantAgent = [...prevStatusDefendant];
      newAddressDefendantAgent[index] = value;
      return newAddressDefendantAgent;
    });
  };

  const handleStatusDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setStatusDefendantAgent((prevStatusDefendant) => {
      const newStatusDefendantAgent = [...prevStatusDefendant];
      newStatusDefendantAgent[index] = value;
      return newStatusDefendantAgent;
    });
  };

  const coDefendant = coDefendantName.map((item, index) => ({
    prefix: coDefendantPrefix[index],
    name: item,
    lastname: coDefendantLastName[index],
    status: coDefendantStatus[index],
    address: coDefendantAddress[index],
    idCard: coDefendantIdCard[index],
    role: `ผู้ค้ำประกันที่${[index + 1]}`,
    order: index + 1,
  }));

  const defendantAgent = nameDefendantAgent.map((item, index) => ({
    prefix: prefixDefendantAgent[index] || null,
    name: item || null,
    lastname: lastNameDefendantAgent[index] || null,
    address: addressDefendantAgent[index] || null,
    idCard: idCardDefendantAgent[index] || null,
    status: statusDefendantAgent[index] || null,
    role: `ตัวแทนผู้ค้ำประกันที่${[index + 1]}` || null,
    order: index + 1,
  }));

  const listCoDefendantStatus = [
    { id: 0, value: "มีชีวิตอยู่" },
    { id: 1, value: "เสียชีวิต" },
  ];

  const listStatusDead = [
    { id: 0, value: "ผู้จัดการมรดก" },
    { id: 1, value: "คู่สมรส" },
    { id: 2, value: "บิดา-มารดา" },
  ];

  // ? moment(decree, "DD/MM/YYYY").format("YYYY-MM-DD")

  const getDataForm = () => {
    setIsLoading(true);

    axios
      .get(`${config.mainAPI}/expal/${params}`)
      .then((response) => {
        console.log("response.data.data", response.data.data);

        const value = response.data.data[0];
        setDateStart(value.date_start);
        setDeadline(value.date_send);
        setType(value.type);
        if (value.type == "บุคคล") {
          setFileNumber(value.file_number);
          setPrefix(value.defendant_prefix);
          setFirstName(value.defendant_firstname);
          setLastName(value.defendant_lastname);
          setIdCard(value.defendant_idcard);
        } else if (value.type == "นิติบุคคล") {
          setNameCompany(value.defendant_fullname);
        }
        setGsbNumber(value.gsb_number);
        setTambon(parseInt(value.defendant_tambon));
        setTambonName(value.defendant_tambon_show);
        setAmphoe(parseInt(value.defendant_amphoe));
        setAmphoeName(value.defendant_amphoe_show);
        setProvince(parseInt(value.defendant_province));
        setProvinceName(value.defendant_province_show);
        if (value.defendant_province) {
          console.log("value.defendant_province", value.defendant_province);
          getAmphoesByProvinceId(parseInt(value.defendant_province));
        }
        if (value.defendant_amphoe) {
          console.log("value.defendant_amphoe", value.defendant_amphoe);
          getTambonsByAmphoeId(parseInt(value.defendant_amphoe));
        }
        setZipcode(parseInt(value.defendant_zipcode));
        setDebtCenter(value.debt_center);
        setCourt(value.court);

        setCourtDate(value.court_date);
        setCourtTime(
          value.court_time ? moment(value.court_time, "HH:mm") : null
        );
        // setCountCourtEtc(value.court_count - 1);
        setCountCourtEtc(value.goCourtEtc ? value.goCourtEtc.length : 0);
        console.log("value.court_count", value.court_count);
        console.log("value.goCourtEtc", value.goCourtEtc);
        if (value.court_count > 0 && value.goCourtEtc) {
          value.goCourtEtc.forEach((item, index) => {
            console.log("index", index);
            // if (index > 0) {
            setDateGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_date;
              return updatedState;
            });

            setTimeGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_time;
              return updatedState;
            });

            setLawyerGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by;
              return updatedState;
            });

            setLawyerNameGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by_name;
              return updatedState;
            });

            setDetailGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_detail;
              return updatedState;
            });
            // }
          });
        }

        setPhone(value.defendant_phone);
        setPhone1(value.defendant_phone1);
        setGoogleMap(value.google_map);
        setPropertyCode(value.property_code);
        setSector(value.sector);
        setBlackCaseNumber(value.black_case_number);
        setBlackCaseYear(value.black_case_year);
        setRedCaseNumber(value.red_case_number);
        setRedCaseYear(value.red_case_year);
        setIssueAWarranOfExecutionDate(value.issue_warran_of_execution_date);
        setIssueAnArrestWarrant(value.issue_arrest_warrant);
        setLicenseDocument(value.license_document);
        setLicenseDocumentCode(value.license_document_code);
        setCaseStatus(value.case_status);
        setExecutionOfficerDate(value.execution_officer_date);
        setIssueAnArrestWarrant(value.issue_arrest_warrant);
        setDebtorsAndDependentsDayOut(value.debtors_and_dependents_day_out);
        setLocationDetail(value.location_detail);
        setTitleDeed(value.title_deed);
        setLandNumber(value.land_number);
        setExplorePage(value.explore_page);
        setBookingTime(value.time_meet);
        setBookingDate(value.date_meet);
        setNote(value.note);
        setChooseMemberCompany(value.work_by);
        setCountDefendant(value.codefendant_count);
        if (value.codefendant_count > 0) {
          value.coDefendant.forEach((item, index) => {
            setCoDefendantPrefix((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_prefix;
              return updatedState;
            });

            setCoDefendantName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_firstname;
              return updatedState;
            });

            setCoDefendantLastName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_lastname;
              return updatedState;
            });

            setCoDefendantAddress((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_address;
              return updatedState;
            });

            setCoDefendantIdCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_idcard;
              return updatedState;
            });

            setCoDefendantStatus((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_status;
              return updatedState;
            });
          });
        }
        // setPrefixDefendantAgent(value.);
        // setNameDefendantAgent(value.);
        // setLastNameDefendantAgent(value.);
        // setIdCardDefendantAgent(value.);
        // setAddressDefendantAgent(value.);
        // setStatusDefendantAgent(value.);
        setCompanyCode(value.company_code);
        setCourtCode(value.company_code);
        setStaffConnectionName(value.admin_meet);
        setStaffConnectionPhone(value.phone_meet);

        // setDefendantHouseNumber(value.defendant_house_number); // (value.defendantHouseNumber) บ้านเลขที่
        // setDefendantGroup(value.defendant_group); // หมู่
        // setDefendantRoad(value.defendant_road); // ถนน
        // setDefendantSoi(value.defendant_soi); // ซอย
        // setDefendantAddress(value.defendant_address);
        // setDefendantTambon(parseInt(value.defendant_tambon));
        // setDefendantTambonName(value.defendant_tambon_show);
        // setDefendantAmphoe(parseInt(value.defendant_amphoe));
        // setDefendantAmphoeName(value.defendant_amphoe_show);
        // setDefendantProvince(parseInt(value.defendant_province));
        // setDefendantProvinceName(value.defendant_province_show);
        // if (value.defendant_province) {
        //   console.log("value.defendant_province", value.defendant_province);
        //   getAmphoesByProvinceId(parseInt(value.defendant_province));
        // }
        // if (value.defendant_amphoe) {
        //   console.log("value.defendant_amphoe", value.defendant_amphoe);
        //   getTambonsByAmphoeId(parseInt(value.defendant_amphoe));
        // }
        // setDefendantZipcode(parseInt(value.defendant_zipcode));

        setPlaceHouseNumber(value.place_house_number); // (value.placeHouseNumber) บ้านเลขที่
        setPlaceGroup(value.place_group); // หมู่
        setPlaceRoad(value.place_road); // ถนน
        setPlaceSoi(value.place_soi); // ซอย
        setPlaceAddress(value.place_address);
        setPlaceTambon(parseInt(value.place_tambon));
        setPlaceTambonName(value.place_tambon_show);
        setPlaceAmphoe(parseInt(value.place_amphoe));
        setPlaceAmphoeName(value.place_amphoe_show);
        setPlaceProvince(parseInt(value.place_province));
        setPlaceProvinceName(value.place_province_show);
        if (value.place_province) {
          // console.log("value.place_province", value.place_province);
          getAmphoesByProvinceId(parseInt(value.place_province));
        }
        if (value.place_amphoe) {
          // console.log("value.place_amphoe", value.place_amphoe);
          getTambonsByAmphoeId(parseInt(value.place_amphoe));
        }
        setPlaceZipcode(parseInt(value.place_zipcode));

        setExecutionCase(value.execution_case);
        console.log("value.executionData", value.executionData);

        if (value.execution_case) {
          getListFileExecution(value.execution_id);
          setExecutionId(value.execution_id);
          setExecutionCode(value.executionData[0].execution_status);
          // setExecutionStatus(value.executionData[0].execution_status);
          setExecutionDeadline(value.executionData[0].led_deadline);
          setExecutionDateStart(value.executionData[0].led_date_start);
          setExecutionCourtDate(value.executionData[0].court_date_led);
          setExecutionCourtTime(value.executionData[0].court_time_led);
          setBringTheRegulations(value.executionData[0].bring_the_regulations);
          setWritOfExecutionDate(value.executionData[0].writ_of_execution_date);
          setExecutionWorkBy(value.executionData[0].work_by_led);
          setExecutionWorkByName(value.executionData[0].work_by_led_name);

          // eieina
          // table: execution_investigate
          setSpouseCheckDate(value.executionData[0].spouse_check_date);
          setSpouseName(value.executionData[0].spouse_name);
          setSpouseIdCard(value.executionData[0].spouseId_card);
          setSpouseNote(value.executionData[0].spouse_note);
          setSpouseIncomeDate(value.executionData[0].spouse_income_date);
          setSpouseStatus(value.executionData[0].spouse_status);
          setWorkPlaceIncomeDate(value.executionData[0].spouse_income_date);
          setWorkPlaceName(value.executionData[0].work_place_income_date);
          setWorkPlaceIncome(value.executionData[0].work_place_name);
          setWorkPlaceDateSendIncome(
            value.executionData[0].work_place_date_send_income
          );
          setWorkPlaceDetailIncome(
            value.executionData[0].work_place_detail_income
          );
          setWorkPlaceStatusIncome(
            value.executionData[0].work_place_status_income
          );
          setDltDateCheck(value.executionData[0].dlt_date_check);
          setDltDetail(value.executionData[0].dlt_detail);
          setDltStatus(value.executionData[0].dlt_status);
          setDolDateCheckGround(value.executionData[0].dol_date_check_ground);
          setDolStatusObject(value.executionData[0].dol_status_object);
          setDolDetail(value.executionData[0].dol_detail);
          setDolFailureDetail(value.executionData[0].dol_failure_detail);
          setDolStatus(value.executionData[0].dol_status);
          setDolSell(value.executionData[0].dol_sell);
          setDolMoneySell(value.executionData[0].dol_money_sell);
          setDolCostReturn(value.executionData[0].dol_cost_return);
          setDolFailureDetail1(value.executionData[0].dol_failure_detail1);
          setDolDateGetSend(value.executionData[0].dol_date_get_send);

          // table: execution_cost
          setLedCostCourt(value.executionData[0].led_cost_court);
          setLedCostCall(value.executionData[0].led_cost_call);
          setLedCostForce(value.executionData[0].led_cost_force);
          setLedCostTell(value.executionData[0].led_cost_tell);
          setLedCostAll(value.executionData[0].led_cost_all);
          setLedCostRequest(value.executionData[0].led_cost_request);
          setLedCostLawyer1(value.executionData[0].led_cost_lawyer1);
          setLedCostLawyer2(value.executionData[0].led_cost_lawyer_2);
          setLedInvestigete1Date(value.executionData[0].led_investigete1_date);
          setLedBankPaid1Date(value.executionData[0].led_bank_paid1_date);
          setLedInvestigete2Date(value.executionData[0].led_investigete2_date);
          setLedBankPaid2Date(value.executionData[0].led_bank_paid2_date);
          setCojCostCourt(value.executionData[0].coj_cost_court);
          setCojCostGo(value.executionData[0].coj_cost_go);
          setCojCostSumAll(value.executionData[0].coj_cost_sum_all);
          setCojCostLawyer(value.executionData[0].coj_cost_lawyer);
          setCojCostAll(value.executionData[0].coj_cost_all);
          setSupCostCourt(value.executionData[0].sup_cost_court);
          setSupCostGo(value.executionData[0].sup_cost_go);
          setSupCostSumAll(value.executionData[0].sup_cost_sum_all);
          setSupCostLawyer(value.executionData[0].sup_cost_lawyer);
          setSupCostAll(value.executionData[0].sup_cost_all);
          setSupDateReq(value.executionData[0].sup_date_req);
          setSupDatePaid(value.executionData[0].sup_date_paid);
          setCostCheckRoleInvestigate(
            value.executionData[0].cost_check_role_investigate
          );
          setCostCheckCarInvestigate(
            value.executionData[0].cost_check_car_investigate
          );
          setCostEtcInvestigate(value.executionData[0].cost_etc_investigate);
          setCostAllInvestigate(value.executionData[0].cost_all_investigate);
          setCostCheckInvestigate(
            value.executionData[0].cost_check_investigate
          );
          setCostCheckMapInvestigate(
            value.executionData[0].cost_check_map_investigate
          );
          setCostCheckAllInvestigate(
            value.executionData[0].cost_check_all_investigate
          );
          setCostWithdrawInvestigate(
            value.executionData[0].cost_withdraw_investigate
          );
          setDatePaidInvestigate(value.executionData[0].date_paid_investigate);

          // execution_cost
          setSequestrationFee(value.executionData[0].sequestration_fee);
          setLumpSum(value.executionData[0].lump_sum);
          setLedCost(value.executionData[0].led_cost);
          setLedAll(value.executionData[0].led_all);
          setledDate0(value.executionData[0].led_date0);
          setLedDateBankPaid(value.executionData[0].led_date_bank_paid);
          setLed20(value.executionData[0].led20);
          setLed20Date1(value.executionData[0].led20_date1);
          setLedDateBankPaid1(value.executionData[0].led_date_bank_paid1);
          setLed20R1(value.executionData[0].led20_r1);
          setLed20DateR2(value.executionData[0].led20_date_r2);
          setLedDateBankPaid2(value.executionData[0].led_date_bank_paid2);
          setLed20R2(value.executionData[0].led20_r2);
          setLed20DateR3(value.executionData[0].led20_date_r3);
          setLedDateBankPaid3(value.executionData[0].led_date_bank_paid3);
          setLed20R3(value.executionData[0].led20_r3);
          setLed20DateR4(value.executionData[0].led20_date_r4);
          setLedDateBankPaid4(value.executionData[0].led_date_bank_paid4);
          setLedFess(value.executionData[0].led_fess);
          setLedFollow(value.executionData[0].led_follow);
          setLedSubmit(value.executionData[0].led_submit);
          setLedCostWagesRequest(value.executionData[0].led_cost_wages_request);
          setLedInvestigateDate(value.executionData[0].led_investigate_date);
          setLedInvestigateDateBankPaid(
            value.executionData[0].led_investigate_date_bank_paid
          );
          setLedWagesRequest(value.executionData[0].led_wages_request);
          setLedWagesDateBankPaid(
            value.executionData[0].led_wages_date_bank_paid
          );
          setLedWagesInvestigateDate(
            value.executionData[0].led_wages_investigate_date
          );
          setCreateLedCost(value.executionData[0].create_led_cost);
          setCostCreateSequester(value.executionData[0].cost_create_sequester);
          setCostAllSequester(value.executionData[0].cost_all_sequester);
          setCostSequester(value.executionData[0].cost_sequester);
          setCostAllLedSequester(value.executionData[0].cost_all_led_sequester);
          setSequesterDate(value.executionData[0].sequester_date);
          setSequesterDateBankPaid(
            value.executionData[0].sequester_date_bank_paid
          );
          setSeizedWages(value.executionData[0].seized_wages);
          setCostSequesterCheck(value.executionData[0].cost_sequester_check);
          setCostSequesterEtc(value.executionData[0].cost_sequester_etc);
          setCostAllSequesterEtc(value.executionData[0].cost_all_sequester_etc);
          setDateSequesterInvestigate(
            value.executionData[0].date_sequester_investigate
          );
          setSequesterInvestigateDateBankPaid(
            value.executionData[0].sequester_investigate_date_bank_paid
          );
          setCost2Per(value.executionData[0].cost2_per);
          setCost2PerInverstigate(
            value.executionData[0].cost2_per_inverstigate
          );
          setCost2PerDateBankPaid(
            value.executionData[0].cost2_per_date_bank_paid
          );
          setCost50Per(value.executionData[0].cost50_per);
          setCost50PerInverstigate(
            value.executionData[0].cost50_per_inverstigate
          );
          setCost50PerDateBankPaid(
            value.executionData[0].cost50_per_date_bank_paid
          );
          setCost1Per(value.executionData[0].cost1_per);
          setCost1PerInverstigate(
            value.executionData[0].cost1_per_inverstigate
          );
          setCost1PerDateBankPaid(
            value.executionData[0].cost1_per_date_bank_paid
          );
          setLawFees(value.executionData[0].law_fees);
          setBankruptGetCostAll(value.executionData[0].bankrupt_get_cost_all);
          setBankruptRound1(value.executionData[0].bankrupt_round1);
          setBankruptRound1All(value.executionData[0].bankrupt_round1_all);
          setBankruptRound1Investigate(
            value.executionData[0].bankrupt_round1_investigate
          );
          setBankruptRound1DateBankPaid(
            value.executionData[0].bankrupt_round1_date_bank_paid
          );
          setBankruptRound1LawFrees(
            value.executionData[0].bankrupt_round1_law_frees
          );
          setBankruptRound2(value.executionData[0].bankrupt_round2);
          setBankruptRound2Investigate(
            value.executionData[0].bankrupt_round2_investigate
          );
          setBankruptDateBankPaid(
            value.executionData[0].bankrupt_date_bank_paid
          );
          setBankruptCostAll(value.executionData[0].bankrupt_cost_all);

          // execution_judgment_result
          setBankruptNumber1(value.executionData[0].bankrupt_number1);
          setBankruptJudge1(value.executionData[0].bankrupt_judge1);
          setBankruptMoney1(value.executionData[0].bankrupt_money1);
          setBankruptInterest1(value.executionData[0].bankrupt_interest1);
          setBankruptSince1(value.executionData[0].bankrupt_since1);
          setBankruptNumber2(value.executionData[0].bankrupt_number2);
          setBankruptJudge2(value.executionData[0].bankrupt_judge2);
          setBankruptMoney2(value.executionData[0].bankrupt_money2);
          setBankruptInterest2(value.executionData[0].bankrupt_interest2);
          setBankruptSince2(value.executionData[0].bankrupt_since2);
          setBankruptCourt(value.executionData[0].bankrupt_court);
          setBankruptFollow(value.executionData[0].bankrupt_follow);
          setBankruptCostLawyer(value.executionData[0].bankrupt_cost_lawyer);
          setBankruptCostSend(value.executionData[0].bankrupt_cost_send);
          setBankruptAll(value.executionData[0].bankrupt_all);
          setDateCalInterestSince(
            value.executionData[0].date_cal_interest_since
          );
          setCountDate(value.executionData[0].count_date);
          setInterest(value.executionData[0].interest);
          setDateCalInterestSince1(
            value.executionData[0].date_cal_interest_since1
          );
          setCountDate1(value.executionData[0].count_date1);
          setInterest1(value.executionData[0].interest1);

          // execution_summary
          setDebtAllByJudgment(value.executionData[0].debt_all_by_judgment);
          setCostLedAll(value.executionData[0].cost_led_all);
          setSumReturnAll(value.executionData[0].sum_return_all);
          setCalDebtSince(value.executionData[0].cal_debt_since);
          setCostWorking(value.executionData[0].cost_working);

          // add object เข้าไปใน step
          // steps.push("บังคับคดี");
          const updatedSteps = [
            ...steps,
            "ข้อมูลบังคับคดี",
            // "สืบทรัพย์บังคับคดี",
            "ค่าใช้จ่ายบังคับคดี",
            "ผลคำพิพากษาบังคับคดี",
            // "สรุปภาระหนี้",
            "เอกสารบังคับคดี",
          ];
          // steps = updatedSteps;
          setSteps((prevSteps) => [
            ...prevSteps,
            "ข้อมูลบังคับคดี",
            // "สืบทรัพย์บังคับคดี",
            "ค่าใช้จ่ายบังคับคดี",
            "ผลคำพิพากษาบังคับคดี",
            // "สรุปภาระหนี้",
            "เอกสารบังคับคดี",
          ]);
          console.log("updatedSteps", updatedSteps);
          setCompleted(Array(updatedSteps.length).fill(false));
          console.log("steps", steps);

          // console.log('steps555',steps);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleCloseFailure = () => {
    setOpenFailure(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate("/expal");
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log("datana", JSON.parse(userData));
    setUser(JSON.parse(userData));
    getUserCompany();
    getDdlPrefix();
    getDdlDebtCenter();
    getCourtList();
    if (params) {
      getDataForm();
      getListFile();
      getListFileImage();
    }
  }, []);
  const navigate = useNavigate();
  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setData(christDate);
  };

  const handleDateGoCourtEtcChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleTimeGoCourtEtcChange = (date, index) => {
    const formattedDate = date;
    setTimeGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = formattedDate;
      return data;
    });
  };

  const updateCountCourtEtc = (event) => {
    setCountCourtEtc(event);
    console.log("CountCourtEtc", event);

    // const handleWorkByChange = (event) => {
    //   const value = parseInt(event.target.dataset.value);
    //   const workByName = event.currentTarget.getAttribute("name");
    //   const workById = event.currentTarget.getAttribute("id");
    //   setChooseMemberCompany(workById);
    //   setChooseMemberCompanyName(workByName);
    // };

    // Splice data from arrays if their lengths are greater than countCourtEtc
    // อนาคตต้องมีการดักว่าต้องกรอกนะ
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  const handleLawyerGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    console.log("workByName", workByName);
    console.log("workById", workById);
    setLawyerGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workById;
      return data;
    });
    setLawyerNameGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workByName;
      return data;
    });
  };

  const handleDetailGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    setDetailGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  function getListFileExecution(executionId) {
    axios
      .get(`${config.mainAPI}/file-list/${executionId}`)
      .then((response) => {
        console.log("value filelistLED", response.data.data);
        const value = response.data.data;
        if (value) {
          setFileList1(value);
          setRows1(
            value.map((item, index) =>
              createData(
                index + 1,
                item.file_uuid,
                item.file_topic,
                item.file_type,
                item.created_at,
                item.created_by,
                item.file_path,
                item.file_main,
                item.update_at
              )
            )
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // const goCourtEtc = dateGoCourtEtc.map((item, index) => ({
  //   date: item ? moment(item).format("YYYY-MM-DD") : null,
  //   time: timeGoCourtEtc[index] ? timeGoCourtEtc[index] : null,
  //   lawyer: lawyerGoCourtEtc[index] ? lawyerGoCourtEtc[index] : null,
  //   lawyerName: lawyerNameGoCourtEtc[index]
  //     ? lawyerNameGoCourtEtc[index]
  //     : null,
  //   detail: detailGoCourtEtc[index] ? detailGoCourtEtc[index] : null,
  //   round: index + 1,
  // }));

  const goCourtEtc = dateGoCourtEtc.map((item, index) => {
    const combinedDateTime = moment(
      timeGoCourtEtc[index],
      "YYYY-MM-DD HH:mm:ss"
    );
    const formattedDate = moment(combinedDateTime).toDate();

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString("en-US", { month: "short" });
    const day = currentDate.getDate();
    const dateString = `${day} ${month} ${year} ${timeGoCourtEtc[index]} GMT+0700 (Indochina Time)`;

    return {
      date: item ? moment(item).format("YYYY-MM-DD") : null,
      time:
        formattedDate instanceof Date && !isNaN(formattedDate)
          ? formattedDate
          : dateString,
      lawyer: lawyerGoCourtEtc[index] ? lawyerGoCourtEtc[index] : null,
      lawyerName: lawyerNameGoCourtEtc[index]
        ? lawyerNameGoCourtEtc[index]
        : null,
      detail: detailGoCourtEtc[index] ? detailGoCourtEtc[index] : null,
      round: index + 1,
    };
  });

  const contract = gsbNumberList.map((item, index) => ({
    order: index + 1,
    id: item,
    description: "ที่ดิน",
    type: loanTypeList[index],
    typeOfCollateral: typeOfCollateralList[index],
    typeOf: "evication",
    pricipal: principalList[index],
    interest: interestList[index],
    noticeGetDay: noticeDayGetList[index],
    noticeOutDay: noticeDayOutList[index],
    noticeDescription: noticeInvestigateList[index],
    bankruptJudge: "",
    countDay: countDateList[index],
    dateOfInterest: "",
    calInterest: dateCalInterestSinceList[index],
  }));

  const combinedTimeShow = (time) => {
    if (time) {
      const combinedDateTime = moment(time, "YYYY-MM-DD HH:mm:ss");
      const formattedDate = moment(combinedDateTime).toDate();

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.toLocaleString("en-US", { month: "short" });
      const day = currentDate.getDate();
      const dateString = `${day} ${month} ${year} ${time} GMT+0700 (Indochina Time)`;
      return formattedDate instanceof Date && !isNaN(formattedDate)
        ? formattedDate
        : dateString;
    } else {
      return null;
    }
  };

  const handleSetNotification = () => {
    setOpenSetNotifition(true);
  };
  const handleCloseSetNotification = () => {
    setOpenSetNotifition(false);
  };

  function updateStatusFinish() {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/document-success`, {
        genCode: genCode,
        type: "expal",
      })
      .then(function (response) {
        setIsLoading(false);
        setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setOpenFailure(true);
      });
  }

  const handleSetOpenCloseCase = () => {
    setOpenCloseCase(true);
  };

  const handleCloseSetOpenCloseCase = () => {
    setOpenCloseCase(false);
  };

  const handleHistoryWithDraw = () => {
    const id = params;
    navigate("/draw", { state: { params: id } });
  };

  const handleHistoryGetMoney = () => {
    const id = params;
    navigate("/money", { state: { params: id } });
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleUploadFile = (row) => {
    console.log("row", row);
    setListFileUpdateSelected(row);
    setTypeSelected(row.type);
    setOpenUploadFile(true);
  };

  const handleDownload = (row) => {
    const file_path = row.path;
    const fileName = path.basename(file_path);
    saveActivityLog(`Click download file: ${fileName}`);
    const download_url = `${config.mainAPI}/download-file?path=${file_path}`;
    window.location.href = download_url;
  };

  const handleDeleteFile = (row) => {
    console.log("row", row);
    const payload = {
      main: row.main,
      fileName: row.path,
      uuid: row.uuid,
      id: params,
      type: "expal",
    };
    setIsLoading(true);
    const fileName = path.basename(row.path);
    saveActivityLog(`Click Delete file: ${fileName}`);
    axios
      .post(`${config.mainAPI}/deleteFileByPath`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;

        getListFile();
        getListFileImage();
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  function createData(order, uuid, list, type, date, work_by, path, main) {
    const formattedDate = date
      ? moment(date).locale("th").add(543, "year").format("DD/MM/YYYY")
      : null;
    const newList = list ? list : "-";
    return {
      order,
      uuid,
      // list: newList,
      type,
      date: formattedDate,
      work_by,
      path,
      main,
    };
  }

  function createDataImages(
    order,
    uuid,
    list,
    type,
    date,
    work_by,
    path,
    main
  ) {
    const formattedDate = date
      ? moment(date).locale("th").add(543, "year").format("DD/MM/YYYY")
      : null;
    const newList = list ? list : "-";
    return {
      order,
      uuid,
      // list: newList,
      type,
      date: formattedDate,
      work_by,
      path,
      main,
    };
  }

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    // { id: "list", label: "รายการ", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
    { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
    {
      id: "uploadFile",
      label: "อัพโหลดเอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleUploadFile(row)}>
            <UploadFileIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "file",
      label: "เอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          {row.path ? (
            <IconButton
              disabled={!row.path}
              onClick={() => handleDownload(row)}
            >
              <PictureAsPdfIcon color={row.path ? "error" : "inherit"} />
            </IconButton>
          ) : null}
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={!row.path}
            onClick={() => handleDeleteFile(row)}
          >
            <DeleteForeverIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  const columnsImage = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    // { id: "type", label: "รายละเอียด", minWidth: 100 },
    { id: "date", label: "วันที่", minWidth: 100 },
    { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
    {
      id: "file",
      label: "ไฟล์",
      minWidth: 100,
      render: (row) => (
        <>
          {row.path ? (
            <IconButton
              disabled={!row.path}
              onClick={() => handleDownload(row)}
            >
              <CollectionsIcon color={row.path ? "error" : "inherit"} />
            </IconButton>
          ) : null}
        </>
      ),
    },
    {
      id: "delete",
      label: "ลบรายการ",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={!row.path}
            onClick={() => handleDeleteFile(row)}
          >
            <DeleteForeverIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  const handleTimeGoCourtChange = (date) => {
    if (date) {
      const combinedDateTime = moment(date, "YYYY-MM-DD HH:mm:ss");
      const formattedDate = moment(combinedDateTime).toDate();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.toLocaleString("en-US", { month: "short" });
      const day = currentDate.getDate();
      const dateString = `${day} ${month} ${year} ${date} GMT+0700 (Indochina Time)`;
      console.log(
        "formattedDate instanceof Date && !isNaN(formattedDate) ? formattedDate : dateString",
        formattedDate instanceof Date && !isNaN(formattedDate)
          ? formattedDate
          : dateString
      );
      setCourtTime(
        formattedDate instanceof Date && !isNaN(formattedDate)
          ? formattedDate
          : dateString
      );
    } else {
      setCourtTime(null);
    }
  };

  const handleClickUploadFile = async () => {
    console.log("ListFileUpdateSelected", listFileUpdateSelected);
    if (fileUpload) {
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("order", listFileUpdateSelected.order);
      formData.append("type", listFileUpdateSelected.type);
      formData.append("type_docs", "expal");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/updateUploadFileDocs`,
          formData
        );
        saveActivityLog(`Update File:${fileUpload.name}  Success`);
        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
        setListFileUpdateSelected(null);
        setOpenUploadFile(false);
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
        setListFileUpdateSelected(null);
        setOpenUploadFile(false);
      }
    }
  };

  const handleCloseOpenUploadFile = () => {
    setTypeSelected("");
    setFileUpload("");
    setListFileUpdateSelected(null);
    setOpenUploadFile(false);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
        >
          แบบฟอร์มขับไล่
        </Typography>
        <Card>
          {params ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 3,
                marginTop: 3,
                float: "right",
              }}
            >
              {/* <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="dark"
                onClick={handleSetNotification}
                startIcon={<NotificationsActiveIcon />}
              >
                การแจ้งเตือน
              </Button> */}
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="dark"
                onClick={handleHistoryWithDraw}
                startIcon={<RequestQuoteIcon />}
              >
                ประวัติการเบิกเงิน
              </Button>
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="dark"
                onClick={handleHistoryGetMoney}
                startIcon={<PaidIcon />}
              >
                ประวัติการได้รับค่าจ้าง
              </Button>
              {/* <Button
                variant="contained"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="success"
                onClick={handleSetOpenCloseCase}
                startIcon={<DoneAllIcon />}
              >
                ปิดคดี
              </Button> */}
            </Box>
          ) : null}
        </Card>

        {/* <Box sx={{ padding: "30px" }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box> */}

        <Box
          sx={{
            padding: "30px",
            "@media (max-width: 950px)": {
              display: "none", // Show on mobile
            },
            "@media (min-width: 951px)": {
              display: "block", // Hide on larger screens
            },
          }}
        >
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box
          sx={{
            padding: "30px",
            "@media (max-width: 950px)": {
              display: "block", // Show on mobile
            },
            "@media (min-width: 951px)": {
              display: "none", // Hide on larger screens
            },
          }}
        >
          <Typography sx={{ fontSize: "16px" }}>รายการ:</Typography>
          <Select
            value={activeStep}
            onChange={(e) => setActiveStep(e.target.value)}
            variant="outlined"
            color="dark"
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            {steps.map((label, index) => (
              <MenuItem value={index}>{label}</MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ padding: "30px" }}>
          {activeStep == 0 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลลูกหนี้"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ประเภท<span style={{ color: "red" }}> *</span>:
                      </Typography>
                      <Select
                        value={type || ""}
                        onChange={(e) => setType(e.target.value)}
                        variant="outlined"
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <MenuItem value="บุคคล">บุคคล</MenuItem>
                        <MenuItem value="นิติบุคคล">นิติบุคคล</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {type == "บุคคล" ? (
                        <Grid>
                          <Typography sx={{ fontSize: "16px" }}>
                            คำนำหน้า:
                          </Typography>
                          <Select
                            value={prefix}
                            onChange={(e) => setPrefix(e.target.value)}
                            variant="outlined"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {prefixList &&
                              prefixList.map((column) => (
                                <MenuItem
                                  key={column.prefix_id}
                                  value={column.prefix_name_th}
                                >
                                  {column.prefix_name_th}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                      ) : null}

                      {type == "นิติบุคคล" ? (
                        <Grid>
                          <Typography sx={{ fontSize: "16px" }}>
                            ชื่อบริษัท:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={nameCompany}
                            onChange={(e) => setNameCompany(e.target.value)}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  {type == "บุคคล" ? (
                    <>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ชื่อ:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            นามสกุล:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            โทรสาร:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 13 }}
                            value={phone1}
                            onChange={(e) => setPhone1(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            โทรศัพท์:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 10 }}
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เลขที่บัตรประชาชน:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 13 }}
                            value={idCard}
                            onChange={(e) => setIdCard(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        บ้านเลขที่:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={defendantHouseNumber}
                        onChange={(e) =>
                          setDefendantHouseNumber(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>หมู่:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 12 }}
                        value={defendantGroup}
                        onChange={(event) =>
                          setDefendantGroup(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>ถนน:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={defendantRoad}
                        onChange={(e) => setDefendantRoad(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>ซอย:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 12 }}
                        value={defendantSoi}
                        onChange={(event) =>
                          setDefendantSoi(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ใกล้เคียง:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={defendantAddress}
                        onChange={(event) =>
                          setDefendantAddress(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จังหวัด:
                      </Typography>
                      <Select
                        value={defendantProvince || ""}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="">โปรดระบุจังหวัด</MenuItem>
                        {provincesData.map((province) => (
                          <MenuItem
                            key={province.id}
                            value={province.id}
                            name={province.name_th}
                            onClick={(event) => handleProvinceChange(event)}
                          >
                            {province.name_th}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    {defendantProvince ? (
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>
                          อำเภอ:
                        </Typography>
                        <Select
                          value={defendantAmphoe || ""}
                          fullWidth
                          color="dark"
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <MenuItem value="">โปรดระบุอำเภอ</MenuItem>
                          {getAmphoesByProvinceId(defendantProvince).map(
                            (amphoe) => (
                              <MenuItem
                                key={amphoe.id}
                                value={amphoe.id}
                                name={amphoe.name_th}
                                onClick={(event) => handleAmphoeChange(event)}
                              >
                                {amphoe.name_th}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </Grid>
                    ) : null}

                    {defendantAmphoe ? (
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>ตำบล:</Typography>
                        <Select
                          value={defendantTambon || ""}
                          fullWidth
                          color="dark"
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <MenuItem value="">โปรดระบุตำบล</MenuItem>
                          {getTambonsByAmphoeId(defendantAmphoe).map(
                            (tambon) => (
                              <MenuItem
                                key={tambon.id}
                                value={tambon.id}
                                name={tambon.name_th}
                                code={tambon.zip_code}
                                onClick={(event) => handleTambonChange(event)}
                              >
                                {tambon.name_th}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รหัสไปรษณี:
                      </Typography>
                      <TextField
                        id="comment"
                        name="comment"
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        disabled
                        value={defendantZipcode || ""}
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      />
                    </Grid>
                  </Grid> */}

                      {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Typography sx={{ fontSize: "16px" }}>
                            จำนวนผู้ค้ำประกัน:
                          </Typography>
                          <TextField
                            id="countDefendant"
                            name="countDefendant"
                            type="number"
                            color="dark"
                            placeholder="กรุณาระบุจำนวน"
                            fullWidth
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={countDefendant}
                            onChange={(event) =>
                              setCountDefendant(event.target.value)
                            }
                          />
                        </Grid>

                        {countDefendant > 0 && (
                          <>
                            {[...Array(parseInt(countDefendant))].map(
                              (_, i) => (
                                <React.Fragment key={i}>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      สถานะผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <Select
                                      id={`coDefendantStatus${i}`}
                                      name={`coDefendantStatus${i}`}
                                      value={coDefendantStatus[i] || ""}
                                      onChange={(event) =>
                                        handleCoDefendantStatusChange(event, i)
                                      }
                                      variant="outlined"
                                      color="dark"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <MenuItem value="">
                                        โปรดเลือกสถานะของผู้ค้ำประกันที่ {i + 1}
                                      </MenuItem>
                                      {listCoDefendantStatus &&
                                        listCoDefendantStatus.map((column) => (
                                          <MenuItem
                                            key={column.id}
                                            value={column.value}
                                          >
                                            {column.value}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      คำนำหน้าผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <Select
                                      id={`coDefendantPrefix${i}`}
                                      name={`coDefendantPrefix${i}`}
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                      }}
                                      value={coDefendantPrefix[i] || ""}
                                      color="dark"
                                      onChange={(e) =>
                                        handleCoDefendantPrefixChange(e, i)
                                      }
                                    >
                                      {prefixList &&
                                        prefixList.map((column) => (
                                          <MenuItem
                                            key={column.prefix_id}
                                            value={column.prefix_name_th}
                                          >
                                            {column.prefix_name_th}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ชื่อผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`coDefendantName${i}`}
                                      name={`coDefendantName${i}`}
                                      value={coDefendantName[i] || ""}
                                      onChange={(e) =>
                                        handleCoDefendantNameChange(e, i)
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      นามสกุลผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`coDefendantLastName${i}`}
                                      name={`coDefendantLastName${i}`}
                                      value={coDefendantLastName[i] || ""}
                                      onChange={(e) =>
                                        handleCoDefendantLastNameChange(e, i)
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ที่อยู่ผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`coDefendantAddress${i}`}
                                      name={`coDefendantAddress${i}`}
                                      value={coDefendantAddress[i] || ""}
                                      onChange={(e) =>
                                        handleCoDefendantAddressChange(e, i)
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      บัตรประชาชนผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`coDefendantIdCard${i}`}
                                      name={`coDefendantIdCard${i}`}
                                      value={coDefendantIdCard[i] || ""}
                                      onChange={(e) =>
                                        handleCoDefendantIdCardChange(e, i)
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                      inputProps={{ maxLength: 13 }}
                                    />
                                  </Grid>
                                  {coDefendantStatus[i] == "เสียชีวิต" ? (
                                    <>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          สถานะตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <Select
                                          id={`statusDefendantAgent${i}`}
                                          name={`statusDefendantAgent${i}`}
                                          value={statusDefendantAgent[i] || ""}
                                          onChange={(event) =>
                                            handleStatusDefendantAgentChange(
                                              event,
                                              i
                                            )
                                          }
                                          variant="outlined"
                                          color="dark"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            width: "100%",
                                          }}
                                        >
                                          {listStatusDead &&
                                            listStatusDead.map((column) => (
                                              <MenuItem
                                                key={column.id}
                                                value={column.value}
                                              >
                                                {column.value}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          คำนำหน้าตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <Select
                                          id={`prefixDefendantAgent${i}`}
                                          name={`prefixDefendantAgent${i}`}
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            width: "100%",
                                          }}
                                          value={prefixDefendantAgent[i] || ""}
                                          color="dark"
                                          onChange={(e) =>
                                            handlePrefixDefendantAgentChange(
                                              e,
                                              i
                                            )
                                          }
                                        >
                                          {prefixList &&
                                            prefixList.map((column) => (
                                              <MenuItem
                                                key={column.prefix_id}
                                                value={column.prefix_name_th}
                                              >
                                                {column.prefix_name_th}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          ชื่อตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <TextField
                                          id={`nameDefendantAgent${i}`}
                                          name={`nameDefendantAgent${i}`}
                                          value={nameDefendantAgent[i] || ""}
                                          onChange={(e) =>
                                            handleNameDefendantAgentChange(e, i)
                                          }
                                          type="text"
                                          color="dark"
                                          fullWidth
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "#ffffff",
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          นามสกุลตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <TextField
                                          id={`lastNameDefendantAgent${i}`}
                                          name={`lastNameDefendantAgent${i}`}
                                          value={
                                            lastNameDefendantAgent[i] || ""
                                          }
                                          onChange={(e) =>
                                            handleLastNameDefendantAgentChange(
                                              e,
                                              i
                                            )
                                          }
                                          type="text"
                                          color="dark"
                                          fullWidth
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "#ffffff",
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          ที่อยู่ตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <TextField
                                          id={`addressDefendantAgent${i}`}
                                          name={`addressDefendantAgent${i}`}
                                          value={addressDefendantAgent[i] || ""}
                                          onChange={(e) =>
                                            handleAddressDefendantAgentChange(
                                              e,
                                              i
                                            )
                                          }
                                          type="text"
                                          color="dark"
                                          fullWidth
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "#ffffff",
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          บัตรประชาชนตัวแทนผู้ค้ำประกันที่{" "}
                                          {i + 1}:
                                        </Typography>
                                        <TextField
                                          id={`idCardDefendantAgent${i}`}
                                          name={`idCardDefendantAgent${i}`}
                                          value={idCardDefendantAgent[i] || ""}
                                          onChange={(e) =>
                                            handleIdCardDefendantAgentChange(
                                              e,
                                              i
                                            )
                                          }
                                          type="text"
                                          color="dark"
                                          fullWidth
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "#ffffff",
                                          }}
                                          inputProps={{ maxLength: 13 }}
                                        />
                                      </Grid>
                                    </>
                                  ) : null}
                                </React.Fragment>
                              )
                            )}
                          </>
                        )}
                      </Grid> */}
                    </>
                  ) : type == "นิติบุคคล" ? (
                    <>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เลขที่:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 13 }}
                            value={defendantHouseNumber}
                            onChange={(e) =>
                              setDefendantHouseNumber(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            หมู่:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 12 }}
                            value={defendantGroup}
                            onChange={(event) =>
                              setDefendantGroup(event.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ถนน:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 13 }}
                            value={defendantRoad}
                            onChange={(e) => setDefendantRoad(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ซอย:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 12 }}
                            value={defendantSoi}
                            onChange={(event) =>
                              setDefendantSoi(event.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ใกล้เคียง:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={defendantAddress}
                            onChange={(event) =>
                              setDefendantAddress(event.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            จังหวัด:
                          </Typography>
                          <Select
                            value={defendantProvince || ""}
                            // onChange={(event) => setDefendantProvince(event.target.value)}
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <MenuItem value="">โปรดระบุจังหวัด</MenuItem>
                            {provincesData.map((province) => (
                              <MenuItem
                                key={province.id}
                                value={province.id}
                                name={province.name_th}
                                onClick={(event) => handleProvinceChange(event)}
                              >
                                {province.name_th}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>

                        {defendantProvince ? (
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              อำเภอ:
                            </Typography>
                            <Select
                              value={defendantAmphoe || ""}
                              fullWidth
                              color="dark"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <MenuItem value="">โปรดระบุอำเภอ</MenuItem>
                              {getAmphoesByProvinceId(defendantProvince).map(
                                (amphoe) => (
                                  <MenuItem
                                    key={amphoe.id}
                                    value={amphoe.id}
                                    name={amphoe.name_th}
                                    onClick={(event) =>
                                      handleAmphoeChange(event)
                                    }
                                  >
                                    {amphoe.name_th}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </Grid>
                        ) : null}

                        {defendantAmphoe ? (
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ตำบล:
                            </Typography>
                            <Select
                              value={defendantTambon || ""}
                              fullWidth
                              color="dark"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <MenuItem value="">โปรดระบุตำบล</MenuItem>
                              {getTambonsByAmphoeId(defendantAmphoe).map(
                                (tambon) => (
                                  <MenuItem
                                    key={tambon.id}
                                    value={tambon.id}
                                    name={tambon.name_th}
                                    code={tambon.zip_code}
                                    onClick={(event) =>
                                      handleTambonChange(event)
                                    }
                                  >
                                    {tambon.name_th}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รหัสไปรษณี:
                          </Typography>
                          <TextField
                            id="comment"
                            name="comment"
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            disabled
                            value={defendantZipcode || ""}
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </CardContent>
              </Card>
              {type == "บุคคล" ? (
                <>
                  <Card sx={{ mb: 3 }}>
                    <CardHeader
                      sx={{ backgroundColor: "#181414" }}
                      title="ข้อมูลผู้ค้ำประกัน"
                    />
                    <CardContent sx={{ border: "0.5px solid" }}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Typography sx={{ fontSize: "16px" }}>
                            จำนวนผู้ค้ำประกัน:
                          </Typography>
                          <TextField
                            id="countDefendant"
                            name="countDefendant"
                            type="number"
                            color="dark"
                            placeholder="กรุณาระบุจำนวน"
                            fullWidth
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={countDefendant}
                            onChange={(event) =>
                              setCountDefendant(event.target.value)
                            }
                          />
                        </Grid>

                        {countDefendant > 0 && (
                          <>
                            {[...Array(parseInt(countDefendant))].map(
                              (_, i) => (
                                <React.Fragment key={i}>
                                  {/* <hr style={{ marginBottom: "3rem", marginTop: "3rem" }} /> */}
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      สถานะผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <Select
                                      id={`coDefendantStatus${i}`}
                                      name={`coDefendantStatus${i}`}
                                      value={coDefendantStatus[i] || ""}
                                      onChange={(event) =>
                                        handleCoDefendantStatusChange(event, i)
                                      }
                                      variant="outlined"
                                      color="dark"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      <MenuItem value="">
                                        โปรดเลือกสถานะของผู้ค้ำประกันที่ {i + 1}
                                      </MenuItem>
                                      {listCoDefendantStatus &&
                                        listCoDefendantStatus.map((column) => (
                                          <MenuItem
                                            key={column.id}
                                            value={column.value}
                                          >
                                            {column.value}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      คำนำหน้าผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <Select
                                      id={`coDefendantPrefix${i}`}
                                      name={`coDefendantPrefix${i}`}
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                      }}
                                      value={coDefendantPrefix[i] || ""}
                                      color="dark"
                                      onChange={(e) =>
                                        handleCoDefendantPrefixChange(e, i)
                                      }
                                    >
                                      {listPrefix &&
                                        listPrefix.map((column) => (
                                          <MenuItem
                                            key={column.id}
                                            value={column.value}
                                          >
                                            {column.value}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ชื่อผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`coDefendantName${i}`}
                                      name={`coDefendantName${i}`}
                                      value={coDefendantName[i] || ""}
                                      onChange={(e) =>
                                        handleCoDefendantNameChange(e, i)
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      นามสกุลผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`coDefendantLastName${i}`}
                                      name={`coDefendantLastName${i}`}
                                      value={coDefendantLastName[i] || ""}
                                      onChange={(e) =>
                                        handleCoDefendantLastNameChange(e, i)
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ที่อยู่ผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`coDefendantAddress${i}`}
                                      name={`coDefendantAddress${i}`}
                                      value={coDefendantAddress[i] || ""}
                                      onChange={(e) =>
                                        handleCoDefendantAddressChange(e, i)
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      บัตรประชาชนผู้ค้ำประกันที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`coDefendantIdCard${i}`}
                                      name={`coDefendantIdCard${i}`}
                                      value={coDefendantIdCard[i] || ""}
                                      onChange={(e) =>
                                        handleCoDefendantIdCardChange(e, i)
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                      inputProps={{ maxLength: 13 }}
                                    />
                                  </Grid>
                                  {coDefendantStatus[i] == "เสียชีวิต" ? (
                                    <>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          สถานะตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <Select
                                          id={`statusDefendantAgent${i}`}
                                          name={`statusDefendantAgent${i}`}
                                          value={statusDefendantAgent[i] || ""}
                                          onChange={(event) =>
                                            handleStatusDefendantAgentChange(
                                              event,
                                              i
                                            )
                                          }
                                          variant="outlined"
                                          color="dark"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            width: "100%",
                                          }}
                                        >
                                          {listStatusDead &&
                                            listStatusDead.map((column) => (
                                              <MenuItem
                                                key={column.id}
                                                value={column.value}
                                              >
                                                {column.value}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          คำนำหน้าตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <Select
                                          id={`prefixDefendantAgent${i}`}
                                          name={`prefixDefendantAgent${i}`}
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            width: "100%",
                                          }}
                                          value={prefixDefendantAgent[i] || ""}
                                          color="dark"
                                          onChange={(e) =>
                                            handlePrefixDefendantAgentChange(
                                              e,
                                              i
                                            )
                                          }
                                        >
                                          {listPrefix &&
                                            listPrefix.map((column) => (
                                              <MenuItem
                                                key={column.id}
                                                value={column.value}
                                              >
                                                {column.value}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          ชื่อตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <TextField
                                          id={`nameDefendantAgent${i}`}
                                          name={`nameDefendantAgent${i}`}
                                          value={nameDefendantAgent[i] || ""}
                                          onChange={(e) =>
                                            handleNameDefendantAgentChange(e, i)
                                          }
                                          type="text"
                                          color="dark"
                                          fullWidth
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "#ffffff",
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          นามสกุลตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <TextField
                                          id={`lastNameDefendantAgent${i}`}
                                          name={`lastNameDefendantAgent${i}`}
                                          value={
                                            lastNameDefendantAgent[i] || ""
                                          }
                                          onChange={(e) =>
                                            handleLastNameDefendantAgentChange(
                                              e,
                                              i
                                            )
                                          }
                                          type="text"
                                          color="dark"
                                          fullWidth
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "#ffffff",
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          ที่อยู่ตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                        </Typography>
                                        <TextField
                                          id={`addressDefendantAgent${i}`}
                                          name={`addressDefendantAgent${i}`}
                                          value={addressDefendantAgent[i] || ""}
                                          onChange={(e) =>
                                            handleAddressDefendantAgentChange(
                                              e,
                                              i
                                            )
                                          }
                                          type="text"
                                          color="dark"
                                          fullWidth
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "#ffffff",
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          บัตรประชาชนตัวแทนผู้ค้ำประกันที่{" "}
                                          {i + 1}:
                                        </Typography>
                                        <TextField
                                          id={`idCardDefendantAgent${i}`}
                                          name={`idCardDefendantAgent${i}`}
                                          value={idCardDefendantAgent[i] || ""}
                                          onChange={(e) =>
                                            handleIdCardDefendantAgentChange(
                                              e,
                                              i
                                            )
                                          }
                                          type="text"
                                          color="dark"
                                          fullWidth
                                          variant="outlined"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "#ffffff",
                                          }}
                                          inputProps={{ maxLength: 13 }}
                                        />
                                      </Grid>
                                    </>
                                  ) : null}
                                </React.Fragment>
                              )
                            )}
                          </>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              ) : null}
            </>
          ) : null}

          {activeStep == 1 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={countAssets}
                        InputProps={{ inputProps: { min: 1, max: 10 } }}
                        onChange={(e) => setCountAssets(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขสัญญา:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={gsbNumber}
                        onChange={(e) => setGsbNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รหัสทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={propertyCode}
                        onChange={(e) => setPropertyCode(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>ภาค:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={sector}
                        onChange={(e) => setSector(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ศูนย์หนี้:
                      </Typography>
                      <Select
                        value={debtCenter || ""}
                        onChange={(e) => setDebtCenter(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {debtCenterList &&
                          debtCenterList.map((column) => (
                            <MenuItem
                              key={column.debt_center_id}
                              value={column.debt_center_name}
                            >
                              {column.debt_center_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>

                  {/* <Grid spacing={2} container sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนสินเชื่อ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 1, max: 10 } }}
                        value={countLoan}
                        onChange={(e) => setCountLoan(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  {countLoan > 0 && (
                    <>
                      {[...Array(parseInt(countLoan))].map((_, i) => (
                        <React.Fragment key={i}>
                          <div class="separator">สินเชื่อลำดับที่ {i + 1}</div>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เลขสัญญา:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`gsbNumberList${i}`}
                                name={`gsbNumberList${i}`}
                                value={gsbNumberList[i] || ""}
                                onChange={(e) =>
                                  onChangeMultipleValue(setGsbNumberList, e, i)
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เงินต้น:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`principalList${i}`}
                                name={`principalList${i}`}
                                value={principalList[i] || ""}
                                onChange={(e) =>
                                  onChangeMultipleValue(setPrincipalList, e, i)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ดอกเบี้ย:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`interestList${i}`}
                                name={`interestList${i}`}
                                value={interestList[i] || ""}
                                disabled={!principalList[i]}
                                onChange={(e) =>
                                  onChangeMultipleValue(setInterestList, e, i)
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ยอดรวม:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                value={
                                  principalList[i]
                                    ? parseInt(principalList[i]) +
                                      (interestList[i]
                                        ? parseInt(interestList[i])
                                        : 0)
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วันที่คำนวณดอกเบี้ยถึง :
                              </Typography>
                              <WatDatePicker
                                className="custom-date-picker"
                                value={dateCalInterestSinceList[i]}
                                onChange={(christDate, buddhistDate) =>
                                  onChangeDateValue(
                                    christDate,
                                    buddhistDate,
                                    i,
                                    setDateCalInterestSinceList
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                จำนวนวัน:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`countDateList${i}`}
                                name={`countDateList${i}`}
                                value={countDateList[i] || ""}
                                onChange={(e) =>
                                  onChangeMultipleValue(setCountDateList, e, i)
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วันออกโนติส:
                              </Typography>
                              <WatDatePicker
                                className="custom-date-picker"
                                value={noticeDayOutList[i]}
                                onChange={(christDate, buddhistDate) =>
                                  onChangeDateValue(
                                    christDate,
                                    buddhistDate,
                                    i,
                                    setNoticeDayOutList
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วันรับโนติส:
                              </Typography>
                              <WatDatePicker
                                className="custom-date-picker"
                                value={noticeDayGetList[i]}
                                onChange={(christDate, buddhistDate) =>
                                  onChangeDateValue(
                                    christDate,
                                    buddhistDate,
                                    i,
                                    setNoticeDayGetList
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                          </Grid>

                          <Grid container sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                              <Typography sx={{ fontSize: "16px" }}>
                                สอบสวนโนติส:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`noticeInvestigateList${i}`}
                                name={`noticeInvestigateList${i}`}
                                value={noticeInvestigateList[i] || ""}
                                onChange={(e) =>
                                  onChangeMultipleValue(
                                    setNoticeInvestigateList,
                                    e,
                                    i
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  )} */}

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>ศาล:</Typography>
                      {/* <Autocomplete
                options={courtList}
                value={court}
                onChange={(event, newValue) => {
                  setCourt(newValue);
                }}
                getOptionLabel={(option) => option.court_name} // Display court_name in the dropdown
                renderInput={(params) => <TextField {...params} color="dark" />}
              /> */}
                      <Select
                        value={court}
                        onChange={(e) => setCourt(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {courtList &&
                          courtList.map((column) => (
                            <MenuItem
                              key={column.court_name}
                              value={column.court_name}
                            >
                              {column.court_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันขึ้นศาล:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={courtDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCourtDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เวลาขึ้นศาล:
                      </Typography>
                      <TimePicker
                        className="custom-date-picker"
                        value={courtTime}
                        // onChange={(date) => setCourtTime(date)}
                        onChange={(date) => handleTimeGoCourtChange(date)}
                        showSecond={false}
                        allowEmpty
                        disabledHours={disabledHours}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เวลาขึ้นศาล:
                      </Typography>
                      <TimePicker
                        className="custom-date-picker"
                        value={courtTime ? moment(courtTime, "HH:mm:ss") : null}
                        // onChange={(date) => setCourtTime(date)}
                        onChange={(date) => handleTimeGoCourtChange(date)}
                        showSecond={false}
                        allowEmpty
                        disabledHours={disabledHours}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนวันนัดขึ้นศาลเพิ่มเติม:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={countCourtEtc}
                        disabled={!courtDate}
                        onChange={(e) => updateCountCourtEtc(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  {countCourtEtc > 0 && (
                    <>
                      {[...Array(parseInt(countCourtEtc))].map((_, i) => (
                        <React.Fragment key={i}>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วันขึ้นศาลครั้งที่{i + 2}:
                              </Typography>
                              <WatDatePicker
                                className="custom-date-picker"
                                value={dateGoCourtEtc[i]}
                                onChange={(christDate, buddhistDate) =>
                                  handleDateGoCourtEtcChangeByIndex(
                                    christDate,
                                    buddhistDate,
                                    i
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เวลาขึ้นศาลครั้งที่{i + 2}:
                              </Typography>
                              <TimePicker
                                className="custom-date-picker"
                                value={
                                  timeGoCourtEtc[i]
                                    ? moment(timeGoCourtEtc[i], "HH:mm:ss")
                                    : null
                                }
                                onChange={(date) =>
                                  handleTimeGoCourtEtcChange(date, i)
                                }
                                showSecond={false}
                                allowEmpty
                                disabledHours={disabledHours}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            {/* <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ผู้รับผิดชอบวันขึ้นศาลครั้งที่ {i + 2}:
                              </Typography>

                              <Select
                                id={`lawyerGoCourtEtc${i}`}
                                name={`lawyerGoCourtEtc${i}`}
                                value={lawyerGoCourtEtc[i] || ""} // Set the default value to an empty string ('') instead of null
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                required
                              >
                                {userCompany
                                  ? userCompany.map((item) => (
                                      <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        name={item.fullname}
                                        id={item.id}
                                        onClick={(e) =>
                                          handleLawyerGoCourtEtcChange(e, i)
                                        }
                                      >
                                        {item.fullname}
                                      </MenuItem>
                                    ))
                                  : null}
                              </Select>
                            </Grid> */}

                            <Grid item xs={12}>
                              <Typography sx={{ fontSize: "16px" }}>
                                รายละเอียดวันขึ้นศาลครั้งที่ {i + 2}:
                              </Typography>
                              <TextField
                                id={`detailGoCourtEtc${i}`}
                                name={`detailGoCourtEtc${i}`}
                                value={detailGoCourtEtc[i] || ""}
                                onChange={(e) =>
                                  handleDetailGoCourtEtcChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  )}

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเลขคดีดำ:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={blackCaseNumber}
                        onChange={(e) => setBlackCaseNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        คดีดำปี:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        helperText="ตัวอย่าง: 2566"
                        value={blackCaseYear}
                        inputProps={{ maxLength: 4 }}
                        onChange={(e) => setBlackCaseYear(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเลขคดีแดง:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={redCaseNumber}
                        onChange={(e) => setRedCaseNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        คดีแดงปี:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        helperText="ตัวอย่าง: 2566"
                        value={redCaseYear}
                        inputProps={{ maxLength: 4 }}
                        onChange={(e) => setRedCaseYear(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันขอออกหมายบังคับคดี:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={issueAWarranOfExecutionDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setIssueAWarranOfExecutionDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันนัดเจ้าหน้าที่บังคับคดี:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={executionOfficerDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setExecutionOfficerDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันขอออกหมายจับ:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={issueAnArrestWarrant}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setIssueAnArrestWarrant,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันลูกหนี้และบริวารออก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={debtorsAndDependentsDayOut}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDebtorsAndDependentsDayOut,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 2 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลทรัพย์"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={countAssets}
                        InputProps={{ inputProps: { min: 1, max: 10 } }}
                        onChange={(e) => setCountAssets(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  {countAssets > 0 && (
                  <>
                    {[...Array(parseInt(countAssets))].map((_, i) => (
                      <React.Fragment key={i}>
                        <div class="separator">ทรัพย์ลำดับที่ {i + 1}</div>
                        
                      </React.Fragment>
                    ))}
                  </>
                )} */}

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เอกสารสิทธิ์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={licenseDocument}
                        onChange={(e) => setLicenseDocument(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เอกสารสิทธิ์เลขที่:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={licenseDocumentCode}
                        onChange={(e) => setLicenseDocumentCode(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ทรัพย์เลขที่:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={placeHouseNumber}
                        onChange={(e) => setPlaceHouseNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>หมู่:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 12 }}
                        value={placeGroup}
                        onChange={(event) => setPlaceGroup(event.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>ถนน:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={placeRoad}
                        onChange={(e) => setPlaceRoad(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>ซอย:</Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 12 }}
                        value={placeSoi}
                        onChange={(event) => setPlaceSoi(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                  {/* บ้านเลขที่ หมู่ ถนน ซอย ใกล้เคียง โทรศัพท์ โทรสาร */}
                  <Grid container sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ใกล้เคียง:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={placeAddress}
                        onChange={(event) =>
                          setPlaceAddress(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จังหวัด:
                      </Typography>
                      <Select
                        value={placeProvince || ""}
                        // onChange={(event) => setDefendantProvince(event.target.value)}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="">โปรดระบุจังหวัด</MenuItem>
                        {provincesData.map((province) => (
                          <MenuItem
                            key={province.id}
                            value={province.id}
                            name={province.name_th}
                            onClick={(event) => handleProvinceChange(event)}
                          >
                            {province.name_th}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    {placeProvince ? (
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>
                          อำเภอ:
                        </Typography>
                        <Select
                          value={placeAmphoe || ""}
                          fullWidth
                          color="dark"
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <MenuItem value="">โปรดระบุอำเภอ</MenuItem>
                          {getAmphoesByProvinceId(placeProvince).map(
                            (amphoe) => (
                              <MenuItem
                                key={amphoe.id}
                                value={amphoe.id}
                                name={amphoe.name_th}
                                onClick={(event) => handleAmphoeChange(event)}
                              >
                                {amphoe.name_th}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </Grid>
                    ) : null}

                    {placeAmphoe ? (
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>ตำบล:</Typography>
                        <Select
                          value={placeTambon || ""}
                          fullWidth
                          color="dark"
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <MenuItem value="">โปรดระบุตำบล</MenuItem>
                          {getTambonsByAmphoeId(placeAmphoe).map((tambon) => (
                            <MenuItem
                              key={tambon.id}
                              value={tambon.id}
                              name={tambon.name_th}
                              code={tambon.zip_code}
                              onClick={(event) => handleTambonChange(event)}
                            >
                              {tambon.name_th}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รหัสไปรษณี:
                      </Typography>
                      <TextField
                        id="comment"
                        name="comment"
                        type="text"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        disabled
                        value={placeZipcode || ""}
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รายละเอียดสถานที่:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={locationDetail}
                        helperText="ตัวอย่าง: ทาวน์เฮาส์ 2 ชั้น"
                        onChange={(e) => setLocationDetail(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  {/* มีการอัปโหลดภาพมาแสดง */}
                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    รูปภาพข้อมูลทรัพย์:
                  </Typography>
                  {fileName ? null : file ? (
                    <IconButton onClick={uploadFile}>
                      <SaveAltIcon color="primary" />
                    </IconButton>
                  ) : (
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ marginTop: "10px" }}
                    >
                      อัพโหลดรูปภาพ
                      <input
                        type="file"
                        id="file"
                        name="file"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                  )}

                  
                </Grid>
              </Grid> */}

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        โฉนดที่ดินเลขที่:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={titleDeed}
                        onChange={(e) => setTitleDeed(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขที่ที่ดิน:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={landNumber}
                        onChange={(e) => setLandNumber(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หน้าสำรวจ:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={explorePage}
                        onChange={(e) => setExplorePage(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        Google Map:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={googleMap}
                        helperText="ตัวอย่าง:   https://goo.gl/maps/GMuHoBGQAFqtRHjE7"
                        onChange={(e) => setGoogleMap(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                สถานะคดีปัจจุบัน:
              </Typography>
              <Select
                value={caseStatus || ""}
                onChange={(e) => setCaseStatus(e.target.value)}
                variant="outlined"
                color="dark"
                sx={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}
              >
                <MenuItem value="">โปรดระบุ</MenuItem>
                <MenuItem value="อยู่ระหว่างลงพื้นที่ตรวจสอบ">
                  อยู่ระหว่างลงพื้นที่ตรวจสอบ
                </MenuItem>
                <MenuItem value="ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์ แต่ขอขยายระยะเวลาออกจากทรัพย์">
                  ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์
                  แต่ขอขยายระยะเวลาออกจากทรัพย์
                </MenuItem>
                <MenuItem value="อยู่ระหว่างเจรจาซื้อทรัพย์คืน">
                  อยู่ระหว่างเจรจาซื้อทรัพย์คืน
                </MenuItem>
                <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ออกหมายขับไล่">
                  อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ออกหมายขับไล่
                </MenuItem>
                <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการ  ออกหมายจับ">
                  อยู่ระหว่างสำนักงานทนายความดำเนินการ ออกหมายจับ
                </MenuItem>
                <MenuItem value="ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว">
                  ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว
                </MenuItem>
                <MenuItem value="ส่งมอบทรัพย์แล้ว">ส่งมอบทรัพย์แล้ว</MenuItem>
                <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ประสานตำรวจจับกุม">
                  อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย
                  ประสานตำรวจจับกุม
                </MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>
                ผู้รับผิดชอบคดีปัจจุบัน
              </Typography>
              <Select
                value={chooseMemberCompanyNow}
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                required
              >
                {userCompany
                  ? userCompany.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id}
                        name={item.fullname}
                        id={item.id}
                        onClick={(event) => handleWorkByNowChange(event)}
                      >
                        {item.fullname}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>
          </Grid> */}

                  <Grid container sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>วันนัด:</Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={bookingDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setBookingDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เวลานัด:
                      </Typography>
                      <TimePicker
                        className="custom-date-picker"
                        value={
                          bookingTime ? moment(bookingTime, "HH:mm:ss") : null
                        }
                        onChange={(date) => setBookingTime(date)}
                        showSecond={false}
                        allowEmpty
                        // disabledHours={disabledHours}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เจ้าหน้าที่ตำรวจประจำท้องที่:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={staffConnectionName}
                        onChange={(e) => setStaffConnectionName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เบอร์ติดต่อ:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        inputProps={{ maxLength: 13 }}
                        value={staffConnectionPhone}
                        onChange={(e) =>
                          setStaffConnectionPhone(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 5 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px", mb: 1 }}>
                        รูปภาพข้อมูลทรัพย์:
                      </Typography>
                      {images.length > 0 ? (
                        <>
                          {/* <Slider {...settings}>
                            {images.map((item, index) => (
                              <div key={index}>
                                <Magnifier
                                  imageSrc={item}
                                  imageAlt={`Image ${index}`}
                                  style={{ width: '100%' }}
                                  mgShape='square'
                                  mgShowOverflow={false}
                                />
                              </div>
                            ))}
                          </Slider> */}
                          <Slider {...settings}>
                            {images.map((item, index) => (
                              <div key={index}>
                                <Magnifier
                                  imageSrc={item} // Directly use the URL here
                                  imageAlt={`Image ${index}`}
                                  style={{ width: "100%" }}
                                  mgShape="square"
                                  mgShowOverflow={false}
                                />
                              </div>
                            ))}
                          </Slider>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px", mb: 2 }}>
                        อัพโหลดรูปภาพ:
                      </Typography>
                      <FileUploader
                        multiple={false}
                        handleChange={handleUploadImageChange}
                        name="fileUpload"
                        types={fileImageTypes}
                      />
                      <p>
                        {fileImageUpload
                          ? `ชื่อไฟล์: ${fileImageUpload.name}`
                          : ""}
                      </p>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        placeSelf: "center",
                        marginTop: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<ClearIcon />}
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginRight: 2,
                          height: "fit-content",
                        }}
                        onClick={handleClearFileImageUpload}
                      >
                        ล้างค่า
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FileUploadIcon />}
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          height: "fit-content",
                        }}
                        onClick={handleSumbitFileImageUpload}
                      >
                        อัปโหลด
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Paper
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "30px",
                        }}
                      >
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {columnsImage.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align="center"
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredRowsImg
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                  <TableRow key={row.order}>
                                    {columnsImage.map((column) => (
                                      <TableCell key={column.id} align="center">
                                        {column.render
                                          ? column.render(row)
                                          : row[column.id]}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                  </Grid>

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item sm={12}>
                  <RMIUploader
                    isOpen={visible}
                    hideModal={hideModal}
                    onSelect={onSelect}
                    onUpload={onUpload}
                    onRemove={onRemove}
                    dataSources={dataSources}
                  />
                </Grid>
              </Grid> */}
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 3 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="รายการเอกสารในสำนวนคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "30px",
                          }}
                        >
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="center"
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredRows
                                  .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                  .map((row) => (
                                    <TableRow key={row.order}>
                                      {columns.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align="center"
                                        >
                                          {column.render
                                            ? column.render(row)
                                            : row[column.id]}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="อัพโหลดเอกสารเพิ่มเติม"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ประเภทเอกสาร:
                          </Typography>
                          <Select
                            value={typeDocsUpload}
                            onChange={(e) => setTypeDocsUpload(e.target.value)}
                            variant="outlined"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {typeDocsList &&
                              typeDocsList.map((column) => (
                                <MenuItem value={column.value}>
                                  {column.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เรื่อง:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={topicDocsUpload}
                            onChange={(e) => setTopicDocsUpload(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px", mb: 1 }}>
                            อัพโหลดเอกสาร:
                          </Typography>
                          <FileUploader
                            multiple={false}
                            handleChange={handleUploadChange}
                            name="fileUpload"
                            types={fileTypes}
                          />
                          <p style={{ marginTop: "1rem" }}>
                            {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                            {fileUpload ? (
                              <>
                                <IconButton onClick={handleClearFileUpload}>
                                  <DeleteForeverIcon color="error" />
                                </IconButton>
                                <IconButton onClick={handleSubmitFileUpload}>
                                  <UploadFileIcon color="success" />
                                </IconButton>
                              </>
                            ) : null}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 4 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลบริษัท"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขแฟ้ม:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={fileNumber}
                        onChange={(e) => setFileNumber(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่รับเรื่อง:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dateStart}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDateStart,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันกำหนดส่ง:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={deadline}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDeadline,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีศาล:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={courtCode}
                        onChange={(e) => setCourtCode(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีบริษัท:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={companyCode}
                        // defaultValue={defaultCodeCompany}
                        // disabled
                        onChange={(e) => setCompanyCode(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        สถานะคดีปัจจุบัน:
                      </Typography>
                      <Select
                        value={caseStatus || ""}
                        onChange={(e) => setCaseStatus(e.target.value)}
                        variant="outlined"
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <MenuItem value="">โปรดระบุ</MenuItem>
                        <MenuItem value="อยู่ระหว่างลงพื้นที่ตรวจสอบ">
                          อยู่ระหว่างลงพื้นที่ตรวจสอบ
                        </MenuItem>
                        <MenuItem value="ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์ แต่ขอขยายระยะเวลาออกจากทรัพย์">
                          ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์
                          แต่ขอขยายระยะเวลาออกจากทรัพย์
                        </MenuItem>
                        <MenuItem value="อยู่ระหว่างเจรจาซื้อทรัพย์คืน">
                          อยู่ระหว่างเจรจาซื้อทรัพย์คืน
                        </MenuItem>
                        <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ออกหมายขับไล่">
                          อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย
                          ออกหมายขับไล่
                        </MenuItem>
                        <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการ  ออกหมายจับ">
                          อยู่ระหว่างสำนักงานทนายความดำเนินการ ออกหมายจับ
                        </MenuItem>
                        <MenuItem value="ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว">
                          ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว
                        </MenuItem>
                        <MenuItem value="ส่งมอบทรัพย์แล้ว">
                          ส่งมอบทรัพย์แล้ว
                        </MenuItem>
                        <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ประสานตำรวจจับกุม">
                          อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย
                          ประสานตำรวจจับกุม
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ผู้รับผิดชอบคดีหลัก
                        <span style={{ color: "red" }}> *</span>
                      </Typography>
                      <Select
                        value={chooseMemberCompany}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        required
                      >
                        {userCompany
                          ? userCompany.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                name={item.fullname}
                                id={item.id}
                                onClick={(event) => handleWorkByChange(event)}
                              >
                                {item.fullname}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 5 && executionCase ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลบังคับคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีศาล:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={executionCode}
                        onChange={(e) => setExecutionCode(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่รับเรื่อง<span style={{ color: "red" }}> *</span>:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={executionDateStart}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setExecutionDateStart,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันกำหนดส่ง:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={executionDeadline}
                        // onChange={(christDate, buddhistDate) =>
                        //   handleDatePickerChange(
                        //     setExecutionDeadline,
                        //     christDate,
                        //     buddhistDate
                        //   )
                        // }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ผู้รับผิดชอบคดี:
                      </Typography>
                      <Select
                        value={executionWorkBy}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        required
                      >
                        {userCompany
                          ? userCompany.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                name={item.fullname}
                                id={item.id}
                                onClick={(event) =>
                                  handleWorkByLedChange(event)
                                }
                              >
                                {item.fullname}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {/* {activeStep == 6 && executionCase ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="คู่สมรส"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบคู่สมรส:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={spouseCheckDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSpouseCheckDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ชื่อคู่สมรส:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={spouseName}
                        onChange={(e) => setSpouseName(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเลขบัตรประชาชนคู่สมรส:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={spouseIdCard}
                        // onChange={(e) => setSpouseIdCard(e.target.value)}
                        onChange={(e) =>
                          onChangeValueWithNumberAndSetLimit(
                            setSpouseIdCard,
                            13,
                            e
                          )
                        }
                        // onChangeValueWithNumberAndSetLimit
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={spouseNote}
                        onChange={(e) => setSpouseNote(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบรายได้:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={spouseIncomeDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSpouseIncomeDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={spouseStatus}
                        onChange={(e) => setSpouseStatus(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ประกันสังคม"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบรายได้:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={workPlaceIncomeDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setWorkPlaceIncomeDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={workPlaceStatusIncome}
                        onChange={(e) =>
                          setWorkPlaceStatusIncome(e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        สถานที่ทำงาน:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={workPlaceName}
                        onChange={(e) => setWorkPlaceName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เหตุที่อายัดไม่ได้(ขัดข้องการส่งเงิน):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={workPlaceDetailIncome}
                        onChange={(e) =>
                          setWorkPlaceDetailIncome(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งอายัดเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={workPlaceDateSendIncome}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setWorkPlaceDateSendIncome,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนเงิน:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={workPlaceIncome}
                        onChange={(e) => setWorkPlaceIncome(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="กรมขนส่ง"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบกรมขนส่ง:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dltDateCheck}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDltDateCheck,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={dltStatus}
                        onChange={(e) => setDltStatus(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ / รายละเอียดทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dltDetail}
                        onChange={(e) => setDltDetail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="กรมที่ดิน"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบกรมที่ดิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dolDateCheckGround}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDolDateCheckGround,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={dolStatusObject}
                        onChange={(e) => setDolStatusObject(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ / รายละเอียดทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolDetail}
                        onChange={(e) => setDolDetail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        สถานะจำนองทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolStatus}
                        onChange={(e) => setDolStatus(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolFailureDetail}
                        onChange={(e) => setDolFailureDetail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ขายทอดตลาด"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolFailureDetail1}
                        onChange={(e) => setDolFailureDetail1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ประกาศขายทอดตลาด:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolSell}
                        onChange={(e) => setDolSell(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        กรณีรับค่าใช้จ่ายเหลือคืน:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolCostReturn}
                        onChange={(e) => setDolCostReturn(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินส่วนได้จากการขายทอดตลาด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={dolMoneySell}
                        onChange={(e) => setDolMoneySell(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่รับบัญชีรับจ่าย:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dolDateGetSend}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDolDateGetSend,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null} */}

          {activeStep == 6 && executionCase ? (
            <>
              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีขายทอดตลาด"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาล:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostCourt}
                        onChange={(e) => setLedCostCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายเรียก:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostCall}
                        onChange={(e) => setLedCostCall(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายคำบังคับ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostForce}
                        onChange={(e) => setLedCostForce(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าบอกกล่าว:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostTell}
                        onChange={(e) => setLedCostTell(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่าย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostAll}
                        onChange={(e) => setLedCostAll(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าคำร้อง/รับรองเอกสาร:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostRequest}
                        onChange={(e) => setLedCostRequest(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายความ(งวดที่1):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostLawyer1}
                        onChange={(e) => setLedCostLawyer1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายความ(งวดที่2):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostLawyer2}
                        onChange={(e) => setLedCostLawyer2(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก งวด 1 (ฟ้อง):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigete1Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigete1Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(งวดที่1):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledBankPaid1Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedBankPaid1Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก งวด 2 (ฟ้อง):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigete2Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigete2Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(งวดที่2):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledBankPaid2Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedBankPaid2Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}

              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีชั้นอุทธรณ์ / ฎีกา"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาลชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostCourt}
                        onChange={(e) => setCojCostCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostGo}
                        onChange={(e) => setCojCostGo(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่ายชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostSumAll}
                        onChange={(e) => setCojCostSumAll(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostLawyer}
                        onChange={(e) => setCojCostLawyer(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการดำเนินคดีชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostAll}
                        onChange={(e) => setCojCostAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาลชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostCourt}
                        onChange={(e) => setSupCostCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostGo}
                        onChange={(e) => setSupCostGo(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่ายชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostSumAll}
                        onChange={(e) => setSupCostSumAll(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostLawyer}
                        onChange={(e) => setSupCostLawyer(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการดำเนินคดีชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostAll}
                        onChange={(e) => setSupCostAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกชั้นฎีกา:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={supDateReq}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSupDateReq,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={supDatePaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSupDatePaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}

              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีชั้นสืบทรัพย์"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจกรรมสิทธิ์ชั้นสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckRoleInvestigate}
                        onChange={(e) =>
                          setCostCheckRoleInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจยานพาหนะชั้นสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckCarInvestigate}
                        onChange={(e) =>
                          setCostCheckCarInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าใช้จ่ายอื่นๆชั้นสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costEtcInvestigate}
                        onChange={(e) => setCostEtcInvestigate(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costAllInvestigate}
                        onChange={(e) => setCostAllInvestigate(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckInvestigate}
                        onChange={(e) =>
                          setCostCheckInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจสอบระวางแผนที่:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckMapInvestigate}
                        onChange={(e) =>
                          setCostCheckMapInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าจ้างสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckAllInvestigate}
                        onChange={(e) =>
                          setCostCheckAllInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกสืบทรัพย์:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={costWithdrawInvestigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCostWithdrawInvestigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={datePaidInvestigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDatePaidInvestigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}

              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีชั้นบังคับคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตั้งเรื่องอายัด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={sequestrationFee}
                        onChange={(e) => setSequestrationFee(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่าย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={lumpSum}
                        onChange={(e) => setLumpSum(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างอายัด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCost}
                        onChange={(e) => setLedCost(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>รวม:</Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledAll}
                        onChange={(e) => setLedAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกอายัด:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledDate0}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setledDate0,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าธรรมเนียม:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledFess}
                        onChange={(e) => setLedFess(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมาย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledFollow}
                        onChange={(e) => setLedFollow(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่ารับรอง:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledSubmit}
                        onChange={(e) => setLedSubmit(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการยื่นคำร้อง:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostWagesRequest}
                        onChange={(e) => setLedCostWagesRequest(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigateDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigateDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigateDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigateDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างยื่นคำร้อง:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledWagesRequest}
                        onChange={(e) => setLedWagesRequest(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledWagesDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedWagesDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledWagesInvestigateDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedWagesInvestigateDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตั้งเรื่องบังคับคดีแทน(ยึดทรัพย์):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={createLedCost}
                        onChange={(e) => setCreateLedCost(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตั้งเรื่องยึดทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCreateSequester}
                        onChange={(e) => setCostCreateSequester(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่าย(ยึดทรัพย์):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costAllSequester}
                        onChange={(e) => setCostAllSequester(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างยึดทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costSequester}
                        onChange={(e) => setCostSequester(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการบังคับคดี(ยึดทรัพย์):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costAllLedSequester}
                        onChange={(e) => setCostAllLedSequester(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกยึดทรัพย์:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={sequesterDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSequesterDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={sequesterDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSequesterDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าวางเงินค่าใช้จ่ายเพิ่ม:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={seizedWages}
                        onChange={(e) => setSeizedWages(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจรับรองบัญชีรับ-จ่าย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costSequesterCheck}
                        onChange={(e) => setCostSequesterCheck(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าใช้จ่ายอื่น ๆ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costSequesterEtc}
                        onChange={(e) => setCostSequesterEtc(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายอื่น ๆ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costAllSequesterEtc}
                        onChange={(e) => setCostAllSequesterEtc(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dateSequesterInvestigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDateSequesterInvestigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={sequesterInvestigateDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSequesterInvestigateDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง 2
                        %(ได้รับชำระหนี้)ยึดอสังหาฯ(แต่ไม่เกิน100,000บาท):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cost2Per}
                        onChange={(e) => setCost2Per(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost2PerInverstigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost2PerInverstigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost2PerDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost2PerDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง 50 %(ได้รับชำระหนี้):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cost50Per}
                        onChange={(e) => setCost50Per(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost50PerInverstigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost50PerInverstigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost50PerDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost50PerDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้าง
                        1%(ได้รับชำระหนี้ปิดบัญชีหรือปรับปรุงหนี้ระหว่างประกาศขาย)(แต่ไม่เกิน
                        10,000 บาท:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cost1Per}
                        onChange={(e) => setCost1Per(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost1PerInverstigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost1PerInverstigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={cost1PerDateBankPaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCost1PerDateBankPaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีชั้นบังคับคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าใช้จ่ายเหมา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        // value={cost1Per}
                        // onChange={(e) => setCost1Per(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ได้รับเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        // value={cost1PerDateBankPaid}
                        // onChange={(christDate, buddhistDate) =>
                        //   handleDatePickerChange(
                        //     setCost1PerDateBankPaid,
                        //     christDate,
                        //     buddhistDate
                        //   )
                        // }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายความงวดที่1:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        // value={cost1Per}
                        // onChange={(e) => setCost1Per(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ได้รับเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        // value={cost1PerDateBankPaid}
                        // onChange={(christDate, buddhistDate) =>
                        //   handleDatePickerChange(
                        //     setCost1PerDateBankPaid,
                        //     christDate,
                        //     buddhistDate
                        //   )
                        // }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายความงวดที่2:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        // value={cost1Per}
                        // onChange={(e) => setCost1Per(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                      วันที่ได้รับเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        // value={cost1PerDateBankPaid}
                        // onChange={(christDate, buddhistDate) =>
                        //   handleDatePickerChange(
                        //     setCost1PerDateBankPaid,
                        //     christDate,
                        //     buddhistDate
                        //   )
                        // }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 7 && executionCase ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ผลคำพิพากษา"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  {/*  */}

                  {countLoan > 0 && (
                    <>
                      {[...Array(parseInt(countLoan))].map((_, i) => (
                        <React.Fragment key={i}>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เลขสัญญา:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                id={`gsbNumberList${i}`}
                                name={`gsbNumberList${i}`}
                                value={gsbNumberList[i] || ""}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                พิพากษา(ยอดหนี้):
                              </Typography>
                              {/* <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                // value={bankruptNumber1}
                                // onChange={(e) => setBankruptNumber1(e.target.value)}
                              /> */}
                              <WatDatePicker
                                className="custom-date-picker"
                                value={judgmentdebtLedList[i]}
                                onChange={(christDate, buddhistDate) =>
                                  onChangeDateValue(
                                    christDate,
                                    buddhistDate,
                                    i,
                                    setJudgmentdebtLedList
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เงินต้น:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                id={`principalList${i}`}
                                name={`principalList${i}`}
                                value={principalList[i] || ""}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ดอกเบี้ย:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`interestList${i}`}
                                name={`interestList${i}`}
                                value={interestList[i] || ""}
                                disabled
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ยอดรวม:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                value={
                                  principalList[i]
                                    ? parseInt(principalList[i]) +
                                      (interestList[i]
                                        ? parseInt(interestList[i])
                                        : 0)
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  )}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขสัญญา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={loanType}
                        onChange={(e) => setLoanType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        พิพากษา(ยอดหนี้):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptNumber1}
                        onChange={(e) => setBankruptNumber1(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินต้น:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptMoney1}
                        onChange={(e) => setBankruptMoney1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ดอกเบี้ย(%):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptInterest1}
                        onChange={(e) => setBankruptInterest1(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        นับแต่วันที่:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={bankruptSince1}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setBankruptSince1,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเลขบัตรเงินสด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptNumber2}
                        onChange={(e) => setBankruptNumber2(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        พิพากษา(ยอดหนี้):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptJudge2}
                        onChange={(e) => setBankruptJudge2(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินต้น:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptMoney2}
                        onChange={(e) => setBankruptMoney2(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ดอกเบี้ย(%):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptInterest2}
                        onChange={(e) => setBankruptInterest2(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        นับแต่วันที่:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={bankruptSince2}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setBankruptSince2,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid> */}

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาล:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptCourt}
                        onChange={(e) => setBankruptCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมาย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptFollow}
                        onChange={(e) => setBankruptFollow(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าทนายความ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptCostLawyer}
                        onChange={(e) => setBankruptCostLawyer(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าส่งคำบังคับ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptCostSend}
                        onChange={(e) => setBankruptCostSend(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>รวม:</Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        disabled
                        value={bankruptAll}
                        onChange={(e) => setBankruptAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 8 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="รายการเอกสารในสำนวนคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "30px",
                          }}
                        >
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns1.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="center"
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredRows1
                                  .slice(
                                    page1 * rowsPerPage1,
                                    page1 * rowsPerPage1 + rowsPerPage1
                                  )
                                  .map((row) => (
                                    <TableRow key={row.order}>
                                      {columns1.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align="center"
                                        >
                                          {column.render
                                            ? column.render(row)
                                            : row[column.id]}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="อัพโหลดเอกสารเพิ่มเติม"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ประเภทเอกสาร:
                          </Typography>
                          <Select
                            value={typeDocsUpload}
                            onChange={(e) => setTypeDocsUpload(e.target.value)}
                            variant="outlined"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {typeDocsList &&
                              typeDocsList.map((column) => (
                                <MenuItem value={column.value}>
                                  {column.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เรื่อง:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={topicDocsUpload}
                            onChange={(e) => setTopicDocsUpload(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px", mb: 1 }}>
                            อัพโหลดเอกสาร:
                          </Typography>
                          <FileUploader
                            multiple={false}
                            handleChange={handleUploadChange}
                            name="fileUpload"
                            types={fileTypes}
                          />
                          <p style={{ marginTop: "1rem" }}>
                            {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                            {fileUpload ? (
                              <>
                                <IconButton onClick={handleClearFileUpload}>
                                  <DeleteForeverIcon color="error" />
                                </IconButton>
                                <IconButton onClick={handleSubmitFileUpload}>
                                  <UploadFileIcon color="success" />
                                </IconButton>
                              </>
                            ) : null}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={handleBack}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>

      <Dialog open={openFailure} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <ErrorIcon sx={{ color: "#ff0c34", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#ff0c34",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseFailure}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openSuccess} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <CheckCircleOutlineIcon sx={{ color: "#52c41a", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h2"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#1f1f1f",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลสำเร็จ!
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleCloseSuccess}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openSetNotifition} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
              ตั้งค่าการแจ้งเตือน
            </Typography>
            <CalendarMonthIcon />
          </Box>
          {/* <Box textAlign="center">
            <CheckCircleOutlineIcon sx={{ color: "#52c41a", fontSize: 100 }} />
          </Box> */}
          <Typography sx={{ marginTop: 3 }} textAlign="center">
            ท่านต้องการอัปเดตวันสำคัญลงใน Google Calendar ใช่หรือไม่?
          </Typography>
          <Typography sx={{ marginTop: 2, marginBottom: 3 }} textAlign="center">
            (วันขึ้นศาล หรืออื่นๆ)
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseSetNotification}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                // onClick={updateStatusFinish}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openCloseCase} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            ยืนยันการปิดคดี
          </Typography>
          {/* <Box textAlign="center">
            <CheckCircleOutlineIcon sx={{ color: "#52c41a", fontSize: 100 }} />
          </Box> */}
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            โปรดตรวจสอบรายละเอียดให้ครบถ้วนก่อนทำการปิดคดี
            เมื่อปิดคดีแล้วจะไม่สามารถแก้ไขได้
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseSetOpenCloseCase}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={updateStatusFinish}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openUploadFile} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            อัพโหลดเอกสาร{typeSelected}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <FileUploader
                  multiple={false}
                  handleChange={handleUploadChange}
                  name="fileUpload"
                  types={fileTypes}
                />
                <p style={{ marginTop: "1rem" }}>
                  {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseOpenUploadFile}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleClickUploadFile}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsFormExpel;
