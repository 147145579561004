import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import {
  TextField,
  Card,
  Button,
  Typography,
  Paper,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardHeader,
  CardContent,
} from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { TryRounded } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "moment/locale/th";
import "react-datepicker/dist/react-datepicker.css";
import { WatDatePicker } from "thaidatepicker-react";
import config from "../../../config";

const DocumentsFormDraw = () => {
  const [user, setUser] = useState();
  const { state } = useLocation();
  const params = state ? state.params : null;
  const [firstDate, setFirstDate] = useState("");
  const [secondDate, setSecondDate] = useState("");
  const [firstDateMoney, setFirstDateMoney] = useState("");
  const [secondDateMoney, setSecondDateMoney] = useState("");
  const [costAll, setCostAll] = useState("");
  const [costWork, setCostWork] = useState("");
  const [costTagBack, setCostTagBack] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log(JSON.parse(userData));
    setUser(JSON.parse(userData));
    if (params) {
      getDataForm();
    }
  }, []);
  const navigate = useNavigate();

  const getDataForm = () => {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/draw/${params}`)
      .then((response) => {
        const value = response.data.data[0];
        console.log("vaue", value);
        setFirstDate(value.first_date);
        setSecondDate(value.second_date);
        setFirstDateMoney(value.first_date_draw);
        setSecondDateMoney(value.second_date_draw);
        setCostAll(value.cost_all);
        setCostWork(value.cost_work);
        setCostTagBack(value.cost_tag_back);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };
  const handleSubmit = (row) => {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/draw`, {
        genCode: params,
        firstDate: firstDate,
        secondDate: secondDate,
        firstDateMoney: firstDateMoney || null,
        secondDateMoney: secondDateMoney || null,
        costAll: costAll,
        costWork: costWork,
        costTagBack: costTagBack,
        user: user.username,
      })
      .then(function (response) {
        console.log("success");
        setIsLoading(false);
        setOpenSuccess(true);

        // Dialog update success
      })
      .catch(function (error) {
        setIsLoading(false);
        setOpenFailure(true);
        console.log(error);
      });
  };

  const handleDocumentForm = () => {
    const id = params;
    navigate("/loan/form", { state: { params: id } });
  };

  const handleHistoryGetMoney = () => {
    const id = params;
    navigate("/money", { state: { params: id } });
  };

  const handleCloseFailure = () => {
    setOpenFailure(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate("/documents");
  };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setData(christDate);
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
        >
          รายละเอียดการขอเบิกค่าจ้างทนายความ
        </Typography>
        <Card>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: 3,
              marginTop: 3,
              float: "right",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                marginRight: "20px",
                marginTop: "10px",
                marginBottom: "20px",
                color: "#000",
                borderColor: "#000",
              }}
              color="primary"
              onClick={handleDocumentForm}
              startIcon={<AssignmentIcon />}
            >
              รายละเอียดเอกสาร
            </Button>
            <Button
              variant="outlined"
              sx={{
                marginRight: "20px",
                marginTop: "10px",
                marginBottom: "20px",
                color: "#000",
                borderColor: "#000",
              }}
              color="primary"
              onClick={handleHistoryGetMoney}
              startIcon={<PaidIcon />}
            >
              ประวัติการได้รับค่าจ้าง
            </Button>
          </Box>
        </Card>
        <Box sx={{ padding: "30px" }}>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="รายละเอียด"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    งวดที่ 1 วันที่:
                  </Typography>
                  <WatDatePicker
                    className="custom-date-picker"
                    value={firstDate}
                    onChange={(christDate, buddhistDate) =>
                      handleDatePickerChange(
                        setFirstDate,
                        christDate,
                        buddhistDate
                      )
                    }
                    dateFormat={"yyyy-MM-dd"}
                    displayFormat={"DD/MM/YYYY"}
                    clearable={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>จำนวนเงิน:</Typography>
                  <TextField
                    type="number"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={firstDateMoney}
                    onChange={(event) => setFirstDateMoney(event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    งวดที่ 2 วันที่:
                  </Typography>
                  <WatDatePicker
                    className="custom-date-picker"
                    value={secondDate}
                    onChange={(christDate, buddhistDate) =>
                      handleDatePickerChange(
                        setSecondDate,
                        christDate,
                        buddhistDate
                      )
                    }
                    dateFormat={"yyyy-MM-dd"}
                    displayFormat={"DD/MM/YYYY"}
                    clearable={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>จำนวนเงิน:</Typography>
                  <TextField
                    type="number"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={secondDateMoney}
                    onChange={(event) => setSecondDateMoney(event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ค่าใช้จ่ายเหมา:
                  </Typography>
                  <TextField
                    type="number"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={costAll}
                    onChange={(event) => setCostAll(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ค่าใช้จ่ายในการดำเนินคดี:
                  </Typography>
                  <TextField
                    type="number"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={costWork}
                    onChange={(event) => setCostWork(event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ค่าธรรมเนียมศาลสั่งคืน:
                  </Typography>
                  <TextField
                    type="number"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={costTagBack}
                    onChange={(event) => setCostTagBack(event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => navigate("/documents")}
                    sx={{ marginRight: 2 }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                  >
                    บันทึก
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Card>
      <Dialog open={openFailure} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <ErrorIcon sx={{ color: "#ff0c34", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#ff0c34",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseFailure}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openSuccess} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <CheckCircleOutlineIcon sx={{ color: "#52c41a", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h2"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#1f1f1f",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลสำเร็จ!
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleCloseSuccess}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsFormDraw;
