import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import moment1 from "moment-timezone";
import { FileUploader } from "react-drag-drop-files";
import path from "path-browserify";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SaveIcon from "@mui/icons-material/Save";
import {
  TextField,
  Card,
  Button,
  Typography,
  Paper,
  Box,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
  CardHeader,
  CardContent,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Divider,
  Stepper,
  Step,
  StepButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";

import provincesData from "../../../components/province.json";
import provincesOnly from "../../../components/provinceOnly.json";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PaidIcon from "@mui/icons-material/Paid";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import useAuth from "function/useAuth";
import { WatDatePicker } from "thaidatepicker-react";
import TimePicker from "rc-time-picker";
import ClearIcon from "@mui/icons-material/Clear";
import "rc-time-picker/assets/index.css";
import config from "../../../config";
import api from "../../../api";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const DocumentsFormLoan = () => {
  const [user, setUser] = useState();
  const [userCompany, setUserCompany] = useState(null);
  const { state } = useLocation();
  const params = state ? state.params : null;
  const genCode = params
    ? params
    : `Thanic-Loan-${moment().format("YYMMDDHHmmssSS")}`;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openCloseCase, setOpenCloseCase] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [openUploadFileExecution, setOpenUploadFileExecution] = useState(false);
  const [openWarningDelete, setOpenWarningDelete] = useState(false);
  const [openSetNotifition, setOpenSetNotifition] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [deadline, setDeadline] = useState(null); // วันกำหนดส่ง
  const [prefix, setPrefix] = useState("");
  const [defendantFirstName, setDefendantFirstName] = useState("");
  const [defendantLastName, setDefendantLastName] = useState("");
  const [defendantIdCard, setDefendantIdCard] = useState("");
  const [defendantAccountNumber, setDefendantAccountNumber] = useState("");
  const [defendantAccountNumber1, setDefendantAccountNumber1] = useState("");
  const [defendantAccount, setDefendantAccount] = useState("");
  const [defendantHouseNumber, setDefendantHouseNumber] = useState("");
  const [defendantGroup, setDefendantGroup] = useState("");
  const [defendantRoad, setDefendantRoad] = useState("");
  const [defendantSoi, setDefendantSoi] = useState("");
  const [defendantAddress, setDefendantAddress] = useState("");
  const [defendantTambon, setDefendantTambon] = useState("");
  const [defendantAmphoe, setDefendantAmphoe] = useState("");
  const [defendantProvince, setDefendantProvince] = useState("");
  const [defendantTambonName, setDefendantTambonName] = useState("");
  const [defendantAmphoeName, setDefendantAmphoeName] = useState("");
  const [defendantProvinceName, setDefendantProvinceName] = useState("");
  const [defendantZipcode, setDefendantZipcode] = useState("");

  const [defendantNumberOfAccounts, setDefendantNumberOfAccounts] = useState(1);

  const [defendantContractHouseNumber, setDefendantContractHouseNumber] =
    useState("");
  const [defendantContractGroup, setDefendantContractGroup] = useState("");
  const [defendantContractRoad, setDefendantContractRoad] = useState("");
  const [defendantContractSoi, setDefendantContractSoi] = useState("");
  const [defendantContractAddress, setDefendantContractAddress] = useState("");
  const [defendantContractTambon, setDefendantContractTambon] = useState("");
  const [defendantContractAmphoe, setDefendantContractAmphoe] = useState("");
  const [defendantContractProvince, setDefendantContractProvince] =
    useState("");
  const [defendantContractTambonName, setDefendantContractTambonName] =
    useState("");
  const [defendantContractAmphoeName, setDefendantContractAmphoeName] =
    useState("");
  const [defendantContractProvinceName, setDefendantContractProvinceName] =
    useState("");
  const [defendantContractZipcode, setDefendantContractZipcode] = useState("");

  const [mortgageProperty, setMortgageProperty] = useState("0"); // ทรัพย์จำนอง
  
  const [mortgagePropertyList, setMortgagePropertyList] = useState([]); // ทรัพย์จำนอง
  const [mortgagePropertyName, setMortgagePropertyName] = useState(""); // ผู้จำนอง
  const [mortgagePropertyDay, setMortgagePropertyDay] = useState(""); // วันที่จำนอง
  const [mortgagePropertyDeed, setMortgagePropertyDeed] = useState(""); // โฉนดเลขที่
  const [mortgagePropertyNumber, setMortgagePropertyNumber] = useState(""); // 	เลขที่ที่ดิน
  const [mortgagePropertyExplore, setMortgagePropertyExplore] = useState(""); // 		หน้าสำรวจ
  const [mortgagePropertyTambon, setMortgagePropertyTambon] = useState(""); // 			ตำบล
  const [mortgagePropertyAmphoe, setMortgagePropertyAmphoe] = useState(""); // 			อำเภอ
  const [mortgagePropertyProvince, setMortgagePropertyProvince] = useState(""); // 		จังหวัด
  const [mortgagePropertyTambonShow, setMortgagePropertyTambonShow] = useState(""); // 			ตำบล
  const [mortgagePropertyAmphoeShow, setMortgagePropertyAmphoeShow] = useState(""); // 			อำเภอ
  const [mortgagePropertyProvinceShow, setMortgagePropertyProvinceShow] = useState(""); // 		จังหวัด
  const [mortgagePropertyZipcode, setMortgagePropertyZipcode] = useState(""); // 		จังหวัด
  const [mortgagePropertyVillage, setMortgagePropertyVillage] = useState(""); // 		จังหวัด
  
  const [mortgagePropertyListSelect, setMortgagePropertyListSelect] = useState(
    []
  ); // ทรัพย์จำนอง
  const [mortgagePropertyType, setMortgagePropertyType] = useState(""); // ประเภททรัพย์จำนอง
  const [mortgagePropertyRoomNumber, setMortgagePropertyRoomNumber] =
    useState(""); // 		ห้องชุดเลขที่
  const [mortgagePropertyTowerNumber, setMortgagePropertyTowerNumber] =
    useState(""); // 		ทะเบียนอาคารชุดเลขที่
  const [mortgagePropertyTowerName, setMortgagePropertyTowerName] =
    useState(""); // 		ชื่ออาคาร

  const [reservePrefix, setReservePrefix] = useState("");
  const [reserveFirstname, setReserveFirstname] = useState("");
  const [reserveLastname, setReserveLastname] = useState("");
  const [reserveIdcard, setReserveIdcard] = useState("");
  const [reserveAddress, setReserveAddress] = useState("");
  const [reserveTel, setReserveTel] = useState("");
  const [reserveRole, setReserveRole] = useState("");

  const [debtCenter, setDebtCenter] = useState("");
  const [branchAgency, setBranchAgency] = useState(""); // หน่วยงานสาขา
  const [districtAgency, setDistrictAgency] = useState(""); // หน่วยงานเขต
  const [sectorAgency, setSectorAgency] = useState(""); // หน่วยงานภาค
  const [loanType, setLoanType] = useState(""); // ประเภทสินเชื่อ
  const [typeOfCollateral, setTypeOfCollateral] = useState(""); // ประเภทหลักประกัน
  const [sueDay, setSueDay] = useState(null); // วันฟ้อง
  const [courtDate, setCourtDate] = useState(null); // วันขึ้นศาล
  const [courtTime, setCourtTime] = useState(null); // เวลาขึ้นศาล
  const [noticeDayGet, setNoticeDayGet] = useState(null);
  const [noticeDayOut, setNoticeDayOut] = useState(null);
  const [noticeDateSign, setNoticeDateSign] = useState(null);

  const [noticeInvestigate, setNoticeInvestigate] = useState(""); // สอบสวนโนติส
  const [principalCapital, setPrincipalCapital] = useState(""); // ทุนทรัพย์เงินต้น
  const [interestCapital, setInterestCapital] = useState(""); // ทุนทรัพย์ดอกเบี้ย
  const [court, setCourt] = useState(""); // ศาล
  const [blackCaseNumber, setBlackCaseNumber] = useState(""); // เลขคดีดำเลขที่
  const [blackCaseYear, setBlackCaseYear] = useState(""); // เลขคดีดำปี
  const [redCaseNumber, setRedCaseNumber] = useState(""); // เลขคดีแดงเลขที่
  const [redCaseYear, setRedCaseYear] = useState(""); // เลขคดีดำปี
  const [witnessExaminationDate, setWitnessExaminationDate] = useState(null); // นัดสืบพยานวันที่
  const [judgmentDay, setJudgmentDay] = useState(null); // วันพิพากษา
  const [judgmentUnilateral, setJudgmentUnilateral] = useState(null); // พิพากษา(ฝ่ายเดียว)
  const [judgmentAccordingToConsent, setJudgmentAccordingToConsent] =
    useState(null); // พิพากษา(ตามยอม)
  const [detailsJudgment, setDetailsJudgment] = useState(""); // รายละเอียด คำพิพากษา
  const [returnFile, setReturnFile] = useState(null); // ส่งแฟ้มคืน
  const [returnFileReason, setReturnFileReason] = useState(""); // ส่งแฟ้มคืนเหตุผล
  const [decree, setDecree] = useState(null); // ออกคำบังคับ
  const [issueAWarranOfExecution, setIssueAWarranOfExecution] = useState(null); // ออกคำบังคับ
  const [withdrawOneCase, setWithdrawOneCase] = useState(null); // ถอนฟ้อง
  const [withdrawOneCaseDueTo, setWithdrawOneCaseDueTo] = useState(""); // เนื่องจาก
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [checkFile, setCheckFile] = useState(false);

  const [accountNumber, setAccountNumber] = useState([]); //

  const [countCoBorrower, setCountCoBorrower] = useState(0); // จำนวนผู้กู้ร่วม
  const [coBorrowerPrefix, setCoBorrowerPrefix] = useState([]); // คำนำหน้าผู้กู้ร่วม
  const [coBorrowerName, setCoBorrowerName] = useState([]); // ชื่อผู้กู้ร่วม
  const [coBorrowerLastName, setCoBorrowerLastName] = useState([]); // นามสกุลผู้กู้ร่วม
  const [coBorrowerAddress, setCoBorrowerAddress] = useState([]); // ที่อยู่ผู้กู้ร่วม
  const [coBorrowerIdCard, setCoBorrowerIdCard] = useState([]); // บัตรประชาชนผู้กู้ร่วม
  const [coBorrowerStatus, setCoBorrowerStatus] = useState([]); // สถานะผู้กู้ร่วม

  const [reserveCoBorrowerPrefix, setReserveCoBorrowerPrefix] = useState([]); // คำนำหน้าผู้กู้ร่วม
  const [reserveCoBorrowerFirstname, setReserveCoBorrowerFirstname] = useState(
    []
  ); // ชื่อผู้กู้ร่วม
  const [reserveCoBorrowerLastname, setReserveCoBorrowerLastname] = useState(
    []
  ); // นามสกุลผู้กู้ร่วม
  const [reserveCoBorrowerIdCard, setReserveCoBorrowerIdCard] = useState([]); // ที่อยู่ผู้กู้ร่วม
  const [reserveCoBorrowerAddress, setReserveCoBorrowerAddress] = useState([]); // บัตรประชาชนผู้กู้ร่วม
  const [reserveCoBorrowerTel, setReserveCoBorrowerTel] = useState([]); // บัตรประชาชนผู้กู้ร่วม
  const [reserveCoBorrowerRole, setReserveCoBorrowerRole] = useState([]); // บัตรประชาชนผู้กู้ร่วม

  const [countDefendant, setCountDefendant] = useState(0); // จำนวนผู้ค้ำประกัน
  const [coDefendantPrefix, setCoDefendantPrefix] = useState([]); // คำนำหน้าผู้ค้ำประกัน
  const [coDefendantName, setCoDefendantName] = useState([]); // ชื่อผู้ค้ำประกัน
  const [coDefendantLastName, setCoDefendantLastName] = useState([]); // นามสกุลผู้ค้ำประกัน
  const [coDefendantAddress, setCoDefendantAddress] = useState([]); // ที่อยู่ผู้ค้ำประกัน
  const [coDefendantIdCard, setCoDefendantIdCard] = useState([]); // บัตรประชาชนผู้ค้ำประกัน
  const [coDefendantStatus, setCoDefendantStatus] = useState([]); // สถานะผู้ค้ำประกัน
  const [statusDefendant, setStatusDefendant] = useState([]);
  const [addressCoDefendant, setAddressCoDefendant] = useState([]);
  const [prefixDefendantAgent, setPrefixDefendantAgent] = useState([]); // ชื่อตัวแทนผู้ค้ำประกัน
  const [nameDefendantAgent, setNameDefendantAgent] = useState([]); // ชื่อตัวแทนผู้ค้ำประกัน
  const [lastNameDefendantAgent, setLastNameDefendantAgent] = useState([]); // นามสกุลตัวแทนผู้ค้ำประกัน
  const [idCardDefendantAgent, setIdCardDefendantAgent] = useState([]); // รหัสบัตรประชาชนตัวแทนผู้ค้ำประกัน
  const [addressDefendantAgent, setAddressDefendantAgent] = useState([]); // ที่อยู่ตัวแทนผู้ค้ำประกัน
  const [statusDefendantAgent, setStatusDefendantAgent] = useState([]); // สถานะตัวแทนผู้ค้ำประกัน
  const [note, setNote] = useState(""); // หมายเหตุ
  const [companyCode, setCompanyCode] = useState(""); // เลขคดีบริษัท
  const [courtCode, setCourtCode] = useState(""); // เลขคดีศาล
  const [chooseMemberCompany, setChooseMemberCompany] = useState(""); // ไอดีผู้รับผิดชอบคดี
  const [chooseMemberCompanyName, setChooseMemberCompanyName] = useState(""); // ผู้รับผิดชอบคดี
  const [appealDueDate, setAppealDueDate] = useState(null); // วันครบกำหนดอุทธรณ์
  const [phone, setPhone] = useState(""); // โทรศัพท์
  const [phone1, setPhone1] = useState(""); // โทรสาร
  const [statusDefendantNow, setStatusDefendantNow] = useState(""); // สถานะจำเลย
  const [fileNumber, setFileNumber] = useState(""); // เลขแฟ้ม
  const [other, setOther] = useState("");
  const [debtCenterList, setDebtCenterList] = useState([]);
  const [countCourtEtc, setCountCourtEtc] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedThaiDate, setSelectedThaiDate] = useState(null);
  const [dateGoCourtEtc, setDateGoCourtEtc] = useState([]); // วันที่ขึ้นศาลครั้งนั้น
  const [timeGoCourtEtc, setTimeGoCourtEtc] = useState([]); // เวลาขึ้นศาลครั้งนั้น
  const [lawyerGoCourtEtc, setLawyerGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [lawyerNameGoCourtEtc, setLawyerNameGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [detailGoCourtEtc, setDetailGoCourtEtc] = useState([]); // รายละเอียดขึ้นศาลครั้งนั้น
  const [countCodefendant, setCountCodefendant] = useState(0);
  const [countDefendantAgent, setCountDefendantAgent] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [courtList, setCourtList] = useState([]);
  const [prefixList, setPrefixList] = useState([]);
  const [caseStatus, setCaseStatus] = useState(""); // สถานะคดีปัจจุบัน
  const [closeDate, setCloseDate] = useState(new Date());
  const [typeDocsUpload, setTypeDocsUpload] = useState("");
  const [topicDocsUpload, setTopicDocsUpload] = useState("");
  const [typeDocsUpload1, setTypeDocsUpload1] = useState("");
  const [topicDocsUpload1, setTopicDocsUpload1] = useState("");
  //   const [typeDocsList, setTypeDocsList] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUpload1, setFileUpload1] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows1, setRows1] = useState([]);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(30);
  const [fileData, setFileData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileList1, setFileList1] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [nameCompany, setNameCompany] = useState(""); // ชื่อบริษัท
  const [addressLoan, setAddressLoan] = useState(0); // ชื่อบริษัท
  const [alternativeAddress, setAlternativeAddress] = useState(""); // ชื่อบริษัท

  // Notice
  const [noticePrincipal, setNoticePrincipal] = useState(0);
  const [noticeInterest, setNoticeInterest] = useState(0);
  const [noticeSumAll, setNoticeSumAll] = useState(0);
  const [noticeCalDate, setNoticeCalDate] = useState("");

  // led
  const [executionCase, setExecutionCase] = useState("");
  const [executionStatus, setExecutionStatus] = useState("");
  const [executionId, setExecutionId] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");

  // const [executionCode, setExecutionCode] = useState("");
  // const [executionDateStart, setExecutionDateStart] = useState(null);
  // const [executionDeadline, setExecutionDeadline] = useState(null);
  // const [executionCourtDate, setExecutionCourtDate] = useState(null);
  // const [executionCourtTime, setExecutionCourtTime] = useState(null);
  // const [executionCourtNumber, setExecutionCourtNumber] = useState("");
  // const [executionWorkBy, setExecutionWorkBy] = useState("");
  // const [executionWorkByName, setExecutionWorkByName] = useState("");
  const [bringTheRegulations, setBringTheRegulations] = useState(null);
  const [writOfExecutionDate, setWritOfExecutionDate] = useState(null);

  const [typeSelected, setTypeSelected] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const [loanTypeList, setLoanTypeList] = useState([]); // ประเภทสินเชื่อ
  const [typeOfCollateralList, setTypeOfCollateralList] = useState([]); // ประเภทหลักประกัน
  const [noticeDayOutList, setNoticeDayOutList] = useState([]); // วันออกโนติส
  const [noticeDayGetList, setNoticeDayGetList] = useState([]); // วันรับโนติส
  const [noticeInvestigateList, setNoticeInvestigateList] = useState([]); // สอบสวนโนติส
  const [principalList, setPrincipalList] = useState([]); // เงินต้น
  const [interestList, setInterestList] = useState([]); // ดอกเบี้ย
  const [gsbNumberList, setGsbNumberList] = useState([]); // เลขสัญญา
  const [dateCalInterestSinceList, setDateCalInterestSinceList] = useState([]); //
  const [countDateList, setCountDateList] = useState([]); //
  const [judgmentdebtLedList, setJudgmentdebtLedList] = useState([]); //

  const [textFail, setTextFail] = useState([]);

  const [listFileUpdateSelected, setListFileUpdateSelected] = useState(null);
  const [gsbNumber, setGsbNumber] = useState("");
  const [type, setType] = useState("");

  const [dateOfSigning, setDateOfSigning] = useState("");
  const [dateOfOutStanding, setDateOfOutStanding] = useState("");
  const [principalContract, setPrincipalContract] = useState("");
  const [interestContract, setInterestContract] = useState("");

  const [steps, setSteps] = useState([
    "ข้อมูลลูกหนี้",
    "ข้อมูลคดี",
    "รายการเอกสารในสำนวนคดี",
    "ข้อมูลบริษัท",
    // "ข้อมูลบังคับคดี",
  ]);
  const [completed, setCompleted] = useState(Array(steps.length).fill(false));

  // Led
  const [executionCode, setExecutionCode] = useState(""); // เลขคดีศาล
  const [executionDateStart, setExecutionDateStart] = useState(null); // วันกำหนดส่ง
  const [executionDeadline, setExecutionDeadline] = useState(null); // วันที่รับเรื่อง
  const [executionCourtDate, setExecutionCourtDate] = useState(null); // วันขึ้นศาล
  const [executionCourtTime, setExecutionCourtTime] = useState(null); // เวลาขึ้นศาล
  const [executionCourtNumber, setExecutionCourtNumber] = useState(""); // วันขึ้นศาล
  const [executionWorkBy, setExecutionWorkBy] = useState(""); // ผู้รับผิดชอบคดี
  const [executionWorkByName, setExecutionWorkByName] = useState(""); // ผู้รับผิดชอบคดี

  // ===================================
  const [spouseCheckDate, setSpouseCheckDate] = useState(null); // วันที่ส่งตรวจสอบคู่สมรส
  const [spouseName, setSpouseName] = useState(""); // ชื่อคู่สมรส
  const [spouseIdCard, setSpouseIdCard] = useState(""); // หมายเลขบัตรประชาชน
  const [spouseNote, setSpouseNote] = useState(""); // หมายเหตุ
  const [spouseIncomeDate, setSpouseIncomeDate] = useState(null); // วันที่ส่งตรวจสอบรายได้
  const [spouseStatus, setSpouseStatus] = useState(""); // สถานะ
  // ===================================
  const [workPlaceIncomeDate, setWorkPlaceIncomeDate] = useState(null); // วันที่ส่งตรวจสอบรายได้
  const [workPlaceName, setWorkPlaceName] = useState(""); // สถานที่ทำงาน
  const [workPlaceIncome, setWorkPlaceIncome] = useState(""); // จำนวนเงิน
  const [workPlaceDateSendIncome, setWorkPlaceDateSendIncome] = useState(null); // วันที่ส่งอายัดเงิน
  const [workPlaceDetailIncome, setWorkPlaceDetailIncome] = useState(""); // เหตุที่อายัดไม่ได้(ขัดข้องการส่งเงิน)
  const [workPlaceStatusIncome, setWorkPlaceStatusIncome] = useState(""); // สถานะ
  // ===================================
  const [dltDateCheck, setDltDateCheck] = useState(null); // วันที่ส่งตรวจสอบกรมขนส่ง
  const [dltDetail, setDltDetail] = useState(""); // หมายเหตุ / รายละเอียดทรัพย์
  const [dltStatus, setDltStatus] = useState(""); // สถานะ
  // ===================================
  const [dolDateCheckGround, setDolDateCheckGround] = useState(null); // วันที่ส่งตรวจสอบกรมที่ดิน
  const [dolStatusObject, setDolStatusObject] = useState(""); // สถานะจำนองทรัพย์
  const [dolDetail, setDolDetail] = useState(""); // หมายเหตุ / รายละเอียดทรัพย์
  const [dolFailureDetail, setDolFailureDetail] = useState(""); // เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน)
  const [dolStatus, setDolStatus] = useState(""); // สถานะ
  // ===================================
  const [dolSell, setDolSell] = useState(""); // ประกาศขายทอดตลาด
  const [dolMoneySell, setDolMoneySell] = useState(""); // เงินส่วนได้จากการขายทอดตลาด
  const [dolCostReturn, setDolCostReturn] = useState(""); // กรณีรับค่าใช้จ่ายเหลือคืน
  const [dolFailureDetail1, setDolFailureDetail1] = useState(""); // เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน)
  const [dolDateGetSend, setDolDateGetSend] = useState(null); // วันที่รับบัญชีรับจ่าย
  // ===================================

  const [ledCostCourt, setLedCostCourt] = useState(""); // ค่าขึ้นศาล
  const [ledCostCall, setLedCostCall] = useState(""); // ค่านำหมายเรียก
  const [ledCostForce, setLedCostForce] = useState(""); // ค่านำหมายคำบังคับ
  const [ledCostTell, setLedCostTell] = useState(""); // ค่าบอกกล่าว
  const [ledCostAll, setLedCostAll] = useState(""); // ค่าเหมาจ่าย
  const [ledCostRequest, setLedCostRequest] = useState(""); // ค่าคำร้อง/รับรองเอกสาร
  const [ledCostLawyer1, setLedCostLawyer1] = useState(""); // ค่าจ้างทนายความ(งวดที่1)
  const [ledCostLawyer2, setLedCostLawyer2] = useState(""); // ค่าจ้างทนายความ(งวดที่2)
  const [ledInvestigete1Date, setLedInvestigete1Date] = useState(null); // วันที่เบิก งวด 1 (ฟ้อง)
  const [ledBankPaid1Date, setLedBankPaid1Date] = useState(null); // วันที่ธนาคารจ่ายเงิน(งวดที่1)
  const [ledInvestigete2Date, setLedInvestigete2Date] = useState(null); // วันที่เบิก งวด 2 (ฟ้อง)
  const [ledBankPaid2Date, setLedBankPaid2Date] = useState(null); // วันที่ธนาคารจ่ายเงิน(งวดที่2)
  // ===================================
  const [cojCostCourt, setCojCostCourt] = useState(""); // ค่าขึ้นศาลชั้นอุทธรณ์
  const [cojCostGo, setCojCostGo] = useState(""); // ค่านำหมายชั้นอุทธรณ์
  const [cojCostSumAll, setCojCostSumAll] = useState(""); // ค่าเหมาจ่ายชั้นอุทธรณ์
  const [cojCostLawyer, setCojCostLawyer] = useState(""); // ค่าจ้างทนายชั้นอุทธรณ์
  const [cojCostAll, setCojCostAll] = useState(""); // รวมค่าใช้จ่ายในการดำเนินคดีชั้นอุทธรณ์
  const [supCostCourt, setSupCostCourt] = useState(""); // ค่าขึ้นศาลชั้นฎีกา
  const [supCostGo, setSupCostGo] = useState(""); // ค่านำหมายชั้นฎีกา
  const [supCostSumAll, setSupCostSumAll] = useState(""); // ค่าเหมาจ่ายชั้นฎีกา
  const [supCostLawyer, setSupCostLawyer] = useState(""); // ค่าจ้างทนายชั้นฎีกา
  const [supCostAll, setSupCostAll] = useState(""); // รวมค่าใช้จ่ายในการดำเนินคดีชั้นฎีกา
  const [supDateReq, setSupDateReq] = useState(null); // วันที่เบิกชั้นฎีกา
  const [supDatePaid, setSupDatePaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  // ===================================
  const [costCheckRoleInvestigate, setCostCheckRoleInvestigate] = useState(""); // ค่าตรวจกรรมสิทธิ์ชั้นสืบทรัพย์
  const [costCheckCarInvestigate, setCostCheckCarInvestigate] = useState(""); // ค่าตรวจยานพาหนะชั้นสืบทรัพย์
  const [costEtcInvestigate, setCostEtcInvestigate] = useState(""); // ค่าใช้จ่ายอื่นๆชั้นสืบทรัพย์
  const [costAllInvestigate, setCostAllInvestigate] = useState(""); // รวมค่าใช้จ่ายในการสืบทรัพย์
  const [costCheckInvestigate, setCostCheckInvestigate] = useState(""); // ค่าจ้างสืบทรัพย์
  const [costCheckMapInvestigate, setCostCheckMapInvestigate] = useState(""); // ค่าตรวจสอบระวางแผนที่
  const [costCheckAllInvestigate, setCostCheckAllInvestigate] = useState(""); // รวมค่าจ้างสืบทรัพย์
  const [costWithdrawInvestigate, setCostWithdrawInvestigate] = useState(null); // วันที่เบิกสืบทรัพย์
  const [datePaidInvestigate, setDatePaidInvestigate] = useState(null); // วันที่ธนาคารจ่ายเงิน
  // ===================================
  // ค่าใช้จ่ายในการดำเนินคดีชั้นบังคับคดี
  // Seized
  // ===================================

  const [sequestrationFee, setSequestrationFee] = useState(""); // ค่าตั้งเรื่องอายัด
  const [lumpSum, setLumpSum] = useState(""); // ค่าเหมาจ่าย
  const [ledCost, setLedCost] = useState(""); // ค่าจ้างอายัด
  const [ledAll, setLedAll] = useState(""); // รวม
  const [ledDate0, setledDate0] = useState(null); // วันที่เบิกอายัด
  const [ledDateBankPaid, setLedDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [led20, setLed20] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)
  const [led20Date1, setLed20Date1] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 1)
  const [ledDateBankPaid1, setLedDateBankPaid1] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 1)
  const [led20R1, setLed20R1] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่ 1)
  const [led20DateR2, setLed20DateR2] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 2)
  const [ledDateBankPaid2, setLedDateBankPaid2] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 2)
  const [led20R2, setLed20R2] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่ 2)
  const [led20DateR3, setLed20DateR3] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 3)
  const [ledDateBankPaid3, setLedDateBankPaid3] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 3)
  const [led20R3, setLed20R3] = useState(""); // ค่าจ้าง 20 %(ได้รับชำระหนี้)อายัดเงินเดือนในแต่ละคร้ง(แต่ไม่เกิน10,000บาท)(ครั้งที่ 3)
  const [led20DateR4, setLed20DateR4] = useState(null); // วันที่เบิก 20 %อายัด(ครั้งที่ 4)
  const [ledDateBankPaid4, setLedDateBankPaid4] = useState(null); // วันที่ธนาคารจ่ายเงิน(ครั้งที่ 4)
  const [ledFess, setLedFess] = useState(""); // ค่าธรรมเนียม
  const [ledFollow, setLedFollow] = useState(""); // ค่านำหมาย
  const [ledSubmit, setLedSubmit] = useState(""); // ค่ารับรอง
  const [ledCostWagesRequest, setLedCostWagesRequest] = useState(""); // รวมค่าใช้จ่ายในการยื่นคำร้อง
  const [ledInvestigateDate, setLedInvestigateDate] = useState(null); // วันที่เบิก
  const [ledInvestigateDateBankPaid, setLedInvestigateDateBankPaid] =
    useState(null); // วันที่ธนาคารจ่ายเงิน
  const [ledWagesRequest, setLedWagesRequest] = useState(""); // ค่าจ้างยื่นคำร้อง
  const [ledWagesDateBankPaid, setLedWagesDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [ledWagesInvestigateDate, setLedWagesInvestigateDate] = useState(null); // วันที่เบิก
  const [createLedCost, setCreateLedCost] = useState(""); // ค่าตั้งเรื่องบังคับคดีแทน(ยึดทรัพย์)
  const [costCreateSequester, setCostCreateSequester] = useState(""); // ค่าตั้งเรื่องยึดทรัพย์
  const [costAllSequester, setCostAllSequester] = useState(""); // ค่าเหมาจ่าย(ยึดทรัพย์)
  const [costSequester, setCostSequester] = useState(""); // ค่าจ้างยึดทรัพย์
  const [costAllLedSequester, setCostAllLedSequester] = useState(""); // รวมค่าใช้จ่ายในการบังคับคดี(ยึดทรัพย์)
  const [sequesterDate, setSequesterDate] = useState(null); // วันที่เบิกยึดทรัพย์
  const [sequesterDateBankPaid, setSequesterDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [seizedWages, setSeizedWages] = useState(""); // ค่าวางเงินค่าใช้จ่ายเพิ่ม
  const [costSequesterCheck, setCostSequesterCheck] = useState(""); // ค่าตรวจรับรองบัญชีรับ-จ่าย
  const [costSequesterEtc, setCostSequesterEtc] = useState(""); // ค่าใช้จ่ายอื่น ๆ
  const [costAllSequesterEtc, setCostAllSequesterEtc] = useState(""); // รวมค่าใช้จ่ายอื่น ๆ
  const [dateSequesterInvestigate, setDateSequesterInvestigate] =
    useState(null); // วันที่เบิก
  const [
    sequesterInvestigateDateBankPaid,
    setSequesterInvestigateDateBankPaid,
  ] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [cost2Per, setCost2Per] = useState(""); // ค่าจ้าง 2 %(ได้รับชำระหนี้)ยึดอสังหาฯ(แต่ไม่เกิน100,000บาท)
  const [cost2PerInverstigate, setCost2PerInverstigate] = useState(null); // วันที่เบิก
  const [cost2PerDateBankPaid, setCost2PerDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [cost50Per, setCost50Per] = useState(""); // ค่าจ้าง 50 %(ได้รับชำระหนี้)
  const [cost50PerInverstigate, setCost50PerInverstigate] = useState(null); // วันที่เบิก
  const [cost50PerDateBankPaid, setCost50PerDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  const [cost1Per, setCost1Per] = useState(""); // ค่าจ้าง 1%(ได้รับชำระหนี้ปิดบัญชีหรือปรับปรุงหนี้ระหว่างประกาศขาย)(แต่ไม่เกิน 10,000 บาท
  const [cost1PerInverstigate, setCost1PerInverstigate] = useState(null); // วันที่เบิก
  const [cost1PerDateBankPaid, setCost1PerDateBankPaid] = useState(null); // วันที่ธนาคารจ่ายเงิน
  // ===================================
  // ค่าใช้จ่ายในการดำเนินคดีชั้นยื่นขอรับชำระหนี้ในคดีล้มละลาย
  // ===================================
  const [lawFees, setLawFees] = useState(""); // ค่าฤชากร/ค่ารับรอง
  const [bankruptGetCostAll, setBankruptGetCostAll] = useState(""); // ค่าเหมาจ่าย
  const [bankruptRound1, setBankruptRound1] = useState(""); // งวด 1
  const [bankruptRound1All, setBankruptRound1All] = useState(""); // รวม
  const [bankruptRound1Investigate, setBankruptRound1Investigate] =
    useState(null); // วันที่เบิก
  const [bankruptRound1DateBankPaid, setBankruptRound1DateBankPaid] =
    useState(null); // วันที่ธนาคารจ่าย
  const [bankruptRound1LawFrees, setBankruptRound1LawFrees] = useState(""); // ค่าฤชากร/ค่ารับรอง
  const [bankruptRound2, setBankruptRound2] = useState(""); // งวด 2
  const [bankruptRound2Investigate, setBankruptRound2Investigate] =
    useState(null); // วันที่เบิก
  const [bankruptDateBankPaid, setBankruptDateBankPaid] = useState(null); // วันที่ธนาคารจ่าย
  const [bankruptCostAll, setBankruptCostAll] = useState(""); // รวมค่าใช้จ่ายทั้งสิ้น
  // ==============end

  // =========================================
  const [bankruptNumber1, setBankruptNumber1] = useState(""); // หมายเลขบัตรเครดิต
  const [bankruptJudge1, setBankruptJudge1] = useState(""); // พิพากษา(ยอดหนี้)
  const [bankruptMoney1, setBankruptMoney1] = useState(""); // เงินต้น
  const [bankruptInterest1, setBankruptInterest1] = useState(""); // ดอกเบี้ย(%)
  const [bankruptSince1, setBankruptSince1] = useState(null); // นับแต่วันที่
  const [bankruptNumber2, setBankruptNumber2] = useState(""); // หมายเลขบัตรเงินสด
  const [bankruptJudge2, setBankruptJudge2] = useState(""); // พิพากษา(ยอดหนี้)
  const [bankruptMoney2, setBankruptMoney2] = useState(""); // เงินต้น
  const [bankruptInterest2, setBankruptInterest2] = useState(""); // ดอกเบี้ย(%)
  const [bankruptSince2, setBankruptSince2] = useState(null); // นับแต่วันที่
  const [bankruptCourt, setBankruptCourt] = useState(""); // ค่าขึ้นศาล
  const [bankruptFollow, setBankruptFollow] = useState(""); // ค่านำหมาย
  const [bankruptCostLawyer, setBankruptCostLawyer] = useState(""); // ค่าทนายความ
  const [bankruptCostSend, setBankruptCostSend] = useState(""); // ค่าส่งคำบังคับ
  const [bankruptAll, setBankruptAll] = useState(""); // รวม
  // ===================================
  const [dateCalInterestSince, setDateCalInterestSince] = useState(null); // วันที่คำนวณดอกเบียถึง
  const [countDate, setCountDate] = useState(""); // จำนวนวัน
  const [interest, setInterest] = useState(""); // ดอกเบี้ย
  // ===================================
  const [dateCalInterestSince1, setDateCalInterestSince1] = useState(null); // วันที่คำนวณดอกเบียถึง
  const [countDate1, setCountDate1] = useState(""); // จำนวนวัน
  const [interest1, setInterest1] = useState(""); // ดอกเบี้ย
  // ===================================

  const [debtAllByJudgment, setDebtAllByJudgment] = useState(""); // รวมภาระหนี้ตามคำพิพากษา
  const [costLedAll, setCostLedAll] = useState(""); // รวมค่าใช้จ่ายในการดำเนินคดีและบังคับคดี
  const [sumReturnAll, setSumReturnAll] = useState(""); // รวมเงินที่ได้รับคืนแล้ว
  const [calDebtSince, setCalDebtSince] = useState(null); // คำนวณภาระหนี้ถึงวันที่
  const [costWorking, setCostWorking] = useState(""); // ค่าใช้จ่ายในการดำเนินคดีฯ,ค่าขึ้นศาลที่ศาลสั่งคืน

  const [countLoan, setCountLoan] = useState(1);

  const [listRoleStatus, setListRoleStatus] = useState([]);

  const [countAddressOptional, setCountAddressOptional] = useState(0);
  const [addressOptionalHouseNumber, setAddressOptionalHouseNumber] = useState(
    []
  );
  const [addressOptionalGroup, setAddressOptionalGroup] = useState([]);
  const [addressOptionalRoad, setAddressOptionalRoad] = useState([]);
  const [addressOptionalSoi, setAddressOptionalSoi] = useState([]);
  const [addressOptionalAddress, setAddressOptionalAddress] = useState([]);
  const [addressOptionalTambon, setAddressOptionalTambon] = useState([]);
  const [addressOptionalAmphoe, setAddressOptionalAmphoe] = useState([]);
  const [addressOptionalProvince, setAddressOptionalProvince] = useState([]);
  const [addressOptionalProvinceName, setAddressOptionalProvinceName] =
    useState([]);
  const [addressOptionalTambonName, setAddressOptionalTambonName] = useState(
    []
  );
  const [addressOptionalAmphoeName, setAddressOptionalAmphoeName] = useState(
    []
  );
  const [addressOptionalZipcode, setAddressOptionalZipcode] = useState([]);

  const fileTypes = ["PDF"];

  const handleUploadChange = (file) => {
    setFileUpload(file);
    console.log(file);
  };

  const typeDocsList = [
    { id: 0, value: "คำร้อง" },
    { id: 1, value: "คำขอ" },
    { id: 2, value: "คำแถลง" },
    { id: 3, value: "คำขอถอนฟ้อง" },
    { id: 4, value: "คำร้องขาดนัดยื่นคำให้การ" },
    { id: 5, value: "คำแถลงขอเลื่อนนัด" },
  ];

  const listCoDefendantStatus = [
    { id: 0, value: "มีชีวิตอยู่" },
    { id: 1, value: "เสียชีวิต" },
  ];

  const listStatusDead = [
    { id: 0, value: "ผู้จัดการมรดก" },
    { id: 1, value: "คู่สมรส" },
    { id: 2, value: "บิดา-มารดา" },
  ];

  const listStatusDead1 = [
    { id: 0, label: "ผู้จัดการมรดก" },
    { id: 1, label: "คู่สมรส" },
    { id: 2, label: "บิดา-มารดา" },
  ];

  const listPrefix = [
    { id: 0, value: "นาย" },
    { id: 1, value: "นาง" },
    { id: 2, value: "นางสาว" },
  ];

  const listTypeOfCollateral = [
    { id: 1, value: "บุคคลค้ำประกัน", name: "บุคคลค้ำประกัน" },
    { id: 2, value: "หลักทรัพย์ค้ำประกัน", name: "หลักทรัพย์ค้ำประกัน" },
    {
      id: 3,
      value: "บุคคลฯและหลักทรัพย์ค้ำประกัน",
      name: "บุคคลฯและหลักทรัพย์ค้ำประกัน",
    },
    { id: 4, value: "ไม่มีหลักประกัน", name: "ไม่มีหลักประกัน" },
  ];

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    console.log("christDate", christDate);
    setData(christDate);
    console.log("executionDateStart", executionDateStart);
    // if(executionDateStart){
    //   setExecutionDeadline(moment(christDate).add(90,'days'))
    // }
  };

  function getDdlListRoleStatus() {
    axios
      .get(`${config.mainAPI}/listRoleStatus/AG_ROLE`)
      .then((response) => {
        const arrayList = [];
        // setListRoleStatus
        response.data.data.map((item, index) => {
          const value = {
            id: index,
            label: item.mst_value,
          };
          arrayList.push(value);
        });
        console.log("arrayList", arrayList);
        setListRoleStatus(arrayList);
        console.log("listRoleStatus", listRoleStatus);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDdlPrefix() {
    axios
      .get(`${config.mainAPI}/prefix-list`)
      .then((response) => {
        setPrefixList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDdlDebtCenter() {
    api
      .get(`${config.mainAPI}/debtCenter`)
      .then((response) => {
        console.log("value", response.data.data);
        setDebtCenterList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getMortgagePropertyList = () => {
    setIsLoading(true);
    api
      .post(`${config.mainAPI}/getConfigValue`, {
        value: "MORTGAGE_PROPERTY",
      })
      .then((response) => {
        setMortgagePropertyListSelect(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  // Owner เห็นเอกสารทั้งหมด
  // User เห็นเอกสารเฉพาะหน้าที่ของตัวเอง

  // function getListFile() {
  //   axios
  //     .get(`${config.mainAPI}/file-list/${params}`)
  //     .then((response) => {
  //       console.log("value filelist", response.data.data);
  //       setFileList(response.data.data);
  //       const value = response.data.data;
  //       setRows(
  //         value.map((item, index) =>
  //           createData(
  //             index + 1,
  //             item.file_uuid,
  //             item.file_topic,
  //             item.file_type,
  //             item.created_at,
  //             item.created_by,
  //             item.file_path,
  //             item.update_at
  //           )
  //         )
  //       );
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  {
    /* 
          โนติส
คำฟ้อง
คำพิพากษา
หมายบังคับคดี
ค่าฤชาธรรมเนียมศาล
หนังสือคดีถึงที่สุด */
  }

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log("datana", JSON.parse(userData));
    setUser(JSON.parse(userData));
    getUserCompany();
    getDdlDebtCenter();
    getDdlPrefix();
    getCourtList();
    getDdlListRoleStatus();
    getMortgagePropertyList();
    // getLocation();
    if (params && userData) {
      getDataForm();
      getListFile();
    }
  }, []);
  const navigate = useNavigate();

  // function handleDownload() {
  //     const file_path = filePath;
  //     const download_url = `http://localhost:7000/download-file?file_path=${file_path}`;
  //     window.location.href = download_url;
  // }

  function createData(order, uuid, list, type, date, work_by, path, main) {
    const formattedDate = date
      ? moment(date).locale("th").add(543, "year").format("DD/MM/YYYY")
      : null;
    const newList = list ? list : "-";
    return {
      order,
      uuid,
      // list: newList,
      type,
      date: formattedDate,
      work_by,
      path,
      main,
    };
  }

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    // { id: "list", label: "รายการ", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
    { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
    {
      id: "uploadFile",
      label: "อัพโหลดเอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleUploadFile(row)}>
            <UploadFileIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "file",
      label: "เอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          {row.path ? (
            <IconButton
              disabled={!row.path}
              onClick={() => handleDownload(row)}
            >
              <PictureAsPdfIcon color={row.path ? "error" : "inherit"} />
            </IconButton>
          ) : null}
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={!row.path}
            onClick={() => handleDeleteFile(row)}
          >
            <DeleteForeverIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  const columns1 = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    // { id: "list", label: "รายการ", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
    { id: "work_by", label: "ผู้ทำรายการ", minWidth: 100 },
    {
      id: "uploadFile",
      label: "อัพโหลดเอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleUploadFileExecution(row)}>
            <UploadFileIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "file",
      label: "เอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          {row.path ? (
            <IconButton
              disabled={!row.path}
              onClick={() => handleDownload(row)}
            >
              <PictureAsPdfIcon color={row.path ? "error" : "inherit"} />
            </IconButton>
          ) : null}
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={!row.path}
            onClick={() => handleDeleteFileExecution(row)}
          >
            <DeleteForeverIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  const handleClickOpenDelete = (row) => {};

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const filteredRows1 = rows1.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // function getLocation(){
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       // const { latitude, longitude } = position.coords;
  //       console.log('position',position)
  //       console.log('position.coords',position.coords)
  //       // Send these coordinates to the backend
  //     },
  //     (error) => {
  //       console.error("Error getting location", error);
  //     }
  //   );
  // }

  const getDataForm = () => {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/documents/${params}`)
      .then((response) => {
        console.log("response.data.data", response.data.data);
        if (response.data && Array.isArray(response.data.data)) {
          setData(response.data.data[0]);
        } else {
          console.error("Data is not an array or undefined");
          setIsLoading(false);
          return;
        }
        const value = response.data.data[0];
        setType(value.type_defendant);
        setNameCompany(value.defendant_company);
        setDateStart(value.date_start);
        setDeadline(value.deadline);
        setFileNumber(value.file_number);
        setPrefix(value.defendant_prefix);
        setDefendantFirstName(value.defendant_firstname);
        setDefendantLastName(value.defendant_lastname);
        setDefendantIdCard(value.defendant_idcard);
        setDefendantAccountNumber(value.defendant_account_number);
        setDefendantAccountNumber1(value.defendant_account_number1);
        setDefendantHouseNumber(value.defendant_house_number); // (value.defendantHouseNumber) บ้านเลขที่
        setDefendantGroup(value.defendant_group); // หมู่
        setDefendantRoad(value.defendant_road); // ถนน
        setDefendantSoi(value.defendant_soi); // ซอย
        setDefendantAddress(value.defendant_address);
        setDefendantTambon(parseInt(value.defendant_tambon));
        setDefendantTambonName(value.defendant_tambon_show);
        setDefendantAmphoe(parseInt(value.defendant_amphoe));
        setDefendantAmphoeName(value.defendant_amphoe_show);
        setDefendantProvince(parseInt(value.defendant_province));
        setDefendantProvinceName(value.defendant_province_show);
        setDateOfSigning(value.date_signed);
        setDateOfOutStanding(value.date_show_out_stadning);
        setPrincipalContract(value.principal_in_contract);
        setInterestContract(value.interest_in_contract);
        setNoticeDateSign(value.notice_date_sign);
        setAddressLoan(value.defendant_contract_house_number ? 1 : 0);
        setAlternativeAddress(value.alternative_address);
        if (value.defendant_contract_house_number) {
          setDefendantContractHouseNumber(
            value.defendant_contract_house_number
          );
          setDefendantContractGroup(value.defendant_contract_group);
          setDefendantContractRoad(value.defendant_contract_road);
          setDefendantContractSoi(value.defendant_contract_soi);
          setDefendantContractAddress(value.defendant_contract_address);
          setDefendantContractTambon(parseInt(value.defendant_contract_tambon));
          setDefendantContractAmphoe(parseInt(value.defendant_contract_amphoe));
          setDefendantContractProvince(
            parseInt(value.defendant_contract_province)
          );
          setDefendantContractTambonName(value.defendant_contract_tambon_show);
          setDefendantContractAmphoeName(value.defendant_contract_amphoe_show);
          setDefendantContractProvinceName(
            value.defendant_contract_province_show
          );
        }

        setMortgageProperty(value.mortgage_property);

        let list = [{ name: "ผู้กู้", value: "ผู้กู้" }];
        if (countCoBorrower > 0) {
          for (let i = 0; i < countCoBorrower; i++) {
            list.push({
              name: `ผู้กู้ร่วมที่${i + 1}`,
              value: `ผู้กู้ร่วมที่${i + 1}`,
            });
          }
        }
        if (countDefendant > 0) {
          for (let i = 0; i < countDefendant; i++) {
            list.push({
              name: `ผู้คำประกันที่${i + 1}`,
              value: `ผู้คำประกันที่${i + 1}`,
            });
          }
        }

        setMortgagePropertyList(list);
        setMortgageProperty(value.mortgage_property);
        if (value.mortgage_property == 1) {
          setMortgagePropertyName(value.mortgage_property_name);
          setMortgagePropertyDeed(value.mortgage_property_deed);
          setMortgagePropertyNumber(value.mortgage_property_number);
          setMortgagePropertyExplore(value.mortgage_property_explore);
          setMortgagePropertyTambon(value.mortgage_property_tambon);
          setMortgagePropertyAmphoe(value.mortgage_property_amphoe);
          setMortgagePropertyProvince(value.mortgage_property_province);
          setMortgagePropertyDay(value.mortgage_property_day);
          setMortgagePropertyType(value.mortgage_property_type);
          setMortgagePropertyRoomNumber(value.mortgage_property_room_number);
          setMortgagePropertyTowerNumber(value.mortgage_property_tower_number);
          setMortgagePropertyTowerName(value.mortgage_property_tower_name);
          
        }

        // setReservePrefix(value.reserve_prefix);
        // setReserveFirstname(value.reserve_firstname);
        // setReserveLastname(value.reserve_lastname);
        // setReserveIdcard(value.reserve_idcard);
        // setReserveAddress(value.reserve_address);
        // setReserveTel(value.reserve_tel);
        // setReserveRole(value.reserve_role);

        setStatusDefendantNow(value.defendant_alive);
        if (value.defendant_alive == "เสียชีวิต") {
          setReservePrefix(value.reserve_prefix);
          setReserveFirstname(value.reserve_firstname);
          setReserveLastname(value.reserve_lastname);
          setReserveIdcard(value.reserve_idcard);
          setReserveAddress(value.reserve_address);
          setReserveTel(value.reserve_tel);
          setReserveRole(value.reserve_role);
        }
        if (value.defendant_province) {
          console.log("value.defendant_province", value.defendant_province);
          getAmphoesByProvinceId(parseInt(value.defendant_province));
        }
        if (value.defendant_amphoe) {
          console.log("value.defendant_amphoe", value.defendant_amphoe);
          getTambonsByAmphoeId(parseInt(value.defendant_amphoe));
        }
        setDefendantZipcode(parseInt(value.defendant_zipcode));
        setPhone(value.defendant_phone);
        setPhone1(value.defendant_phone1);
        setGsbNumber(value.gsb_number);
        setDebtCenter(value.debt_center);
        setBranchAgency(value.branch_agency);
        setDistrictAgency(value.district_agency);
        setSectorAgency(value.sector_agency);
        setLoanType(value.loan_type);
        setTypeOfCollateral(value.type_of_collateral);
        setSueDay(value.sue_day);
        setNoticeDayGet(value.notic_get);
        setNoticeDayOut(value.notic_out);
        setNoticeInvestigate(value.notice_investigate);
        setPrincipalCapital(value.principal_capital);
        setInterestCapital(value.interest_capital);
        setCourt(value.court);
        setCourtDate(
          value.court_date ? moment(value.court_date).toDate() : null
        );
        console.log("value.court_time", value.court_time);
        setCourtTime(value.court_time);
        setCountCourtEtc(value.goCourtEtc ? value.goCourtEtc.length : 0);

        // Notice
        setNoticePrincipal(value.notice_pricipal);
        setNoticeInterest(value.notice_interest);
        setNoticeCalDate(value.notice_cal_date);
        setCountLoan(value.contract.length);
        if (value.contract) {
          value.contract.map((item, index) => {
            setLoanTypeList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.contract_type;
              return updatedState;
            });

            setGsbNumberList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.contract_number;
              return updatedState;
            });

            setTypeOfCollateralList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.type_of_collateral;
              return updatedState;
            });

            setNoticeDayOutList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.notic_out_day;
              return updatedState;
            });

            setNoticeDayGetList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.notic_get_day;
              return updatedState;
            });

            setNoticeInvestigateList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.notic_description;
              return updatedState;
            });

            setJudgmentdebtLedList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.judgment_debt_led;
              return updatedState;
            });

            setPrincipalList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.principal;
              return updatedState;
            });

            setInterestList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.interest;
              return updatedState;
            });

            setDateCalInterestSinceList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.date_of_interest;
              return updatedState;
            });

            setCountDateList((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.count_day;
              return updatedState;
            });
          });
        }
        // setCountCourtEtc(value.court_count - 1);
        if (value.court_count > 1 && value.goCourtEtc) {
          // มันต้อง + 1
          value.goCourtEtc.forEach((item, index) => {
            // console.log("index", index);

            setDateGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_date;
              return updatedState;
            });

            setTimeGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_time;
              return updatedState;
            });

            setLawyerGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by;
              return updatedState;
            });

            setLawyerNameGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by_name;
              return updatedState;
            });

            setDetailGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_detail;
              return updatedState;
            });
            // }
          });
        }
        setBlackCaseNumber(value.black_case_number);
        setBlackCaseYear(value.black_case_year);
        setRedCaseNumber(value.red_case_number);
        setRedCaseYear(value.red_case_year);
        setWitnessExaminationDate(
          value.witness_examination_date
            ? moment(value.witness_examination_date).toDate()
            : null
        );
        setJudgmentDay(
          value.judgment_day ? moment(value.judgment_day).toDate() : null
        );
        setAppealDueDate(
          value.appeal_due_date ? moment(value.appeal_due_date).toDate() : null
        );
        setJudgmentUnilateral(
          value.judgment_unilateral
            ? moment(value.judgment_unilateral).toDate()
            : null
        );
        setJudgmentAccordingToConsent(
          value.judgment_according_to_consent
            ? moment(value.judgment_according_to_consent).toDate()
            : null
        );
        setDetailsJudgment(value.judgment_details);
        setReturnFile(
          value.return_file ? moment(value.return_file).toDate() : null
        );
        setReturnFileReason(value.return_file_reason);
        setDecree(value.decree ? moment(value.decree).toDate() : null);
        setIssueAWarranOfExecution(
          value.issue_a_warran_of_execution
            ? moment(value.issue_a_warran_of_execution).toDate()
            : null
        );
        setWithdrawOneCase(
          value.withdraw_one_case
            ? moment(value.withdraw_one_case).toDate()
            : null
        );
        setWithdrawOneCaseDueTo(value.withdraw_one_case_due_to);
        if (value.file_name && value.file_path) {
          setCheckFile(true);
        }
        setFileName(value.file_name);
        setFilePath(value.file_path);
        setNote(value.note);
        setChooseMemberCompany(value.work_by);
        setChooseMemberCompanyName(value.work_by_name);
        setCountDefendant(value.codefendant_count);
        setCountCodefendant(value.codefendant_count);
        if (
          value.codefendant_count &&
          value.codefendant_count > 0 &&
          value.coDefendant?.length > 0
        ) {
          value.coDefendant.forEach((item, index) => {
            setCoDefendantPrefix((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_prefix;
              return updatedState;
            });

            setCoDefendantName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_firstname;
              return updatedState;
            });

            setCoDefendantLastName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_lastname;
              return updatedState;
            });

            setCoDefendantAddress((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_address;
              return updatedState;
            });

            setCoDefendantIdCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_idcard;
              return updatedState;
            });

            setCoDefendantStatus((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_status;
              return updatedState;
            });
          });
        }
        // setCountDefendantAgent()
        console.log("value.defendantAgent", value.defendantAgent);
        if (value.defendantAgent) {
          let countDefendantAgentShow = value.defendantAgent.filter(
            (item) =>
              item.defendant_agent_status &&
              item.defendant_agent_firstname &&
              item.defendant_agent_lastname &&
              item.defendant_agent_prefix
          );
        }
        setCountDefendantAgent(
          value.defendantAgent ? value.defendantAgent.length : 0
        );
        console.log("defendantAgent", defendantAgent);
        if (value.defendantAgent && value.defendantAgent.length > 0) {
          value.defendantAgent.forEach((item, index) => {
            setPrefixDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_prefix;
              return updatedState;
            });

            setNameDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_firstname;
              return updatedState;
            });

            setLastNameDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_lastname;
              return updatedState;
            });

            setIdCardDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_idcard;
              return updatedState;
            });

            setAddressDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_address;
              return updatedState;
            });

            setStatusDefendantAgent((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.defendant_agent_status;
              return updatedState;
            });
          });
        }
        setOther(value.other);
        setCourtCode(value.court_code);
        setCompanyCode(value.company_code);
        // if (value.contract && value.contract.length > 0) {
        //   setCountLoan(value.contract.length);
        //   value.contract.forEach((item, index) => {
        //     setLoanTypeList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = item.contract_type;
        //       return updatedState;
        //     });
        //     setTypeOfCollateralList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = item.type_of_collateral;
        //       return updatedState;
        //     });
        //     setNoticeDayOutList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = moment(item.notic_out_day).toDate();
        //       return updatedState;
        //     });
        //     setNoticeDayGetList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = moment(item.notic_get_day).toDate();
        //       return updatedState;
        //     });
        //     setNoticeInvestigateList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = null;
        //       return updatedState;
        //     });
        //     setPrincipalList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = parseInt(item.principal);
        //       return updatedState;
        //     });
        //     setInterestList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = parseInt(item.interest);
        //       return updatedState;
        //     });
        //     setGsbNumberList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = item.contract_number;
        //       return updatedState;
        //     });
        //     setDateCalInterestSinceList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = moment(item.date_of_interest).toDate();
        //       return updatedState;
        //     });
        //     setCountDateList((prevState) => {
        //       const updatedState = [...prevState];
        //       updatedState[index] = item.count_day;
        //       return updatedState;
        //     });
        //   });
        // }
        setCountCoBorrower(value.subdefendant_count);
        if (value.subDefendant && value.subDefendant.length > 0) {
          value.subDefendant.map((item, index) => {
            setCoBorrowerStatus((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_status;
              return updatedState;
            });

            setCoBorrowerPrefix((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_prefix;
              return updatedState;
            });

            setCoBorrowerName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_firstname;
              return updatedState;
            });

            setCoBorrowerLastName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_lastname;
              return updatedState;
            });

            setCoBorrowerIdCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_idcard;
              return updatedState;
            });

            setCoBorrowerAddress((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.sub_defendant_address;
              return updatedState;
            });
          });
        }
        // led
        setExecutionCase(value.execution_case);
        console.log("value.executionData", value.executionData);

        // ที่อยู่สำรอง
        if (value.addressOptional && value.addressOptional.length > 0) {
          setCountAddressOptional(parseInt(value.addressOptional.length));
          // มันต้อง + 1
          value.addressOptional.forEach((item, index) => {
            console.log("item addressOptional", item);
            // console.log("index", index);

            setAddressOptionalHouseNumber((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.house_number;
              return updatedState;
            });

            setAddressOptionalGroup((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.group;
              return updatedState;
            });

            setAddressOptionalRoad((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.road;
              return updatedState;
            });

            setAddressOptionalSoi((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.soi;
              return updatedState;
            });

            setAddressOptionalAddress((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.address;
              return updatedState;
            });

            setAddressOptionalTambon((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = parseInt(item.tambon);
              return updatedState;
            });

            setAddressOptionalTambonName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.tambon_show;
              return updatedState;
            });

            setAddressOptionalAmphoe((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = parseInt(item.amphoe);
              return updatedState;
            });

            setAddressOptionalAmphoeName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.amphoe_show;
              return updatedState;
            });

            setAddressOptionalProvince((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = parseInt(item.province);
              return updatedState;
            });

            setAddressOptionalProvinceName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.province_show;
              return updatedState;
            });

            setAddressOptionalZipcode((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = parseInt(item.zipcode);
              return updatedState;
            });
          });
        }

        if (value.execution_case) {
          getListFileExecution(value.execution_id);
          setExecutionId(value.execution_id);
          setExecutionCode(value.executionData[0].execution_status);
          // setExecutionStatus(value.executionData[0].execution_status);
          setExecutionDeadline(value.executionData[0].led_deadline);
          setExecutionDateStart(value.executionData[0].led_date_start);
          setExecutionCourtDate(value.executionData[0].court_date_led);
          setExecutionCourtTime(value.executionData[0].court_time_led);
          setBringTheRegulations(value.executionData[0].bring_the_regulations);
          setWritOfExecutionDate(value.executionData[0].writ_of_execution_date);
          setExecutionWorkBy(value.executionData[0].work_by_led);
          setExecutionWorkByName(value.executionData[0].work_by_led_name);

          // eieina
          // table: execution_investigate
          setSpouseCheckDate(value.executionData[0].spouse_check_date);
          setSpouseName(value.executionData[0].spouse_name);
          setSpouseIdCard(value.executionData[0].spouseId_card);
          setSpouseNote(value.executionData[0].spouse_note);
          setSpouseIncomeDate(value.executionData[0].spouse_income_date);
          setSpouseStatus(value.executionData[0].spouse_status);
          setWorkPlaceIncomeDate(value.executionData[0].spouse_income_date);
          setWorkPlaceName(value.executionData[0].work_place_income_date);
          setWorkPlaceIncome(value.executionData[0].work_place_name);
          setWorkPlaceDateSendIncome(
            value.executionData[0].work_place_date_send_income
          );
          setWorkPlaceDetailIncome(
            value.executionData[0].work_place_detail_income
          );
          setWorkPlaceStatusIncome(
            value.executionData[0].work_place_status_income
          );
          setDltDateCheck(value.executionData[0].dlt_date_check);
          setDltDetail(value.executionData[0].dlt_detail);
          setDltStatus(value.executionData[0].dlt_status);
          setDolDateCheckGround(value.executionData[0].dol_date_check_ground);
          setDolStatusObject(value.executionData[0].dol_status_object);
          setDolDetail(value.executionData[0].dol_detail);
          setDolFailureDetail(value.executionData[0].dol_failure_detail);
          setDolStatus(value.executionData[0].dol_status);
          setDolSell(value.executionData[0].dol_sell);
          setDolMoneySell(value.executionData[0].dol_money_sell);
          setDolCostReturn(value.executionData[0].dol_cost_return);
          setDolFailureDetail1(value.executionData[0].dol_failure_detail1);
          setDolDateGetSend(value.executionData[0].dol_date_get_send);

          // table: execution_cost
          setLedCostCourt(value.executionData[0].led_cost_court);
          setLedCostCall(value.executionData[0].led_cost_call);
          setLedCostForce(value.executionData[0].led_cost_force);
          setLedCostTell(value.executionData[0].led_cost_tell);
          setLedCostAll(value.executionData[0].led_cost_all);
          setLedCostRequest(value.executionData[0].led_cost_request);
          setLedCostLawyer1(value.executionData[0].led_cost_lawyer1);
          setLedCostLawyer2(value.executionData[0].led_cost_lawyer_2);
          setLedInvestigete1Date(value.executionData[0].led_investigete1_date);
          setLedBankPaid1Date(value.executionData[0].led_bank_paid1_date);
          setLedInvestigete2Date(value.executionData[0].led_investigete2_date);
          setLedBankPaid2Date(value.executionData[0].led_bank_paid2_date);
          setCojCostCourt(value.executionData[0].coj_cost_court);
          setCojCostGo(value.executionData[0].coj_cost_go);
          setCojCostSumAll(value.executionData[0].coj_cost_sum_all);
          setCojCostLawyer(value.executionData[0].coj_cost_lawyer);
          setCojCostAll(value.executionData[0].coj_cost_all);
          setSupCostCourt(value.executionData[0].sup_cost_court);
          setSupCostGo(value.executionData[0].sup_cost_go);
          setSupCostSumAll(value.executionData[0].sup_cost_sum_all);
          setSupCostLawyer(value.executionData[0].sup_cost_lawyer);
          setSupCostAll(value.executionData[0].sup_cost_all);
          setSupDateReq(value.executionData[0].sup_date_req);
          setSupDatePaid(value.executionData[0].sup_date_paid);
          setCostCheckRoleInvestigate(
            value.executionData[0].cost_check_role_investigate
          );
          setCostCheckCarInvestigate(
            value.executionData[0].cost_check_car_investigate
          );
          setCostEtcInvestigate(value.executionData[0].cost_etc_investigate);
          setCostAllInvestigate(value.executionData[0].cost_all_investigate);
          setCostCheckInvestigate(
            value.executionData[0].cost_check_investigate
          );
          setCostCheckMapInvestigate(
            value.executionData[0].cost_check_map_investigate
          );
          setCostCheckAllInvestigate(
            value.executionData[0].cost_check_all_investigate
          );
          setCostWithdrawInvestigate(
            value.executionData[0].cost_withdraw_investigate
          );
          setDatePaidInvestigate(value.executionData[0].date_paid_investigate);

          // execution_cost
          setSequestrationFee(value.executionData[0].sequestration_fee);
          setLumpSum(value.executionData[0].lump_sum);
          setLedCost(value.executionData[0].led_cost);
          setLedAll(value.executionData[0].led_all);
          setledDate0(value.executionData[0].led_date0);
          setLedDateBankPaid(value.executionData[0].led_date_bank_paid);
          setLed20(value.executionData[0].led20);
          setLed20Date1(value.executionData[0].led20_date1);
          setLedDateBankPaid1(value.executionData[0].led_date_bank_paid1);
          setLed20R1(value.executionData[0].led20_r1);
          setLed20DateR2(value.executionData[0].led20_date_r2);
          setLedDateBankPaid2(value.executionData[0].led_date_bank_paid2);
          setLed20R2(value.executionData[0].led20_r2);
          setLed20DateR3(value.executionData[0].led20_date_r3);
          setLedDateBankPaid3(value.executionData[0].led_date_bank_paid3);
          setLed20R3(value.executionData[0].led20_r3);
          setLed20DateR4(value.executionData[0].led20_date_r4);
          setLedDateBankPaid4(value.executionData[0].led_date_bank_paid4);
          setLedFess(value.executionData[0].led_fess);
          setLedFollow(value.executionData[0].led_follow);
          setLedSubmit(value.executionData[0].led_submit);
          setLedCostWagesRequest(value.executionData[0].led_cost_wages_request);
          setLedInvestigateDate(value.executionData[0].led_investigate_date);
          setLedInvestigateDateBankPaid(
            value.executionData[0].led_investigate_date_bank_paid
          );
          setLedWagesRequest(value.executionData[0].led_wages_request);
          setLedWagesDateBankPaid(
            value.executionData[0].led_wages_date_bank_paid
          );
          setLedWagesInvestigateDate(
            value.executionData[0].led_wages_investigate_date
          );
          setCreateLedCost(value.executionData[0].create_led_cost);
          setCostCreateSequester(value.executionData[0].cost_create_sequester);
          setCostAllSequester(value.executionData[0].cost_all_sequester);
          setCostSequester(value.executionData[0].cost_sequester);
          setCostAllLedSequester(value.executionData[0].cost_all_led_sequester);
          setSequesterDate(value.executionData[0].sequester_date);
          setSequesterDateBankPaid(
            value.executionData[0].sequester_date_bank_paid
          );
          setSeizedWages(value.executionData[0].seized_wages);
          setCostSequesterCheck(value.executionData[0].cost_sequester_check);
          setCostSequesterEtc(value.executionData[0].cost_sequester_etc);
          setCostAllSequesterEtc(value.executionData[0].cost_all_sequester_etc);
          setDateSequesterInvestigate(
            value.executionData[0].date_sequester_investigate
          );
          setSequesterInvestigateDateBankPaid(
            value.executionData[0].sequester_investigate_date_bank_paid
          );
          setCost2Per(value.executionData[0].cost2_per);
          setCost2PerInverstigate(
            value.executionData[0].cost2_per_inverstigate
          );
          setCost2PerDateBankPaid(
            value.executionData[0].cost2_per_date_bank_paid
          );
          setCost50Per(value.executionData[0].cost50_per);
          setCost50PerInverstigate(
            value.executionData[0].cost50_per_inverstigate
          );
          setCost50PerDateBankPaid(
            value.executionData[0].cost50_per_date_bank_paid
          );
          setCost1Per(value.executionData[0].cost1_per);
          setCost1PerInverstigate(
            value.executionData[0].cost1_per_inverstigate
          );
          setCost1PerDateBankPaid(
            value.executionData[0].cost1_per_date_bank_paid
          );
          setLawFees(value.executionData[0].law_fees);
          setBankruptGetCostAll(value.executionData[0].bankrupt_get_cost_all);
          setBankruptRound1(value.executionData[0].bankrupt_round1);
          setBankruptRound1All(value.executionData[0].bankrupt_round1_all);
          setBankruptRound1Investigate(
            value.executionData[0].bankrupt_round1_investigate
          );
          setBankruptRound1DateBankPaid(
            value.executionData[0].bankrupt_round1_date_bank_paid
          );
          setBankruptRound1LawFrees(
            value.executionData[0].bankrupt_round1_law_frees
          );
          setBankruptRound2(value.executionData[0].bankrupt_round2);
          setBankruptRound2Investigate(
            value.executionData[0].bankrupt_round2_investigate
          );
          setBankruptDateBankPaid(
            value.executionData[0].bankrupt_date_bank_paid
          );
          setBankruptCostAll(value.executionData[0].bankrupt_cost_all);

          // execution_judgment_result
          setBankruptNumber1(value.executionData[0].bankrupt_number1);
          setBankruptJudge1(value.executionData[0].bankrupt_judge1);
          setBankruptMoney1(value.executionData[0].bankrupt_money1);
          setBankruptInterest1(value.executionData[0].bankrupt_interest1);
          setBankruptSince1(value.executionData[0].bankrupt_since1);
          setBankruptNumber2(value.executionData[0].bankrupt_number2);
          setBankruptJudge2(value.executionData[0].bankrupt_judge2);
          setBankruptMoney2(value.executionData[0].bankrupt_money2);
          setBankruptInterest2(value.executionData[0].bankrupt_interest2);
          setBankruptSince2(value.executionData[0].bankrupt_since2);
          setBankruptCourt(value.executionData[0].bankrupt_court);
          setBankruptFollow(value.executionData[0].bankrupt_follow);
          setBankruptCostLawyer(value.executionData[0].bankrupt_cost_lawyer);
          setBankruptCostSend(value.executionData[0].bankrupt_cost_send);
          setBankruptAll(value.executionData[0].bankrupt_all);
          setDateCalInterestSince(
            value.executionData[0].date_cal_interest_since
          );
          setCountDate(value.executionData[0].count_date);
          setInterest(value.executionData[0].interest);
          setDateCalInterestSince1(
            value.executionData[0].date_cal_interest_since1
          );
          setCountDate1(value.executionData[0].count_date1);
          setInterest1(value.executionData[0].interest1);

          // execution_summary
          setDebtAllByJudgment(value.executionData[0].debt_all_by_judgment);
          setCostLedAll(value.executionData[0].cost_led_all);
          setSumReturnAll(value.executionData[0].sum_return_all);
          setCalDebtSince(value.executionData[0].cal_debt_since);
          setCostWorking(value.executionData[0].cost_working);

          // add object เข้าไปใน step
          // steps.push("บังคับคดี");
          const updatedSteps = [
            ...steps,
            "ข้อมูลบังคับคดี",
            "สืบทรัพย์บังคับคดี",
            "ค่าใช้จ่ายบังคับคดี",
            "ผลคำพิพากษาบังคับคดี",
            // "สรุปภาระหนี้",
            "เอกสารบังคับคดี",
          ];
          // steps = updatedSteps;
          setSteps((prevSteps) => [
            ...prevSteps,
            "ข้อมูลบังคับคดี",
            "สืบทรัพย์บังคับคดี",
            "ค่าใช้จ่ายบังคับคดี",
            "ผลคำพิพากษาบังคับคดี",
            // "สรุปภาระหนี้",
            "เอกสารบังคับคดี",
          ]);
          console.log("updatedSteps", updatedSteps);
          setCompleted(Array(updatedSteps.length).fill(false));
          console.log("steps", steps);

          // console.log('steps555',steps);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const disabledHours = () => {
    const disabledHoursArray = [];
    for (let i = 0; i < 8; i++) {
      disabledHoursArray.push(i);
    }
    for (let i = 19; i <= 23; i++) {
      disabledHoursArray.push(i);
    }
    return disabledHoursArray;
  };

  function getCourtList() {
    axios
      .get(`${config.mainAPI}/court-list`)
      .then((response) => {
        setCourtList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const onChangeMultipleValue = (setData, event, index) => {
    const { value } = event.target;
    setData((prevStatusDefendant) => {
      const newStatusDefendant = [...prevStatusDefendant];
      newStatusDefendant[index] = value;
      return newStatusDefendant;
    });
  };

  const handleCoDefendantStatusChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantStatus((prevStatusDefendant) => {
      const newStatusDefendant = [...prevStatusDefendant];
      newStatusDefendant[index] = value;
      return newStatusDefendant;
    });
  };

  const handleCoDefendantPrefixChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantPrefix((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantLastNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantLastName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantAddressChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantAddress((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  const handleCoDefendantIdCardChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantIdCard((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  //   setPrefixDefendantAgent;

  const handleCloseFailure = () => {
    setOpenFailure(false);
    setTextFail([]);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate("/loan");
  };

  const handlePrefixDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setPrefixDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleLastNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setLastNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleIdCardDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setIdCardDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleAddressDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setAddressDefendantAgent((prevStatusDefendant) => {
      const newAddressDefendantAgent = [...prevStatusDefendant];
      newAddressDefendantAgent[index] = value;
      return newAddressDefendantAgent;
    });
  };

  const handleStatusDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setStatusDefendantAgent((prevStatusDefendant) => {
      const newStatusDefendantAgent = [...prevStatusDefendant];
      newStatusDefendantAgent[index] = value;
      return newStatusDefendantAgent;
    });
  };

  // const handleDateGoCourtEtcChange = (event, index) => {
  //   // const { value } = event.target;
  //   const { value } = event;
  //   console.log(value);
  //   setDateGoCourtEtc((prevStatusDefendant) => {
  //     const data = [...prevStatusDefendant];
  //     data[index] = value;
  //     return data;
  //   });
  // };

  // const handleDateGoCourtEtcChange = (item, index) => {
  //   // const { value } = event.target;
  //   const { value } = item;
  //   console.log(value);
  //   setDateGoCourtEtc((prevStatusDefendant) => {
  //     const data = [...prevStatusDefendant];
  //     data[index] = value;
  //     return data;
  //   });
  // };

  const handleDateGoCourtEtcChange = (item, index) => {
    setDateGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      console.log("item", item);
      data[index] = item;
      console.log("data", data);
      return data;
    });
  };

  const onChangeDateValue = (christDate, buddhistDate, index, setData) => {
    setData((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const onChangeTimeValue = (date, index, setData) => {
    const formattedDate = date;
    setData((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = formattedDate ? new Date(formattedDate) : null;
      // data[index] = formattedDate;
      return data;
    });
    // new Date(item.time)
  };

  const handleDateGoCourtEtcChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleTimeGoCourtEtcChange = (date, index) => {
    const formattedDate = date;
    setTimeGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = formattedDate ? new Date(formattedDate) : null;
      // data[index] = formattedDate;
      return data;
    });
    // new Date(item.time)
  };

  const handleTimeGoCourtChange = (date) => {
    if (date) {
      const combinedDateTime = moment(date, "YYYY-MM-DD HH:mm:ss");
      const formattedDate = moment(combinedDateTime).toDate();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.toLocaleString("en-US", { month: "short" });
      const day = currentDate.getDate();
      const dateString = `${day} ${month} ${year} ${date} GMT+0700 (Indochina Time)`;
      console.log(
        "formattedDate instanceof Date && !isNaN(formattedDate) ? formattedDate : dateString",
        formattedDate instanceof Date && !isNaN(formattedDate)
          ? formattedDate
          : dateString
      );
      setCourtTime(
        formattedDate instanceof Date && !isNaN(formattedDate)
          ? formattedDate
          : dateString
      );
    } else {
      setCourtTime(null);
    }
  };

  const handleLawyerGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    console.log("workByName", workByName);
    console.log("workById", workById);
    setLawyerGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workById;
      return data;
    });
    setLawyerNameGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workByName;
      return data;
    });
  };

  const handleDetailGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    setDetailGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const goCourtEtc = dateGoCourtEtc.map((item, index) => {
    const combinedDateTime = moment(
      timeGoCourtEtc[index],
      "YYYY-MM-DD HH:mm:ss"
    );
    const formattedDate = moment(combinedDateTime).toDate();

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString("en-US", { month: "short" });
    const day = currentDate.getDate();
    const dateString = `${day} ${month} ${year} ${timeGoCourtEtc[index]} GMT+0700 (Indochina Time)`;

    return {
      date: item ? moment(item).format("YYYY-MM-DD") : null,
      time:
        formattedDate instanceof Date && !isNaN(formattedDate)
          ? formattedDate
          : dateString,
      lawyer: lawyerGoCourtEtc[index] ? lawyerGoCourtEtc[index] : null,
      lawyerName: lawyerNameGoCourtEtc[index]
        ? lawyerNameGoCourtEtc[index]
        : null,
      detail: detailGoCourtEtc[index] ? detailGoCourtEtc[index] : null,
      round: index + 1,
    };
  });

  const combinedTimeShow = (time) => {
    if (time) {
      const combinedDateTime = moment(time, "YYYY-MM-DD HH:mm:ss");
      const formattedDate = moment(combinedDateTime).toDate();

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.toLocaleString("en-US", { month: "short" });
      const day = currentDate.getDate();
      const dateString = `${day} ${month} ${year} ${time} GMT+0700 (Indochina Time)`;
      return formattedDate instanceof Date && !isNaN(formattedDate)
        ? formattedDate
        : dateString;
    } else {
      return null;
    }
  };

  const subDefendant = coBorrowerName.map((item, index) => ({
    order: index + 1,
    prefix: coBorrowerPrefix[index],
    name: item,
    lastname: coBorrowerLastName[index],
    fullName: coBorrowerPrefix[index] + item + " " + coBorrowerLastName[index],
    address: coBorrowerAddress[index],
    idCard: coBorrowerIdCard[index],
    status: coBorrowerStatus[index],
    reserveCoBorrowerPrefix: reserveCoBorrowerPrefix[index],
    reserveCoBorrowerFirstname: reserveCoBorrowerFirstname[index],
    reserveCoBorrowerLastname: reserveCoBorrowerLastname[index],
    reserveCoBorrowerFullname:
      reserveCoBorrowerPrefix[index] && reserveCoBorrowerFirstname[index]
        ? reserveCoBorrowerPrefix[index] +
          reserveCoBorrowerFirstname[index] +
          " " +
          reserveCoBorrowerLastname[index]
        : null,
    reserveCoBorrowerIdCard: reserveCoBorrowerIdCard[index],
    reserveCoBorrowerAddress: reserveCoBorrowerAddress[index],
    reserveCoBorrowerTel: reserveCoBorrowerTel[index],
    reserveCoBorrowerRole: reserveCoBorrowerRole[index],
  }));

  const contract = gsbNumberList.map((item, index) => ({
    order: index + 1,
    id: item,
    description: "",
    type: loanTypeList[index],
    typeOfCollateral: typeOfCollateralList[index],
    typeOf: "loan",
    pricipal: principalList[index],
    interest: interestList[index],
    noticeGetDay: noticeDayGetList[index],
    noticeOutDay: noticeDayOutList[index],
    noticeDescription: noticeInvestigateList[index],
    bankruptJudge: "",
    countDay: countDateList[index],
    dateOfInterest: "",
    calInterest: dateCalInterestSinceList[index],
  }));

  const coDefendant = coDefendantName.map((item, index) => ({
    prefix: coDefendantPrefix[index],
    name: item,
    lastname: coDefendantLastName[index],
    status: coDefendantStatus[index],
    address: coDefendantAddress[index],
    idCard: coDefendantIdCard[index],
    role: `ผู้ค้ำประกันที่${[index + 1]}`,
    order: index + 1,
  }));

  const defendantAgent = nameDefendantAgent.map((item, index) => ({
    prefix: prefixDefendantAgent[index] || null,
    name: item || null,
    lastname: lastNameDefendantAgent[index] || null,
    address: addressDefendantAgent[index] || null,
    idCard: idCardDefendantAgent[index] || null,
    status: statusDefendantAgent[index] || null,
    role: `ตัวแทนผู้ค้ำประกันที่${[index + 1]}` || null,
    order: index + 1,
  }));

  const addressOptional = addressOptionalHouseNumber.map((item, index) => ({
    order: index + 1,
    houseNumber: addressOptionalHouseNumber[index] || null,
    group: addressOptionalHouseNumber[index] || null,
    road: addressOptionalHouseNumber[index] || null,
    soi: addressOptionalHouseNumber[index] || null,
    address: addressOptionalHouseNumber[index] || null,
    tambon: addressOptionalTambon[index] || null,
    tambonName: addressOptionalTambonName[index] || null,
    amphoe: addressOptionalAmphoe[index] || null,
    amphoeName: addressOptionalAmphoeName[index] || null,
    province: addressOptionalProvince[index] || null,
    provinceName: addressOptionalProvinceName[index] || null,
    zipcode: addressOptionalZipcode[index] || null,
  }));

  const postData = async (url, data, successMessage) => {
    try {
      const response = await api.post(url, data);
      saveActivityLog(successMessage);
      setOpenSuccess(true);
      setTextFail([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (row) => {
    const caseData = params != null ? "edit" : "add";
    console.log("caseData", caseData);
    if (type == "บุคคล") {
      // if (
      //   dateStart &&
      //   defendantFirstName &&
      //   defendantLastName &&
      //   defendantIdCard &&
      //   // defendantAccountNumber &&
      //   chooseMemberCompany &&
      //   debtCenter &&
      //   branchAgency &&
      //   defendantHouseNumber &&
      //   defendantProvince &&
      //   defendantTambon &&
      //   defendantAmphoe &&
      //   defendantZipcode
      // )
      if (
        dateStart &&
        defendantFirstName &&
        defendantLastName &&
        defendantIdCard &&
        // defendantAccountNumber &&
        chooseMemberCompany &&
        debtCenter 
      )
      {
        const payload = {
          type: type,
          dateStart: dateStart,
          deadline: deadline,
          gsbNumber: gsbNumber,
          fileNumber: fileNumber,
          nameCompany: nameCompany,
          statusDefendantNow: statusDefendantNow,
          prefix: prefix,
          defendantFirstName: defendantFirstName,
          defendantLastName: defendantLastName,
          defendantIdCard: defendantIdCard,
          defendantAccountNumber: defendantAccountNumber,
          defendantAccountNumber1: defendantAccountNumber1,
          defendantAccount: defendantAccount,
          defendantHouseNumber: defendantHouseNumber,
          defendantGroup: defendantGroup,
          defendantRoad: defendantRoad,
          defendantSoi: defendantSoi,
          defendantAddress: defendantAddress,
          defendantTambonName: defendantTambonName,
          defendantAmphoeName: defendantAmphoeName,
          defendantProvinceName: defendantProvinceName,
          defendantTambon: defendantTambon,
          defendantAmphoe: defendantAmphoe,
          defendantProvince: defendantProvince,
          defendantZipcode: defendantZipcode,
          defendantContractHouseNumber: defendantContractHouseNumber,
          defendantContractGroup: defendantContractGroup,
          defendantContractRoad: defendantContractRoad,
          defendantContractSoi: defendantContractSoi,
          defendantContractAddress: defendantContractAddress,
          defendantContractTambon: defendantContractTambon,
          defendantContractAmphoe: defendantContractAmphoe,
          defendantContractProvince: defendantContractProvince,
          defendantContractTambonName: defendantContractTambonName,
          defendantContractAmphoeName: defendantContractAmphoeName,
          defendantContractProvinceName: defendantContractProvinceName,
          defendantContractZipcode: defendantContractZipcode,
          reservePrefix: reservePrefix,
          reserveFirstname: reserveFirstname,
          reserveLastname: reserveLastname,
          reserveIdcard: reserveIdcard,
          reserveAddress: reserveAddress,
          reserveTel: reserveTel,
          reserveRole: reserveRole,
          addressLoan: addressLoan,
          contract: contract,
          subDefendant: subDefendant,
          addressOptional: addressOptional,
          phone: phone,
          phone1: phone1,
          debtCenter: debtCenter,
          branchAgency: branchAgency,
          districtAgency: districtAgency,
          sectorAgency: sectorAgency,
          loanType: loanType,
          typeOfCollateral: typeOfCollateral,
          noticeDayGet: noticeDayGet
            ? moment(noticeDayGet).format("YYYY-MM-DD")
            : null,
          noticeDayOut: noticeDayOut
            ? moment(noticeDayOut).format("YYYY-MM-DD")
            : null,
          noticeInvestigate: noticeInvestigate,
          dateOfSigning: dateOfSigning,
          dateOfOutStanding: dateOfOutStanding,
          principalContract: principalContract,
          interestContract: interestContract,
          mortgageProperty: mortgageProperty,
          mortgagePropertyName: mortgagePropertyName,
          mortgagePropertyDeed: mortgagePropertyDeed,
          mortgagePropertyNumber: mortgagePropertyNumber,
          mortgagePropertyExplore: mortgagePropertyExplore,
          mortgagePropertyTambon: mortgagePropertyTambon,
          mortgagePropertyTambonShow: mortgagePropertyTambonShow,
          mortgagePropertyAmphoe: mortgagePropertyAmphoe,
          mortgagePropertyAmphoeShow: mortgagePropertyAmphoeShow,
          mortgagePropertyProvince: mortgagePropertyProvince,
          mortgagePropertyProvinceShow: mortgagePropertyProvinceShow,
          mortgagePropertyZipcode:mortgagePropertyZipcode,
          mortgagePropertyDay: mortgagePropertyDay,
          mortgagePropertyType:mortgagePropertyType,
          mortgagePropertyRoomNumber:mortgagePropertyRoomNumber,
          mortgagePropertyTowerNumber:mortgagePropertyTowerNumber,
          mortgagePropertyTowerName:mortgagePropertyTowerName,
          mortgagePropertyVillage:mortgagePropertyVillage,






          sueDay: sueDay ? moment(sueDay).format("YYYY-MM-DD") : null,
          principalCapital: principalCapital,
          interestCapital: interestCapital,
          court: court,
          courtDate: courtDate ? moment(courtDate).format("YYYY-MM-DD") : null,
          courtTime: combinedTimeShow(courtTime),
          countCourtEtc: parseInt(countCourtEtc),
          goCourtEtc: goCourtEtc,
          blackCaseNumber: blackCaseNumber,
          blackCaseYear: blackCaseYear,
          redCaseNumber: redCaseNumber,
          redCaseYear: redCaseYear,
          witnessExaminationDate: witnessExaminationDate
            ? moment(witnessExaminationDate).format("YYYY-MM-DD")
            : null,
          judgmentUnilateral: judgmentUnilateral
            ? moment(judgmentUnilateral).format("YYYY-MM-DD")
            : null,
          judgmentAccordingToConsent: judgmentAccordingToConsent
            ? moment(judgmentAccordingToConsent).format("YYYY-MM-DD")
            : null,
          judgmentDay: judgmentDay
            ? moment(judgmentDay).format("YYYY-MM-DD")
            : null,
          noticeDateSign: noticeDateSign
            ? moment(noticeDateSign).format("YYYY-MM-DD")
            : null,
          detailsJudgment: detailsJudgment,
          withdrawOneCase: withdrawOneCase
            ? moment(withdrawOneCase).format("YYYY-MM-DD")
            : null,
          withdrawOneCaseDueTo: withdrawOneCaseDueTo,
          returnFile: returnFile
            ? moment(returnFile).format("YYYY-MM-DD")
            : null,
          returnFileReason: returnFileReason,
          decree: decree ? moment(decree).format("YYYY-MM-DD") : null,
          issueAWarranOfExecution: issueAWarranOfExecution
            ? moment(issueAWarranOfExecution).format("YYYY-MM-DD")
            : null,
          fileName: fileName,
          filePath: filePath,
          coDefendant: coDefendant,
          countDefendant: parseInt(countDefendant),
          defendantAgent: defendantAgent,
          companyCode: companyCode,
          courtCode: courtCode,
          chooseMemberCompany: chooseMemberCompany,
          chooseMemberCompanyName: chooseMemberCompanyName,
          appealDueDate: appealDueDate
            ? moment(appealDueDate).format("YYYY-MM-DD")
            : null,
          genCode: genCode,
          caseData: caseData,
          note: note,
          other: other,
          user: user,
          countCodefendentDB: caseData == "edit" ? countCodefendant : null,
          countDefendentAgentDB:
            caseData == "edit" ? countDefendantAgent : null,
          countGoCourtDB:
            caseData == "edit" ? countCourtEtc + 1 : courtDate ? 1 : 0,
          noticePrincipal: noticePrincipal,
          noticeInterest: noticeInterest,
          noticeSumAll: noticeSumAll,
          noticeCalDate: noticeCalDate,
          // if(caseData === "edit"){

          // }
        };
        const payloadExecution = {
          executionId: executionId,
          executionCode: executionCode,
          sequestrationFee: sequestrationFee,
          lumpSum: lumpSum,
          ledCost: ledCost,
          ledAll: ledAll,
          ledDate0: ledDate0,
          ledDateBankPaid: ledDateBankPaid,
          led20: led20,
          led20Date1: led20Date1,
          ledDateBankPaid1: ledDateBankPaid1,
          led20R1: led20R1,
          led20DateR2: led20DateR2,
          ledDateBankPaid2: ledDateBankPaid2,
          led20R2: led20R2,
          led20DateR3: led20DateR3,
          ledDateBankPaid3: ledDateBankPaid3,
          led20R3: led20R3,
          led20DateR4: led20DateR4,
          ledDateBankPaid4: ledDateBankPaid4,
          ledFess: ledFess,
          ledFollow: ledFollow,
          ledSubmit: ledSubmit,
          ledCostWagesRequest: ledCostWagesRequest,
          ledInvestigateDate: ledInvestigateDate,
          ledInvestigateDateBankPaid: ledInvestigateDateBankPaid,
          ledWagesRequest: ledWagesRequest,
          ledWagesDateBankPaid: ledWagesDateBankPaid,
          ledWagesInvestigateDate: ledWagesInvestigateDate,
          createLedCost: createLedCost,
          costCreateSequester: costCreateSequester,
          costAllSequester: costAllSequester,
          costSequester: costSequester,
          costAllLedSequester: costAllLedSequester,
          sequesterDate: sequesterDate,
          sequesterDateBankPaid: sequesterDateBankPaid,
          seizedWages: seizedWages,
          costSequesterCheck: costSequesterCheck,
          costSequesterEtc: costSequesterEtc,
          costAllSequesterEtc: costAllSequesterEtc,
          dateSequesterInvestigate: dateSequesterInvestigate,
          sequesterInvestigateDateBankPaid: sequesterInvestigateDateBankPaid,
          cost2Per: cost2Per,
          cost2PerInverstigate: cost2PerInverstigate,
          cost2PerDateBankPaid: cost2PerDateBankPaid,
          cost50Per: cost50Per,
          cost50PerInverstigate: cost50PerInverstigate,
          cost50PerDateBankPaid: cost50PerDateBankPaid,
          cost1Per: cost1Per,
          cost1PerInverstigate: cost1PerInverstigate,
          cost1PerDateBankPaid: cost1PerDateBankPaid,
          lawFees: lawFees,
          bankruptGetCostAll: bankruptGetCostAll,
          bankruptRound1: bankruptRound1,
          bankruptRound1All: bankruptRound1All,
          bankruptRound1Investigate: bankruptRound1Investigate,
          bankruptRound1DateBankPaid: bankruptRound1DateBankPaid,
          bankruptRound1LawFrees: bankruptRound1LawFrees,
          bankruptRound2: bankruptRound2,
          bankruptRound2Investigate: bankruptRound2Investigate,
          bankruptDateBankPaid: bankruptDateBankPaid,
          bankruptCostAll: bankruptCostAll,

          spouseCheckDate: spouseCheckDate,
          spouseName: spouseName,
          spouseIdCard: spouseIdCard,
          spouseNote: spouseNote,
          spouseIncomeDate: spouseIncomeDate,
          spouseStatus: spouseStatus,
          workPlaceIncomeDate: workPlaceIncomeDate,
          workPlaceName: workPlaceName,
          workPlaceIncome: workPlaceIncome,
          workPlaceDateSendIncome: workPlaceDateSendIncome,
          workPlaceDetailIncome: workPlaceDetailIncome,
          workPlaceStatusIncome: workPlaceStatusIncome,
          dltDateCheck: dltDateCheck,
          dltDetail: dltDetail,
          dltStatus: dltStatus,
          dolDateCheckGround: dolDateCheckGround,
          dolStatusObject: dolStatusObject,
          dolDetail: dolDetail,
          dolFailureDetail: dolFailureDetail,
          dolStatus: dolStatus,
          dolSell: dolSell,
          dolMoneySell: dolMoneySell,
          dolCostReturn: dolCostReturn,
          dolFailureDetail1: dolFailureDetail1,
          dolDateGetSend: dolDateGetSend,

          bankruptNumber1: bankruptNumber1,
          bankruptJudge1: bankruptJudge1,
          bankruptMoney1: bankruptMoney1,
          bankruptInterest1: bankruptInterest1,
          bankruptSince1: bankruptSince1,
          bankruptNumber2: bankruptNumber2,
          bankruptJudge2: bankruptJudge2,
          bankruptMoney2: bankruptMoney2,
          bankruptInterest2: bankruptInterest2,
          bankruptSince2: bankruptSince2,
          bankruptCourt: bankruptCourt,
          bankruptFollow: bankruptFollow,
          bankruptCostLawyer: bankruptCostLawyer,
          bankruptCostSend: bankruptCostSend,
          bankruptAll: bankruptAll,
          dateCalInterestSince: dateCalInterestSince,
          countDate: countDate,
          interest: interest,
          dateCalInterestSince1: dateCalInterestSince1,
          countDate1: countDate1,
          interest1: interest1,

          debtAllByJudgment: debtAllByJudgment,
          costLedAll: costLedAll,
          sumReturnAll: sumReturnAll,
          calDebtSince: calDebtSince,
          costWorking: costWorking,

          ledCostCourt: ledCostCourt,
          ledCostCall: ledCostCall,
          ledCostForce: ledCostForce,
          ledCostTell: ledCostTell,
          ledCostAll: ledCostAll,
          ledCostRequest: ledCostRequest,
          ledCostLawyer1: ledCostLawyer1,
          ledCostLawyer2: ledCostLawyer2,
          ledInvestigete1Date: ledInvestigete1Date,
          ledBankPaid1Date: ledBankPaid1Date,
          ledInvestigete2Date: ledInvestigete2Date,
          ledBankPaid2Date: ledBankPaid2Date,
          cojCostCourt: cojCostCourt,
          cojCostGo: cojCostGo,
          cojCostSumAll: cojCostSumAll,
          cojCostLawyer: cojCostLawyer,
          cojCostAll: cojCostAll,
          supCostCourt: supCostCourt,
          supCostGo: supCostGo,
          supCostSumAll: supCostSumAll,
          supCostLawyer: supCostLawyer,
          supCostAll: supCostAll,
          supDateReq: supDateReq,
          supDatePaid: supDatePaid,
          costCheckRoleInvestigate: costCheckRoleInvestigate,
          costCheckCarInvestigate: costCheckCarInvestigate,
          costEtcInvestigate: costEtcInvestigate,
          costAllInvestigate: costAllInvestigate,
          costCheckInvestigate: costCheckInvestigate,
          costCheckMapInvestigate: costCheckMapInvestigate,
          costCheckAllInvestigate: costCheckAllInvestigate,
          costWithdrawInvestigate: costWithdrawInvestigate,
          datePaidInvestigate: datePaidInvestigate,
          executionDateStart: executionDateStart,
          executionDeadline: executionDeadline,
          executionCourtDate: executionCourtDate,
          executionCourtTime: executionCourtTime,
          executionCourtNumber: executionCourtNumber,
          executionWorkBy: executionWorkBy,
          executionWorkByName: executionWorkByName,
        };
        const newValue = executionCase
          ? { ...payload, ...payloadExecution }
          : payload;
        console.log("newValue", newValue);
        console.log("payload", payload);
        postData(
          `${config.mainAPI}/updateLoan`,
          { ...payload },
          `Create Document Type: Loan Code: ${genCode}`
        );
        console.log("executionCase", executionCase);

        // Second API request based on executionCase
        if (executionCase == 1) {
          postData(
            `${config.mainAPI}/updateExecutionData`,
            { ...newValue },
            `Create Document Type: Execution Code: ${genCode}`
          );
        }
      } else {
        if (!dateStart) {
          setTextFail((prevTextFail) => [...prevTextFail, "วันที่รับเรื่อง"]);
        }
        if (!defendantFirstName) {
          setTextFail((prevTextFail) => [...prevTextFail, "ชื่อจำเลย"]);
        }
        if (!defendantLastName) {
          setTextFail((prevTextFail) => [...prevTextFail, "นามสกุลจำเลย"]);
        }
        if (!defendantIdCard) {
          setTextFail((prevTextFail) => [...prevTextFail, "เลขที่บัตรประชาชน"]);
        }
        // if (!defendantAccountNumber) {
        //   setTextFail((prevTextFail) => [...prevTextFail, "เลขที่บัญชีหลัก"]);
        // }
        if (!chooseMemberCompany) {
          setTextFail((prevTextFail) => [...prevTextFail, "ผู้รับผิดชอบคดี"]);
        }
        if (!debtCenter) {
          setTextFail((prevTextFail) => [...prevTextFail, "ศูนย์หนี้"]);
        }
        // if (!branchAgency) {
        //   setTextFail((prevTextFail) => [...prevTextFail, "สาขา"]);
        // }
        // if (!defendantHouseNumber) {
        //   setTextFail((prevTextFail) => [...prevTextFail, "บ้านเลขที่"]);
        // }
        // if (!defendantProvince) {
        //   setTextFail((prevTextFail) => [...prevTextFail, "จังหวัด"]);
        // }
        // if (!defendantTambon) {
        //   setTextFail((prevTextFail) => [...prevTextFail, "ตำบล/แขวง"]);
        // }
        // if (!defendantAmphoe) {
        //   setTextFail((prevTextFail) => [...prevTextFail, "อำเภอ/เขต"]);
        // }
        // if (!defendantZipcode) {
        //   setTextFail((prevTextFail) => [...prevTextFail, "รหัสไปรษณี"]);
        // }
        console.log("textFail", textFail);
        setOpenFailure(true);
      }
    } else if (type == "นิติบุคคล") {
      // if (
      //   dateStart &&
      //   nameCompany &&
      //   chooseMemberCompany &&
      //   debtCenter &&
      //   branchAgency &&
      //   defendantHouseNumber &&
      //   defendantProvince &&
      //   defendantTambon &&
      //   defendantAmphoe &&
      //   defendantZipcode
      // ) 
      if (
        dateStart &&
        nameCompany &&
        chooseMemberCompany &&
        debtCenter 
      ) 
      {
        const payload = {
          type: type,
          dateStart: dateStart,
          deadline: deadline,
          fileNumber: fileNumber,
          nameCompany: nameCompany,
          prefix: prefix,
          defendantFirstName: defendantFirstName,
          defendantLastName: defendantLastName,
          defendantIdCard: defendantIdCard,
          defendantAccountNumber: defendantAccountNumber,
          defendantAccountNumber1: defendantAccountNumber1,
          defendantAccount: defendantAccount,
          defendantHouseNumber: defendantHouseNumber,
          defendantGroup: defendantGroup,
          defendantRoad: defendantRoad,
          defendantSoi: defendantSoi,
          defendantAddress: defendantAddress,
          defendantTambonName: defendantTambonName,
          defendantAmphoeName: defendantAmphoeName,
          defendantProvinceName: defendantProvinceName,
          defendantTambon: defendantTambon,
          defendantAmphoe: defendantAmphoe,
          defendantProvince: defendantProvince,
          defendantZipcode: defendantZipcode,
          contract: contract,
          subDefendant: subDefendant,
          addressOptional: addressOptional,
          reservePrefix: reservePrefix,
          reserveFirstname: reserveFirstname,
          reserveLastname: reserveLastname,
          reserveIdcard: reserveIdcard,
          reserveAddress: reserveAddress,
          reserveTel: reserveTel,
          reserveRole: reserveRole,
          phone: phone,
          phone1: phone1,
          gsbNumber: gsbNumber,
          debtCenter: debtCenter,
          branchAgency: branchAgency,
          districtAgency: districtAgency,
          sectorAgency: sectorAgency,
          loanType: loanType,
          typeOfCollateral: typeOfCollateral,
          noticeDayGet: noticeDayGet
            ? moment(noticeDayGet).format("YYYY-MM-DD")
            : null,
          noticeDayOut: noticeDayOut
            ? moment(noticeDayOut).format("YYYY-MM-DD")
            : null,
          noticeInvestigate: noticeInvestigate,
          dateOfSigning: dateOfSigning,
          dateOfOutStanding: dateOfOutStanding,
          principalContract: principalContract,
          interestContract: interestContract,
          mortgagePropertyName: mortgagePropertyName,
          mortgagePropertyDeed: mortgagePropertyDeed,
          mortgagePropertyNumber: mortgagePropertyNumber,
          mortgagePropertyExplore: mortgagePropertyExplore,
          mortgagePropertyTambon: mortgagePropertyTambon,
          mortgagePropertyAmphoe: mortgagePropertyAmphoe,
          mortgagePropertyProvince: mortgagePropertyProvince,
          mortgagePropertyDay: mortgagePropertyDay,
          mortgagePropertyType:mortgagePropertyType,
          mortgagePropertyRoomNumber:mortgagePropertyRoomNumber,
          mortgagePropertyTowerNumber:mortgagePropertyTowerNumber,
          mortgagePropertyTowerName:mortgagePropertyTowerName,






          sueDay: sueDay ? moment(sueDay).format("YYYY-MM-DD") : null,
          principalCapital: principalCapital,
          interestCapital: interestCapital,
          court: court,
          courtDate: courtDate ? moment(courtDate).format("YYYY-MM-DD") : null,
          courtTime: combinedTimeShow(courtTime),
          countCourtEtc: parseInt(countCourtEtc),
          goCourtEtc: goCourtEtc,
          blackCaseNumber: blackCaseNumber,
          blackCaseYear: blackCaseYear,
          redCaseNumber: redCaseNumber,
          redCaseYear: redCaseYear,
          noticeDateSign: noticeDateSign
            ? moment(noticeDateSign).format("YYYY-MM-DD")
            : null,
          witnessExaminationDate: witnessExaminationDate
            ? moment(witnessExaminationDate).format("YYYY-MM-DD")
            : null,
          judgmentUnilateral: judgmentUnilateral
            ? moment(judgmentUnilateral).format("YYYY-MM-DD")
            : null,
          judgmentAccordingToConsent: judgmentAccordingToConsent
            ? moment(judgmentAccordingToConsent).format("YYYY-MM-DD")
            : null,
          judgmentDay: judgmentDay
            ? moment(judgmentDay).format("YYYY-MM-DD")
            : null,
          detailsJudgment: detailsJudgment,
          withdrawOneCase: withdrawOneCase
            ? moment(withdrawOneCase).format("YYYY-MM-DD")
            : null,
          withdrawOneCaseDueTo: withdrawOneCaseDueTo,
          returnFile: returnFile
            ? moment(returnFile).format("YYYY-MM-DD")
            : null,
          returnFileReason: returnFileReason,
          decree: decree ? moment(decree).format("YYYY-MM-DD") : null,
          issueAWarranOfExecution: issueAWarranOfExecution
            ? moment(issueAWarranOfExecution).format("YYYY-MM-DD")
            : null,
          fileName: fileName,
          filePath: filePath,
          coDefendant: coDefendant,
          countDefendant: parseInt(countDefendant),
          defendantAgent: defendantAgent,
          companyCode: companyCode,
          courtCode: courtCode,
          chooseMemberCompany: chooseMemberCompany,
          chooseMemberCompanyName: chooseMemberCompanyName,
          appealDueDate: appealDueDate
            ? moment(appealDueDate).format("YYYY-MM-DD")
            : null,
          genCode: genCode,
          caseData: caseData,
          note: note,
          other: other,
          user: user,
          countCodefendentDB: caseData == "edit" ? countCodefendant : null,
          countDefendentAgentDB:
            caseData == "edit" ? countDefendantAgent : null,
          countGoCourtDB:
            caseData == "edit" ? countCourtEtc + 1 : courtDate ? 1 : 0,
          noticePrincipal: noticePrincipal,
          noticeInterest: noticeInterest,
          noticeSumAll: noticeSumAll,
          noticeCalDate: noticeCalDate,
          // if(caseData === "edit"){

          // }
          if(executionCase) {},
        };
        const payloadExecution = {
          sequestrationFee: sequestrationFee,
          lumpSum: lumpSum,
          ledCost: ledCost,
          ledAll: ledAll,
          ledDate0: ledDate0,
          ledDateBankPaid: ledDateBankPaid,
          led20: led20,
          led20Date1: led20Date1,
          ledDateBankPaid1: ledDateBankPaid1,
          led20R1: led20R1,
          led20DateR2: led20DateR2,
          ledDateBankPaid2: ledDateBankPaid2,
          led20R2: led20R2,
          led20DateR3: led20DateR3,
          ledDateBankPaid3: ledDateBankPaid3,
          led20R3: led20R3,
          led20DateR4: led20DateR4,
          ledDateBankPaid4: ledDateBankPaid4,
          ledFess: ledFess,
          ledFollow: ledFollow,
          ledSubmit: ledSubmit,
          ledCostWagesRequest: ledCostWagesRequest,
          ledInvestigateDate: ledInvestigateDate,
          ledInvestigateDateBankPaid: ledInvestigateDateBankPaid,
          ledWagesRequest: ledWagesRequest,
          ledWagesDateBankPaid: ledWagesDateBankPaid,
          ledWagesInvestigateDate: ledWagesInvestigateDate,
          createLedCost: createLedCost,
          costCreateSequester: costCreateSequester,
          costAllSequester: costAllSequester,
          costSequester: costSequester,
          costAllLedSequester: costAllLedSequester,
          sequesterDate: sequesterDate,
          sequesterDateBankPaid: sequesterDateBankPaid,
          seizedWages: seizedWages,
          costSequesterCheck: costSequesterCheck,
          costSequesterEtc: costSequesterEtc,
          costAllSequesterEtc: costAllSequesterEtc,
          dateSequesterInvestigate: dateSequesterInvestigate,
          sequesterInvestigateDateBankPaid: sequesterInvestigateDateBankPaid,
          cost2Per: cost2Per,
          cost2PerInverstigate: cost2PerInverstigate,
          cost2PerDateBankPaid: cost2PerDateBankPaid,
          cost50Per: cost50Per,
          cost50PerInverstigate: cost50PerInverstigate,
          cost50PerDateBankPaid: cost50PerDateBankPaid,
          cost1Per: cost1Per,
          cost1PerInverstigate: cost1PerInverstigate,
          cost1PerDateBankPaid: cost1PerDateBankPaid,
          lawFees: lawFees,
          bankruptGetCostAll: bankruptGetCostAll,
          bankruptRound1: bankruptRound1,
          bankruptRound1All: bankruptRound1All,
          bankruptRound1Investigate: bankruptRound1Investigate,
          bankruptRound1DateBankPaid: bankruptRound1DateBankPaid,
          bankruptRound1LawFrees: bankruptRound1LawFrees,
          bankruptRound2: bankruptRound2,
          bankruptRound2Investigate: bankruptRound2Investigate,
          bankruptDateBankPaid: bankruptDateBankPaid,
          bankruptCostAll: bankruptCostAll,

          spouseCheckDate: spouseCheckDate,
          spouseName: spouseName,
          spouseIdCard: spouseIdCard,
          spouseNote: spouseNote,
          spouseIncomeDate: spouseIncomeDate,
          spouseStatus: spouseStatus,
          workPlaceIncomeDate: workPlaceIncomeDate,
          workPlaceName: workPlaceName,
          workPlaceIncome: workPlaceIncome,
          workPlaceDateSendIncome: workPlaceDateSendIncome,
          workPlaceDetailIncome: workPlaceDetailIncome,
          workPlaceStatusIncome: workPlaceStatusIncome,
          dltDateCheck: dltDateCheck,
          dltDetail: dltDetail,
          dltStatus: dltStatus,
          dolDateCheckGround: dolDateCheckGround,
          dolStatusObject: dolStatusObject,
          dolDetail: dolDetail,
          dolFailureDetail: dolFailureDetail,
          dolStatus: dolStatus,
          dolSell: dolSell,
          dolMoneySell: dolMoneySell,
          dolCostReturn: dolCostReturn,
          dolFailureDetail1: dolFailureDetail1,
          dolDateGetSend: dolDateGetSend,

          bankruptNumber1: bankruptNumber1,
          bankruptJudge1: bankruptJudge1,
          bankruptMoney1: bankruptMoney1,
          bankruptInterest1: bankruptInterest1,
          bankruptSince1: bankruptSince1,
          bankruptNumber2: bankruptNumber2,
          bankruptJudge2: bankruptJudge2,
          bankruptMoney2: bankruptMoney2,
          bankruptInterest2: bankruptInterest2,
          bankruptSince2: bankruptSince2,
          bankruptCourt: bankruptCourt,
          bankruptFollow: bankruptFollow,
          bankruptCostLawyer: bankruptCostLawyer,
          bankruptCostSend: bankruptCostSend,
          bankruptAll: bankruptAll,
          dateCalInterestSince: dateCalInterestSince,
          countDate: countDate,
          interest: interest,
          dateCalInterestSince1: dateCalInterestSince1,
          countDate1: countDate1,
          interest1: interest1,

          debtAllByJudgment: debtAllByJudgment,
          costLedAll: costLedAll,
          sumReturnAll: sumReturnAll,
          calDebtSince: calDebtSince,
          costWorking: costWorking,

          ledCostCourt: ledCostCourt,
          ledCostCall: ledCostCall,
          ledCostForce: ledCostForce,
          ledCostTell: ledCostTell,
          ledCostAll: ledCostAll,
          ledCostRequest: ledCostRequest,
          ledCostLawyer1: ledCostLawyer1,
          ledCostLawyer2: ledCostLawyer2,
          ledInvestigete1Date: ledInvestigete1Date,
          ledBankPaid1Date: ledBankPaid1Date,
          ledInvestigete2Date: ledInvestigete2Date,
          ledBankPaid2Date: ledBankPaid2Date,
          cojCostCourt: cojCostCourt,
          cojCostGo: cojCostGo,
          cojCostSumAll: cojCostSumAll,
          cojCostLawyer: cojCostLawyer,
          cojCostAll: cojCostAll,
          supCostCourt: supCostCourt,
          supCostGo: supCostGo,
          supCostSumAll: supCostSumAll,
          supCostLawyer: supCostLawyer,
          supCostAll: supCostAll,
          supDateReq: supDateReq,
          supDatePaid: supDatePaid,
          costCheckRoleInvestigate: costCheckRoleInvestigate,
          costCheckCarInvestigate: costCheckCarInvestigate,
          costEtcInvestigate: costEtcInvestigate,
          costAllInvestigate: costAllInvestigate,
          costCheckInvestigate: costCheckInvestigate,
          costCheckMapInvestigate: costCheckMapInvestigate,
          costCheckAllInvestigate: costCheckAllInvestigate,
          costWithdrawInvestigate: costWithdrawInvestigate,
          datePaidInvestigate: datePaidInvestigate,
          executionId: executionId,
          executionCode: executionCode,
          executionDateStart: executionDateStart,
          executionDeadline: executionDeadline,
          executionCourtDate: executionCourtDate,
          executionCourtTime: executionCourtTime,
          executionCourtNumber: executionCourtNumber,
          executionWorkBy: executionWorkBy,
          executionWorkByName: executionWorkByName,
        };
        const newValue = executionCase
          ? { ...payload, ...payloadExecution }
          : payload;
        console.log("newValue", newValue);
        console.log("payload", payload);

        // axios
        //   .post(`${config.mainAPI}/documents`, {
        //     ...payload,
        //   })
        //   .then(function (response) {
        //     saveActivityLog(
        //       caseData == "edit"
        //         ? `Edit Document Type: Loan Code: ${genCode}`
        //         : `Create Document Type: Loan Code: ${genCode}`
        //     );
        //     // if(executionCase){
        //     //   axios
        //     //   .post(`${config.mainAPI}/updateExecutionData`, {
        //     //     ...payload,
        //     //   })
        //     //   .then(function (response) {
        //     //     saveActivityLog(
        //     //       caseData == "edit"
        //     //         ? `Edit Document Type: Execution Code: ${genCode}`
        //     //         : `Create Document Type: Execution Code: ${genCode}`
        //     //     );
        //     //     setOpenSuccess(true);
        //     //     setTextFail([]);

        //     //   })
        //     //   .catch(function (error) {
        //     //     console.log(error);
        //     //   });
        //     // }else{
        //     //   setOpenSuccess(true);
        //     //   setTextFail([]);
        //     // }
        //     console.log('executionCase',executionCase);
        //     setOpenSuccess(true);
        //     setTextFail([]);
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });

        // axios
        //   .all([
        //     axios.post(`${config.mainAPI}/documents`, {
        //       ...payload,
        //     }),
        //     axios.post(`${config.mainAPI}/updateExecutionData`, { ...payload }),
        //   ])
        //   .then(
        //     axios.spread((firstResponse, secondResponse) => {
        //       console.log(firstResponse.data, secondResponse.data);
        //     })
        //   )
        //   .catch((error) => console.log(error));

        // axios
        //   .post(`${config.mainAPI}/documents`, {
        //     ...payload,
        //   })
        //   .then(function (response) {
        //     saveActivityLog(
        //       caseData == "edit"
        //         ? `Edit Document Type: Loan Code: ${genCode}`
        //         : `Create Document Type: Loan Code: ${genCode}`
        //     );
        //     setOpenSuccess(true);
        //     setTextFail([]);
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });

        // comment this
        postData(
          `${config.mainAPI}/updateLoan`,
          { ...payload },
          `Create Document Type: Loan Code: ${genCode}`
        );

        // Second API request based on executionCase
        if (executionCase === 1) {
          postData(
            `${config.mainAPI}/updateExecutionData`,
            { ...newValue },
            `Create Document Type: Execution Code: ${genCode}`
          );
        }
      } else {
        if (!dateStart) {
          setTextFail((prevTextFail) => [...prevTextFail, "วันที่รับเรื่อง"]);
        }
        if (!nameCompany) {
          setTextFail((prevTextFail) => [...prevTextFail, "ชื่อบริษัท"]);
        }
        // if (!defendantAccountNumber) {
        //   setTextFail((prevTextFail) => [...prevTextFail, "เลขที่บัญชีหลัก"]);
        // }
        if (!chooseMemberCompany) {
          setTextFail((prevTextFail) => [...prevTextFail, "ผู้รับผิดชอบคดี"]);
        }
        if (!debtCenter) {
          setTextFail((prevTextFail) => [...prevTextFail, "ศูนย์หนี้"]);
        }
        console.log("textFail", textFail);
        setOpenFailure(true);
      }
    }
  };

  const handleBack = () => {
    navigate("/loan");
  };

  const handleSubmitFileUpload = async () => {
    if (fileUpload) {
      console.log("fileUpload", fileUpload);
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("type", typeDocsUpload);
      formData.append("type_docs", "loan");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileUpload.name} Success`);

        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
      }
    }
  };

  function handleClearFileUpload() {
    setFileUpload(null);
    setTypeDocsUpload("");
    setTopicDocsUpload("");
  }

  function handleClearFileUpload1() {
    setFileUpload1(null);
    setTypeDocsUpload1("");
    setTopicDocsUpload1("");
  }

  function getListFile() {
    axios
      .get(`${config.mainAPI}/file-list/${params}`)
      .then((response) => {
        console.log("value filelist", response.data.data);
        const value = response.data.data;
        if (value) {
          setFileList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.file_uuid,
                item.file_topic,
                item.file_type,
                item.created_at,
                item.created_by,
                item.file_path,
                item.file_main,
                item.update_at
              )
            )
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getListFileExecution(executionId) {
    axios
      .get(`${config.mainAPI}/file-list/${executionId}`)
      .then((response) => {
        console.log("value filelistLED", response.data.data);
        const value = response.data.data;
        if (value) {
          setFileList1(value);
          setRows1(
            value.map((item, index) =>
              createData(
                index + 1,
                item.file_uuid,
                item.file_topic,
                item.file_type,
                item.created_at,
                item.created_by,
                item.file_path,
                item.file_main,
                item.update_at
              )
            )
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleDeleteFile = (row) => {
    const payload = {
      main: row.main,
      fileName: row.path,
      uuid: row.uuid,
      id: params,
      type: "loan",
    };
    console.log(payload);
    setIsLoading(true);
    const fileName = path.basename(row.path);
    saveActivityLog(`Click Delete file: ${fileName}`);
    axios
      .post(`${config.mainAPI}/deleteFileByPath`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;

        getListFile();
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  // ทรัพย์จำนอง
  const onChangeMortgageProperty = (value) => {
    setMortgageProperty(value);
    if (value == "0") {
      setMortgagePropertyName("");
      setMortgagePropertyDeed("");
      setMortgagePropertyNumber("");
      setMortgagePropertyExplore("");
      setMortgagePropertyTambon("");
      setMortgagePropertyAmphoe("");
      setMortgagePropertyProvince("");
      setMortgagePropertyDay("");
      setMortgagePropertyType("")
      setMortgagePropertyRoomNumber("")
      setMortgagePropertyTowerNumber("")
      setMortgagePropertyTowerName("")
      return;
    }

    // Check
    let list = [{ name: "ผู้กู้", value: "ผู้กู้" }];

    // ผู้กู้ร่วม
    // countCoBorrower
    if (countCoBorrower > 0) {
      for (let i = 0; i < countCoBorrower; i++) {
        list.push({
          name: `ผู้กู้ร่วมที่${i + 1}`,
          value: `ผู้กู้ร่วมที่${i + 1}`,
        });
      }
    }
    // ผู้คำประกัน
    // countDefendant
    if (countDefendant > 0) {
      for (let i = 0; i < countDefendant; i++) {
        list.push({
          name: `ผู้คำประกันที่${i + 1}`,
          value: `ผู้คำประกันที่${i + 1}`,
        });
      }
    }
    setMortgagePropertyList(list);
  };

  const handleDeleteFileExecution = (row) => {
    const payload = {
      main: row.main,
      fileName: row.path,
      uuid: row.uuid,
      id: executionId,
      type: "execution",
    };
    console.log(payload);
    setIsLoading(true);
    const fileName = path.basename(row.path);
    saveActivityLog(`Click Delete file: ${fileName}`);
    axios
      .post(`${config.mainAPI}/deleteFileByPath`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;

        getListFile();
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSetOpenWarningDelete = () => {
    setOpenWarningDelete(true);
  };

  const handleCloseSetOpenWarningDelete = () => {
    setOpenWarningDelete(false);
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleDownload = (row) => {
    const file_path = row.path;
    const fileName = path.basename(file_path);
    saveActivityLog(`Click download file: ${fileName}`);
    const download_url = `${config.mainAPI}/download-file?path=${file_path}`;
    window.location.href = download_url;
  };

  // const handleProvinceChange = (event) => {
  //   if (event) {
  //     const value = parseInt(event.target.dataset.value);
  //     const defendantProvinceName = event.currentTarget.getAttribute("name");
  //     setDefendantProvince(value);
  //     setDefendantProvinceName(defendantProvinceName);
  //     setDefendantAmphoe("");
  //     setDefendantTambon("");
  //   }
  // };

  const handleAutoComplateChange = (event, newValue, setData) => {
    if (newValue) {
      setData(newValue.label);
    }
  };

  const handleAutoComplateMultipleChange = (
    event,
    newValue,
    setData,
    index
  ) => {
    if (newValue) {
      // setData(newValue.label);
      setData((prevStatusDefendant) => {
        const value = [...prevStatusDefendant];
        value[index] = newValue.label;
        return value;
      });
    }
  };

  const handleProvinceChange = (event, newValue) => {
    if (newValue) {
      setDefendantProvince(newValue.id);
      setDefendantProvinceName(newValue.label);
      setDefendantAmphoe("");
      setDefendantTambon("");
      setDefendantZipcode("");
    }
  };

  const handleAmphoeChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantAmphoeName = event.currentTarget.getAttribute("name");
      setDefendantAmphoe(value);
      setDefendantAmphoeName(defendantAmphoeName);
      setDefendantTambon("");
    }
  };

  const handleTambonChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantTambonName = event.currentTarget.getAttribute("name");
      const defendantTambonZipcode = event.currentTarget.getAttribute("code");
      setDefendantZipcode(defendantTambonZipcode);
      setDefendantTambon(value);
      setDefendantTambonName(defendantTambonName);
    }
  };

  const handleProvinceContractChange = (event, newValue) => {
    if (newValue) {
      setDefendantContractProvince(newValue.id);
      setDefendantContractProvinceName(newValue.label);
      setDefendantContractAmphoe("");
      setDefendantContractTambon("");
      setDefendantContractZipcode("");
    }
  };

  const handleAmphoeContractChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantAmphoeName = event.currentTarget.getAttribute("name");
      setDefendantContractAmphoe(value);
      setDefendantContractAmphoeName(defendantAmphoeName);
      setDefendantContractTambon("");
    }
  };

  const handleTambonContractChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const defendantTambonName = event.currentTarget.getAttribute("name");
      const defendantTambonZipcode = event.currentTarget.getAttribute("code");
      setDefendantContractZipcode(defendantTambonZipcode);
      setDefendantContractTambon(value);
      setDefendantContractTambonName(defendantTambonName);
    }
  };

  const getAmphoesByProvinceId = (provinceId) => {
    console.log("provinceId", provinceId);
    if (provinceId) {
      return provincesData.find((province) => province.id === provinceId)
        .amphure;
    }
  };

  const getTambonsByAmphoeId = (amphoeId) => {
    if (amphoeId) {
      return provincesData
        .flatMap((province) => province.amphure)
        .find((amphoe) => amphoe.id === amphoeId).tambon;
    }
  };

  function getUserCompany() {
    axios
      .get(`${config.mainAPI}/users-company`)
      .then((response) => {
        setUserCompany(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleHistoryWithDraw = () => {
    const id = params;
    navigate("/draw", { state: { params: id } });
  };

  const handleHistoryGetMoney = () => {
    const id = params;
    navigate("/money", { state: { params: id } });
  };

  const handleSetNotification = () => {
    setOpenSetNotifition(true);
  };
  const handleCloseSetNotification = () => {
    setOpenSetNotifition(false);
  };

  const handleWorkByChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setChooseMemberCompany(workById);
    setChooseMemberCompanyName(workByName);
  };

  const handleWorkByLedChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setExecutionWorkBy(workById);
    setExecutionWorkByName(workByName);
  };

  function updateStatusFinish() {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/document-success`, {
        genCode: genCode,
        type: "loan",
      })
      .then(function (response) {
        setIsLoading(false);
        setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setOpenFailure(true);
      });
  }

  const handleSetOpenCloseCase = () => {
    setOpenCloseCase(true);
  };

  const handleCloseSetOpenCloseCase = () => {
    setOpenCloseCase(false);
  };

  const handleUploadFile = (row) => {
    console.log("row", row);
    setListFileUpdateSelected(row);
    setTypeSelected(row.type);
    setOpenUploadFile(true);
  };

  const handleUploadFileExecution = (row) => {
    console.log("row", row);
    setListFileUpdateSelected(row);
    setTypeSelected(row.type);
    setOpenUploadFileExecution(true);
  };

  const handleCloseOpenUploadFile = () => {
    setTypeSelected("");
    setFileUpload("");
    setListFileUpdateSelected(null);
    setOpenUploadFile(false);
  };

  const handleCloseOpenUploadFileExecution = () => {
    setTypeSelected("");
    setFileUpload("");
    setListFileUpdateSelected(null);
    setOpenUploadFile(false);
  };

  const handleClickUploadFileExecution = async () => {
    console.log("ListFileUpdateSelected", listFileUpdateSelected);
    if (fileUpload) {
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("order", listFileUpdateSelected.order);
      formData.append("type", listFileUpdateSelected.type);
      formData.append("type_docs", "execution");
      formData.append("user", user.username);
      formData.append("id", executionId);
      try {
        const res = await axios.post(
          `${config.mainAPI}/updateUploadFileDocs`,
          formData
        );
        saveActivityLog(`Update File:${fileUpload.name}  Success`);
        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
        setListFileUpdateSelected(null);
        setOpenUploadFile(false);
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
        setListFileUpdateSelected(null);
        setOpenUploadFile(false);
      }
    }
  };

  const handleClickUploadFile = async () => {
    console.log("ListFileUpdateSelected", listFileUpdateSelected);
    if (fileUpload) {
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("order", listFileUpdateSelected.order);
      formData.append("type", listFileUpdateSelected.type);
      formData.append("type_docs", "loan");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/updateUploadFileDocs`,
          formData
        );
        saveActivityLog(`Update File:${fileUpload.name}  Success`);
        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
        setListFileUpdateSelected(null);
        setOpenUploadFile(false);
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
        setListFileUpdateSelected(null);
        setOpenUploadFile(false);
      }
    }
  };

  const clearDate = () => {
    setStartDate(null);
  };

  const updateCountCourtEtc = (event) => {
    setCountCourtEtc(event);
    console.log("CountCourtEtc", event);

    // const handleWorkByChange = (event) => {
    //   const value = parseInt(event.target.dataset.value);
    //   const workByName = event.currentTarget.getAttribute("name");
    //   const workById = event.currentTarget.getAttribute("id");
    //   setChooseMemberCompany(workById);
    //   setChooseMemberCompanyName(workByName);
    // };

    // Splice data from arrays if their lengths are greater than countCourtEtc
    // อนาคตต้องมีการดักว่าต้องกรอกนะ
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const onChangeValueWithNumberAndSetLimit = (setData, limit, e) => {
    if (e.target.value.toString().length <= limit) {
      setData(e.target.value);
    }
  };

  const handleProvinceChangeOptional = (index, event, newValue) => {
    if (newValue) {
      const newProvinceArray = [...addressOptionalProvince];
      newProvinceArray[index] = newValue.id;
      setAddressOptionalProvince(newProvinceArray);

      const newProvinceNameArray = [...addressOptionalProvinceName];
      newProvinceNameArray[index] = newValue.label;
      setAddressOptionalProvinceName(newProvinceNameArray);

      const newAmphoeArray = [...addressOptionalAmphoe];
      newAmphoeArray[index] = "";
      setAddressOptionalAmphoe(newAmphoeArray);

      const newAmphoeNameArray = [...addressOptionalAmphoeName];
      newAmphoeNameArray[index] = "";
      setAddressOptionalAmphoeName(newAmphoeNameArray);

      const newTambonArray = [...addressOptionalTambon];
      newTambonArray[index] = "";
      setAddressOptionalTambon(newTambonArray);

      const newTambonNameArray = [...addressOptionalTambonName];
      newTambonNameArray[index] = "";
      setAddressOptionalTambonName(newTambonNameArray);

      const newZipcodeArray = [...addressOptionalZipcode];
      newZipcodeArray[index] = "";
      setAddressOptionalZipcode(newZipcodeArray);
    }
  };

  const handleAmphoeChangeOptional = (index, event, newValue) => {
    console.log("event", event);
    console.log("newValue", newValue);
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const newAmphoeArray = [...addressOptionalAmphoe];
      newAmphoeArray[index] = value;
      setAddressOptionalAmphoe(newAmphoeArray);

      const newAmphoeNameArray = [...addressOptionalAmphoeName];
      newAmphoeNameArray[index] = newValue.name_th;
      setAddressOptionalAmphoeName(newAmphoeNameArray);

      const newTambonArray = [...addressOptionalTambon];
      newTambonArray[index] = "";
      setAddressOptionalTambon(newTambonArray);
    }
  };

  const handleTambonChangeOptional = (index, event, newValue) => {
    console.log("event", event);
    console.log("newValue", newValue);
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const newTambonArray = [...addressOptionalTambon];
      newTambonArray[index] = value;
      setAddressOptionalTambon(newTambonArray);

      const newTambonNameArray = [...addressOptionalTambonName];
      newTambonNameArray[index] = newValue.name_th;
      setAddressOptionalTambonName(newTambonNameArray);

      const newZipcodeArray = [...addressOptionalZipcode];
      newZipcodeArray[index] = event.currentTarget.getAttribute("code");
      setAddressOptionalZipcode(newZipcodeArray);
    }
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
        >
          แบบฟอร์มสินเชื่อ
        </Typography>
        <Card>
          {params ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 3,
                marginTop: 3,
                float: "right",
              }}
            >
              {/* <Button
                            variant="outlined"
                            sx={{ marginRight: '20px', marginTop: '10px', marginBottom: '20px' }}
                            color="primary"
                            onClick={handleSetNotification}
                            startIcon={<NotificationsActiveIcon />}
                        >
                            ตั้งค่าการแจ้งเตือน
                        </Button> */}
              {/* <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="dark"
                onClick={handleSetNotification}
                startIcon={<NotificationsActiveIcon />}
              >
                การแจ้งเตือน
              </Button> */}
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="dark"
                onClick={handleHistoryWithDraw}
                startIcon={<RequestQuoteIcon />}
              >
                ประวัติการเบิกเงิน
              </Button>
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="dark"
                onClick={handleHistoryGetMoney}
                startIcon={<PaidIcon />}
              >
                ประวัติการได้รับค่าจ้าง
              </Button>
              {/* <Button
                variant="contained"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="success"
                onClick={handleSetOpenCloseCase}
                startIcon={<DoneAllIcon />}
              >
                ปิดคดี
              </Button> */}
            </Box>
          ) : null}
        </Card>

        <Box
          sx={{
            padding: "30px",
            "@media (max-width: 950px)": {
              display: "none", // Show on mobile
            },
            "@media (min-width: 951px)": {
              display: "block", // Hide on larger screens
            },
          }}
        >
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box
          sx={{
            padding: "30px",
            "@media (max-width: 950px)": {
              display: "block", // Show on mobile
            },
            "@media (min-width: 951px)": {
              display: "none", // Hide on larger screens
            },
          }}
        >
          <Typography sx={{ fontSize: "16px" }}>รายการ:</Typography>
          <Select
            value={activeStep}
            onChange={(e) => setActiveStep(e.target.value)}
            variant="outlined"
            color="dark"
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            {steps.map((label, index) => (
              <MenuItem value={index}>{label}</MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ padding: "30px" }}>
          {activeStep == 0 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลลูกหนี้"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ประเภท<span style={{ color: "red" }}> *</span>:
                      </Typography>
                      <Select
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        variant="outlined"
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <MenuItem value="บุคคล">บุคคล</MenuItem>
                        <MenuItem value="นิติบุคคล">นิติบุคคล</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  {type == "บุคคล" ? (
                    <>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            คำนำหน้า<span style={{ color: "red" }}> *</span>
                          </Typography>
                          <Select
                            value={prefix}
                            onChange={(e) => setPrefix(e.target.value)}
                            variant="outlined"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {prefixList &&
                              prefixList.map((column) => (
                                <MenuItem
                                  key={column.prefix_id}
                                  value={column.prefix_name_th}
                                >
                                  {column.prefix_name_th}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ชื่อจำเลย<span style={{ color: "red" }}> *</span>
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={defendantFirstName}
                            onChange={(e) =>
                              setDefendantFirstName(e.target.value)
                            }
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            นามสกุลจำเลย<span style={{ color: "red" }}> *</span>
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={defendantLastName}
                            onChange={(e) =>
                              setDefendantLastName(e.target.value)
                            }
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เลขที่บัตรประชาชน
                            <span style={{ color: "red" }}> *</span>
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            value={defendantIdCard}
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            // onChange={(e) => setDefendantIdCard(e.target.value)}
                            onChange={(e) =>
                              onChangeValueWithNumberAndSetLimit(
                                setDefendantIdCard,
                                13,
                                e
                              )
                            }
                            required
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : type == "นิติบุคคล" ? (
                    <>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ชื่อบริษัท<span style={{ color: "red" }}> *</span>
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={nameCompany}
                            onChange={(e) => setNameCompany(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  {type ? (
                    <>
                      {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            จำนวนบัญชี
                            <span style={{ color: "red" }}> *</span>
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 1 } }}
                            value={defendantNumberOfAccounts}
                            onChange={(event) =>
                              setDefendantNumberOfAccounts(event.target.value)
                            }
                            // onChange={(e) => onChangeValueWithNumberAndSetLimit(setDefendantAccountNumber, 12, e)}
                            required
                          />
                        </Grid>
                      </Grid> */}

                      {/* {defendantNumberOfAccounts > 0 ? (
                        <>
                          {[...Array(parseInt(defendantNumberOfAccounts))].map(
                            (_, i) => (
                              <React.Fragment key={i}>
                                <div class="separator">บัญชีที่ {i + 1}</div>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      เลขที่บัญชี:
                                    </Typography>
                                    <TextField
                                      id={`accountNumber${i}`}
                                      name={`accountNumber${i}`}
                                      value={accountNumber[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setAccountNumber,
                                          e,
                                          i
                                        )
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            )
                          )}
                        </>
                      ) : null} */}

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เลขที่บัญชี
                            <span style={{ color: "red" }}> *</span>
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={defendantAccountNumber}
                            onChange={(event) =>
                              setDefendantAccountNumber(event.target.value)
                            }
                            // onChange={(e) => onChangeValueWithNumberAndSetLimit(setDefendantAccountNumber, 12, e)}
                            required
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เลขที่บัญชีสำรอง
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={defendantAccountNumber1}
                            // onChange={(event) =>
                            //   setDefendantAccountNumber1(event.target.value)
                            // }
                            onChange={(e) => onChangeValueWithNumberAndSetLimit(setDefendantAccountNumber1, 12, e)}
                            required
                          />
                        </Grid> */}
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            บ้านเลขที่<span style={{ color: "red" }}> *</span>:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 13 }}
                            value={defendantHouseNumber}
                            onChange={(e) =>
                              setDefendantHouseNumber(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            หมู่:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 12 }}
                            value={defendantGroup}
                            onChange={(event) =>
                              setDefendantGroup(event.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ถนน:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 13 }}
                            value={defendantRoad}
                            onChange={(e) => setDefendantRoad(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ซอย:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 12 }}
                            value={defendantSoi}
                            onChange={(event) =>
                              setDefendantSoi(event.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                      {/* บ้านเลขที่ หมู่ ถนน ซอย ใกล้เคียง โทรศัพท์ โทรสาร */}
                      <Grid container sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ใกล้เคียง:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={defendantAddress}
                            onChange={(event) =>
                              setDefendantAddress(event.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            จังหวัด<span style={{ color: "red" }}> *</span>:
                          </Typography>
                          <Autocomplete
                            freeSolo
                            disableClearable
                            id="combo-box-demo"
                            options={provincesOnly}
                            value={defendantProvinceName || ""}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={handleProvinceChange}
                          />
                        </Grid>

                        {defendantProvince ? (
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              อำเภอ<span style={{ color: "red" }}> *</span>:
                            </Typography>
                            <Select
                              value={defendantAmphoe || ""}
                              fullWidth
                              color="dark"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <MenuItem value="">โปรดระบุอำเภอ</MenuItem>
                              {getAmphoesByProvinceId(defendantProvince).map(
                                (amphoe) => (
                                  <MenuItem
                                    key={amphoe.id}
                                    value={amphoe.id}
                                    name={amphoe.name_th}
                                    onClick={(event) =>
                                      handleAmphoeChange(event)
                                    }
                                  >
                                    {amphoe.name_th}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </Grid>
                        ) : null}

                        {defendantAmphoe ? (
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ตำบล<span style={{ color: "red" }}> *</span>:
                            </Typography>
                            <Select
                              value={defendantTambon || ""}
                              fullWidth
                              color="dark"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <MenuItem value="">โปรดระบุตำบล</MenuItem>
                              {getTambonsByAmphoeId(defendantAmphoe).map(
                                (tambon) => (
                                  <MenuItem
                                    key={tambon.id}
                                    value={tambon.id}
                                    name={tambon.name_th}
                                    code={tambon.zip_code}
                                    onClick={(event) =>
                                      handleTambonChange(event)
                                    }
                                  >
                                    {tambon.name_th}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รหัสไปรษณี<span style={{ color: "red" }}> *</span>:
                          </Typography>
                          <TextField
                            id="comment"
                            name="comment"
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            disabled
                            value={defendantZipcode || ""}
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            โทรสาร:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 13 }}
                            value={phone1}
                            onChange={(e) => setPhone1(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            โทรศัพท์:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            inputProps={{ maxLength: 10 }}
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ที่อยู่ตามสัญญากู้:
                          </Typography>
                          <RadioGroup
                            row
                            value={addressLoan}
                            onChange={(e) => setAddressLoan(event.target.value)}
                          >
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="ไม่มี"
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="มี"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>

                      {addressLoan == 1 ? (
                        <>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                บ้านเลขที่:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                inputProps={{ maxLength: 13 }}
                                value={defendantContractHouseNumber}
                                onChange={(e) =>
                                  setDefendantContractHouseNumber(
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                หมู่:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                inputProps={{ maxLength: 12 }}
                                value={defendantContractGroup}
                                onChange={(event) =>
                                  setDefendantContractGroup(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ถนน:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                inputProps={{ maxLength: 13 }}
                                value={defendantContractRoad}
                                onChange={(e) =>
                                  setDefendantContractRoad(e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ซอย:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                inputProps={{ maxLength: 12 }}
                                value={defendantContractSoi}
                                onChange={(event) =>
                                  setDefendantContractSoi(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          {/* บ้านเลขที่ หมู่ ถนน ซอย ใกล้เคียง โทรศัพท์ โทรสาร */}
                          <Grid container sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ใกล้เคียง:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={defendantContractAddress}
                                onChange={(event) =>
                                  setDefendantContractAddress(
                                    event.target.value
                                  )
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                จังหวัด:
                              </Typography>
                              <Autocomplete
                                freeSolo
                                disableClearable
                                id="combo-box-demo"
                                options={provincesOnly}
                                value={defendantContractProvinceName || ""}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={handleProvinceContractChange}
                              />
                            </Grid>

                            {defendantContractProvince ? (
                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  อำเภอ:
                                </Typography>
                                <Select
                                  value={defendantContractAmphoe || ""}
                                  fullWidth
                                  color="dark"
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  <MenuItem value="">โปรดระบุอำเภอ</MenuItem>
                                  {getAmphoesByProvinceId(
                                    defendantContractProvince
                                  ).map((amphoe) => (
                                    <MenuItem
                                      key={amphoe.id}
                                      value={amphoe.id}
                                      name={amphoe.name_th}
                                      onClick={(event) =>
                                        handleAmphoeContractChange(event)
                                      }
                                    >
                                      {amphoe.name_th}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            ) : null}

                            {defendantContractAmphoe ? (
                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  ตำบล:
                                </Typography>
                                <Select
                                  value={defendantContractTambon || ""}
                                  fullWidth
                                  color="dark"
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  <MenuItem value="">โปรดระบุตำบล</MenuItem>
                                  {getTambonsByAmphoeId(
                                    defendantContractAmphoe
                                  ).map((tambon) => (
                                    <MenuItem
                                      key={tambon.id}
                                      value={tambon.id}
                                      name={tambon.name_th}
                                      code={tambon.zip_code}
                                      onClick={(event) =>
                                        handleTambonContractChange(event)
                                      }
                                    >
                                      {tambon.name_th}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            ) : null}
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                รหัสไปรษณี:
                              </Typography>
                              <TextField
                                id="comment"
                                name="comment"
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                disabled
                                value={defendantContractZipcode || ""}
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </Grid>
                          </Grid>

                          {/* ที่อยู๋เพิ่มเติม */}
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ที่อยู่เพิ่มเติม:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={alternativeAddress}
                                onChange={(event) =>
                                  setAlternativeAddress(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : null}

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            จำนวนที่อยู่สำรอง:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            variant="outlined"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={countAddressOptional}
                            onChange={(event) =>
                              setCountAddressOptional(event.target.value)
                            }
                            InputProps={{ inputProps: { min: 0, max: 5 } }}
                          />
                        </Grid>
                      </Grid>
                      {countAddressOptional > 0 ? (
                        <>
                          {[...Array(parseInt(countAddressOptional))].map(
                            (_, i) => (
                              <React.Fragment key={i}>
                                <div class="separator">
                                  ที่อยู่สำรองที่ {i + 1}
                                </div>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      บ้านเลขที่:
                                    </Typography>
                                    <TextField
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                      id={`addressOptionalHouseNumber${i}`}
                                      name={`addressOptionalHouseNumber${i}`}
                                      value={
                                        addressOptionalHouseNumber[i] || ""
                                      }
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setAddressOptionalHouseNumber,
                                          e,
                                          i
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      หมู่:
                                    </Typography>
                                    <TextField
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                      id={`addressOptionalGroup${i}`}
                                      name={`addressOptionalGroup${i}`}
                                      value={addressOptionalGroup[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setAddressOptionalGroup,
                                          e,
                                          i
                                        )
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ถนน:
                                    </Typography>
                                    <TextField
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                      id={`addressOptionalRoad${i}`}
                                      name={`addressOptionalRoad${i}`}
                                      value={addressOptionalRoad[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setAddressOptionalRoad,
                                          e,
                                          i
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ซอย:
                                    </Typography>
                                    <TextField
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                      id={`addressOptionalSoi${i}`}
                                      name={`addressOptionalSoi${i}`}
                                      value={addressOptionalSoi[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setAddressOptionalSoi,
                                          e,
                                          i
                                        )
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                  <Grid item xs={12}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ใกล้เคียง:
                                    </Typography>
                                    <TextField
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                      id={`addressOptionalAddress${i}`}
                                      name={`addressOptionalAddress${i}`}
                                      value={addressOptionalAddress[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setAddressOptionalAddress,
                                          e,
                                          i
                                        )
                                      }
                                    />
                                  </Grid>
                                </Grid>

                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      จังหวัด:
                                    </Typography>
                                    <Autocomplete
                                      freeSolo
                                      disableClearable
                                      id="combo-box-demo"
                                      options={provincesOnly}
                                      value={
                                        addressOptionalProvinceName[i] || ""
                                      }
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      onChange={(event, newValue) =>
                                        handleProvinceChangeOptional(
                                          i,
                                          event,
                                          newValue
                                        )
                                      }
                                    />
                                  </Grid>
                                  {addressOptionalProvince[i] ? (
                                    <Grid item xs={12} sm={6}>
                                      <Typography sx={{ fontSize: "16px" }}>
                                        อำเภอ
                                        <span style={{ color: "red" }}> *</span>
                                        :
                                      </Typography>
                                      <Select
                                        value={addressOptionalAmphoe[i] || ""}
                                        fullWidth
                                        color="dark"
                                        sx={{
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                          backgroundColor: "#ffffff",
                                        }}
                                      >
                                        <MenuItem value="">
                                          โปรดระบุอำเภอ
                                        </MenuItem>
                                        {getAmphoesByProvinceId(
                                          addressOptionalProvince[i]
                                        ).map((amphoe) => (
                                          <MenuItem
                                            key={amphoe.id}
                                            value={amphoe.id}
                                            name={amphoe.name_th}
                                            onClick={(event) =>
                                              handleAmphoeChangeOptional(
                                                i,
                                                event,
                                                amphoe
                                              )
                                            }
                                          >
                                            {amphoe.name_th}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Grid>
                                  ) : null}
                                </Grid>
                                {addressOptionalAmphoe[i] ? (
                                  <>
                                    <Grid container spacing={2} sx={{ mb: 2 }}>
                                      <Grid item xs={12} sm={6}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          ตำบล
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                          :
                                        </Typography>
                                        <Select
                                          value={addressOptionalTambon[i] || ""}
                                          fullWidth
                                          color="dark"
                                          sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <MenuItem value="">
                                            โปรดระบุตำบล
                                          </MenuItem>
                                          {getTambonsByAmphoeId(
                                            addressOptionalAmphoe[i]
                                          ).map((tambon) => (
                                            <MenuItem
                                              key={tambon.id}
                                              value={tambon.id}
                                              name={tambon.name_th}
                                              code={tambon.zip_code}
                                              onClick={(event) =>
                                                handleTambonChangeOptional(
                                                  i,
                                                  event,
                                                  tambon
                                                )
                                              }
                                            >
                                              {tambon.name_th}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Grid>
                                      {addressOptionalTambon[i] ? (
                                        <Grid item xs={12} sm={6}>
                                          <Typography sx={{ fontSize: "16px" }}>
                                            รหัสไปรษณี
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                            :
                                          </Typography>
                                          <TextField
                                            id="comment"
                                            name="comment"
                                            type="text"
                                            color="dark"
                                            fullWidth
                                            variant="outlined"
                                            disabled
                                            value={
                                              addressOptionalZipcode[i] || ""
                                            }
                                            sx={{
                                              marginTop: "10px",
                                              marginBottom: "10px",
                                              backgroundColor: "#ffffff",
                                            }}
                                          />
                                        </Grid>
                                      ) : null}
                                    </Grid>
                                  </>
                                ) : null}
                              </React.Fragment>
                            )
                          )}
                        </>
                      ) : null}

                      {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                        <RadioGroup
                          row
                          // value={selectedValue}
                          // onChange={handleChange}
                        >
                          <Grid item xs={12} >
                          <Typography sx={{ fontSize: "16px" }}>
                            โทรศัพท์:
                          </Typography>
                            <FormControlLabel
                              value="radioA"
                              control={<Radio />}
                              label="ที่อยู่ตามสัญญากู้"
                            />
                             <FormControlLabel
                              value="radioB"
                              control={<Radio />}
                              label="Radio B"
                            />
                          </Grid>
                        </RadioGroup>
                      </Grid> */}

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            สถานะ:
                          </Typography>
                          <Select
                            value={
                              statusDefendantNow
                                ? statusDefendantNow
                                : "มีชีวิตอยู่"
                            }
                            onChange={(e) =>
                              setStatusDefendantNow(e.target.value)
                            }
                            variant="outlined"
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              width: "100%",
                            }}
                          >
                            <MenuItem value="มีชีวิตอยู่">มีชีวิตอยู่</MenuItem>
                            <MenuItem value="เสียชีวิต">เสียชีวิต</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>

                      {statusDefendantNow &&
                      statusDefendantNow == "เสียชีวิต" ? (
                        <>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                บทบาทผู้แทนจำเลย:
                              </Typography>
                              {/* <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={reserveRole}
                                onChange={(e) => setReserveRole(e.target.value)}
                              /> */}
                              <Autocomplete
                                freeSolo
                                disableClearable
                                id="combo-box-demo"
                                options={listRoleStatus}
                                value={reserveRole || ""}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                onChange={(e) =>
                                  handleAutoComplateChange(setReserveRole)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                คำนำหน้าผู้แทนจำเลย:
                              </Typography>
                              <Select
                                value={reserveFirstname}
                                onChange={(e) =>
                                  setReserveFirstname(e.target.value)
                                }
                                variant="outlined"
                                fullWidth
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              >
                                {prefixList &&
                                  prefixList.map((column) => (
                                    <MenuItem
                                      key={column.prefix_id}
                                      value={column.prefix_name_th}
                                    >
                                      {column.prefix_name_th}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ชื่อผู้แทนจำเลย:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={reserveFirstname}
                                onChange={(event) =>
                                  setReserveFirstname(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                นามสกุลผู้แทนจำเลย:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={reserveLastname}
                                onChange={(event) =>
                                  setReserveLastname(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                บัตรประชาชนผู้แทนจำเลย:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={reserveIdcard}
                                onChange={(event) =>
                                  setReserveIdcard(event.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เบอร์ติดต่อผู้แทนจำเลย:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={reserveTel}
                                onChange={(event) =>
                                  setReserveTel(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ที่อยู่ผู้แทนจำเลย:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                value={reserveAddress}
                                onChange={(event) =>
                                  setReserveAddress(event.target.value)
                                }
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </CardContent>
              </Card>

              {type == "บุคคล" ? (
                <Card sx={{ mb: 3 }}>
                  <CardHeader
                    sx={{ backgroundColor: "#181414" }}
                    title="ข้อมูลผู้กู้ร่วม"
                  />
                  <CardContent sx={{ border: "0.5px solid" }}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: "16px" }}>
                          จำนวนผู้กู้ร่วม:
                        </Typography>
                        <TextField
                          id="countCoBorrower"
                          name="countCoBorrower"
                          type="number"
                          color="dark"
                          placeholder="กรุณาระบุจำนวน"
                          fullWidth
                          variant="outlined"
                          InputProps={{ inputProps: { min: 0 } }}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                          value={countCoBorrower}
                          onChange={(event) =>
                            setCountCoBorrower(event.target.value)
                          }
                        />
                      </Grid>

                      {countCoBorrower > 0 && (
                        <>
                          {[...Array(parseInt(countCoBorrower))].map((_, i) => (
                            <React.Fragment key={i}>
                              {/* <hr style={{ marginBottom: "3rem", marginTop: "3rem" }} /> */}
                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  สถานะผู้กู้ร่วมที่ {i + 1}:
                                </Typography>
                                <Select
                                  id={`coBorrowerStatus${i}`}
                                  name={`coBorrowerStatus${i}`}
                                  value={coBorrowerStatus[i] || ""}
                                  onChange={(event) =>
                                    onChangeMultipleValue(
                                      setCoBorrowerStatus,
                                      event,
                                      i
                                    )
                                  }
                                  variant="outlined"
                                  color="dark"
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <MenuItem value="">
                                    โปรดเลือกสถานะของผู้กู้ร่วมที่ {i + 1}
                                  </MenuItem>
                                  {listCoDefendantStatus &&
                                    listCoDefendantStatus.map((column) => (
                                      <MenuItem
                                        key={column.id}
                                        value={column.value}
                                      >
                                        {column.value}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  คำนำหน้าผู้กู้ร่วมที่ {i + 1}:
                                </Typography>
                                <Select
                                  id={`coBorrowerPrefix${i}`}
                                  name={`coBorrowerPrefix${i}`}
                                  variant="outlined"
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    width: "100%",
                                  }}
                                  value={coBorrowerPrefix[i] || ""}
                                  color="dark"
                                  onChange={(e) =>
                                    onChangeMultipleValue(
                                      setCoBorrowerPrefix,
                                      e,
                                      i
                                    )
                                  }
                                >
                                  {prefixList &&
                                    prefixList.map((column) => (
                                      <MenuItem
                                        key={column.prefix_id}
                                        value={column.prefix_name_th}
                                      >
                                        {column.prefix_name_th}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  ชื่อผู้กู้ร่วมที่ {i + 1}:
                                </Typography>
                                <TextField
                                  id={`coBorrowerName${i}`}
                                  name={`coBorrowerName${i}`}
                                  value={coBorrowerName[i] || ""}
                                  onChange={(e) =>
                                    onChangeMultipleValue(
                                      setCoBorrowerName,
                                      e,
                                      i
                                    )
                                  }
                                  type="text"
                                  color="dark"
                                  fullWidth
                                  variant="outlined"
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    backgroundColor: "#ffffff",
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  นามสกุลผู้กู้ร่วมที่ {i + 1}:
                                </Typography>
                                <TextField
                                  id={`coBorrowerLastName${i}`}
                                  name={`coBorrowerLastName${i}`}
                                  value={coBorrowerLastName[i] || ""}
                                  onChange={(e) =>
                                    onChangeMultipleValue(
                                      setCoBorrowerLastName,
                                      e,
                                      i
                                    )
                                  }
                                  type="text"
                                  color="dark"
                                  fullWidth
                                  variant="outlined"
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    backgroundColor: "#ffffff",
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  ที่อยู่ผู้กู้ร่วมที่ {i + 1}:
                                </Typography>
                                <TextField
                                  id={`coBorrowerAddress${i}`}
                                  name={`coBorrowerAddress${i}`}
                                  value={coBorrowerAddress[i] || ""}
                                  onChange={(e) =>
                                    onChangeMultipleValue(
                                      setCoBorrowerAddress,
                                      e,
                                      i
                                    )
                                  }
                                  type="text"
                                  color="dark"
                                  fullWidth
                                  variant="outlined"
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    backgroundColor: "#ffffff",
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: "16px" }}>
                                  บัตรประชาชนผู้กู้ร่วมที่ {i + 1}:
                                </Typography>
                                <TextField
                                  id={`coBorrowerIdCard${i}`}
                                  name={`coBorrowerIdCard${i}`}
                                  value={coBorrowerIdCard[i] || ""}
                                  onChange={(e) =>
                                    onChangeMultipleValue(
                                      setCoBorrowerIdCard,
                                      e,
                                      i
                                    )
                                  }
                                  type="text"
                                  color="dark"
                                  fullWidth
                                  variant="outlined"
                                  sx={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    backgroundColor: "#ffffff",
                                  }}
                                  inputProps={{ maxLength: 13 }}
                                />
                              </Grid>

                              {coBorrowerStatus[i] == "เสียชีวิต" ? (
                                <>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      บทบาทตัวแทนผู้กู้ร่วมที่ {i + 1}:
                                    </Typography>

                                    <Autocomplete
                                      freeSolo
                                      disableClearable
                                      id="combo-box-demo"
                                      options={listRoleStatus}
                                      value={reserveCoBorrowerRole[i] || ""}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      onChange={(e) =>
                                        handleAutoComplateMultipleChange(
                                          setReserveRole,
                                          i
                                        )
                                      }
                                    />

                                    {/* <Select
                                      id={`reserveCoBorrowerRole${i}`}
                                      name={`reserveCoBorrowerRole${i}`}
                                      value={reserveCoBorrowerRole[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setReserveCoBorrowerRole,
                                          e,
                                          i
                                        )
                                      }
                                      variant="outlined"
                                      color="dark"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                      }}
                                    >
                                      {listStatusDead &&
                                        listStatusDead.map((column) => (
                                          <MenuItem
                                            key={column.id}
                                            value={column.value}
                                          >
                                            {column.value}
                                          </MenuItem>
                                        ))}
                                    </Select> */}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      คำนำหน้าตัวแทนผู้กู้ร่วมที่ {i + 1}:
                                    </Typography>
                                    <Select
                                      id={`reserveCoBorrowerPrefix${i}`}
                                      name={`reserveCoBorrowerPrefix${i}`}
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                      }}
                                      value={reserveCoBorrowerPrefix[i] || ""}
                                      color="dark"
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setReserveCoBorrowerPrefix,
                                          e,
                                          i
                                        )
                                      }
                                    >
                                      {listPrefix &&
                                        listPrefix.map((column) => (
                                          <MenuItem
                                            key={column.id}
                                            value={column.value}
                                          >
                                            {column.value}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ชื่อตัวแทนผู้กู้ร่วมที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`reserveCoBorrowerFirstname${i}`}
                                      name={`reserveCoBorrowerFirstname${i}`}
                                      value={
                                        reserveCoBorrowerFirstname[i] || ""
                                      }
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setReserveCoBorrowerFirstname,
                                          e,
                                          i
                                        )
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      นามสกุลตัวแทนผู้กู้ร่วมที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`reserveCoBorrowerLastname${i}`}
                                      name={`reserveCoBorrowerLastname${i}`}
                                      value={reserveCoBorrowerLastname[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setReserveCoBorrowerLastname,
                                          e,
                                          i
                                        )
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      ที่อยู่ตัวแทนผู้กู้ร่วมที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`reserveCoBorrowerAddress${i}`}
                                      name={`reserveCoBorrowerAddress${i}`}
                                      value={reserveCoBorrowerAddress[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setReserveCoBorrowerAddress,
                                          e,
                                          i
                                        )
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      บัตรประชาชนตัวแทนผู้กู้ร่วมที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`reserveCoBorrowerIdCard${i}`}
                                      name={`reserveCoBorrowerIdCard${i}`}
                                      value={reserveCoBorrowerIdCard[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setReserveCoBorrowerIdCard,
                                          e,
                                          i
                                        )
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                      inputProps={{ maxLength: 13 }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      เบอร์ติดต่อตัวแทนผู้กู้ร่วมที่ {i + 1}:
                                    </Typography>
                                    <TextField
                                      id={`reserveCoBorrowerTel${i}`}
                                      name={`reserveCoBorrowerTel${i}`}
                                      value={reserveCoBorrowerTel[i] || ""}
                                      onChange={(e) =>
                                        onChangeMultipleValue(
                                          setReserveCoBorrowerTel,
                                          e,
                                          i
                                        )
                                      }
                                      type="text"
                                      color="dark"
                                      fullWidth
                                      variant="outlined"
                                      sx={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        backgroundColor: "#ffffff",
                                      }}
                                      inputProps={{ maxLength: 13 }}
                                    />
                                  </Grid>
                                </>
                              ) : null}
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              ) : null}

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลผู้ค้ำประกัน"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนผู้ค้ำประกัน:
                      </Typography>
                      <TextField
                        id="countDefendant"
                        name="countDefendant"
                        type="number"
                        color="dark"
                        placeholder="กรุณาระบุจำนวน"
                        fullWidth
                        variant="outlined"
                        InputProps={{ inputProps: { min: 0 } }}
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={countDefendant}
                        onChange={(event) =>
                          setCountDefendant(event.target.value)
                        }
                      />
                    </Grid>

                    {countDefendant > 0 && (
                      <>
                        {[...Array(parseInt(countDefendant))].map((_, i) => (
                          <React.Fragment key={i}>
                            {/* <hr style={{ marginBottom: "3rem", marginTop: "3rem" }} /> */}
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                สถานะผู้ค้ำประกันที่ {i + 1}:
                              </Typography>
                              <Select
                                id={`coDefendantStatus${i}`}
                                name={`coDefendantStatus${i}`}
                                value={coDefendantStatus[i] || ""}
                                onChange={(event) =>
                                  handleCoDefendantStatusChange(event, i)
                                }
                                variant="outlined"
                                color="dark"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  width: "100%",
                                }}
                              >
                                <MenuItem value="">
                                  โปรดเลือกสถานะของผู้ค้ำประกันที่ {i + 1}
                                </MenuItem>
                                {listCoDefendantStatus &&
                                  listCoDefendantStatus.map((column) => (
                                    <MenuItem
                                      key={column.id}
                                      value={column.value}
                                    >
                                      {column.value}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                คำนำหน้าผู้ค้ำประกันที่ {i + 1}:
                              </Typography>
                              <Select
                                id={`coDefendantPrefix${i}`}
                                name={`coDefendantPrefix${i}`}
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  width: "100%",
                                }}
                                value={coDefendantPrefix[i] || ""}
                                color="dark"
                                onChange={(e) =>
                                  handleCoDefendantPrefixChange(e, i)
                                }
                              >
                                {listPrefix &&
                                  listPrefix.map((column) => (
                                    <MenuItem
                                      key={column.id}
                                      value={column.value}
                                    >
                                      {column.value}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ชื่อผู้ค้ำประกันที่ {i + 1}:
                              </Typography>
                              <TextField
                                id={`coDefendantName${i}`}
                                name={`coDefendantName${i}`}
                                value={coDefendantName[i] || ""}
                                onChange={(e) =>
                                  handleCoDefendantNameChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                นามสกุลผู้ค้ำประกันที่ {i + 1}:
                              </Typography>
                              <TextField
                                id={`coDefendantLastName${i}`}
                                name={`coDefendantLastName${i}`}
                                value={coDefendantLastName[i] || ""}
                                onChange={(e) =>
                                  handleCoDefendantLastNameChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ที่อยู่ผู้ค้ำประกันที่ {i + 1}:
                              </Typography>
                              <TextField
                                id={`coDefendantAddress${i}`}
                                name={`coDefendantAddress${i}`}
                                value={coDefendantAddress[i] || ""}
                                onChange={(e) =>
                                  handleCoDefendantAddressChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                บัตรประชาชนผู้ค้ำประกันที่ {i + 1}:
                              </Typography>
                              <TextField
                                id={`coDefendantIdCard${i}`}
                                name={`coDefendantIdCard${i}`}
                                value={coDefendantIdCard[i] || ""}
                                onChange={(e) =>
                                  handleCoDefendantIdCardChange(e, i)
                                }
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                inputProps={{ maxLength: 13 }}
                              />
                            </Grid>
                            {coDefendantStatus[i] == "เสียชีวิต" ? (
                              <>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "16px" }}>
                                    สถานะตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                  </Typography>
                                  <Select
                                    id={`statusDefendantAgent${i}`}
                                    name={`statusDefendantAgent${i}`}
                                    value={statusDefendantAgent[i] || ""}
                                    onChange={(event) =>
                                      handleStatusDefendantAgentChange(event, i)
                                    }
                                    variant="outlined"
                                    color="dark"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      width: "100%",
                                    }}
                                  >
                                    {listStatusDead &&
                                      listStatusDead.map((column) => (
                                        <MenuItem
                                          key={column.id}
                                          value={column.value}
                                        >
                                          {column.value}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "16px" }}>
                                    คำนำหน้าตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                  </Typography>
                                  <Select
                                    id={`prefixDefendantAgent${i}`}
                                    name={`prefixDefendantAgent${i}`}
                                    variant="outlined"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      width: "100%",
                                    }}
                                    value={prefixDefendantAgent[i] || ""}
                                    color="dark"
                                    onChange={(e) =>
                                      handlePrefixDefendantAgentChange(e, i)
                                    }
                                  >
                                    {listPrefix &&
                                      listPrefix.map((column) => (
                                        <MenuItem
                                          key={column.id}
                                          value={column.value}
                                        >
                                          {column.value}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "16px" }}>
                                    ชื่อตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                  </Typography>
                                  <TextField
                                    id={`nameDefendantAgent${i}`}
                                    name={`nameDefendantAgent${i}`}
                                    value={nameDefendantAgent[i] || ""}
                                    onChange={(e) =>
                                      handleNameDefendantAgentChange(e, i)
                                    }
                                    type="text"
                                    color="dark"
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      backgroundColor: "#ffffff",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "16px" }}>
                                    นามสกุลตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                  </Typography>
                                  <TextField
                                    id={`lastNameDefendantAgent${i}`}
                                    name={`lastNameDefendantAgent${i}`}
                                    value={lastNameDefendantAgent[i] || ""}
                                    onChange={(e) =>
                                      handleLastNameDefendantAgentChange(e, i)
                                    }
                                    type="text"
                                    color="dark"
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      backgroundColor: "#ffffff",
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "16px" }}>
                                    ที่อยู่ตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                  </Typography>
                                  <TextField
                                    id={`addressDefendantAgent${i}`}
                                    name={`addressDefendantAgent${i}`}
                                    value={addressDefendantAgent[i] || ""}
                                    onChange={(e) =>
                                      handleAddressDefendantAgentChange(e, i)
                                    }
                                    type="text"
                                    color="dark"
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      backgroundColor: "#ffffff",
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ fontSize: "16px" }}>
                                    บัตรประชาชนตัวแทนผู้ค้ำประกันที่ {i + 1}:
                                  </Typography>
                                  <TextField
                                    id={`idCardDefendantAgent${i}`}
                                    name={`idCardDefendantAgent${i}`}
                                    value={idCardDefendantAgent[i] || ""}
                                    onChange={(e) =>
                                      handleIdCardDefendantAgentChange(e, i)
                                    }
                                    type="text"
                                    color="dark"
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      backgroundColor: "#ffffff",
                                    }}
                                    inputProps={{ maxLength: 13 }}
                                  />
                                </Grid>
                              </>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ยอดหนี้ / ออกโนติส"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ยอดเงินต้น:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        InputProps={{ inputProps: { min: 0 } }}
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={noticePrincipal}
                        onChange={(event) =>
                          setNoticePrincipal(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ยอดดอกเบี้ย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        InputProps={{ inputProps: { min: 0 } }}
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={noticeInterest}
                        onChange={(event) =>
                          setNoticeInterest(event.target.value)
                        }
                      />
                    </Grid>
                  </Grid> */}
                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ลงสัญญากู้:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={noticeDateSign}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setNoticeDateSign,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันคำนวนออกโนติส:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={noticeCalDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setNoticeCalDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid> */}

                  <Grid spacing={2} container sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนสินเชื่อ<span style={{ color: "red" }}> *</span>:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        variant="outlined"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 1, max: 10 } }}
                        value={countLoan}
                        onChange={(e) => setCountLoan(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  {countLoan > 0 && (
                    <>
                      {[...Array(parseInt(countLoan))].map((_, i) => (
                        <React.Fragment key={i}>
                          <div class="separator">สินเชื่อลำดับที่ {i + 1}</div>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เลขสัญญา:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`gsbNumberList${i}`}
                                name={`gsbNumberList${i}`}
                                value={gsbNumberList[i] || ""}
                                onChange={(e) =>
                                  onChangeMultipleValue(setGsbNumberList, e, i)
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ประเภทสินเชื่อ:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`loanTypeList${i}`}
                                name={`loanTypeList${i}`}
                                value={loanTypeList[i] || ""}
                                onChange={(e) =>
                                  onChangeMultipleValue(setLoanTypeList, e, i)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ประเภทหลักประกัน:
                              </Typography>
                              <Select
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  width: "100%",
                                }}
                                color="dark"
                                id={`typeOfCollateralList${i}`}
                                name={`typeOfCollateralList${i}`}
                                value={typeOfCollateralList[i] || ""}
                                onChange={(e) =>
                                  onChangeMultipleValue(
                                    setTypeOfCollateralList,
                                    e,
                                    i
                                  )
                                }
                              >
                                {listTypeOfCollateral.map((column) => (
                                  <MenuItem
                                    key={column.id}
                                    value={column.value}
                                  >
                                    {column.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เงินต้น:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                InputProps={{ inputProps: { min: 0 } }}
                                id={`principalList${i}`}
                                name={`principalList${i}`}
                                value={principalList[i] || ""}
                                onChange={(e) =>
                                  onChangeMultipleValue(setPrincipalList, e, i)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ดอกเบี้ย:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                InputProps={{ inputProps: { min: 0 } }}
                                id={`interestList${i}`}
                                name={`interestList${i}`}
                                value={interestList[i] || ""}
                                disabled={!principalList[i]}
                                onChange={(e) =>
                                  onChangeMultipleValue(setInterestList, e, i)
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ยอดรวม:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                InputProps={{ inputProps: { min: 0 } }}
                                disabled
                                value={
                                  principalList[i]
                                    ? parseInt(principalList[i]) +
                                      (interestList[i]
                                        ? parseInt(interestList[i])
                                        : 0)
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                วันที่คำนวณของหนังสือบอกกล่าวทวงถาม :
                              </Typography>
                              <WatDatePicker
                                className="custom-date-picker"
                                value={dateCalInterestSinceList[i]}
                                onChange={(christDate, buddhistDate) =>
                                  onChangeDateValue(
                                    christDate,
                                    buddhistDate,
                                    i,
                                    setDateCalInterestSinceList
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                จำนวนวัน:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                InputProps={{ inputProps: { min: 0 } }}
                                id={`countDateList${i}`}
                                name={`countDateList${i}`}
                                value={countDateList[i] || ""}
                                onChange={(e) =>
                                  onChangeMultipleValue(setCountDateList, e, i)
                                }
                              />
                            </Grid>
                          </Grid>

                          {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              วันออกโนติส:
                            </Typography>
                            <WatDatePicker
                              className="custom-date-picker"
                              value={noticeDayOutList[i]}
                              onChange={(christDate, buddhistDate) =>
                                onChangeDateValue(
                                  christDate,
                                  buddhistDate,
                                  i,
                                  setNoticeDayOutList
                                )
                              }
                              dateFormat={"yyyy-MM-dd"}
                              displayFormat={"DD/MM/YYYY"}
                              clearable={true}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              วันรับโนติส:
                            </Typography>
                            <WatDatePicker
                              className="custom-date-picker"
                              value={noticeDayGetList[i]}
                              onChange={(christDate, buddhistDate) =>
                                onChangeDateValue(
                                  christDate,
                                  buddhistDate,
                                  i,
                                  setNoticeDayGetList
                                )
                              }
                              dateFormat={"yyyy-MM-dd"}
                              displayFormat={"DD/MM/YYYY"}
                              clearable={true}
                            />
                          </Grid>
                        </Grid> */}

                          {/* <Grid container sx={{ mb: 2 }}>
                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: "16px" }}>
                              สอบสวนโนติส:
                            </Typography>
                            <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              id={`noticeInvestigateList${i}`}
                              name={`noticeInvestigateList${i}`}
                              value={noticeInvestigateList[i] || ""}
                              onChange={(e) =>
                                onChangeMultipleValue(
                                  setNoticeInvestigateList,
                                  e,
                                  i
                                )
                              }
                            />
                          </Grid>
                        </Grid> */}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 1 ? (
            <Card sx={{ mb: 3 }}>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลคดี"
              />
              <CardContent sx={{ border: "0.5px solid" }}>
                <Grid spacing={2} container sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ศูนย์หนี้<span style={{ color: "red" }}> *</span>:
                    </Typography>
                    <Select
                      value={debtCenter || ""}
                      onChange={(e) => setDebtCenter(e.target.value)}
                      variant="outlined"
                      fullWidth
                      color="dark"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      {debtCenterList &&
                        debtCenterList.map((column) => (
                          <MenuItem
                            key={column.debt_center_id}
                            value={column.debt_center_name}
                          >
                            {column.debt_center_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      หน่วยงานสาขา<span style={{ color: "red" }}> *</span>:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      variant="outlined"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={branchAgency}
                      onChange={(e) => setBranchAgency(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      หน่วยงานเขต:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      variant="outlined"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={districtAgency}
                      onChange={(e) => setDistrictAgency(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      หน่วยงานภาค:
                    </Typography>
                    <TextField
                      type="number"
                      color="dark"
                      fullWidth
                      variant="outlined"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={sectorAgency}
                      onChange={(e) => setSectorAgency(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>ศาล:</Typography>
                    <Select
                      value={court} // Make sure court matches one of the options in courtList
                      onChange={(e) => setCourt(e.target.value)}
                      variant="outlined"
                      fullWidth
                      color="dark"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <MenuItem value="">Select a court</MenuItem>
                      {courtList &&
                        courtList.map((column) => (
                          <MenuItem
                            key={column.court_name}
                            value={column.court_name}
                          >
                            {column.court_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>วันฟ้อง:</Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={sueDay}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setSueDay,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันขึ้นศาล:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={courtDate}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setCourtDate,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เวลาขึ้นศาล:
                    </Typography>
                    <TimePicker
                      className="custom-date-picker"
                      value={courtTime ? moment(courtTime, "HH:mm:ss") : null}
                      onChange={(date) => handleTimeGoCourtChange(date)}
                      showSecond={false}
                      allowEmpty
                      disabledHours={disabledHours}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      จำนวนวันนัดขึ้นศาลเพิ่มเติม:
                    </Typography>
                    <TextField
                      type="number"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      value={countCourtEtc}
                      disabled={!courtDate}
                      onChange={(e) => updateCountCourtEtc(e.target.value)}
                    />
                  </Grid>
                </Grid>
                {countCourtEtc > 0 && (
                  <>
                    {[...Array(parseInt(countCourtEtc))].map((_, i) => (
                      <React.Fragment key={i}>
                        <hr
                          style={{ marginBottom: "3rem", marginTop: "3rem" }}
                        />
                        {/* วันขึ้นศาลครั้งที่2 นัดสืบพยาน/ฟังคำพิพากษา(อื่นๆ)เขียนเป็นตัวเล็กๆข้างล่าง */}
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              วันขึ้นศาลครั้งที่{i + 2}:
                            </Typography>
                            <WatDatePicker
                              className="custom-date-picker"
                              value={dateGoCourtEtc[i]}
                              onChange={(christDate, buddhistDate) =>
                                handleDateGoCourtEtcChangeByIndex(
                                  christDate,
                                  buddhistDate,
                                  i
                                )
                              }
                              dateFormat={"yyyy-MM-dd"}
                              displayFormat={"DD/MM/YYYY"}
                              clearable={true}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              เวลาขึ้นศาลครั้งที่{i + 2}:
                            </Typography>
                            <TimePicker
                              className="custom-date-picker"
                              value={
                                timeGoCourtEtc[i]
                                  ? moment(timeGoCourtEtc[i], "HH:mm:ss")
                                  : null
                              }
                              onChange={(date) =>
                                handleTimeGoCourtEtcChange(date, i)
                              }
                              showSecond={false}
                              allowEmpty
                              disabledHours={disabledHours}
                            />
                          </Grid>
                        </Grid>

                        {/* ใช้เป็นคนเดิม */}
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          {/* <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ผู้รับผิดชอบวันขึ้นศาลครั้งที่ {i + 2}:
                            </Typography>

                            <Select
                              id={`lawyerGoCourtEtc${i}`}
                              name={`lawyerGoCourtEtc${i}`}
                              value={lawyerGoCourtEtc[i] || ""} // Set the default value to an empty string ('') instead of null
                              fullWidth
                              color="dark"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              required
                            >
                              {userCompany
                                ? userCompany.map((item) => (
                                    <MenuItem
                                      key={item.id}
                                      value={item.id}
                                      name={item.fullname}
                                      id={item.id}
                                      onClick={(e) =>
                                        handleLawyerGoCourtEtcChange(e, i)
                                      }
                                    >
                                      {item.fullname}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </Grid> */}

                          <Grid item xs={12}>
                            <Typography sx={{ fontSize: "16px" }}>
                              รายละเอียดวันขึ้นศาลครั้งที่ {i + 2}:
                            </Typography>
                            <TextField
                              id={`detailGoCourtEtc${i}`}
                              name={`detailGoCourtEtc${i}`}
                              value={detailGoCourtEtc[i] || ""}
                              onChange={(e) =>
                                handleDetailGoCourtEtcChange(e, i)
                              }
                              type="text"
                              color="dark"
                              fullWidth
                              variant="outlined"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </>
                )}

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันที่ลงสัญญากู้:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={dateOfSigning}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setDateOfSigning,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันที่ปรากฏยอดค้าง:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={dateOfOutStanding}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setDateOfOutStanding,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid> */}
                </Grid>

                {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เงินต้นในสัญญา:
                    </Typography>
                    <TextField
                      type="number"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      value={principalContract}
                      onChange={(e) => setPrincipalContract(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ดอกเบี้ยในสัญญา:
                    </Typography>
                    <TextField
                      type="number"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      value={interestContract}
                      onChange={(e) => setInterestContract(e.target.value)}
                    />
                  </Grid>
                </Grid> */}

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ทรัพย์จำนอง:
                    </Typography>
                    <RadioGroup
                      row
                      value={mortgageProperty}
                      onChange={(e) =>
                        onChangeMortgageProperty(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="ไม่มี"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="มี"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>

                {mortgageProperty == 1 ? (
                  <>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>
                          ผู้จำนอง:
                        </Typography>
                        <Select
                          value={mortgagePropertyName}
                          onChange={(e) =>
                            setMortgagePropertyName(e.target.value)
                          }
                          variant="outlined"
                          fullWidth
                          color="dark"
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          {mortgagePropertyList &&
                            mortgagePropertyList.map((column) => (
                              <MenuItem key={column.value} value={column.name}>
                                {column.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>
                          วันที่จำนอง:
                        </Typography>
                        <WatDatePicker
                          className="custom-date-picker"
                          value={mortgagePropertyDay}
                          onChange={(christDate, buddhistDate) =>
                            handleDatePickerChange(
                              setMortgagePropertyDay,
                              christDate,
                              buddhistDate
                            )
                          }
                          dateFormat={"yyyy-MM-dd"}
                          displayFormat={"DD/MM/YYYY"}
                          clearable={true}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>
                          ประเภททรัพย์:
                        </Typography>
                        <Select
                          value={mortgagePropertyType}
                          onChange={(e) =>
                            setMortgagePropertyType(e.target.value)
                          }
                          variant="outlined"
                          fullWidth
                          color="dark"
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          {mortgagePropertyListSelect &&
                            mortgagePropertyListSelect.map((column) => (
                              <MenuItem value={column.config_value1}>
                                {column.config_value1}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                    </Grid>

                    {mortgagePropertyType == "ห้องชุด" ? (
                      <>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ห้องชุดเลขที่:
                            </Typography>
                            <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              value={mortgagePropertyRoomNumber}
                              onChange={(e) =>
                                setMortgagePropertyRoomNumber(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ทะเบียนอาคารชุดเลขที่:
                            </Typography>
                            <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              value={mortgagePropertyTowerNumber}
                              onChange={(e) =>
                                setMortgagePropertyTowerNumber(e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              ชื่ออาคาร:
                            </Typography>
                            <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              value={mortgagePropertyTowerName}
                              onChange={(e) =>
                                setMortgagePropertyTowerName(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              โฉนดเลขที่:
                            </Typography>
                            <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              value={mortgagePropertyDeed}
                              onChange={(e) =>
                                setMortgagePropertyDeed(e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                    {/* {mortgagePropertyType == "บ้าน"} */}
                    {mortgagePropertyType == "ที่ดิน" ? (
                      <>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              โฉนดเลขที่:
                            </Typography>
                            <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              value={mortgagePropertyDeed}
                              onChange={(e) =>
                                setMortgagePropertyDeed(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              เลขที่ที่ดิน:
                            </Typography>
                            <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              value={mortgagePropertyNumber}
                              onChange={(e) =>
                                setMortgagePropertyNumber(e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: "16px" }}>
                              หน้าสำรวจ:
                            </Typography>
                            <TextField
                              type="text"
                              color="dark"
                              fullWidth
                              sx={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                backgroundColor: "#ffffff",
                              }}
                              value={mortgagePropertyExplore}
                              onChange={(e) =>
                                setMortgagePropertyExplore(e.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : null}

                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>ตำบล:</Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                          value={mortgagePropertyTambon}
                          onChange={(e) =>
                            setMortgagePropertyTambon(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>
                          อำเภอ:
                        </Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                          value={mortgagePropertyAmphoe}
                          onChange={(e) =>
                            setMortgagePropertyAmphoe(e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontSize: "16px" }}>
                          จังหวัด:
                        </Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                          value={mortgagePropertyProvince}
                          onChange={(e) =>
                            setMortgagePropertyProvince(e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ทุนทรัพย์เงินต้น:
                    </Typography>
                    <TextField
                      type="number"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      value={principalCapital}
                      onChange={(e) => setPrincipalCapital(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ทุนทรัพย์ดอกเบี้ย:
                    </Typography>
                    <TextField
                      type="number"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      InputProps={{ inputProps: { min: 0 } }}
                      value={interestCapital}
                      onChange={(e) => setInterestCapital(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีดำเลขที่:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={blackCaseNumber}
                      onChange={(e) => setBlackCaseNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีดำปี:
                    </Typography>
                    <TextField
                      type="number"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={blackCaseYear}
                      inputProps={{ maxLength: 4 }}
                      onChange={(e) => setBlackCaseYear(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีแดงเลขที่:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={redCaseNumber}
                      onChange={(e) => setRedCaseNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีแดงปี:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={redCaseYear}
                      inputProps={{ maxLength: 4 }}
                      onChange={(e) => setRedCaseYear(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      พิพากษา(ฝ่ายเดียว):
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={judgmentUnilateral}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setJudgmentUnilateral,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      พิพากษา(ตามยอม):
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={judgmentAccordingToConsent}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setJudgmentAccordingToConsent,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันพิพากษา:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={judgmentDay}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setJudgmentDay,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันครบกำหนดอุทรณ์:
                    </Typography>

                    <WatDatePicker
                      className="custom-date-picker"
                      value={appealDueDate}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setAppealDueDate,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                </Grid>
                {/* ถ้ามีข้อมูลเนื่องจากจะหายdisable */}

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>ถอนฟ้อง:</Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={withdrawOneCase}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setWithdrawOneCase,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เนื่องจาก:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      disabled={!withdrawOneCase}
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={withdrawOneCaseDueTo}
                      onChange={(e) => setWithdrawOneCaseDueTo(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ mb: 2 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      รายละเอียด คำพิพากษา(โดยย่อ):
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={detailsJudgment}
                      onChange={(e) => setDetailsJudgment(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ส่งแฟ้มคืน:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={returnFile}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setReturnFile,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>เหตุผล:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      disabled={!returnFile}
                      value={returnFileReason}
                      onChange={(e) => setReturnFileReason(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ออกคำบังคับ:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={decree}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setDecree,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ออกหมายบังคับคดี:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={issueAWarranOfExecution}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setIssueAWarranOfExecution,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      นำคำบังคับ:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={bringTheRegulations}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setBringTheRegulations,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ออกหมายตั้งเจ้าพนักงานบังคับคดี:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={writOfExecutionDate}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setWritOfExecutionDate,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>หมายเหตุ:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      variant="outlined"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={note}
                      onChange={(event) => setNote(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : null}

          {/* 
          โนติส
คำฟ้อง
คำพิพากษา
หมายบังคับคดี
ค่าฤชาธรรมเนียมศาล
หนังสือคดีถึงที่สุด */}

          {activeStep == 2 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="รายการเอกสารในสำนวนคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "30px",
                          }}
                        >
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="center"
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredRows
                                  .slice(
                                    page * rowsPerPage1,
                                    page * rowsPerPage1 + rowsPerPage1
                                  )
                                  .map((row) => (
                                    <TableRow key={row.order}>
                                      {columns.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align="center"
                                        >
                                          {column.render
                                            ? column.render(row)
                                            : row[column.id]}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      /> */}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="อัพโหลดเอกสารเพิ่มเติม"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ประเภทเอกสาร:
                          </Typography>
                          <Select
                            value={typeDocsUpload}
                            onChange={(e) => setTypeDocsUpload(e.target.value)}
                            variant="outlined"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {typeDocsList &&
                              typeDocsList.map((column) => (
                                <MenuItem value={column.value}>
                                  {column.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เรื่อง:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={topicDocsUpload}
                            onChange={(e) => setTopicDocsUpload(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px", mb: 1 }}>
                            อัพโหลดเอกสาร:
                          </Typography>
                          <FileUploader
                            multiple={false}
                            handleChange={handleUploadChange}
                            name="fileUpload"
                            types={fileTypes}
                          />
                          <p style={{ marginTop: "1rem" }}>
                            {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                            {fileUpload ? (
                              <>
                                <IconButton onClick={handleClearFileUpload}>
                                  <DeleteForeverIcon color="error" />
                                </IconButton>
                                <IconButton onClick={handleSubmitFileUpload}>
                                  <UploadFileIcon color="success" />
                                </IconButton>
                              </>
                            ) : null}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 3 ? (
            <Card sx={{ mb: 3 }}>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลบริษัท"
              />
              <CardContent sx={{ border: "0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>เลขแฟ้ม:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={fileNumber}
                      onChange={(e) => setFileNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันที่รับเรื่อง<span style={{ color: "red" }}> *</span>:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={dateStart}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setDateStart,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันกำหนดส่ง:
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={deadline}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setDeadline,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีศาล:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={courtCode}
                      onChange={(e) => setCourtCode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีบริษัท:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={companyCode}
                      onChange={(e) => setCompanyCode(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item sm={6} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ผู้รับผิดชอบคดี<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <Select
                      value={chooseMemberCompany}
                      fullWidth
                      color="dark"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      required
                    >
                      {userCompany
                        ? userCompany.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              name={item.fullname}
                              id={item.id}
                              onClick={(event) => handleWorkByChange(event)}
                            >
                              {item.fullname}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      {/* สถานะคดีปัจจุบัน<span style={{ color: "red" }}> *</span>: */}
                    </Typography>
                    {/* <Select
                    value={caseStatus || ""}
                    onChange={(e) => setCaseStatus(e.target.value)}
                    variant="outlined"
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <MenuItem value="">โปรดระบุ</MenuItem>
                    <MenuItem value="อยู่ระหว่างลงพื้นที่ตรวจสอบ">
                      อยู่ระหว่างลงพื้นที่ตรวจสอบ
                    </MenuItem>
                    <MenuItem value="ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์ แต่ขอขยายระยะเวลาออกจากทรัพย์">
                      ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์
                      แต่ขอขยายระยะเวลาออกจากทรัพย์
                    </MenuItem>
                    <MenuItem value="อยู่ระหว่างเจรจาซื้อทรัพย์คืน">
                      อยู่ระหว่างเจรจาซื้อทรัพย์คืน
                    </MenuItem>
                    <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ออกหมายขับไล่">
                      อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย
                      ออกหมายขับไล่
                    </MenuItem>
                    <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการ  ออกหมายจับ">
                      อยู่ระหว่างสำนักงานทนายความดำเนินการ ออกหมายจับ
                    </MenuItem>
                    <MenuItem value="ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว">
                      ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว
                    </MenuItem>
                    <MenuItem value="ส่งมอบทรัพย์แล้ว">
                      ส่งมอบทรัพย์แล้ว
                    </MenuItem>
                    <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ประสานตำรวจจับกุม">
                      อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย
                      ประสานตำรวจจับกุม
                    </MenuItem>
                  </Select> */}
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      อื่นๆ(สำหรับโน้ต):
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      value={other}
                      onChange={(e) => setOther(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : null}

          {activeStep == 4 && executionCase ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ข้อมูลบังคับคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขคดีศาล:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={executionCode}
                        onChange={(e) => setExecutionCode(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่รับเรื่อง<span style={{ color: "red" }}> *</span>:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={executionDateStart}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setExecutionDateStart,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันกำหนดส่ง:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={executionDeadline}
                        // onChange={(christDate, buddhistDate) =>
                        //   handleDatePickerChange(
                        //     setExecutionDeadline,
                        //     christDate,
                        //     buddhistDate
                        //   )
                        // }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        สถานะทรัพย์:
                      </Typography>
                      <Select
                        value={propertyStatus}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        onChange={(e) => setPropertyStatus(e.target.value)}
                      >
                        <MenuItem value="อายัด">อายัด</MenuItem>
                        <MenuItem value="ยึดทรัพย์">ยึดทรัพย์</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ผู้รับผิดชอบคดี:
                      </Typography>
                      <Select
                        value={executionWorkBy}
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        required
                      >
                        {userCompany
                          ? userCompany.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                name={item.fullname}
                                id={item.id}
                                onClick={(event) =>
                                  handleWorkByLedChange(event)
                                }
                              >
                                {item.fullname}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 5 && executionCase ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="คู่สมรส"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบคู่สมรส:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={spouseCheckDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSpouseCheckDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ชื่อคู่สมรส:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={spouseName}
                        onChange={(e) => setSpouseName(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเลขบัตรประชาชนคู่สมรส:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={spouseIdCard}
                        // onChange={(e) => setSpouseIdCard(e.target.value)}
                        onChange={(e) =>
                          onChangeValueWithNumberAndSetLimit(
                            setSpouseIdCard,
                            13,
                            e
                          )
                        }
                        // onChangeValueWithNumberAndSetLimit
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={spouseNote}
                        onChange={(e) => setSpouseNote(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบรายได้:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={spouseIncomeDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSpouseIncomeDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={spouseStatus}
                        onChange={(e) => setSpouseStatus(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ประกันสังคม"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบรายได้:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={workPlaceIncomeDate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setWorkPlaceIncomeDate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={workPlaceStatusIncome}
                        onChange={(e) =>
                          setWorkPlaceStatusIncome(e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        สถานที่ทำงาน:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={workPlaceName}
                        onChange={(e) => setWorkPlaceName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เหตุที่อายัดไม่ได้(ขัดข้องการส่งเงิน):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={workPlaceDetailIncome}
                        onChange={(e) =>
                          setWorkPlaceDetailIncome(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งอายัดเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={workPlaceDateSendIncome}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setWorkPlaceDateSendIncome,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        จำนวนเงิน:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={workPlaceIncome}
                        onChange={(e) => setWorkPlaceIncome(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="กรมขนส่ง"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบกรมขนส่ง:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dltDateCheck}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDltDateCheck,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={dltStatus}
                        onChange={(e) => setDltStatus(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ / รายละเอียดทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dltDetail}
                        onChange={(e) => setDltDetail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="กรมที่ดิน"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ส่งตรวจสอบกรมที่ดิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dolDateCheckGround}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDolDateCheckGround,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>สถานะ:</Typography>
                      <Select
                        value={dolStatusObject}
                        onChange={(e) => setDolStatusObject(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <MenuItem value="พบ">พบ</MenuItem>
                        <MenuItem value="ไม่พบ">ไม่พบ</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ / รายละเอียดทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolDetail}
                        onChange={(e) => setDolDetail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        สถานะจำนองทรัพย์:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolStatus}
                        onChange={(e) => setDolStatus(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolFailureDetail}
                        onChange={(e) => setDolFailureDetail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ขายทอดตลาด"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เหตุที่ยึดไม่ได้ (ขัดข้องการส่งเงิน):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolFailureDetail1}
                        onChange={(e) => setDolFailureDetail1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ประกาศขายทอดตลาด:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolSell}
                        onChange={(e) => setDolSell(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        กรณีรับค่าใช้จ่ายเหลือคืน:
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={dolCostReturn}
                        onChange={(e) => setDolCostReturn(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินส่วนได้จากการขายทอดตลาด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={dolMoneySell}
                        onChange={(e) => setDolMoneySell(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่รับบัญชีรับจ่าย:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={dolDateGetSend}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDolDateGetSend,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}
            </>
          ) : null}

          {activeStep == 6 && executionCase ? (
            <>
              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีขายทอดตลาด"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาล:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostCourt}
                        onChange={(e) => setLedCostCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายเรียก:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostCall}
                        onChange={(e) => setLedCostCall(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายคำบังคับ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostForce}
                        onChange={(e) => setLedCostForce(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าบอกกล่าว:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostTell}
                        onChange={(e) => setLedCostTell(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่าย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostAll}
                        onChange={(e) => setLedCostAll(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าคำร้อง/รับรองเอกสาร:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostRequest}
                        onChange={(e) => setLedCostRequest(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายความ(งวดที่1):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostLawyer1}
                        onChange={(e) => setLedCostLawyer1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายความ(งวดที่2):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={ledCostLawyer2}
                        onChange={(e) => setLedCostLawyer2(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก งวด 1 (ฟ้อง):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigete1Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigete1Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(งวดที่1):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledBankPaid1Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedBankPaid1Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิก งวด 2 (ฟ้อง):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledInvestigete2Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedInvestigete2Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน(งวดที่2):
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={ledBankPaid2Date}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setLedBankPaid2Date,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}

              {/* <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีชั้นอุทธรณ์ / ฎีกา"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาลชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostCourt}
                        onChange={(e) => setCojCostCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostGo}
                        onChange={(e) => setCojCostGo(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่ายชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostSumAll}
                        onChange={(e) => setCojCostSumAll(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostLawyer}
                        onChange={(e) => setCojCostLawyer(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการดำเนินคดีชั้นอุทธรณ์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={cojCostAll}
                        onChange={(e) => setCojCostAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาลชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostCourt}
                        onChange={(e) => setSupCostCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมายชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostGo}
                        onChange={(e) => setSupCostGo(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าเหมาจ่ายชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostSumAll}
                        onChange={(e) => setSupCostSumAll(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างทนายชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostLawyer}
                        onChange={(e) => setSupCostLawyer(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการดำเนินคดีชั้นฎีกา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={supCostAll}
                        onChange={(e) => setSupCostAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกชั้นฎีกา:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={supDateReq}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSupDateReq,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={supDatePaid}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setSupDatePaid,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card> */}

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ค่าใช้จ่ายในการดำเนินคดีชั้นสืบทรัพย์"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจกรรมสิทธิ์ชั้นสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckRoleInvestigate}
                        onChange={(e) =>
                          setCostCheckRoleInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจยานพาหนะชั้นสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckCarInvestigate}
                        onChange={(e) =>
                          setCostCheckCarInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าใช้จ่ายอื่นๆชั้นสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costEtcInvestigate}
                        onChange={(e) => setCostEtcInvestigate(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าใช้จ่ายในการสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costAllInvestigate}
                        onChange={(e) => setCostAllInvestigate(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าจ้างสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckInvestigate}
                        onChange={(e) =>
                          setCostCheckInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าตรวจสอบระวางแผนที่:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckMapInvestigate}
                        onChange={(e) =>
                          setCostCheckMapInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมค่าจ้างสืบทรัพย์:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={costCheckAllInvestigate}
                        onChange={(e) =>
                          setCostCheckAllInvestigate(e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่เบิกสืบทรัพย์:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={costWithdrawInvestigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setCostWithdrawInvestigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        วันที่ธนาคารจ่ายเงิน:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={datePaidInvestigate}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setDatePaidInvestigate,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {propertyStatus == "อายัด" ? (
                <>
                  <Card sx={{ mb: 3 }}>
                    <CardHeader
                      sx={{ backgroundColor: "#181414" }}
                      title="ค่าใช้จ่าย (อายัด)"
                    />
                    <CardContent sx={{ border: "0.5px solid" }}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าตั้งเรื่องอายัด:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={sequestrationFee}
                            onChange={(e) =>
                              setSequestrationFee(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าเหมาจ่าย:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={lumpSum}
                            onChange={(e) => setLumpSum(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าจ้างอายัด:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledCost}
                            onChange={(e) => setLedCost(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รวม:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledAll}
                            onChange={(e) => setLedAll(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่เบิกอายัด:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledDate0}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setledDate0,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setLedDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าธรรมเนียม:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledFess}
                            onChange={(e) => setLedFess(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่านำหมาย:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledFollow}
                            onChange={(e) => setLedFollow(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่ารับรอง:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledSubmit}
                            onChange={(e) => setLedSubmit(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รวมค่าใช้จ่ายในการยื่นคำร้อง:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledCostWagesRequest}
                            onChange={(e) =>
                              setLedCostWagesRequest(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่เบิก:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledInvestigateDate}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setLedInvestigateDate,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledInvestigateDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setLedInvestigateDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าจ้างยื่นคำร้อง:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={ledWagesRequest}
                            onChange={(e) => setLedWagesRequest(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={ledWagesDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setLedWagesDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              ) : propertyStatus == "ยึดทรัพย์" ? (
                <>
                  <Card sx={{ mb: 3 }}>
                    <CardHeader
                      sx={{ backgroundColor: "#181414" }}
                      title="ค่าใช้จ่าย (ยึดทรัพย์)"
                    />
                    <CardContent sx={{ border: "0.5px solid" }}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าตั้งเรื่องบังคับคดีแทน(ยึดทรัพย์):
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={createLedCost}
                            onChange={(e) => setCreateLedCost(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าตั้งเรื่องยึดทรัพย์:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costCreateSequester}
                            onChange={(e) =>
                              setCostCreateSequester(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าเหมาจ่าย(ยึดทรัพย์):
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costAllSequester}
                            onChange={(e) =>
                              setCostAllSequester(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าจ้างยึดทรัพย์:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costSequester}
                            onChange={(e) => setCostSequester(e.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รวมค่าใช้จ่ายในการบังคับคดี(ยึดทรัพย์):
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costAllLedSequester}
                            onChange={(e) =>
                              setCostAllLedSequester(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่เบิกยึดทรัพย์:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={sequesterDate}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setSequesterDate,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={sequesterDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setSequesterDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าวางเงินค่าใช้จ่ายเพิ่ม:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={seizedWages}
                            onChange={(e) => setSeizedWages(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าตรวจรับรองบัญชีรับ-จ่าย:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costSequesterCheck}
                            onChange={(e) =>
                              setCostSequesterCheck(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ค่าใช้จ่ายอื่น ๆ:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costSequesterEtc}
                            onChange={(e) =>
                              setCostSequesterEtc(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            รวมค่าใช้จ่ายอื่น ๆ:
                          </Typography>
                          <TextField
                            type="number"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={costAllSequesterEtc}
                            onChange={(e) =>
                              setCostAllSequesterEtc(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่เบิก:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={dateSequesterInvestigate}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setDateSequesterInvestigate,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            วันที่ธนาคารจ่ายเงิน:
                          </Typography>
                          <WatDatePicker
                            className="custom-date-picker"
                            value={sequesterInvestigateDateBankPaid}
                            onChange={(christDate, buddhistDate) =>
                              handleDatePickerChange(
                                setSequesterInvestigateDateBankPaid,
                                christDate,
                                buddhistDate
                              )
                            }
                            dateFormat={"yyyy-MM-dd"}
                            displayFormat={"DD/MM/YYYY"}
                            clearable={true}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              ) : null}
            </>
          ) : null}

          {activeStep == 7 && executionCase ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="ผลคำพิพากษา"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  {/*  */}

                  {countLoan > 0 && (
                    <>
                      {[...Array(parseInt(countLoan))].map((_, i) => (
                        <React.Fragment key={i}>
                          <div class="separator">สินเชื่อลำดับที่ {i + 1}</div>
                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เลขสัญญา:
                              </Typography>
                              <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                id={`gsbNumberList${i}`}
                                name={`gsbNumberList${i}`}
                                value={gsbNumberList[i] || ""}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                พิพากษา(ยอดหนี้):
                              </Typography>
                              {/* <TextField
                                type="text"
                                color="dark"
                                fullWidth
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                // value={bankruptNumber1}
                                // onChange={(e) => setBankruptNumber1(e.target.value)}
                              /> */}
                              <WatDatePicker
                                className="custom-date-picker"
                                value={judgmentdebtLedList[i]}
                                onChange={(christDate, buddhistDate) =>
                                  onChangeDateValue(
                                    christDate,
                                    buddhistDate,
                                    i,
                                    setJudgmentdebtLedList
                                  )
                                }
                                dateFormat={"yyyy-MM-dd"}
                                displayFormat={"DD/MM/YYYY"}
                                clearable={true}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                เงินต้น:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                id={`principalList${i}`}
                                name={`principalList${i}`}
                                value={principalList[i] || ""}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ดอกเบี้ย:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                id={`interestList${i}`}
                                name={`interestList${i}`}
                                value={interestList[i] || ""}
                                disabled
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Typography sx={{ fontSize: "16px" }}>
                                ยอดรวม:
                              </Typography>
                              <TextField
                                type="number"
                                color="dark"
                                fullWidth
                                variant="outlined"
                                sx={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  backgroundColor: "#ffffff",
                                }}
                                disabled
                                value={
                                  principalList[i]
                                    ? parseInt(principalList[i]) +
                                      (interestList[i]
                                        ? parseInt(interestList[i])
                                        : 0)
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  )}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เลขสัญญา:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={loanType}
                        onChange={(e) => setLoanType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        พิพากษา(ยอดหนี้):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptNumber1}
                        onChange={(e) => setBankruptNumber1(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินต้น:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptMoney1}
                        onChange={(e) => setBankruptMoney1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ดอกเบี้ย(%):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptInterest1}
                        onChange={(e) => setBankruptInterest1(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        นับแต่วันที่:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={bankruptSince1}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setBankruptSince1,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเลขบัตรเงินสด:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptNumber2}
                        onChange={(e) => setBankruptNumber2(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        พิพากษา(ยอดหนี้):
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptJudge2}
                        onChange={(e) => setBankruptJudge2(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินต้น:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptMoney2}
                        onChange={(e) => setBankruptMoney2(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ดอกเบี้ย(%):
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptInterest2}
                        onChange={(e) => setBankruptInterest2(e.target.value)}
                      />
                    </Grid>
                  </Grid> */}

                  {/* <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        นับแต่วันที่:
                      </Typography>
                      <WatDatePicker
                        className="custom-date-picker"
                        value={bankruptSince2}
                        onChange={(christDate, buddhistDate) =>
                          handleDatePickerChange(
                            setBankruptSince2,
                            christDate,
                            buddhistDate
                          )
                        }
                        dateFormat={"yyyy-MM-dd"}
                        displayFormat={"DD/MM/YYYY"}
                        clearable={true}
                      />
                    </Grid>
                  </Grid> */}

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าขึ้นศาล:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptCourt}
                        onChange={(e) => setBankruptCourt(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่านำหมาย:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptFollow}
                        onChange={(e) => setBankruptFollow(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าทนายความ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptCostLawyer}
                        onChange={(e) => setBankruptCostLawyer(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าส่งคำบังคับ:
                      </Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={bankruptCostSend}
                        onChange={(e) => setBankruptCostSend(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "16px" }}>รวม:</Typography>
                      <TextField
                        type="number"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        disabled
                        value={bankruptAll}
                        onChange={(e) => setBankruptAll(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          {activeStep == 8 ? (
            <>
              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="รายการเอกสารในสำนวนคดี"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <Paper
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "30px",
                          }}
                        >
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {columns1.map((column) => (
                                    <TableCell
                                      key={column.id}
                                      align="center"
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredRows1
                                  .slice(
                                    page1 * rowsPerPage1,
                                    page1 * rowsPerPage1 + rowsPerPage1
                                  )
                                  .map((row) => (
                                    <TableRow key={row.order}>
                                      {columns1.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align="center"
                                        >
                                          {column.render
                                            ? column.render(row)
                                            : row[column.id]}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card sx={{ mb: 3 }}>
                <CardHeader
                  sx={{ backgroundColor: "#181414" }}
                  title="อัพโหลดเอกสารเพิ่มเติม"
                />
                <CardContent sx={{ border: "0.5px solid" }}>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            ประเภทเอกสาร:
                          </Typography>
                          <Select
                            value={typeDocsUpload}
                            onChange={(e) => setTypeDocsUpload(e.target.value)}
                            variant="outlined"
                            fullWidth
                            color="dark"
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            {typeDocsList &&
                              typeDocsList.map((column) => (
                                <MenuItem value={column.value}>
                                  {column.value}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px" }}>
                            เรื่อง:
                          </Typography>
                          <TextField
                            type="text"
                            color="dark"
                            fullWidth
                            sx={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: "#ffffff",
                            }}
                            value={topicDocsUpload}
                            onChange={(e) => setTopicDocsUpload(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ fontSize: "16px", mb: 1 }}>
                            อัพโหลดเอกสาร:
                          </Typography>
                          <FileUploader
                            multiple={false}
                            handleChange={handleUploadChange}
                            name="fileUpload"
                            types={fileTypes}
                          />
                          <p style={{ marginTop: "1rem" }}>
                            {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                            {fileUpload ? (
                              <>
                                <IconButton onClick={handleClearFileUpload}>
                                  <DeleteForeverIcon color="error" />
                                </IconButton>
                                <IconButton onClick={handleSubmitFileUpload}>
                                  <UploadFileIcon color="success" />
                                </IconButton>
                              </>
                            ) : null}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          ) : null}

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleBack}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
                disabled={!type}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>

      <Dialog open={openFailure} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <ErrorIcon sx={{ color: "#ff0c34", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#ff0c34",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลไม่สำเร็จกรุณาลองใหม่อีกครั้ง
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#ff0c34",
              fontSize: 16,
            }}
          >
            กรุณากรอก{" "}
            {textFail.map((item, index) =>
              index == textFail.length - 1 ? (
                <span>{item}</span>
              ) : (
                <span>{item}, </span>
              )
            )}
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseFailure}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openSuccess} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <CheckCircleOutlineIcon sx={{ color: "#52c41a", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h2"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#1f1f1f",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลสำเร็จ!
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleCloseSuccess}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={openSetNotifition} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
              ตั้งค่าการแจ้งเตือน
            </Typography>
            <CalendarMonthIcon />
          </Box>
          <Typography sx={{ marginTop: 3 }} textAlign="center">
            ท่านต้องการอัปเดตวันสำคัญลงใน Google Calendar ใช่หรือไม่?
          </Typography>
          <Typography sx={{ marginTop: 2, marginBottom: 3 }} textAlign="center">
            (วันขึ้นศาล หรืออื่นๆ)
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseSetNotification}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                // onClick={updateStatusFinish}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openCloseCase}
        fullWidth={true}
        maxWidth={"xs"}
        PaperProps={{ style: { minHeight: "70vh" } }}
      >
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            ยืนยันการปิดคดี
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            โปรดตรวจสอบรายละเอียดให้ครบถ้วนก่อนทำการปิดคดี
            เมื่อปิดคดีแล้วจะไม่สามารถแก้ไขได้
          </Typography>
          <Grid container spacing={2} sx={{ paddingBottom: 3 }}>
            <Grid item xs={12}>
              <WatDatePicker
                className="custom-date-picker"
                value={closeDate}
                onChange={(christDate, buddhistDate) =>
                  handleDatePickerChange(setCloseDate, christDate, buddhistDate)
                }
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD/MM/YYYY"}
                clearable={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseSetOpenCloseCase}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={updateStatusFinish}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openUploadFileExecution} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            อัพโหลดเอกสาร{typeSelected}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <FileUploader
                  multiple={false}
                  handleChange={handleUploadChange}
                  name="fileUpload"
                  types={fileTypes}
                />
                <p style={{ marginTop: "1rem" }}>
                  {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseOpenUploadFileExecution}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleClickUploadFileExecution}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openUploadFile} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            อัพโหลดเอกสาร{typeSelected}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <FileUploader
                  multiple={false}
                  handleChange={handleUploadChange}
                  name="fileUpload"
                  types={fileTypes}
                />
                <p style={{ marginTop: "1rem" }}>
                  {fileUpload ? `ชื่อไฟล์: ${fileUpload.name}` : ""}
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseOpenUploadFile}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleClickUploadFile}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* <Dialog
        open={openWarningDelete}
        fullWidth={true}
        maxWidth={"xs"}
        PaperProps={{ style: { minHeight: "70vh" } }}
      >
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            ยืนยันการลบไฟล์
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            โปรดตรวจสอบไฟล์ก่อนทำการลบ เมื่อลบแล้วไฟล์จะหายไปจากฐานข้อมูล
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseSetOpenWarningDelete}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={deleteFile}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog> */}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsFormLoan;
