import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  Modal,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "function/useAuth";
import config from "../../../config";
import api from "../../../api";

const DeptCenter = () => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [tel, setTel] = useState("");
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [user, setUser] = useState();
  const [debtCenter, setDebtCenter] = useState();
  const [debtCenterTel, setDebtCenterTel] = useState("");

  useAuth();
  const handleOpen = () => {
    setOpenCreate(true);
  };

  const handleClose = () => {
    setOpenCreate(false);
  };

  const handleChange = (event) => {
    setHospital(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .post(`${config.mainAPI}/debt-center-create`, {
        name: debtCenter,
        tel: debtCenterTel,
        user: user.username,
      })
      .then(function (response) {
        getData();
        setOpenCreate(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmitEdit = () => {
    api
      .post(`${config.mainAPI}/debt-center-update`, {
        name: debtCenter,
        tel: debtCenterTel,
        user: user.username,
        id: id,
      })
      .then(function (response) {
        getData();
        setOpenEdit(false);
      })
      .catch(function (error) {
        console.error(error);
        setOpenEdit(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Edit
  const handleOpenEdit = (row) => {
    setId(row.id);
    setDebtCenter(row.name);
    setTel(row.tel);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setId("");
    setName("");
    setDebtCenter("");
    setTel("");
    setOpenEdit(false);
  };

  // Delete
  const handleOpenDelete = (row) => {
    setId(row.id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setId(null);
    setOpenDelete(false);
  };

  const handleSubmitDelete = () => {
    const id = id;
    axios
      .delete(`${config.mainAPI}/users/${id}`)
      .then((response) => {
        setOpenDelete(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUser(userData);
    getData();
  }, []);

  const getData = () => {
    const payload = {
      value: "DEBT_CENTER",
    };
    api
      .post(`${config.mainAPI}/getConfigValue`, { ...payload })
      .then((response) => {
        const value = response?.data.data;
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              item.config_value1,
              item.config_value1_desc,
              item.config_id
            )
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 30 },
    { id: "name", label: "ชื่อศูนย์หนี้", minWidth: 30 },
    { id: "tel", label: "เบอร์ติดต่อ", minWidth: 30 },
    {
      id: "mange",
      label: "",
      minWidth: 50,
      render: (row) => (
        <>
          <IconButton aria-label="edit" onClick={() => handleOpenEdit(row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleOpenDelete(row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  function createData(order, name, tel, id) {
    return { order, name, tel, id };
  }

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  return (
    <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
      <Typography
        variant="h3"
        sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
      >
        รายการศูนย์หนี้
      </Typography>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          float: "right",
          marginRight: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          color: "#000",
          borderColor: "#000",
        }}
        color="success"
        startIcon={<AddCircleIcon />}
      >
        เพิ่มศูนย์หนี้
      </Button>

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.order}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="center">
                      {column.render ? column.render(row) : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        open={openCreate}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant="h3"
            sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
          >
            เพิ่มศูนย์หนี้
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container sx={{ mb: 1, mt: 3 }}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "16px" }}>
                  ชื่อศูนย์หนี้<span style={{ color: "red" }}> *</span>:
                </Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(event) => setDebtCenter(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: "16px" }}>
                  เบอร์ติดต่อ:<span style={{ color: "red" }}> *</span>:
                </Typography>
                <TextField
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(event) => setDebtCenterTel(event.target.value)}
                />
              </Grid>
            </Grid>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ marginLeft: 3 }}
                type="submit"
                disabled={!debtCenter || !debtCenterTel}
                onClick={handleSubmit}
              >
                บันทึก
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* แก้ไขผู้ใช้งาน */}
      <Dialog
        open={openEdit}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#181414" }}
        >
          <Typography variant="h3" sx={{ fontWeight: 500, color: "#fff" }}>
            แก้ไขศูนย์หนี้
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: "16px" }}>
                ชื่อศูนย์หนี้<span style={{ color: "red" }}> *</span>:
              </Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                variant="outlined"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                defaultValue={debtCenter}
                onChange={(event) => setDebtCenter(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: "16px" }}>
                เบอร์ติดต่อ:<span style={{ color: "red" }}> *</span>:
              </Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                variant="outlined"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                defaultValue={tel}
                onChange={(event) => setDebtCenterTel(event.target.value)}
              />
            </Grid>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseEdit}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ marginLeft: 3 }}
                onClick={handleSubmitEdit}
                disabled={!debtCenter || !debtCenterTel}
              >
                อัปเดต
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* ลบข้อมูลผู้ใช้ */}
      <Dialog
        open={openDelete}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box textAlign="center">
              <ErrorIcon sx={{ color: "#ff0c34", fontSize: 180 }} />
            </Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#ff0c34",
              }}
            >
              ยืนยันการลบข้อมูล
            </Typography>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ borderRadius: 100 }}
                onClick={handleCloseDelete}
              >
                ย้อนกลับ
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ marginLeft: 3, borderRadius: 100 }}
                onClick={handleSubmitDelete}
              >
                ลบ
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default DeptCenter;
